import React from "react";
import {Link} from 'react-router-dom';
import {Field, reduxForm} from 'redux-form';
import {renderField, renderNumber, SelectField} from '../Utils/renderField';
import {combine, validate, validatorFromFunction, validators} from 'validate-redux-form';
import {
    AGENT_COORDINATOR,
    MANAGING,
    STAFF,
    MCA_FINANCE,
    OFFICE_MANAGER,
    PRODUCTIVITY_COACH,
    USER_ROLES_LIST
} from "../../../utility/constants";
import _ from "lodash";

const InvitesForm = (props) => {

    const {option, handleSubmit, cancelInvite, setPermissions, is_view, is_update, item, item2, setItem, me, goBack} = props;

    const description = (role) => {
        let text = ''
        if (role === MANAGING) text = 'Can see all the offices and that can access everything.'
        else if (role === OFFICE_MANAGER) text = 'Can do everything only in a specific office.'
        else if (role === AGENT_COORDINATOR) text = 'Only has access to Agents + Home.'
        else if (role === MCA_FINANCE) text = 'Only had access to Wallet + Stats + Home.'
        else if (role === PRODUCTIVITY_COACH) text = 'Has access Stats – (only to the agents performance) + Home.'
        return text
    }
    let _USER_ROLES_LIST = USER_ROLES_LIST

    if (me && me.roles !== MANAGING) {
        _USER_ROLES_LIST = _.filter(USER_ROLES_LIST, function(o) { return o.value !== MANAGING; })
    }

    return (

        <form name="invitesForm" className="my-5" style={{minHeight: "28rem"}} onSubmit={handleSubmit}>
            <div className="row w-75 px-5 mx-auto pt-2">
                <div className="form-group has-feedback col-md-6">
                    <label htmlFor="first_name">First name</label>
                    <Field
                        name="first_name"
                        label="First name"
                        component={renderField}
                        type="text"
                        className="form-control"
                        disabled={(is_view || item2.user)}
                    />
                </div>
                <div className="form-group has-feedback col-md-6">
                    <label htmlFor="last_name">Last Name</label>
                    <Field
                        name="last_name"
                        label="Last Name"
                        component={renderField}
                        className="form-control"
                        disabled={(is_view || item2.user)}
                    />
                </div>
            </div>
            <div className="row w-75 px-5 mx-auto">
                <div className="form-group has-feedback col-md-6">
                    <label htmlFor="email">Email</label>
                    <Field
                        name="email"
                        label="email"
                        component={renderField}
                        type="email"
                        className="form-control"
                        disabled={(is_view || item2.user)}
                    />
                </div>
                <div className="form-group has-feedback col-md-6">
                    <label htmlFor="phone">Mobile Number</label>
                    <Field
                        numberFormat={"###-###-####"}
                        name="phone"
                        component={renderNumber}
                        className="form-control"
                        disabled={(is_view || item2.user)}
                    />
                </div>

            </div>

            <div className="row w-75 px-5 mx-auto mb-4">
                <div className="form-group has-feedback col-md-6">
                    <label htmlFor="user_role">Roles</label>
                    <Field
                        name="roles"
                        label="user role"
                        isMulti={false}
                        options={_USER_ROLES_LIST}
                        component={SelectField}
                        customClass="form-control-gray"
                        className="form-control"
                        disabled={is_view}
                    />
                    <p className="mb-0 text-12 mt-3">
                        {description(props.roles)}
                    </p>
                </div>
                {
                    (props.roles !== MANAGING) &&
                    <div className="form-group has-feedback col-md-6">
                        <label htmlFor="brokerage_permission">Offices</label>
                        <Field
                            name="brokerage_permission"
                            label="brokerage permission"
                            isMulti={true}
                            options={option.results ? option.results : []}
                            set_value={setPermissions}
                            component={SelectField}
                            item={item}
                            setItem={setItem}
                            customClass="form-control-gray"
                            className="form-control"
                            disabled={is_view}
                        />
                    </div>
                }
                {props.roles === STAFF && (
                    <div className="form-group has-feedback col-md-6">
                        <label htmlFor="staff_name">Add Role</label>
                        <Field
                            name="staff_name"
                            label="Add Role"
                            component={renderField}
                            type="text"
                            className="form-control"
                            disabled={is_view || item2.user}
                        />
                    </div>
                )}
            </div>
            <div className="row mx-auto pt-5 justify-content-center">
                {/* <Link to="/users" className={`btn button-03 mx-3 pt-1 col-2`}>
                    {is_view ? "Back" : "Cancel"}
                </Link> */}
                <button type="button" className="btn button-03 mx-3 pt-1 col-2" onClick={goBack}>Back</button>
                {is_view && !item2.user &&
                <button
                    type="button"
                    className="btn button-01 mx-3 py-0 col-2"
                    onClick={() => {
                        cancelInvite(item2.id);
                    }}
                >
                    Cancel Invite
                </button>

                }
                {!is_view && !is_update &&
                <button type="submit" className="btn btn-primary mx-3 py-0 col-2">
                    Send
                </button>
                }
                {!is_view && is_update &&
                <button type="submit" className="btn btn-primary mx-3 py-0 col-2">
                    Update
                </button>
                }
            </div>
        </form>
    );
};

export const minValue = (min, {value}) => validatorFromFunction(() => {
    return value && value < min ? `Must be at least ${min}` : undefined;
});


const numberPhone = (number) => validatorFromFunction(() => {
    return number.length === 10;
});


export default reduxForm({
    form: 'invitesForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            email: validators.exists()('This field is required'),
            first_name: validators.exists()('This field is required'),
            last_name: validators.exists()('This field is required'),
            roles: validators.exists()('This field is required'),
            // brokerage_permission: validators.exists()('This field is required'),
            phone: combine(
                validators.exists()('This field is required'),
                numberPhone(data.phone)()('Invalid phone number'),
            ),
        });
    },
})(InvitesForm);

