import React, { Component } from "react";

class Verification extends Component {
    componentWillMount = () => {
        const { match } = this.props;
        const token = match.params.token;
        this.props.verifyInviteQualifying(token);
    };

    render() {
        return null;
    }
}
export default Verification;
