import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/influencer/profile'
import InfluencerProfile from "./InfluencerProfile";

const ms2p = (state) => {
    return{
        ...state.influencerProfile,
    };
};

const md2p = {
    ...actions
};

export default connect(ms2p, md2p)(InfluencerProfile);