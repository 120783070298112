import React from "react";
import { Field, reduxForm } from "redux-form";
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from "validate-redux-form";
import { renderField } from "../../Utils/renderField";

const ResetForm = (props) => {
    const { handleSubmit, goBack, item, isProfile = false } = props;
    return (
        <form name="changedPasseForm" className={isProfile? "w-100":"w-50 mx-auto"} onSubmit={handleSubmit}>
            {!isProfile &&
            <div className="row w-100 mt-3">
                <div className="form-group has-feedback col-8 mx-auto">
                    {item && item.brokerage_information && (
                        <React.Fragment>
                            <label htmlFor="brokerage_license" className="my-0">Brokerage Firm Name</label>
                            <p className="mt-0 mb-2 text-12">{item && item.brokerage_information !== undefined && item.brokerage_information.firm_name}</p>
                        </React.Fragment>
                    )}
                    <label htmlFor="broker" className="my-0">Agent Full Name</label>
                    <p className="mt-0 mb-2 text-12">{item && item.first_name} {item && item.last_name} </p>
                </div>
            </div>
            }
            <div className="row w-100 mx-auto">
                <div className="form-group has-feedback col-lg-9 col-sm-12 mx-auto">
                    <label htmlFor="password">
                        New Password
                    </label>
                    <Field
                        name="password"
                        label="Password"
                        component={renderField}
                        type="password"
                        className="form-control"
                    />
                </div>
            </div>
            <div className="row w-100 mx-auto">
                <div className="form-group has-feedback col-lg-9 col-sm-12 mx-auto">
                    <label htmlFor="confirmPassword">
                        Confirm New Password
                    </label>
                    <Field
                        name="confirmPassword"
                        label="NewPassword"
                        component={renderField}
                        type="password"
                        className="form-control"
                    />
                </div>
            </div>
            <div className="row w-100 py-4 justify-content-center mx-auto">
                {!isProfile &&
                <button type="button" className="btn button-03 col-3 mx-2" onClick={goBack}>
                    Back
                </button>
                }
                <button type="submit" className={`btn btn-primary ${isProfile?'col-lg-6': 'col-lg-3'} col-sm-6 mx-2`}>
                    Change password
                </button>
            </div>
        </form>
    );
};

export const matchPassword = (pass, confirm) =>
    validatorFromFunction((value) => {
        return pass === confirm;
    });

export const minValue = (value) => validatorFromFunction(() => {
    if (value.length >= 6) {
        return value.length >= 'Must be at least 6 characters';
    }
    // return value.length >='Must be at least 6 characters'
});

export default reduxForm({
    form: "changedPassAgent", // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            password: validators.exists()("This field is required"),
            confirmPassword: combine(
                validators.exists()('This field is required'),
                matchPassword(data.password, data.confirmPassword)()('The password don\'t match'),
            ),
        });
    },
})(ResetForm);
