import React, {useEffect} from 'react';
import './agentRegister.css';
import { connect } from "react-redux";
import {Field, reduxForm, formValueSelector} from 'redux-form';
import {renderField, SelectField} from '../Utils/renderField';

const validate = (value, props) => {
    const { officeValue } = props;
    let error = {}
    if(!value.office) {
        error.office = "This field is required"
    }
    if(officeValue === 'not_listed') {
        if(!value.open_market_office) {
            error.open_market_office = "This field is required"
        }
    }

    return error
}

const Form = (props) => {
    //  State
    const { offices, officeValue } = props;
    //  Bind
    const {handleSubmit} = props;

    useEffect(() => {
        if (document.getElementById('AGENT-REGISTER')) {
            document.getElementById('AGENT-REGISTER').scrollIntoView({ behavior: 'smooth' });
        }
    }, [])

    return (
        <div className="row flex-1 justify-content-center pt-3">
            <form
                name="agentRegisterForm"
                className="w-100 col-xl-10 col-lg-11 col-md-11 col-12 d-flex flex-column"
                onSubmit={handleSubmit}
            >
                <div className="flex-1 d-flex flex-column justify-content-between">
                    <div className="row">
                        <div className="form-group has-feedback col-12 mb-md-5 mb-5">
                            <label htmlFor="first_name" className="mb-5 d-flex flex-column">
                                <strong className="question2">Office Name</strong>
                                <span className='agent-register-label-subtitle mt-3'>Please choose your office name from the list below. If your office is not listed, please select 'Not Listed' and then you can add it manually.</span>
                            </label>
                            <div className='row d-flex flex-column align-items-center justify-content-center'>
                                <div className="form-group has-feedback col-xl-4 col-lg-5 col-md-6 col-12 mb-md-5 mb-5">
                                    <Field
                                        name="office"
                                        label="user role"
                                        isMulti={false}
                                        options={offices}
                                        component={SelectField}
                                        customClass="form-control-gray"
                                        className="form-control"
                                    />
                                </div>
                                {officeValue === 'not_listed' && (
                                    <div className="form-group has-feedback col-xl-4 col-lg-5 col-md-6 col-12 mb-md-5 mb-5">
                                        <label htmlFor="open_market_office" className="mb-0">
                                            <strong className="question2">Add your Office Name</strong>
                                        </label>
                                        <Field
                                            name="open_market_office"
                                            label="First Name"
                                            component={renderField}
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="buttons-box col-12 justify-content-center mx-auto py-md-3 py-4">
                            <button
                                type="submit"
                                className="btn agent-register-button col-xl-4 col-lg-5 align-self-center"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    )

}

const AgentOfficeForm = reduxForm({
    form: 'agentRegisterForm', // a unique identifier for this form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
})(Form);

const selector = formValueSelector('agentRegisterForm');
const mstp = state => {
    const officeValue = selector(state, 'office');
    return {
        officeValue
    };
};

export default connect(mstp, null)(AgentOfficeForm);
