import './agentRegister.css';
import React, { Component } from "react";
import LoadMask from "../Utils/LoadMask/LoadMask";
import { RegisterSuccess, StepProgressBar } from './AgentRegisterComponents';
import AgentInfoForm from './AgentInfoForm';
import AgentOfficeForm from './AgentOfficeForm';
import ConfirmEmailForm from './ConfirmEmailForm';
import AgentPasswordForm from './AgentPasswordForm';
import AgentWorkingAreaForm from './AgentWorkingAreaForm';


class AgentRegister extends Component {
    constructor(props) {
        super(props);
        this.state = {
            origin: null,
        };
    }

    componentDidMount() {
        this.props.resetInitialData()
        this.props.getOffices()

        document.title = "Agent Sign Up"
        //  Capture query params from URL, ?source=fb
        const query = new URLSearchParams(this.props.location.search);
        console.log("SERACH: ", query.get('origin'));
        this.setState({ origin: query.get('origin') });
    }

    render() {
        //  State
        const { loader, success, step, pendingValidation, offices, stepBack, code, email_confirmed } = this.props;
        const { origin } = this.state;
        //  Bind
        const { onSubmitStep, goBack, resendCodeEmail, validateCode, signUp, setWorkingArea } = this.props;

        return (
            <div className="body-img">
                <div className="blue-gradient-bg d-flex align-items-center">
                    <div className='agent-register-container'>
                        <div
                            className="agent-register-wrapper"
                        >
                            <div
                                id='AGENT-REGISTER'
                                className={`card agent-register-card col-lg-10 col-sm-11`}
                            >
                                <div className="agent-register-card-body">
                                    <div className="row flex-1">
                                        <div className="col-lg-4 col-md-3 col-12">
                                            <div className="agent-register-title-page-container">
                                                <div className="agent-register-titles-container mt-1 mt-lg-3">
                                                    <span className='agent-register-title'>AGENT</span>
                                                    <span className='agent-register-subtitle'>Sign Up</span>
                                                </div>
                                                <div className="agent-register-logo-container mt-4 mt-md-0">
                                                    <img
                                                        src={require("../../../../assets/img/logo-01.png")}
                                                        alt={"showIt logo"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-9 col-12">
                                            <div className="agent-register-form-container">
                                                <LoadMask loading={loader} light>
                                                    <div className="mb-2">
                                                        <div className="d-flex flex-row align-items-center mb-2">
                                                            <div className='agent-register-back-button-container'>
                                                                {!success && step > 0 && step <= 4 && (
                                                                    <div className='agent-register-back-button' onClick={() => goBack()}>
                                                                        <img
                                                                            src={require("../../../../assets/img/chevron-left-solid.svg")}
                                                                            alt="back"
                                                                            />
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <span className='agent-register-form-title'>Create an Account</span>
                                                        </div>
                                                        {!success && step <= 4 && (
                                                            <StepProgressBar progress={step} />
                                                        )}
                                                    </div>

                                                    {step === 0 && (
                                                        <AgentInfoForm
                                                            onSubmit={() => onSubmitStep()}
                                                        />
                                                    )}
                                                    {step === 1 && (
                                                        <AgentOfficeForm
                                                            offices={offices}
                                                            onSubmit={() => onSubmitStep()}
                                                        />
                                                    )}
                                                    {step === 2 && (
                                                        <ConfirmEmailForm
                                                            stepBack={stepBack}
                                                            resendCode={resendCodeEmail}
                                                            code={code}
                                                            emailConfirmed={email_confirmed}
                                                            onSubmit={() => onSubmitStep()}
                                                            validateCode={(value) => {
                                                                validateCode(value)
                                                            }}
                                                        />
                                                    )}
                                                    {step === 3 && (
                                                        <AgentWorkingAreaForm
                                                            setWorkingArea={setWorkingArea}
                                                            onSubmit={() => onSubmitStep()}
                                                        />
                                                    )}
                                                    {step === 4 && (
                                                        <AgentPasswordForm
                                                            onSubmit={() => signUp(origin)}
                                                        />
                                                    )}
                                                    {step === 5 && success && (
                                                        <RegisterSuccess
                                                            pendingValidation={pendingValidation}
                                                        />
                                                    )}
                                                </LoadMask>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default AgentRegister;
