import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";
import {NotificationManager} from "react-notifications";
import {api} from "api";
import {push} from "react-router-redux";
import {initialize as initializeForm} from "redux-form";

// ------------------------------------
// BASE ACTION
// ------------------------------------
const baseReducer = createReducer(
    "broker",
    "broker-invite",
    "invitesForm",
    "/users",
);

// ------------------------------------
// CONSTANTS REDUX
// ------------------------------------

const SET_OPTION_BROKERAGE = 'SET_OPTION_BROKERAGE';
const SET_SELECT_PERMISSIONS = 'SET_SELECT_PERMISSIONS';
const SET_ITEM_2 = 'SET_ITEM_2_INVITE';
const SET_REAL_ID = 'SET_REAL_ID';

// ------------------------------------
// Pure Actions
// ------------------------------------

const setOptions = option => ({
    type: SET_OPTION_BROKERAGE,
    option,
});

const setRealId = real_id => ({
    type: SET_REAL_ID,
    real_id,
});

const setItem2 = item2 => ({
    type: SET_ITEM_2,
    item2,
});


// ------------------------------------
// Actions
// ------------------------------------

const getBrokerages = () => (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));
    api.get('brokerage/option_select')
        .then(response => {
            dispatch(setOptions(response));
        })
        .catch(() => {
        })
        .finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        });
};

const getInvites = () => (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));
    api.get('broker-user/invites')
        .then(response => {
            // dispatch(setOptions(response));
        })
        .catch(() => {
            NotificationManager.error('Error in transaction', 'ERROR', 0);
        })
        .finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        });
};

const customRead = id => (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));
    api.get(`broker-invite/${id}`, {'id': id})
        .then((response) => {
            if (response.user) {
                response.roles = response.user.roles.value
            }
            dispatch(initializeForm('invitesForm', response));
            dispatch(setPermissions(response["broker_user"]));
            dispatch(setItem2(response));
            dispatch(baseReducer.actions.setItem(response["broker_user"]));
        })
        .catch(() => {
            NotificationManager.error('Error in transaction', 'ERROR', 0);
        })
        .finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        });
};

const cancelInvite = id => (dispatch, getStore) => {
    const item2 = getStore().broker.item2
    dispatch(baseReducer.actions.setLoader(true));
    api.post(`broker-invite/cancel_invite`, {'id': id})
        .then((response) => {
            NotificationManager.success('Invite canceled successfully', 'SUCCESS', 0);
            dispatch(push('/users'));
        })
        .catch(() => {
            NotificationManager.error('Failed to cancel invite', 'ERROR', 0);
        })
        .finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        });
};

const invites = (goBack) => (dispatch, getStore) => {
    //create invite
    const dataForm = getStore().form.invitesForm.values;
    //validate if contains offices permission
    const resource = getStore().broker;
    let data = dataForm
    data.permissions = resource.permissions
    if (data.roles > 1 && resource.permissions.length < 1) {
        NotificationManager.error('None Offices selected', 'Error', 2000)
    } else {
        if (data.roles === 1) {
            delete data.permissions
        }
         dispatch(baseReducer.actions.setLoader(true));
        api.post('broker-invite', data)
            .then(() => {
                NotificationManager.success('Invitation sent', 'Success', 2000);
                dispatch(baseReducer.actions.setItem({}));
                // dispatch(push('/users'));
                goBack()
            })
            .catch((error) => {
                if (error.detail) {
                    NotificationManager.error(`${error.detail}`, 'Error', 5000);
                } else {
                    NotificationManager.error('Transaction error', 'Error', 2500);
                }
            })
            .finally(() => {
                dispatch(baseReducer.actions.setLoader(false));
            });
    }
};

const updatePermission = (goBack) => (dispatch, getStore) => {

    const dataForm = getStore().form.invitesForm.values;
    const resource = getStore().broker;
    const item2 = getStore().broker.item2;
    //update
    let data = dataForm
    data.permissions = resource.permissions
    if (data.roles > 1 && resource.permissions.length < 1) {
        NotificationManager.error('None Offices selected', 'Error', 2000)
    } else {
        if (data.roles === 1) {
            delete data.permissions
        }
        dispatch(baseReducer.actions.setLoader(true));
        api.put(`broker-invite/${item2.id}`, data)
            .then((response) => {
                NotificationManager.success(`${data.roles !== 1 ? 'Permission' : 'Role'} updated`, 'Success', 2000);
                dispatch(baseReducer.actions.setItem({}));
                dispatch(setItem2({}));
                dispatch(setRealId(0));
                // dispatch(push('/users'));
                goBack()
            })
            .catch(() => {
                if (error.detail) {
                    NotificationManager.error(`${error.detail}`, 'Error', 4000);
                } else {
                    NotificationManager.error('Update invite failed, try again later', 'Error', 2000);
                }
            })
            .finally(() => {
                dispatch(baseReducer.actions.setLoader(false));
            });
    }
};


const setPermissions = (permissions) => (dispatch) => {
    dispatch({
        type: SET_SELECT_PERMISSIONS,
        permissions,
    });
};

export const actions = {
    getBrokerages,
    invites,
    setPermissions,
    getInvites,
    customRead,
    setRealId,
    cancelInvite,
    setItem2,
    updatePermission,
    ...baseReducer.actions,
};
export const reducers = {
    ...baseReducer.reducers,
    [SET_OPTION_BROKERAGE]: (state, {option}) => {
        return {
            ...state,
            option,
        };
    },
    [SET_SELECT_PERMISSIONS]: (state, {permissions}) => {
        return {
            ...state,
            permissions,
        };
    },
    [SET_REAL_ID]: (state, {real_id}) => {
        return {
            ...state,
            real_id,
        };
    },
    [SET_ITEM_2]: (state, {item2}) => {
        return {
            ...state,
            item2,
        };
    },
};
export const initialState = {
    option: [],
    permissions: [],
    item2: {},
    real_id: 0,
    ...baseReducer.initialState,
};

export default handleActions(reducers, initialState);
