import React, { useState } from 'react';
import { Modal } from 'react-responsive-modal';

const LinkWrapper = (props) => {
    const { getLink, me, getAccount } = props;

    const [link, setUrl] = useState(null);
    const [show, setShow] = useState(false);
    const [reload, setReload] = useState(false);
    const handleShow = () => setShow(true);

    const handleClose = () => {
        if (reload) {
            getAccount(null, null);
            setUrl(null);
            setReload(false);
            setShow(false);
        } else {
            setShow(false);
        }
    };

    const setLink = (_link) => {
        const url = new URL(_link);
        setUrl(url);
    };
    // const url = new URL(link);
    const actionClick = () => {
        // handleShow()
        getLink(handleShow, setLink);
    };

    return (
        <React.Fragment>
            <button className="btn btn-light w-100 mt-2" onClick={actionClick} disabled={(!me.stripe_active)}>
                Edit Bank Account
            </button>
            <Modal
                open={show}
                onClose={handleClose}
                center
                closeOnEsc
                showCloseIcon={true}
                // showCloseIcon={false}
                closeOnOverlayClick={false}
                initialFocusRef={document.getElementById('app-container')}
                classNames={{ modal: 'col-lg-5 col-sm-10 p-0' }}
            >
                <div className="card-body">
                    {/*<iframe src={link} style={{*/}
                    {/*    width: '100%',*/}
                    {/*    height: '38rem',*/}
                    {/*}}/>*/}
                    <p className="text-12 py-0 font-weight-bold mb-1">
                        Pressing edit will open a new Stripe tab to edit your bank account
                    </p>
                    <p className="text-12 py-0 mb-1">
                        Instruction
                    </p>
                    <ul className="text-12">
                        <li>While in the Account section, go to the
                            <strong className="font-weight-bold">Transfer Data</strong> section
                        </li>
                        <li>Edit the bank account details</li>
                        <li><strong className="font-weight-bold">Log out </strong> of Stripe</li>
                        <li>At the end return to the page of ShowIt Solution Broker</li>
                    </ul>
                    <div className="row w-100 justify-content-center">
                        {
                            !reload &&
                            <a
                                href={link}
                                target='_blank'
                                className="btn btn-primary px-5 text-white align-self-center"
                                onClick={() => setReload(true)}
                            >
                                Edit
                            </a>
                        }
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default LinkWrapper;
