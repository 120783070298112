import React, {useState, useEffect} from "react";
import Avatar from "react-avatar";
import {
    SIZE_PER_PAGE,
    REGISTERED_ACTIVE,
    REGISTERED_LOCKED,
    REGISTERED_BLOCKED,
    REGISTERED_PENDING,
    CATEGORY_REGISTERED_LIST
} from "../../../../../utility/constants";
import Grid from "../../../Utils/GridTable";

const InfluencerAgentsGrid = (props) => {
    const {
        data,
        page,
        toList,
        loader,
        tabContainerHeight,
    } = props;
    // const [gridHeight, setGridHeight] = useState(200)

    // useEffect(() => {
    //     let _height = 200;
    //     setTimeout(() => {
    //         const elementId = document.getElementById("INFLUENCER-AGENT-LIST");
    //         if (elementId) {
    //             _height = elementId.clientHeight
    //         }
    //         console.log("H: ", _height);
    //         setGridHeight(_height)
    //     }, 300);
    // }, [])

    return (
        <div className="container-list card border-dark border-card-page px-4 py-2 flex-1">
            <div className="card-body px-0 pt-2 mb-0 pb-0 d-flex flex-column flex-1">
                <Grid
                    hover
                    height={tabContainerHeight}
                    wrapperClasses="table-scroll-auto"
                    keyField={'id'}
                    loading={loader}
                    page={page}
                    data={data}
                    sizePerPage={SIZE_PER_PAGE}
                    onPageChange={(value) => toList(value)}
                    columns={[
                        {
                            dataField: "photo",
                            formatter: avatar(),
                            text: " ",
                        },
                        {
                            dataField: "agent_account_status",
                            formatter: formatStatusAccount(),
                            text: "Status",
                        },
                        {
                            dataField: "name",
                            formatter: (cell, row) => {
                                return <span>{row.first_name} {row.last_name}</span>
                            },
                            text: "Name",
                        },
                    ]}
                    />
                </div>
            </div>
    )
}

export function avatar() {
    return (cell, row) => {
        return (
            <div style={{width: '20px'}} className="mx-auto">
                <Avatar
                    size="23"
                    src={cell}
                    round={true}
                    name={row.first_name}
                />
            </div>
        );
    };
}

export function formatStatusAccount() {
    return (cell, row) => {
        const getStatus = () => {
            let text = ''
            CATEGORY_REGISTERED_LIST.forEach(item => {
                if (cell === item.value) {
                    text = item.label
                }
            })
            return text
        }
        const getColor = () => {
            let colorText = 'black'
            if (cell === REGISTERED_PENDING) {
                colorText = 'warning'
            } else if (cell === REGISTERED_ACTIVE) {
                colorText = 'success'
            } else if (cell === REGISTERED_LOCKED) {
                colorText = 'danger'
            } else if (cell === REGISTERED_BLOCKED) {
                colorText = 'danger'
            }
            return colorText
        }
        return (
            <React.Fragment>
                <span className={`text-capitalize font-weight-bold text-${getColor()}`}>
                    {getStatus()}
                </span>
            </React.Fragment>
        )
    };
}

export default InfluencerAgentsGrid;

