import {handleActions} from 'redux-actions';
import {NotificationManager} from "react-notifications";
import {initialize as initializeForm, change} from 'redux-form';
import {api} from "api";
import {push} from "react-router-redux";

const SUBMIT = 'REGISTER_SUBMIT';
const LOADER = 'REGISTER_LOADER';
const PROGRESS_STEP = 'PROGRESS_STEP';
const ALL_DATA_FORM = 'ALL_DATA_FORM';
const SET_SUCCESS = 'SET_SUCCESS';
const SET_ROLES = 'SET_ROLES';
const SET_DATA_VALIDATE = 'SET_DATA_VALIDATE';
const SET_CODE_VALIDATE = 'SET_CODE_VALIDATE';
const SET_STEP_BACK = 'SET_STEP_BACK';
const SET_PHONE_CONFIRMED = 'SET_PHONE_CONFIRMED';
const SET_EMAIL_CONFIRMED = 'SET_EMAIL_CONFIRMED';
const OPERATION_ACCESS = 'OPERATION_ACCESS_REGISTER';

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setCode = code => ({
    type: SET_CODE_VALIDATE,
    code,
});

export const setStepBack = stepBack => ({
    type: SET_STEP_BACK,
    stepBack,
});
export const setEmailConfirmed = email_confirmed => ({
    type: SET_EMAIL_CONFIRMED,
    email_confirmed,
});

export const setPhoneConfirmed = phone_confirmed => ({
    type: SET_PHONE_CONFIRMED,
    phone_confirmed,
});

export const setDataValidate = data_validate => ({
    type: SET_DATA_VALIDATE,
    data_validate,
});

const setStep = step => ({
    type: PROGRESS_STEP,
    step: step,
});

const setAllDataForm = (data) => ({
    type: ALL_DATA_FORM,
    data,
});

const setSuccess = (success) => ({
    type: SET_SUCCESS,
    success,
});

const setRoles = (data) => ({
    type: SET_ROLES,
    data,
});

const setOperationAccess = (operationAccess) => ({
    type: OPERATION_ACCESS,
    operationAccess,
})


// ------------------------------------
// Actions
// ------------------------------------

const onSubmitAction = (influencerId=null) => (dispatch, getStore) => {
    const currentData = getStore().register.data;
    const perationAccess = getStore().register.operationAccess;
    const passe = getStore().form.accountPassword.values
    const data = Object.assign(currentData, passe);
    // console.log('register: ', currentData)
    data['brokerages'] = JSON.parse(localStorage.getItem('brokerages'));
    if (influencerId) {
        data['influencer_id'] = influencerId
    }
    data['operation_access'] = perationAccess
    dispatch(setLoader(true));
    api.post('broker-user', data)
        .then((response) => {
            NotificationManager.success('Account created successfully', 'SUCCESS', 3000);
            const company = response[0].company
            const data2 = Object.assign(currentData, {company});
            dispatch(setDataValidate(data2));
            if (currentData.broker_id || currentData.invite_id) {
                dispatch(setStep(120));
                dispatch(setSuccess(true));
            } else {
                dispatch(setStep(120));
            }
            localStorage.removeItem('brokerages')
        })
        .catch((error) => {
            console.log("ERROR: ", error);
            const _message = "Signup failed, try again";
            const message = error.detail ? error.detail : _message 
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            localStorage.removeItem('brokerages')
            dispatch(setLoader(false));
        });
};

const onSubmitOther = () => (dispatch, getStore) => {
    const currentData = getStore().register.data;
    const other = getStore().form.registerStep6.values
    const data = Object.assign(other, currentData);
    dispatch(setLoader(true));
    api.post('broker-user/other_user', data)
        .then(() => {
            NotificationManager.success('Invite sent', 'SUCCESS', 3000);
            dispatch(setSuccess(true));
        })
        .catch((error) => {
            if (error && error.details && error.details === "Email already exist") {
                NotificationManager.error('Email already exist', 'ERROR', 3000);
            } else {
                NotificationManager.error('Signup failed, try again', 'ERROR', 3000);
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const getRoles = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('user/roles')
        .then((response) => {
            dispatch(setRoles(response));
        })
        .then((response) => {
            dispatch(setRoles(response));
        })
        .catch(() => {

        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const logOut = () => (dispatch) => {
    localStorage.removeItem('token');
};

const onSubmitStep = () => (dispatch, getStore) => {
    const step = getStore().register.step;
    let data = getStore().register.data;
    const email_confirmed = getStore().register.email_confirmed;
    const phone_confirmed = getStore().register.phone_confirmed;
    let form = '';
    if (step === 0) {
        dispatch(setStep(step + 20));
        if (data && data.first_name) {
            const value_init = {
                first_name: data.first_name,
                last_name: data.last_name,
                broker_license_number: data.broker_license_number,
                role: data.role,
                role_name: data.role_name,
                address: data.address,
                city: data.city,
                latitude: data.latitude,
                longitude: data.longitude,
                zipcode: data.zipcode,
                office_phone: data.office_phone,
                has_multiple_offices: data.has_multiple_offices,
                number_offices: data.number_offices,
            }
            dispatch(initializeForm('register', value_init));
        } else {
            dispatch(initializeForm('register', {'role': 5}));
        }
    }
    if (step === 20) {
        form = getStore().form.register.values;
        dispatch(setStep(step + 20));
        dispatch(setStepBack(false));
    }
    if (step === 40) {
        if (data && data.mobile_number) {
            dispatch(initializeForm('registerStep3', {
                mobile_number: data.mobile_number,
            }));
        }
        dispatch(setStep(step + 20));
        dispatch(setStepBack(false));
    }

    if (step === 60) {
        form = getStore().form.registerStep3.values;
        if (data && data.email) {
            dispatch(initializeForm('registerStep4', {
                email: data.email,
            }));
        }
        if (Object.keys(data).includes('mobile_number')) {
            if (data['mobile_number'] === form.mobile_number && !data.broker_id) {
                if (form.mobile_number === phone_confirmed) {
                    // if mobile_number and phone_confirmed match then not post to API
                    dispatch(setStep(step + 20));
                    dispatch(setStepBack(false));
                } else {
                    // activate the form to insert the submitted code to mobile number
                    dispatch(setStepBack(true))
                }
            } else {
                // if mobile number inserted is new
                dispatch(insertPhone(form.mobile_number));
            }
        } else {
            // first mobile number inserted
            dispatch(insertPhone(form.mobile_number));
        }
    }

    if (step === 80) {
        form = getStore().form.registerStep4.values;
        if (Object.keys(data).includes('email')) {
            if (data['email'] === form.email && !data.broker_id) {
                if (form.email === email_confirmed) {
                    // if email and email_confirmed match then not post to API
                    dispatch(setStep(step + 20));
                    dispatch(setStepBack(false));
                } else {
                    // if email and email_confirmed not match then actives form validate code
                    dispatch(setStepBack(true))
                }
            } else {
                // post because user inserted new email address in form
                dispatch(insertEmail(form.email));
            }
        } else {
            // if is first email inserted
            dispatch(insertEmail(form.email));
        }
    }
    // insert new data to initialize data
    const new_data = Object.assign(data, form);
    dispatch(setAllDataForm(new_data));
}

const assignAddressData = (data, form) => (dispatch) => {
    if (data) {
        const {state, city, zip_code} = data
        dispatch(change(form, 'state', state))
        dispatch(change(form, 'city', city))
        dispatch(change(form, 'zipcode', zip_code))
    }

}

const insertPhone = (mobile_number) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    let data = getStore().register.data;
    api.post('confirmation-code', {'phone_number': mobile_number})
        .then(response => {
            dispatch(setDataValidate(mobile_number));
            dispatch(setStepBack(true));
        })
        .catch(() => {
            delete data.mobile_number
            NotificationManager.error('Fail to send code, please verify your mobile number', 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};


const insertEmail = (email) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    let data = getStore().register.data;
    api.post('confirmation-code/create_code', {'email': email})
        .then(response => {
            dispatch(setDataValidate(email));
            dispatch(setStepBack(true));
        })
        .catch((error) => {
            delete data.email
            const  _message = "Fail to send code, please verify your email and try again"
            const message = error.detail ? error.detail : _message
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
}

const validateCode = (value) => (dispatch, getStore) => {
    const step = getStore().register.step;
    const allInfo = getStore().register.data;
    const field = getStore().register.data_validate;
    const url = step === 60 ? 'confirmation-code/validate_code' : 'confirmation-code/validate_code_email';
    const body = step === 60 ? {'phone_number': field, 'code': value} : {'email': field, 'code': value};
    dispatch(setLoader(true));
    api.post(`${url}`, body)
        .then(response => {
            if (step === 60) {
                dispatch(setPhoneConfirmed(field));
                if (allInfo && allInfo.invite_id) {
                    dispatch(initializeForm('registerStep4', {'email': allInfo.email}));
                }
            }
            if (step === 80) {
                dispatch(setEmailConfirmed(field));
            }
            dispatch(setStep(step + 20));
            dispatch(setStepBack(false)); // for view the code_number form
            dispatch(setCode(false)); // for view the code_number form
        })
        .catch((error) => {
            dispatch(setCode('error'))
        })
        .finally(() => {
            dispatch(setLoader(false))
        })
};

const resendCodePhone = () => (dispatch, getStore) => {
    dispatch(setLoader(true))
    const phone = getStore().register.data_validate;
    api.post('confirmation-code/resend_code_phone', {'phone_number': phone})
        .then(() => {
            NotificationManager.success('Code sent', 'Success', 2000)
        })
        .catch(() => {
            NotificationManager.error('Code send Failed', 'Error', 2000)
        })
        .finally(() => {
            dispatch(setLoader(false));
        })
};

const resendCodeEmail = () => (dispatch, getStore) => {
    dispatch(setLoader(true))
    const email = getStore().register.data_validate;
    api.post('confirmation-code/resend_code_email', {'email': email})
        .then(() => {
            NotificationManager.success('Code sent', 'Success', 2000)
        })
        .catch(() => {
            NotificationManager.error('Code send Failed', 'Error', 2000)
        })
        .finally(() => {
            dispatch(setLoader(false));
        })
};

const goToStep2 = () => (dispatch, getStore) => {
    const step = getStore().register.step;
    dispatch(setStep(step + 20));
    const data = getStore().register.data;
    const form = getStore().form.FormStep1Invited.values;
    // insert new data to initialize data
    const new_data = Object.assign(data, form);
    dispatch(setAllDataForm(new_data));
}

const validateNumber = () => (dispatch, getStore) => {
    const step = getStore().register.step;
    const data = getStore().register.data;
    const valueForm = getStore().form.FormStep2Invited.values;
    const new_data = Object.assign(data, valueForm);
    if (new_data.numberSend && new_data.numberSend === valueForm.mobile_number) {
        dispatch(setStep(step + 20));
    } else {
        dispatch(setLoader(true))
        api.post('broker-user/validate_phone_number', new_data)
            .then(() => {
                dispatch(setDataValidate(new_data.mobile_number))
                dispatch(setStepBack(true));
                new_data.numberSend = valueForm.mobile_number;
                dispatch(setAllDataForm(new_data));
                // NotificationManager.success('MSM sent', 'Success', 2000)
            })
            .catch((error) => {
                delete new_data.numberSend
                if (error.detail) {
                    NotificationManager.error(`${error.detail}`, 'Error', 4000)
                } else {
                    NotificationManager.error('Incorrect mobile number', 'Error', 2000)
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            })
    }
}

const validateCode2 = (code) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const step = getStore().register.step;
    const data = getStore().register.data;

    api.post('confirmation-code/validate_code', {'phone_number': data.numberSend, 'code': code})
        .then(() => {
            dispatch(setStep(step + 20));
            dispatch(setStepBack(false)); // for view the code_number form
            NotificationManager.success('Mobile Number validated', 'Success', 2000)
        })
        .catch((error) => {
            delete new_data.numberSend
            if (error.detail) {
                NotificationManager.error(`${error.detail}`, 'Error', 2000)
            } else {
                NotificationManager.error('Incorrect mobile number', 'Error', 2000)
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        })
}

const submitRegisterInvite = () => (dispatch, getStore) => {
    dispatch(setLoader(true))
    const form = getStore().form.FormStep3Invite.values;
    const currentData = getStore().register.data;
    const data = Object.assign(currentData, form);
    api.post('broker-user/signup_user_invite', data)
        .then(() => {
            dispatch(push('/login'));
            NotificationManager.success('Account created', 'Success', 2000)
        })
        .catch((error) => {
            if (error && error.detail) {
                NotificationManager.error(error.detail, 'Error', 4000)
            } else {
                NotificationManager.error('Failed to signup, try again', 'Error', 2000)
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        })
}

const goLogin = () => (dispatch) => {
    dispatch(push('/login'));
}

const clickBack = () => (dispatch, getStore) => {
    const resource = getStore().register
    const real_step = resource.step;
    const data = resource.data;
    const stepBack = resource.stepBack;
    if (real_step === 20) {
        dispatch(setStep(real_step - 20));
    }
    if (real_step === 40) {
        dispatch(setStep(real_step - 20));
    }
    console.log("DATA=====: ", data);
    if (real_step === 60) {
        dispatch(initializeForm('register', data));
        dispatch(setStep(real_step - 20));
    }
    if (real_step === 80) {
        dispatch(setStepBack(false));
        dispatch(initializeForm('registerStep3', data));
        dispatch(setStep(real_step - 20));
    }
    if (real_step === 100) {
        dispatch(setStepBack(false));
        dispatch(initializeForm('registerStep4', data));
        dispatch(setStep(real_step - 20));
    }
}

const clickGoBack2 = () => (dispatch, getStore) => {
    const real_step = getStore().register.step;
    const data = getStore().register.data;
    const stepBack = getStore().register.stepBack;
    if (real_step === 40 && stepBack) {
        dispatch(initializeForm('FormStep2Invited', data));
        // insert new data to initialize data
        const new_data = data
        delete new_data.numberSend
        dispatch(setAllDataForm(new_data));
        dispatch(setStepBack(false));
    }
    if (real_step === 40 && !stepBack) {
        dispatch(initializeForm('FormStep1Invited', data));
        dispatch(setStep(real_step - 20));
    }
    if (real_step === 60) {
        dispatch(initializeForm('FormStep2Invited', data));
        dispatch(setStep(real_step - 20));
    }
}

const sendOperationAccess = (data) => (dispatch, getStore) => {
    const real_step = getStore().register.step;
    const currentData = getStore().register.data;
    api.post('broker-user/operation_access', {option: data, pkBroker: currentData.company})
        .then((res) => {
            console.log(res);
            dispatch(setStep(real_step + 20))
        }).catch(() => {
        NotificationManager.error('Error, please try again', 'Error', 2000)
    }).finally(() => {
    })
}

const resetInitialData = () => (dispatch) => {
    dispatch(setAllDataForm({}));
    dispatch(setStep(0));
    dispatch(setCode(false));
    dispatch(setSuccess(false));
    dispatch(setDataValidate(null));
    dispatch(setDataValidate(null));
    dispatch(setPhoneConfirmed(false));
    dispatch(setEmailConfirmed(false));
    dispatch(setStepBack(false));
}

export const actions = {
    onSubmitAction,
    resendCodePhone,
    resendCodeEmail,
    clickBack,
    goLogin,
    setStep,
    setSuccess,
    onSubmitOther,
    onSubmitStep,
    getRoles,
    setAllDataForm,
    validateCode,
    goToStep2,
    validateNumber,
    clickGoBack2,
    submitRegisterInvite,
    sendOperationAccess,
    resetInitialData,
    validateCode2,
    assignAddressData,
    setOperationAccess,
};

export const reducers = {
    [LOADER]: (state, {loader}) => {
        return {
            ...state,
            loader,
        };
    },
    [PROGRESS_STEP]: (state, {step}) => {
        return {
            ...state,
            step,
        };
    },
    [ALL_DATA_FORM]: (state, {data}) => {
        return {
            ...state,
            data,
        };
    },
    [SET_SUCCESS]: (state, {success}) => {
        return {
            ...state,
            success,
        };
    },
    [SET_ROLES]: (state, {roles}) => {
        return {
            ...state,
            roles,
        };
    },
    [SET_DATA_VALIDATE]: (state, {data_validate}) => {
        return {
            ...state,
            data_validate,
        };
    },
    [SET_CODE_VALIDATE]: (state, {code}) => {
        return {
            ...state,
            code,
        };
    },
    [SET_STEP_BACK]: (state, {stepBack}) => {
        return {
            ...state,
            stepBack,
        };
    },
    [SET_EMAIL_CONFIRMED]: (state, {email_confirmed}) => {
        return {
            ...state,
            email_confirmed,
        };
    },
    [SET_PHONE_CONFIRMED]: (state, {phone_confirmed}) => {
        return {
            ...state,
            phone_confirmed,
        };
    },
    [OPERATION_ACCESS]: (state, {operationAccess}) => {
        return {
            ...state,
            operationAccess,
        };
    },
};

export const initialState = {
    loader: false,
    step: 0,
    data: {},
    success: false,
    code: false,
    roles: [],
    stepBack: false,
    data_validate: null,
    phone_confirmed: false,
    email_confirmed: false,
    operationAccess: 1,
};

export default handleActions(reducers, initialState);
