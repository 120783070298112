import React, { useEffect } from 'react';
import './agentRegister.css';
import { connect } from "react-redux";
import ReactCodeInput from "react-verification-code-input";
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { renderField } from '../Utils/renderField';

const validate = (value) => {
    let error = {}
    if (!value.email) {
        error.email = "This field is required"
    } else {
        if (!value.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            error.email = "Invalid email address";
        }
    }

    return error
}

const Form = (props) => {
    //  State
    const { stepBack, code, emailConfirmed } = props;
    //  Bind
    const { handleSubmit, validateCode, resendCode } = props;

    useEffect(() => {
        if (document.getElementById('AGENT-REGISTER')) {
            document.getElementById('AGENT-REGISTER').scrollIntoView({ behavior: 'smooth' });
        }
    }, [])

    return (
        <div className="row flex-1 justify-content-center pt-3">
            <form
                name="agentRegisterForm"
                className="w-100 col-xl-10 col-lg-11 col-md-11 col-12 d-flex flex-column"
                onSubmit={handleSubmit}
            >
                <div className="flex-1 d-flex flex-column justify-content-between">
                    <div className="row pt-4">
                        <div className="form-group has-feedback col-12 mb-md-5 mb-5">
                            <div className="form-group has-feedback">
                                <span className='agent-register-label-title'>Confirming your email address</span>
                                <br />
                                <span className='agent-register-label-subtitle'>We will send you a confirmation code to your email</span>
                                <br />
                            </div>
                        </div>
                    </div>
                    <div className="row flex-1">
                        <div className="form-group has-feedback col-12 mb-md-5 mb-5">
                            <div className="row">
                                <div className="form-group has-feedback col-sm-12 mb-lg-5 mb-3">
                                    <label htmlFor="email" className=''>
                                        <strong className="question2">Email address</strong>
                                    </label>
                                    <Field
                                        name="email"
                                        label="email"
                                        component={renderField}
                                        type="email"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                        {emailConfirmed ? (
                            <div className="buttons-box col-12 justify-content-center mx-auto pt-lg-5 mb-4">
                                <button type="submit" className="btn agent-register-button col-xl-4 col-lg-5 align-self-center">
                                    Next
                                </button>
                            </div>
                        ) : (
                            !stepBack ? (
                                <div className="buttons-box col-12 justify-content-center mx-auto pt-lg-5 mb-4">
                                    <button type="submit" className="btn agent-register-button col-xl-4 col-lg-5 align-self-center">
                                        Send the code
                                    </button>
                                </div>
                            ) : (
                                <React.Fragment>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="form-group has-feedback col-lg-8  mx-auto justify-content-center text-center">
                                                <label htmlFor="mobile_number" className="text-left">
                                                    <p className="agent-register-label-subtitle mb-1">
                                                        Enter the 4-digit code bellow
                                                    </p>
                                                </label>
                                                <div style={code === 'error' ?
                                                    {} : { width: '300px !important' }}
                                                    className={`p-0 w-100 ${code === 'error' && "is-invalid mx-auto"} `}
                                                >
                                                    <ReactCodeInput
                                                        onComplete={validateCode}
                                                        fieldWidth={50}
                                                        fieldHeight={33}
                                                        title={''}
                                                        className={` w-100 ${code === 'error' && "is-invalid"} `}
                                                        fields={4}
                                                        required={true}
                                                        type={"number"}
                                                    />
                                                </div>
                                                {code === 'error' &&
                                                    <strong className="invalid-feedback"> Wrong code, please check your code</strong>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="buttons-box col-12 justify-content-center mx-auto mb-3">
                                        <button
                                            type="button"
                                            className="btn button-03 col-xl-2 col-lg-3 col-6 align-self-center text-12"
                                            style={{ fontSize: "12px !important" }}
                                            onClick={() => resendCode()}
                                        >
                                            Resend code
                                        </button>
                                    </div>
                                </React.Fragment>
                            )
                        )}
                    </div>
                </div>

            </form>
        </div>
    )

}

const ConfirmEmailForm = reduxForm({
    form: 'agentRegisterForm', // a unique identifier for this form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
})(Form);

const selector = formValueSelector('agentRegisterForm');
const mstp = state => {
    return {
    };
};

export default connect(mstp, null)(ConfirmEmailForm);
