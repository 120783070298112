import React, {Component} from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {Link} from "react-router-dom";
import Avatar from "react-avatar";

const defaultAvatar = require("assets/img/person-shape.svg");


class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {dropdownOpen: false};
    }

    toggle = () => {
        this.setState({dropdownOpen: !this.state.dropdownOpen});
    };

    isInfluencer = () => {
        const userType = localStorage.getItem('user_type')
        
        return userType == 20
    }

    render() {
        const {navToggle, logOut, user, verifyUser, isManaging} = this.props;

        return (
            <nav className="align-items-stretch flex-md-nowrap p-0 navbar">
                <nav className="nav">
                    <a className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-inline d-lg-none text-center"
                       onClick={navToggle}>
                        <i className="material-icons"></i>
                    </a>
                </nav>
                <ul className="flex-row m-0 p-0">
                    <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                        <DropdownToggle color="light" caret className="nav-item-dropdown m-0 pt-2 px-5 new-black">
                            <div className="rounded-circle bg-white mr-3 align-middle d-inline-flex new-black">
                                <Avatar
                                    size="28"
                                    src={user.photo ? user.photo : defaultAvatar}
                                    round={true}
                                    name={user.first_name ? user.first_name : 'Photo'}
                                />
                            </div>
                            <span className="mr-2 d-inline align-middle new-black">{user.first_name} </span>
                        </DropdownToggle>
                        <DropdownMenu>
                            {!this.isInfluencer() && (
                                <React.Fragment>
                                    <DropdownItem tag="a" href="#/edit-user-profile">
                                        <div className="flex row mx-auto align-items-center py-0">
                                            <i className="material-icons"></i>
                                            <p className="mb-0 mt-2">
                                                Profile
                                            </p>
                                        </div>
                                    </DropdownItem>
                                    {/* {isManaging &&
                                        <DropdownItem tag="a" href="/#/broker-profile">
                                            <div className="flex row mx-auto align-items-center py-0">
                                                <i className="material-icons">group</i>
                                                <p className="mb-0 mt-2">
                                                    Broker Profile
                                                </p>
                                            </div>
                                        </DropdownItem>
                                    } */}
                                    <DropdownItem divider/>
                                </React.Fragment>
                            )}
                            <DropdownItem tag="a" href="/" onClick={logOut}>
                                <div className="flex row mx-auto align-items-center py-0">
                                    <i className="material-icons text-danger"></i>
                                    <p className="mb-0 pt-1">
                                        Logout
                                    </p>
                                </div>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </ul>
            </nav>
        );
    }
}

export default Navbar;
