import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import LoginForm from './LoginForm';
import './login.css';
import LoadMask from "Utils/LoadMask/LoadMask";

class Login extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    componentDidMount(props) {
        this.state = { prueba: true };
    }

    render() {
        const { onSubmit, loader } = this.props;
        if (localStorage.getItem('token')) {
            let userType = localStorage.getItem('user_type')
            if (userType && userType == 20) {
                return (<Redirect to="/influencer/profile" />);
            }else {
                return (<Redirect to="/" />);
            }
        }
        return (
            <div className="body-img">
                <div className="blue-gradient-bg">
                    <div className="login-wrapper  h-100" style={{minWidth:'60%'}}>
                        <div className="card card-login col-sm-12 col-lg-3 px-lg-1" >
                            <div
                                className="container-logo-register justify-content-center mt-0 align-self-center">
                                <img src={require("../../../../../assets/img/logo-01.png")}
                                     className="logo-form-sing-in" alt={"showIt logo"}
                                />
                            </div>
                            <LoadMask loading={loader} light>
                                <LoginForm onSubmit={onSubmit} />
                                <div className="d-block mt-3 mb-1">
                                    <Link to="/forgot-password" className="text-center d-block text-12">Forgot Password?</Link>
                                    {/* <Link to="/register" className="text-center d-block mt-3 text-12 register-a">Register</Link> */}
                                </div>
                            </LoadMask>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
