import _ from 'lodash'
import { api } from "api";
import { handleActions } from "redux-actions";
import { createReducer } from "../baseReducer/baseReducer";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import {getMe} from "../cuenta/login"
import { initialize as initializeForm } from "redux-form";

// ------------------------------------
// CONSTANTS
// ------------------------------------
const endpoint = "influencer";
const SET_FILE = 'SET_FILE_INFLUENCER_LEADING';
const SET_LOADER_2 = 'SET_LOADER_2_INFLUENCER_LEADING';
const SET_URL_EXCEL_TEMPLATE = 'SET_URL_EXCEL_TEMPLATE_INFLUENCER_LEADING';
const SET_DATA_MONTHLY_PROFITE = 'SET_DATA_MONTHLY_PROFITE_INFLUENCER_LEADING';
const SET_LOADER_MONTHLY_PROFITE = 'SET_LOADER_MONTHLY_PROFITE_INFLUENCER_LEADING';
const SET_DATA_YEARLY_PROFITE = 'SET_DATA_YEARLY_PROFITE_INFLUENCER_LEADING';
const SET_LOADER_YEARLY_PROFITE = 'SET_LOADER_YEARLY_PROFITE_INFLUENCER_LEADING';
const SET_YEAR = 'SET_YEAR_INFLUENCER_LEADING';

// ------------------------------------
// BASE ACTION
// ------------------------------------
const baseReducer = createReducer(
    "influencerProfile",
    endpoint,
    "influencerForm",
    ""
);

// ------------------------------------
// Pure Actions
// ------------------------------------
const setFile = file => ({
    type: SET_FILE,
    file,
});
const setUrlExcelTemplate = urlTemplate => ({
    type: SET_URL_EXCEL_TEMPLATE,
    urlTemplate,
});
const setLoader2 = loader2 => ({
    type: SET_LOADER_2,
    loader2,
});
const setDataMonthlyProfile = monthlyProfite => ({
    type: SET_DATA_MONTHLY_PROFITE,
    monthlyProfite,
});
const setMonthlyProfileLoader = monthlyProfiteLoader => ({
    type: SET_LOADER_MONTHLY_PROFITE,
    monthlyProfiteLoader,
});
const setDataYearlyProfile = yearlyProfite => ({
    type: SET_DATA_YEARLY_PROFITE,
    yearlyProfite,
});
const setYearlyProfileLoader = yearlyProfiteLoader => ({
    type: SET_LOADER_YEARLY_PROFITE,
    yearlyProfiteLoader,
});
const setYear = year => ({
    type: SET_YEAR,
    year,
});

// ------------------------------------
// Actions
// ------------------------------------

const getUrlExelTemplate = () => (dispatch) => {
    api.get('brokerage/template_excel')
        .then((response) => {
            dispatch(setUrlExcelTemplate(response.url));
        })
        .catch(() => {
        })
        .finally(() => {
        });
};

const setItemToNull = () => (dispatch) => {
    dispatch(initializeForm('influencerTeamForm', {}))
};

const setExcel = (file) => (dispatch) => {
    dispatch(setFile(file))
}

const createTeam = (data, closeModal) => (dispatch, getStore) => {
    dispatch(setLoader2(true))
    delete data.office_excel
    const store = getStore();
    const {file} = store.influecerLeading;
    console.log("FILE: ", file);
    let _attachments = []
    if (file) {
        _attachments.push({name: 'roster_file', file: file})
    }
    api.postAttachments('influencer_team', data, _attachments)
        .then((response) => {
            dispatch(setFile(null))
            closeModal && closeModal()
            dispatch(getMe())
            dispatch(toList())
            NotificationManager.success('Team created', 'SUCCESS', 3000);
        }).catch(error => {
            let msg = "Create failed"
            if (error && error.detail) {
                msg = error.detail
            }
            NotificationManager.error(msg, 'ERROR', 5000);
        })
        .finally(() => {
            dispatch(setLoader2(false));
        });
}

const toList = () => (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true))
    api.get(`${endpoint}/sponcer_list`).then((response) => {
        if (response) {
            dispatch(baseReducer.actions.setData(response))
        } else {
            dispatch(baseReducer.actions.setData({
                results: [],
                count: 0,
                totals: {
                    current_month_fee: 0,
                    current_month_jobs: 0,
                    current_year_fee: 0,
                    current_year_jobs: 0,
                    total_agents: 0,
                    total_agents_registered: 0,
                }
            }))
        }
    }).catch((err) => {
        dispatch(baseReducer.actions.setData({
            results: [],
            count: 0,
            totals: {
                current_month_fee: 0,
                current_month_jobs: 0,
                current_year_fee: 0,
                current_year_jobs: 0,
                total_agents: 0,
                total_agents_registered: 0,
            }
        }))
    }).finally(() => {
        dispatch(baseReducer.actions.setLoader(false))
    })
}

const getMonthlyProfite = () => (dispatch, getStore) => {
    dispatch(setMonthlyProfileLoader(true))
    const store = getStore();
    const {year} = store.influecerLeading;
    let params = {};
    if (year && year.value) {
        params.year = year.value
    }

    api.get(`${endpoint}/sponcer_month_graph`, params)
        .then((response) => {
            console.log('RESPONSE: ', response);
            let dataReceived = []
            let labels = [];

            response.data.forEach(item => {
                let data = []
                for (let names in item) {
                    if (item[names] === null) {
                        data.push(0)
                    } else {
                        data.push(item[names])
                    }
                    const _name = _.find(labels, function(o) {
                        return o == names
                    })
                    if (!_name) {
                        labels.push(names);
                    }
                }
                dataReceived.push({
                    data: data,
                    tension: 0.4,
                    label: "Fee",
                    borderWidth: 1.6,
                    borderColor: '#5C7CCF',
                    backgroundColor: '#5C7CCF',
                })
            })
            const dataFormatter = {
                labels: labels,
                datasets: dataReceived,
                total: response.total ? response.total : 0,
            }
            dispatch(setDataMonthlyProfile(dataFormatter));
        }).catch((err) => {
            dispatch(setDataMonthlyProfile({
                total: 0,
                labels: [],
                datasets: [],
            }))
        }).finally(() => {
            dispatch(setMonthlyProfileLoader(false))
        })
}

const getYearlyProfite = () => (dispatch) => {
    dispatch(setYearlyProfileLoader(true))

    api.get(`${endpoint}/sponcer_year_graph`)
        .then((response) => {
            let dataReceived = []
            let labels = [];

            response.data.forEach(item => {
                response.data.forEach(item => {
                    let data = []
                    for (let names in item) {
                        if (item[names] === null) {
                            data.push(0)
                        } else {
                            data.push(item[names])
                        }
                        const _name = _.find(labels, function(o) {
                            return o == names
                        })
                        if (!_name) {
                            labels.push(names);
                        }
                    }
                    dataReceived.push({
                        data: data,
                        tension: 0.4,
                        label: "Fee",
                        borderWidth: 1.6,
                        borderColor: '#E28B2A',
                        backgroundColor: '#E28B2A',
                    })
                })
            })
            const dataFormatter = {
                labels: labels,
                datasets: dataReceived,
                total: response.total ? response.total : 0,
            }
            dispatch(setDataYearlyProfile(dataFormatter));
        }).catch((err) => {
            dispatch(setDataYearlyProfile({
                total: 0,
                labels: [],
                datasets: [],
            }))
        }).finally(() => {
            dispatch(setYearlyProfileLoader(false))
        })
}

const changeYear = (year) => (dispatch) => {
    dispatch(setYear(year))
    dispatch(getMonthlyProfite())
}

const getTeam = (id, openModal) => (dispatch) =>  {
    dispatch(setLoader2(true))
    api.get(`influencer_team/${id}`)
        .then(response => {
            const file_url_parts = response.roster_file ? response.roster_file.split('influencer/Files/') : [];
            dispatch(
                initializeForm("influencerTeamForm", {
                    id: response.id,
                    name: response.name,
                    total_agents: response.total_agents,
                    roster_replace: 'update'
                })
            );
            openModal(file_url_parts.length > 1 ? file_url_parts[1] : null)
        }).catch(erro => {
            const msg = "It was not possible to obtain the data of the team"
            NotificationManager.error(msg, 'ERROR', 5000);
        }).finally(() => {
            dispatch(setLoader2(false))
        })
}

const updateTeam = (id, data, closeModal) => (dispatch, getStore) => {
    dispatch(setLoader2(true))
    delete data.office_excel
    const store = getStore();
    const {file} = store.influecerLeading;
    console.log("FILE: ", file);
    let _attachments = []
    if (file) {
        _attachments.push({name: 'roster_file', file: file})
    }
    if (_attachments.length > 0) {
        api.putAttachments(`influencer_team/${id}`, data, _attachments)
            .then((response) => {
                dispatch(setFile(null))
                closeModal && closeModal()
                dispatch(getMe())
                dispatch(toList())
                NotificationManager.success('Team updated', 'SUCCESS', 3000);
            }).catch(error => {
                let msg = "Update failed"
                if (error && error.detail) {
                    msg = error.detail
                }
                NotificationManager.error(msg, 'ERROR', 5000);
            })
            .finally(() => {
                dispatch(setLoader2(false));
            });
    } else {
        api.put(`influencer_team/${id}`, data)
            .then((response) => {
                dispatch(setFile(null))
                closeModal && closeModal()
                dispatch(getMe())
                dispatch(toList())
                NotificationManager.success('Team updated', 'SUCCESS', 3000);
            }).catch(error => {
                let msg = "Update failed"
                if (error && error.detail) {
                    msg = error.detail
                }
                NotificationManager.error(msg, 'ERROR', 5000);
            })
            .finally(() => {
                dispatch(setLoader2(false));
            });
    }
}

export const actions = {
    ...baseReducer.actions,
    getUrlExelTemplate,
    setItemToNull,
    setExcel,
    createTeam,
    toList,
    getMonthlyProfite,
    getYearlyProfite,
    changeYear,
    getTeam,
    updateTeam,
};

export const reducers = {
    ...baseReducer.reducers,
    [SET_FILE]: (state, {file}) => {
        return {
            ...state,
            file,
        };
    },
    [SET_LOADER_2]: (state, {loader2}) => {
        return {
            ...state,
            loader2,
        };
    },
    [SET_URL_EXCEL_TEMPLATE]: (state, {urlTemplate}) => {
        return {
            ...state,
            urlTemplate,
        };
    },
    [SET_DATA_MONTHLY_PROFITE]: (state, {monthlyProfite}) => {
        return {
            ...state,
            monthlyProfite,
        };
    },
    [SET_LOADER_MONTHLY_PROFITE]: (state, {monthlyProfiteLoader}) => {
        return {
            ...state,
            monthlyProfiteLoader,
        };
    },
    [SET_DATA_YEARLY_PROFITE]: (state, {yearlyProfite}) => {
        return {
            ...state,
            yearlyProfite,
        };
    },
    [SET_LOADER_YEARLY_PROFITE]: (state, {yearlyProfiteLoader}) => {
        return {
            ...state,
            yearlyProfiteLoader,
        };
    },
    [SET_YEAR]: (state, {year}) => {
        return {
            ...state,
            year,
        };
    },
};

export const initialState = {
    ...baseReducer.initialState,
    data: {
        results: [],
        count: 0,
        totals: {
            current_month_fee: 0,
            current_month_jobs: 0,
            current_year_fee: 0,
            current_year_jobs: 0,
            total_agents: 0,
            total_agents_registered: 0,
        },
    },
    file: null,
    urlTemplate: "",
    loader2: false,
    monthlyProfite: {
        total: 0,
        labels: [],
        datasets: [],
    },
    monthlyProfiteLoader: false,
    yearlyProfite: {
        total: 0,
        labels: [],
        datasets: [],
    },
    yearlyProfiteLoader: false,
    year: {
        label: new Date().getFullYear().toString(),
        value: new Date().getFullYear(),
    },
};

export default handleActions(reducers, initialState);
