import React, { Component, useState, useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import AgentReviewGrid from "./AgentReviewGrid"

const AgentRating = (props) => {
    const { rating } = props;
    const [starKeyForce, setStarKeyForce] = useState(0)

    useEffect(() => {
        setStarKeyForce(prev => prev + 1)
    }, [rating])


    return (
        <ReactStars
            size={28}
            count={5}
            value={rating}
            edit={false}
            isHalf={true}
            key={starKeyForce}
            onChange={() => console.log('')}
        />
    )
}


export class AgentReview extends Component {
    state = {
        id: null,
        gridHeight: 200,
    }
    componentDidMount() {
        const { match, toList } = this.props;
        if (match.params.id) {
            this.setState({id: match.params.id})
            toList(match.params.id);
        }

        const _height = document.getElementById("AGENT-REVIEW-GRID").clientHeight;
        this.setState({gridHeight: _height})
    }

    goBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    render() {
        //  State
        const {data, loader} = this.props;
        const {id, gridHeight} = this.state;
        //  Bind
        const {toList, remove} = this.props;
        const agent = data.agent ? data.agent : null
        return (
            <div className="d-flex flex-column flex-1">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">
                            <button
                                className="btn back-btn mr-3 my-2"
                                onClick={this.goBack}
                            >
                                <i className="material-icons pointer">
                                    arrow_back
                                </i>
                            </button>
                            Agent Reviews
                        </div>
                    </div>
                </div>
                <div className="card border-dark  border-card-page px-4 py-2 flex-1">
                    <div className="card-body px-0 py-2 d-flex flex-column">
                        <div className="row flex-1">
                            <div className="col-lg-3 col-md-4 col-sm-12">
                                {agent && (
                                    <div className="row">
                                        <div className="col-sm-12 d-flex flex-column align-items-center">
                                            <img
                                                src={
                                                    agent.photo
                                                        ? agent.photo
                                                        : require("../../../../../assets/img/uploadLogo.PNG")
                                                }
                                                style={{
                                                    width: agent.photo
                                                        ? "160px"
                                                        : "30px",
                                                    height: agent.photo
                                                        ? "160px"
                                                        : "25px",
                                                }}
                                                alt="logo"
                                                className="align-self-center justify-content-center rounded-circle"
                                            />
                                        </div>
                                        <div className="col-sm-12 d-flex flex-column align-items-center">
                                            <h4 className="text-center font-weight-bold mt-2">
                                                {agent.first_name}{" "}
                                                {agent.last_name}
                                            </h4>
                                        </div>
                                        <div className="col-sm-12 d-flex flex-column align-items-center">
                                            <AgentRating rating={agent.rating >= 0 && agent.rating}/>
                                            <span className="font-weight-bolder h4 mb-0" style={{color: '#F7D701'}}>{agent.rating >= 0 ? agent.rating: 0}</span>
                                        </div>

                                        <div className="col-sm-12 d-flex flex-column align-items-center">
                                            <h4 className="text-center text-dark font-weight-bold mt-5 mb-0">{data.count ? data.count : 0}</h4>
                                            <h4 className="text-center font-weight-bold mt-1 mb-0">Reviews</h4>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div id="AGENT-REVIEW-GRID" className="col-lg-9 col-md-8 col-sm-12 mt-3 mt-lg-0">
                                <AgentReviewGrid
                                    id={id}
                                    loader={loader}
                                    data={data}
                                    toList={toList}
                                    height={gridHeight}
                                    remove={remove}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AgentReview;
