import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/agent/agentReviews';
import AgentReview from "./AgentReview";

const ms2p = (state) => {
    return {
        ...state.agentReviews,
    };
};

const md2p = { 
    ...actions
};

export default connect(ms2p, md2p)(AgentReview);
