import React, {Component} from 'react';
import BrokerProfileForm from "./BrokerProfileForm";
import LoadMask from "../../Utils/LoadMask/LoadMask";


class BrokerProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const {me} = this.props;
        this.props.getBrokerProfile(me.broker_company);
    }

    updateAction = (data) => {
        this.props.updateProfileBroker(data.id, data)
    };

    render() {
        const {loader, itemBroker, goToPage, assignAddressData} = this.props;

        return (
            <div id="PAGE-CONTAINER">
                <div className=" pl-1 pt-3 no-gutters row">
                    <div className="mb-2">
                        <div className="header-page">Broker Profile</div>
                    </div>
                </div>
                <div className="general-container card border-dark border-card-page px-4 py-4">
                    <LoadMask loading={loader} dark blur>
                        <BrokerProfileForm
                            onSubmit={this.updateAction}
                            item={itemBroker}
                            goToPage={goToPage}
                            assignAddressData={assignAddressData}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default BrokerProfile;

