import { api } from "api";
import { push } from "react-router-redux";
import { handleActions } from "redux-actions";
import { NotificationManager } from "react-notifications";
import { initialize as initializeForm } from 'redux-form';

// ------------------------------------
// Constants
// ------------------------------------
const endpoint = 'influencer/help_support'
const LOADER = "HELP_SUPPORT_LOADER";

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

// -----------------------------------
// Actions
// -----------------------------------

const create = (data, attachments, goBack) => (dispatch) => {
    dispatch(setLoader(true));
    if (attachments && attachments.length) {
        const files = [{name: 'file', file: attachments[0]}];
        api.postAttachments(endpoint, data, files).then(() => {
            goBack();
            NotificationManager.success('Thanks for your feedback', 'Success', 3000);
        }).catch(() => {
            NotificationManager.error('There was an error, please try again', 'ERROR');
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }else {
        api.post(endpoint, data).then(() => {
            goBack();
            NotificationManager.success('Thanks for your feedback', 'Success', 3000);
        }).catch(() => {
            NotificationManager.error('There was an error, please try again', 'ERROR');
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
};

export const actions = {
    create
}

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
}
const initialState = {
    loader: false,
}

export default handleActions(reducers, initialState);
