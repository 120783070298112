import './vendorRegister.css';
import React, { Component } from "react";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import { InfoVendor, StepProgressBar, SuccessRegistered } from "./VendorRegisterComnponents";
import VendorStep2Form from "./VendorStep2Form";
import VendorStep3Form from "./VendorStep3Form";
import VendorStep5Form from "./VendorStep5Form";
import VendorStep6Form from "./VendorStep6Form";

export default class VendorRegister extends Component {
    state = {
        insuranceName: '',
        insuranceFile: null,
        insuranceActive: true,
        certificationName: '',
        certificationFile: null,
        certificationActive: true,
        businessRegistrationName: '',
        businessRegistrationFile: null,
        businessRegistrationActive: true,
    }
    componentDidMount() {
        this.props.getProfessions();
        this.setState({
            insuranceActive: true,
            insuranceFile: null,
            insuranceName: '',
            certificationName: '',
            certificationFile: null,
            certificationActive: true,
        });
        this.props.resetInitialData()
    }

    setInsurance = (file) => {
        this.setState({
            insuranceName: file.name,
            insuranceFile: file,
            insuranceActive: false,
        });
    };
    setCertification = (file) => {
        this.setState({
            certificationName: file.name,
            certificationFile: file,
            certificationActive: false,
        });
    };
    setBusinessRegistration = (file) => {
        this.setState({
            businessRegistrationName: file.name,
            businessRegistrationFile: file,
            businessRegistrationActive: false,
        });
    };

    setInsuranceActive = (value) => {
        this.setState({
            insuranceActive: value,
            insuranceFile: null,
            insuranceName: '',
        });
    };

    setCertificationActive = (value) => {
        this.setState({
            certificationActive: value,
            certificationFile: null,
            certificationName: '',
        });
    };

    setBusinessRegistrationActive = (value) => {
        this.setState({
            businessRegistrationActive: value,
            businessRegistrationFile: null,
            businessRegistrationName: '',
        });
    };

    actionOnSubmit = () => {
        let files = []
        const { insuranceFile, certificationFile, businessRegistrationFile } = this.state
        if (insuranceFile) {
            files.push({ name: 'insurance', file: insuranceFile })
        }
        if (certificationFile) {
            files.push({ name: 'certification', file: certificationFile })
        }
        if (businessRegistrationFile) {
            files.push({ name: 'business_registration', file: businessRegistrationFile })
        }
        this.props.onSubmitAction(files);
    };

    render() {
        //  State
        const {
            step,
            code,
            data,
            loader,
            success,
            professions,
            stepBack,
            register,
        } = this.props;
        const {
            insuranceName,
            insuranceActive,
            certificationName,
            certificationActive,
            businessRegistrationName,
            businessRegistrationActive,
        } = this.state;
        //  Bind
        const {
            clickBack,
            onSubmitStep,
            assignAddressData,
            validateCode,
            resendCodePhone,
            resendCodeEmail,
        } = this.props;

        return (
            <div className="body-img">
                <div className="blue-gradient-bg d-flex align-items-center">
                    <div className='vendor-register-container'>
                        <div
                            className="vendor-register-wrapper"
                        >
                            <div
                                id='VENDOR-REGISTER'
                                className={`card vendor-register-card col-lg-10 col-sm-11`}
                            >
                                <div className="vendor-register-card-body">
                                    <div className="row flex-1">
                                        <div className="col-lg-4 col-md-3 col-12">
                                            <div className="vendor-register-title-page-container">
                                                <div className="vendor-register-titles-container mt-1 mt-lg-3">
                                                    <span className='vendor-register-title'>VENDOR</span>
                                                    <span className='vendor-register-subtitle'>Sign Up</span>
                                                </div>
                                                <div className="vendor-register-logo-container mt-4 mt-md-0">
                                                    <img
                                                        src={require("../../../../../assets/img/logo-01.png")}
                                                        alt={"showIt logo"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-9 col-12">
                                            <div className="vendor-register-form-container">
                                                <LoadMask loading={loader} light>
                                                    <div className="mb-2">
                                                        <div className="d-flex flex-row align-items-center mb-2">
                                                            <div className='vendor-register-back-button-container'>
                                                                {!success && step > 20 && step <= 100 && !register && (
                                                                    <div className='vendor-register-back-button' onClick={() => clickBack()}>
                                                                        <img
                                                                            src={require("../../../../../assets/img/chevron-left-solid.svg")}
                                                                            alt="back"
                                                                            />
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <span className='vendor-register-form-title'>Create an Account</span>
                                                        </div>
                                                        {!success && step < 100 && (
                                                            <StepProgressBar progress={step} />
                                                        )}
                                                    </div>
                                                    {step === 20 && (
                                                        <InfoVendor
                                                            onSubmit={() => onSubmitStep()}
                                                        />
                                                    )}
                                                    {step === 40 && (
                                                        <VendorStep2Form
                                                            onSubmit={() => onSubmitStep()}
                                                            assignAddressData={
                                                                assignAddressData
                                                            }
                                                        />
                                                    )}
                                                    {step === 60 && (
                                                        <VendorStep3Form
                                                            optionProfessions={professions}
                                                            onSubmit={() => onSubmitStep()}
                                                            insuranceText={insuranceName}
                                                            insuranceActive={insuranceActive}
                                                            certificationText={certificationName}
                                                            certificationActive={certificationActive}
                                                            businessRegistrationText={businessRegistrationName}
                                                            businessRegistrationActive={businessRegistrationActive}
                                                            setInsurance={this.setInsurance}
                                                            setInsuranceActive={this.setInsuranceActive}
                                                            setCertification={this.setCertification}
                                                            setCertificationActive={this.setCertificationActive}
                                                            setBusinessRegistration={this.setBusinessRegistration}
                                                            setBusinessRegistrationActive={this.setBusinessRegistrationActive}
                                                        />
                                                    )}
                                                    {step === 80 && (
                                                        <VendorStep5Form
                                                            onSubmitStep={onSubmitStep}
                                                            validateCode={validateCode}
                                                            code={code}
                                                            stepBack={stepBack}
                                                            resendCode={resendCodePhone}
                                                        />
                                                    )}
                                                    {step === 100 && (
                                                        <VendorStep6Form
                                                            register={register}
                                                            onSubmitStep={() => {
                                                                if (register) {
                                                                    this.actionOnSubmit()
                                                                } else {
                                                                    onSubmitStep()
                                                                }
                                                            }}
                                                            validateCode={(value) => {
                                                                if (!register) {
                                                                    validateCode(value)
                                                                }
                                                            }}
                                                            code={code}
                                                            stepBack={stepBack}
                                                            resendCode={resendCodeEmail}
                                                        />
                                                    )}
                                                    {step === 120 && (
                                                        <SuccessRegistered />
                                                    )}
                                                </LoadMask>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
