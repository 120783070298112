import {Collapse} from 'reactstrap';
import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {
    PENDING,
} from "../../../../utility/constants";
import {Redirect} from "react-router";

class SideBarMCAFinance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            isStats: false,
        };
    }

    toggle = (isStats = false) => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
            isStats: isStats,
        });
    };

    customClick = () => {
        this.toggle(true);
        // this.props.navToggle();
    };

    customClickNav = () => {
        if (this.state.dropdownOpen) {
            this.toggle(false);
        }
        this.setState({isStats: false});
        this.props.navToggle();
    };

    validateLocation = () => {
        if (this.props.url) {
            const path = this.props.url
            if (path === '/' || path === '/wallet' || path === '/user-profile' || path === '/edit-user-profile' || path.includes('/stats') || path === '/help-and-support') {
                console.log('')
            } else {
                return <Redirect to="/page-not-found"/>
            }
        }
    }

    render() {
        const {toggleOpen, navToggle, user, textColor, url} = this.props;
        return (
            <aside className={`main-sidebar px-0 col-md-3 col-lg-x-2 ${toggleOpen ? '' : 'open'}`}>
                {this.validateLocation()}
                <div className="main-navbar my-0 py-0">
                    <nav className="align-items-stretch flex-md-nowrap navbar mb-5 pt-0">
                        <a href="#" className="w-100 h-100">
                            <div className="d-table m-auto">
                                <img id="main-logo"
                                     className="d-inline-block align-top mx-2 p-0 "
                                     src={require('assets/img/logo-01.png')}
                                     alt="Logo"/>
                            </div>
                        </a>
                        <a
                            className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
                            onClick={this.customClickNav}>
                            <i className="material-icons"></i>
                        </a>
                    </nav>
                </div>
                <div className="nav-wrapper mt-4">
                    <ul className="nav--no-borders flex-column nav">
                        <li className="nav-item" onClick={this.customClickNav}>
                            <NavLink
                                to="/"
                                exact
                                className="nav-link"
                                activeClassName={'active text-dark'}
                            >
                                <i className="material-icons mr-2">home</i>
                                Home
                            </NavLink>
                        </li>
                        {user.broker_verified !== PENDING &&
                        <React.Fragment>
                            <li className="nav-item">
                                <a
                                    // this.state.isStats
                                    className={`nav-link ${`${url}`.includes('/stats') && 'active'}`}
                                    style={{
                                        color: textColor,
                                        cursor: 'pointer',
                                    }}
                                    id="stats-id"
                                    onClick={this.customClick}
                                >
                                    <i className="material-icons mr-2">bar_chart</i>
                                    Stats
                                </a>
                                <Collapse isOpen={this.state.dropdownOpen}>
                                    <ul className="custom_nav_ul" id='collapse-1'>
                                        <li className="nav-item" onClick={navToggle}>
                                            <NavLink
                                                to="/stats/revenue"
                                                className="nav-link pl-5 nav-white"
                                                activeClassName={'active-2'}
                                            >
                                                Revenue
                                            </NavLink>
                                        </li>
                                        <li className="nav-item" onClick={navToggle}>
                                            <NavLink
                                                className="nav-link pl-5"
                                                to="/stats/agent-performance"
                                                activeClassName={'active-2'}
                                            >
                                                Agent Performance
                                            </NavLink>
                                        </li>
                                        <li className="nav-item" onClick={navToggle}>
                                            <NavLink
                                                className="nav-link pl-5"
                                                to="/stats/job-detail-raw-data"
                                                activeClassName="active-2"
                                            >
                                                Job Details Raw Data
                                            </NavLink>
                                        </li>
                                    </ul>
                                </Collapse>
                            </li>
                            <li className="nav-item text-danger" onClick={this.customClickNav}>
                                <NavLink
                                    to="/wallet"
                                    className={`nav-link ${!user.stripe_active && 'text-danger font-weight-bold'}`}
                                    activeClassName={'active text-white'}
                                >
                                    <i className={`material-icons mr-2 ${!user.stripe_active && 'text-danger'}`}>account_balance_wallet</i>
                                    Wallet
                                </NavLink>
                            </li>
                            <li className="nav-item" onClick={this.customClickNav}>
                                <NavLink
                                    to="/help-and-support"
                                    exact
                                    className="nav-link"
                                    activeClassName={'active text-dark'}
                                >
                                    <i className="material-icons mr-2">contact_support</i>
                                    Help & Support
                                </NavLink>
                            </li>
                        </React.Fragment>
                        }
                    </ul>
                </div>
            </aside>
        );
    }
}

export default SideBarMCAFinance;
