import React, {useEffect} from 'react';
import {Field, reduxForm} from 'redux-form';
import {renderField} from '../../../Utils/renderField';
import {minLength} from "../../../../../utility/validation";
import {validate, validatorFromFunction, validators, combine} from 'validate-redux-form';

const RegisterPassword = (props) => {
    const {handleSubmit, showPassword, showPassAction} = props;
    
    useEffect(() => {
        if (document.getElementById('BROKER-REGISTER')) {
            document.getElementById('BROKER-REGISTER').scrollIntoView({ behavior: 'smooth' });
        }
    }, [])

    return (
        <form name="accountPassword" className="w-100 col-sm-12 mt-2 mt-lg-4" onSubmit={handleSubmit} style={{minHeight: '20rem'}}>
            <div className="row pt-4">
                <div className="form-group has-feedback col-xl-5 col-lg-6 col-md-9 col-sm-12 mx-auto">
                    <label htmlFor="password">Create your account password</label>
                    <div className="w-100 mx-auto row">
                        <Field
                            name="password"
                            placeholder="Minimum 6 characters"
                            component={renderField}
                            container={"w-100"}
                            type={`${showPassword ? 'text' : 'password'}`}
                            className="form-control"
                            validate={[minLength(6)]}
                        />
                        <label className="button-eyes"
                                onClick={showPassword ? () => showPassAction(false) : () => showPassAction(true)}>
                            {showPassword ?
                                <i className="material-icons pointer">visibility_off</i> :
                                <i className="material-icons pointer">visibility</i>
                            }
                        </label>
                    </div>
                </div>
            </div>
            <div className="row pb-5">
                <div className="form-group has-feedback col-xl-5 col-lg-6 col-md-9 col-sm-12 mx-auto">
                    <label htmlFor="confirmPassword">Confirmed your account password*</label>
                    <div className="w-100 mx-auto row">
                        <Field
                            container={"w-100"}
                            name="confirmPassword"
                            component={renderField}
                            type={`${showPassword ? 'text' : 'password'}`}
                            className="form-control w-100"
                        />
                        <label className="button-eyes"
                                onClick={showPassword ? () => showPassAction(false) : () => showPassAction(true)}>
                            {showPassword ?
                                <i className="material-icons pointer">visibility_off</i> :
                                <i className="material-icons pointer">visibility</i>
                            }
                        </label>
                    </div>
                </div>
            </div>
            <div className="row mb-4">
                <div className="buttons-box col-12 justify-content-center mx-auto py-md-3 py-4">
                    <button type="submit" className="btn vendor-register-button col-xl-4 col-lg-5 align-self-center">
                        Register
                    </button>
                </div>
            </div>
        </form>
    );
};

export const matchPassword = (passe, confirm) => validatorFromFunction(() => {
    return passe === confirm;
});

export default reduxForm({
    form: 'accountPassword', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            confirmPassword: combine(
                validators.exists()('This field is required'),
                matchPassword(data.password, data.confirmPassword)()('The account password don\'t match'),
            ),
            password: validators.exists()('This field is required'),
        });
    },
})(RegisterPassword);
