import React from 'react';
import {
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';

// Profile, login register
import {Login, Profile, Register} from './common/components/LoginRegister';
import {Profile as EditProfile} from './common/components/LoginRegister';
import RegisterUserInvite from './common/components/LoginRegister/RegisterForInvited';

//  Vendor Register
import {VendorRegister} from './common/components/VendorRegister'
//  Agent Register
import AgentRegister from './common/components/AgentRegister';
//  Wholesaler Register
import WholesalerRegister from './common/components/WholeSalerRegister';

// Dashboard
// import Demo from './common/components/Demo/Demo';
import Dashboard from './common/components/Dashboard';

// Page NotFound
import NotFound from './common/components/layout/NotFound/NotFound';

// Examples and Utils
import {NotificationContainer} from 'react-notifications';
import Grids from "./common/components/Examples/Grids";
import Notifications from './common/components/Examples/Notificaciones';
import ExampleTabs from './common/components/Examples/Tabs/Tabs';

// CRUD Brokerages
import Brokerage from './common/components/Brokerage/BrokerageContainer';
import BrokerageCreate from './common/components/Brokerage/CrateContainer';
import UpdateBrokerage from './common/components/Brokerage/CrateContainer';
import BrokerageDetail from './common/components/Brokerage/DetailsContainer';
import UserChangePassword from './common/components/Brokerage/UserChangePassword'

// CRUD AGENTS
// import Agent from './common/components/AgentList/Unregister/AgentContainer';
import AgentTab from './common/components/AgentList/index';
import CreateAgentRoster from './common/components/AgentList/CreateAgentRoster';
import UpdateAgent from './common/components/AgentList/Register/UpdateContainer';
import ChangedPassword from './common/components/AgentList/Password/Index';
import AgentLockNote from './common/components/AgentList/LockNotes';
import AgentReviewList from './common/components/AgentList/AgentReview'

// Invites to Broker
import Brokers from './common/components/Broker/ListContainer';
import Invite from './common/components/Broker/InvitesContainer';

// BROKER PROFILE
import BrokerProfile from './common/components/LoginRegister/BrokerProfile';

// Broker Qualifying
import InvitesQualifyingForm from "./common/components/QualifyingBroker";
import VerifyQualifying from "./common/components/QualifyingBroker/verifyInvite";

// Wallet
import Waller from './common/components/Wallet'

// Stats
import Stats from './common/components/Stats/Revenue';
import AgentPerformance from './common/components/Stats/AgentPerformance/JobPerformance';
// import AgentPerformance from './common/components/Stats/AgentPerformance/index'
import AgentPerformanceDetails from './common/components/Stats/AgentPerformance/AgentDetail';
import AgentPostsDetails from './common/components/Stats/AgentPerformance/PostsDetails';
import { JobDetailRawDataList } from './common/components/Stats/JobDetailRawData'

//  INFLUENCER
import {InfluencerProfile, LeadingList, InfluencerAgentsList, HelpSupport} from './common/components/Influencer'

//  AGENTS AND JOBS
import AgentsJobs from './common/components/AgentsJobs'

// Forgot-Password
import CreateLinkToChangePass from './common/components/LoginRegister/ForgotPass/CreateLink'
import ResetPass from './common/components/LoginRegister/ForgotPass/ChangePass'

// All Styles
require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
import 'bootstrap/dist/css/bootstrap.min.css';

require('../assets/fonts/fonts.css');
require('../style/index.css');


module.exports = (
    <div>
        <div className="container__content">
            <Switch>
                <Route exact path="/login" component={Login}/>
                <Route exact path="/register" component={Register}/>
                <Route exact path="/register2" component={RegisterUserInvite}/>
                <Route exact path="/vendor_register" component={VendorRegister}/>
                <Route exact path="/agent_register" component={AgentRegister}/>
                <Route exact path="/wholesaler_register" component={WholesalerRegister}/>
                <Route exact path="/wholesaler_register/:team_id" component={WholesalerRegister}/>
                <Route exact path="/forgot-password" component={CreateLinkToChangePass}/>
                <Route exact path="/reset-password/:code" component={ResetPass}/>
                {/* Verify invite Qualifying Broker*/}
                <Route exact path="/invites/:token" component={VerifyQualifying}/>
                {/* Send invites Qualifying Broker*/}
                <ProtectedRoute exact path="/invites-qualifying" component={InvitesQualifyingForm}/>
                {/* Dashboard */}
                <ProtectedRoute exact path="/" component={Dashboard}/>
                {/* Wallet */}
                <ProtectedRoute exact path="/wallet" component={Waller}/>
                {/* Stats */}
                <ProtectedRoute exact path="/stats/revenue" component={Stats}/>
                <ProtectedRoute exact path="/stats/agent-performance" component={AgentPerformance}/>
                <ProtectedRoute exact path="/stats/agent-performance/:id/details" component={AgentPerformanceDetails}/>
                <ProtectedRoute exact path="/stats/agent-performance/:id/posts-details" component={AgentPostsDetails}/>
                <ProtectedRoute exact path="/stats/job-detail-raw-data" component={JobDetailRawDataList} />
                {/* Brokerage */}
                <ProtectedRoute exact path="/brokerage" component={Brokerage}/>
                <ProtectedRoute exact path="/brokerage/add-brokerage" component={BrokerageCreate}/>
                <ProtectedRoute exact path="/brokerage/:id/edit" component={UpdateBrokerage}/>
                <ProtectedRoute exact path="/brokerage/:id/details" component={BrokerageDetail}/>
                <ProtectedRoute exact path="/brokerage/:company_id/users/invite" component={Invite}/>
                <ProtectedRoute exact path="/brokerage/:company_id/users/:id/edit" component={Invite}/>
                <ProtectedRoute exact path="/brokerage/users/:id/change-password" component={UserChangePassword}/>
                {/* Agents */}
                <ProtectedRoute exact path="/agents" component={AgentTab}/>
                <ProtectedRoute exact path="/agents/agent/:id/edit" component={UpdateAgent}/>
                <ProtectedRoute exact path="/agents/agent-details/:id/" component={UpdateAgent}/>
                <ProtectedRoute exact path="/agents/:brokerage_id/create-agent-roster" component={CreateAgentRoster}/>
                <ProtectedRoute exact path="/agents/agent-roster/:id/edit" component={CreateAgentRoster}/>
                <ProtectedRoute exact path="/agents/agent/:id/edit/changed-password" component={ChangedPassword}/>
                <ProtectedRoute exact path="/agents/agent/:id/lock-notes" component={AgentLockNote}/>
                <ProtectedRoute exact path="/agents/agent-reviews/:id" component={AgentReviewList}/>
                {/* Users */}
                <ProtectedRoute exact path="/users" component={Brokers}/>
                <ProtectedRoute exact path="/users/invite" component={Invite}/>
                <ProtectedRoute exact path="/users/:id/edit" component={Invite}/>
                <ProtectedRoute exact path="/users/:id/details" component={Invite}/>
                {/*Profile*/}
                <ProtectedRoute exact path="/user-profile" component={Profile}/>
                <ProtectedRoute exact path="/edit-user-profile" component={EditProfile}/>
                {/*  BROKER PROFILE  */}
                <ProtectedRoute exact path="/broker-profile" component={BrokerProfile}/>
                {/*<ProtectedRoute exact path="/grids" component={Grids}/>*/}
                <ProtectedRoute exact path="/notifications" component={Notifications}/>
                <ProtectedRoute exact path="/help-and-support" component={HelpSupport}/>
                {/*<ProtectedRoute exact path="/tabs" component={ExampleTabs}/>*/}
                {/* INFLUENCER */}
                <ProtectedRoute exact path="/influencer/profile" component={InfluencerProfile}/>
                <ProtectedRoute exact path="/influencer/leading-list" component={LeadingList}/>
                <ProtectedRoute exact path="/influencer/agents" component={InfluencerAgentsList}/>
                <ProtectedRoute exact path="/influencer/help-and-support" component={HelpSupport}/>
                <ProtectedRoute exact path="/influencer/:id/changed-password" component={ChangedPassword}/>

                {/* AGENTS AND JOBS */}
                <ProtectedRoute exact path="/agents-jobs" component={AgentsJobs}/>

                <Route path="/page-not-found" component={NotFound}/>
                <Route component={NotFound}/>
            </Switch>
        </div>
        <NotificationContainer/>
    </div>
);
