import React, { Component } from 'react';
import UpdateForm from "./BrokerageUpdateForm";
import LoadMask from "../Utils/LoadMask/LoadMask";
import { NotificationManager } from "react-notifications";

class Create extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_update: false,
            photo: '',
            nameFile: '',
            file: null,
            active: true,
            new_file: false,
        };
    }

    componentDidMount() {
        const { match, read, setItem } = this.props;
        if (match.params.id) {
            read(match.params.id);
            this.props.agentList(1, match.params.id);
            this.setState({ is_update: true });
            const { item } = this.props;
            this.setState({ photo: item !== null && item.logo ? item.logo : '' });
        } else {
            setItem(null);
            this.setState({
                is_update: false,
                photo: '',
                nameFile: '',
            });
        }
        this.props.getUrlExelTemplate();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.id) {
            const { item } = this.props;
            this.setState({
                is_update: true,
                photo: item && item.logo ? item.logo : null,
            });
        }
        if (!nextProps.match.params.id) {
            this.setState({
                is_update: false,
                photo: null,
            });
        }
    }

    actionFile = (data) => {
        const { setFiles } = this.props;
        setFiles(data);
        this.setState({ photo: data });
    };

    onChanged = (file) => {
        const { setExcel } = this.props;
        this.setState({
            nameFile: file.name,
            file: file,
            active: false,
        });
        setExcel(file);
    };

    setActive = (value) => {
        this.setState({
            active: value,
            file: null,
            nameFile: '',
        });
        const { setExcel } = this.props;
        setExcel([]);
    };

    submitAction = (data) => {
        const { match, createAttachment } = this.props;
        if (match.params.id) {
            this.props.updateAttachment(match.params.id, data);
        } else {
            if (this.state.file) {
                createAttachment(data);
            } else {
                // NotificationManager.error('Agent list is required', 'ERROR', 3000);
                createAttachment(data);
            }
        }
    };

    setNewFileButton = () => {
        this.setState({
            active: true,
            file: null,
            nameFile: '',
            new_file: true,
        });
        const { setExcel } = this.props;
        setExcel([]);
    };

    goBack = () => {
        const {history} = this.props
        history.goBack();
    }

    render() {
        //  State
        const { loader, item, urlTemplate, loader2, roster } = this.props;
        //  Bind
        const {searchAgent, agentList, removeInvite, assignAddressData} = this.props

        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="mb-2">
                        <div className="header-page">
                            <button
                                className="btn back-btn mr-3 my-2"
                                onClick={this.goBack}
                            >
                                <i className="material-icons pointer">
                                    arrow_back
                                </i>
                            </button>
                            {
                                this.state.is_update ? "Office Detail" : "Create Office"
                            }
                        </div>
                    </div>
                </div>
                <div className="general-container card border-dark border-card-page px-4 py-4">
                    <LoadMask loading={loader} light>
                        <UpdateForm
                            text={this.state.nameFile}
                            loader={loader}
                            setLogo={this.setLogo}
                            handleOnChange={this.onChanged}
                            setFiles={this.actionFile}
                            office={item}
                            onSubmit={this.submitAction}
                            is_update={this.state.is_update}
                            active={this.state.active}
                            setActive={this.setActive}
                            new_file={this.state.new_file}
                            setNewFileButton={this.setNewFileButton}
                            urlTemplate={urlTemplate}
                            goToAgents={() => {}}
                            goBack={this.goBack}
                            data={roster}
                            searchAction={searchAgent}
                            loader2={loader2}
                            agentList={agentList}
                            removeInvite={removeInvite}
                            assignAddressData={assignAddressData}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default Create;
