import React, {Component} from 'react';
import ChangePasswordForm from "./ChangePasswordForm"
import LoadMask from "../../../Utils/LoadMask/LoadMask";


class ChangePass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPass: false,
            expired: false
        }
    }

    componentDidMount() {
        const {match} = this.props;
        if (match.params.code) {
            this.props.verifyCodeToResetPass(match.params.code, this.setExpiredCode)
        }
    }

    componentWillUnmount() {
        this.props.setItem({})
    }

    handleSubmit = (data) => {
        this.props.resetPass(data);
    }

    showPassword = (value) => {
        this.setState({showPass: value})
    }

    setExpiredCode = () => {
        this.setState({expired: true})
    }


    render() {
        const {loader} = this.props;
        const {expired} = this.state;

        return (
            <div className="body-img">
                <div className="blue-gradient-bg">
                    <div className="login-wrapper  h-100" style={{minWidth: '60%'}}>
                        <div className="card card-login col-sm-12 col-lg-4 px-lg-5">
                            <div className="container-logo-register justify-content-center mt-0 align-self-center">
                                <img
                                    src={require("../../../../../../assets/img/logo-01.png")}
                                    className="logo-form-sing-in"
                                    alt={"ShowIt Logo"}
                                    style={{width: '50%'}}
                                />
                            </div>
                            <LoadMask loading={loader} light>
                                <div className="px-lg-5 py-3 px-sm-0">
                                    <h6 className="font-weight-bold py-2">
                                        Reset Password
                                    </h6>
                                    {
                                        !expired ?
                                            <p className='pt-3 pb-5 text-12'>
                                                The link to recover your password has probably expired.
                                            </p> :
                                            <ChangePasswordForm
                                                onSubmit={this.handleSubmit}
                                                showPassAction={this.showPassword}
                                                showPassword={this.state.showPass}
                                            />
                                    }
                                </div>
                            </LoadMask>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ChangePass;
