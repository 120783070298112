import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/stats/agentPerformance'
import Performance from "./Performance";

const ms2p = (state) => {
    return{
        ...state.statsAgentPerformance,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Performance);