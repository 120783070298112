import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { combine, validate, validatorFromFunction, validators } from 'validate-redux-form';
import { renderField, renderNumber } from '../../../Utils/renderField';

const SignupFormOtherUser = (props) => {
    const { handleSubmit, ActionClick } = props;
    return (
        <form name="registerStep6" className="w-100 col-sm-12" onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group has-feedback col-lg-9 col-md-9 col-sm-12 mx-auto">
                    <label className="text-dark" style={{ fontSize: "15px" }}>
                        Optional
                    </label>
                    <label className="pb-2">
                        It is your choice to grant permission to another person 
                        in your office to manage your ShowIT Solutions™ back 
                        office on your behalf.
                    </label>
                    <label htmlFor="other_first_name" className="mb-0">
                        {" "}
                        First Name
                    </label>
                    <Field
                        name="other_first_name"
                        label="Name"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-lg-9 col-md-9 col-sm-12 mx-auto">
                    <label htmlFor="other_last_name" className="mb-0">
                        {" "}
                        Last Name{" "}
                    </label>
                    <Field
                        name="other_last_name"
                        label="Last Name"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-lg-9 col-md-9 col-sm-12 mx-auto">
                    <label htmlFor="other_email" className="mb-0">
                        {" "}
                        E-mail address{" "}
                    </label>
                    <Field
                        name="other_email"
                        label="Other Email"
                        component={renderField}
                        type="email"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-lg-9 col-md-9 col-sm-12 mx-auto">
                    <label htmlFor="other_mobile_number" className="mb-0">
                        {" "}
                        Phone{" "}
                    </label>
                    <Field
                        numberFormat={"###-###-####"}
                        name="other_mobile_number"
                        component={renderNumber}
                        className="form-control"
                    />
                </div>

                <div className="buttons-box row w-75 mx-auto pt-3">
                    <button
                        type="submit"
                        className="btn button-01 col-md-4 py-0 align-self-center"
                    >
                        Next
                    </button>
                </div>
                <div className="buttons-box row w-75 mx-auto pt-3 mb-0 pb-0">
                    <p className={"skip mb-0"} onClick={ActionClick}>
                        Skip
                    </p>
                </div>
            </div>
        </form>
    );
};

const numberPhone = (number) => validatorFromFunction(() => {
    return number.length === 10;
});

export default reduxForm({
    form: 'registerStep6', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            other_last_name: validators.exists()('This field is required'),
            other_first_name: validators.exists()('This field is required'),
            other_mobile_number: combine(
                validators.exists()('This field is required'),
                numberPhone(data.other_mobile_number)()('Invalid phone number'),
            ),
            other_email: validators.exists()('This field is required'),
        });
    },
})(SignupFormOtherUser);
