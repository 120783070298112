import React, {Component} from 'react';
import {Link} from "react-router-dom";


class NotFound extends Component {
    render() {
        const userType = localStorage.getItem('user_type')

        const isInfluencer = userType == 20;

        return (
            <div className="d-flex flex-column align-items-center pt-3">
                <div className="error-template">
                    <h1>Oops!</h1>
                    <h2>404 Page not found</h2>
                    <div className="error-actions">
                        {isInfluencer ? (
                            <Link to="/influencer/profile"> Return</Link>
                        ): (
                            <Link to="/"> Return</Link>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default NotFound;
