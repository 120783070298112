import {handleActions} from 'redux-actions';
import {push} from "react-router-redux";
import {NotificationManager} from "react-notifications";
import {api} from "api";
import {setMe, getMe} from "./login";
import {initialize as initializeForm, change} from "redux-form";

const LOADER = 'LOGIN_LOADER';
const ITEM_BROKER_PROFILE = 'ITEM_BROKER_PROFILE__GENERAL';

export const constants = {};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setItemProfileBroker = itemBroker => ({
    type: ITEM_BROKER_PROFILE,
    itemBroker,
});
// ------------------------------------
// Actions
// ------------------------------------

export const update = (data = {}, attachments = []) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.putAttachments('user/update_me', data, attachments)
        .then((response) => {
            // dispatch(setMe(response));
            dispatch(getMe());
            dispatch(push("/"));
            NotificationManager.success('Profile updated', 'SUCCESS', 3000);
        })
        .catch(() => {
            NotificationManager.error('Fail to update profile, please try again', 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const changePassword = (data = {}) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`broker-user/change_password`, data)
        .then((response) => {
            const me = response;
            dispatch(setMe(me));
            NotificationManager.success('Account password updated', 'SUCCESS', 3000);
        })
        .catch(() => {
            NotificationManager.error('Error to change password', 'ERROR', 2000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const updateProfileBroker = (id = '', data = {}) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`broker/${id}`, data)
        .then((response) => {
            dispatch(getBrokerProfile(id));
            NotificationManager.success('Broker profile updated', 'SUCCESS', 3000);
        })
        .catch(() => {
            NotificationManager.error('Fail to update, please try again', 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const getBrokerProfile = (id = '') => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`broker/${id}`, {})
        .then((response) => {
            dispatch(setItemProfileBroker(response))
            dispatch(initializeForm('brokerProfileForm', response));
        })
        .catch((err) => {
            console.log('err0r in get broker', err)
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const goToPage = (page) => (dispatch) => {
    dispatch(push(page))
}

const assignAddressData = (data) => (dispatch) => {
    if (data) {
        const {state, city, zip_code} = data
        dispatch(change('brokerProfileForm', 'state', state))
        dispatch(change('brokerProfileForm', 'city', city))
        dispatch(change('brokerProfileForm', 'zipcode', zip_code))
    }

}

export const actions = {
    update,
    getMe,
    changePassword,
    updateProfileBroker,
    getBrokerProfile,
    goToPage,
    assignAddressData,
};

export const reducers = {
    [LOADER]: (state, {loader}) => {
        return {
            ...state,
            loader,
        };
    },
    [ITEM_BROKER_PROFILE]: (state, {itemBroker}) => {
        return {
            ...state,
            itemBroker,
        };
    },
};

export const initialState = {
    loader: false,
    itemBroker: {operation_access: 1}
};

export default handleActions(reducers, initialState);
