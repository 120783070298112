import React from "react";
import moment from "moment";
import {connect} from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { renderSearchField, MultiSelectField, renderDayPicker, SelectField } from "../../../Utils/renderField";
import {
    RANKING_LIST_INT,
    SELECT_ALL_OPTION,
    AGENT_PERFORMANCE_TIME
} from '../../../../../utility/constants'

let AgentPerformanceDetailFilterForm = (props) => {
    //  State
    const {
        id,
        timeValue,
        start_dateValue,
        end_dateValue,
        optionJobTypes=[],
    } = props;

    //  Bind
    const {changeFilters} = props;

    return (
        <div className="row">
            <div className={`col-xl-12 col-lg-12 col-sm-12 col-md-12`}>
                <div className="row">
                    <div
                        className={`col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2`}
                    >
                        <div className="form-group has-feedback w-100">
                            <label htmlFor="rating">Assessment</label>
                            <Field
                                name="rating"
                                isMulti={false}
                                className="w-100"
                                options={RANKING_LIST_INT}
                                set_value={(option) => {
                                    changeFilters(
                                        "rating",
                                        id,
                                        ""
                                    );
                                }}
                                component={SelectField}
                            />
                        </div>
                    </div>
                    <div
                        className={`col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2`}
                    >
                        <div className="form-group has-feedback w-100">
                            <label htmlFor="category">Category</label>
                            <Field
                                name="category"
                                options={[
                                    { label: "Posted", value: "posted" },
                                    { label: "Performed", value: "performed" },
                                ]}
                                set_value={(value) => {
                                    changeFilters("category", id, value);
                                }}
                                component={MultiSelectField}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div
                        className={`col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2`}
                    >
                        <div className="form-group has-feedback w-100">
                            <label htmlFor="job_type">Job Type</label>
                            <Field
                                name="job_type"
                                options={optionJobTypes}
                                set_value={(value) => {
                                    changeFilters("job_type", id, value);
                                }}
                                component={MultiSelectField}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2">
                        <div className="form-group has-feedback w-100">
                            <label htmlFor="time">Time</label>
                            <Field
                                name="time"
                                isMulti={false}
                                options={AGENT_PERFORMANCE_TIME}
                                set_value={(option) => {
                                    changeFilters(
                                        "time",
                                        id,
                                        option ? option.value : ""
                                    );
                                }}
                                component={SelectField}
                                className="form-control"
                            />
                        </div>
                    </div>
                    {timeValue == "custom" && (
                        <React.Fragment>
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2">
                                <div className="form-group has-feedback w-100">
                                    <label htmlFor="start_date">
                                        Start Date
                                    </label>
                                    <Field
                                        name="start_date"
                                        initialDate={moment()
                                            .format("YYYY-MM-DD")
                                            .toString()}
                                        component={renderDayPicker}
                                        maxDate={
                                            end_dateValue
                                                ? moment(end_dateValue).toDate()
                                                : new Date()
                                        }
                                        onChange={(e) => {
                                            changeFilters("start_date", id, "");
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2">
                                <div className="form-group has-feedback w-100">
                                    <label htmlFor="end_date">End Date</label>
                                    <Field
                                        name="end_date"
                                        initialDate={moment()
                                            .format("YYYY-MM-DD")
                                            .toString()}
                                        component={renderDayPicker}
                                        minDate={
                                            start_dateValue
                                                ? moment(
                                                      start_dateValue
                                                  ).toDate()
                                                : new Date()
                                        }
                                        onChange={(e) => {
                                            changeFilters("end_date", id, "");
                                        }}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};

AgentPerformanceDetailFilterForm = reduxForm({
    form: 'AgentPerformanceDetailFilterForm', // a unique identifier for this form
    // initialValues: {category: SELECT_ALL_OPTION["value"]}
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(AgentPerformanceDetailFilterForm);

const selector = formValueSelector('AgentPerformanceDetailFilterForm');

const mstp = state => {
    const timeValue = selector(state, 'time');
    const start_dateValue = selector(state, 'start_date');
    const end_dateValue = selector(state, 'end_date');

    return {
        timeValue,
        start_dateValue,
        end_dateValue,
    }
}

export default connect(mstp, null)(AgentPerformanceDetailFilterForm);
