import React from 'react';
import {Field, reduxForm} from "redux-form";
import {renderField} from "../../../Utils/renderField";
import {minLength} from "../../../../../utility/validation";
import {combine, validate, validators} from "validate-redux-form";
import {matchPassword} from "../../Register/Forms/SignupFormStep5";

const ChangePasswordForm = (props) => {
    const {handleSubmit, showPassword, showPassAction} = props;
    return (
        <form
            name="resetPassword"
            className="w-100"
            onSubmit={handleSubmit}
            style={{minHeight: '15rem'}}
        >
            <div className="form-group has-feedback mt-3">
                <label htmlFor="password" className="text-12">New account password</label>
                <div className="w-100 mx-auto row">
                    <Field
                        name="password"
                        placeholder="Minimum 6 characters"
                        component={renderField}
                        container={"w-100"}
                        type={`${showPassword ? 'text' : 'password'}`}
                        className="form-control"
                        validate={[minLength(6)]}
                    />
                    <label className="button-eyes"
                           onClick={showPassword ?
                               () => showPassAction(false) :
                               () => showPassAction(true)}>
                        {showPassword ?
                            <i className="material-icons pointer">visibility_off</i> :
                            <i className="material-icons pointer">visibility</i>
                        }
                    </label>
                </div>
            </div>
            <div className="form-group has-feedback">
                <label htmlFor="confirmPassword" className="text-12">Confirm your new account password</label>
                <div className="w-100 mx-auto row">
                    <Field
                        container={"w-100"}
                        name="confirmPassword"
                        component={renderField}
                        type={`${showPassword ? 'text' : 'password'}`}
                        className="form-control w-100"
                    />
                    <label className="button-eyes"
                           onClick={showPassword ? () => showPassAction(false) : () => showPassAction(true)}>
                        {showPassword ?
                            <i className="material-icons pointer">visibility_off</i> :
                            <i className="material-icons pointer">visibility</i>
                        }
                    </label>
                </div>
            </div>
            <div className="w-100 text-center mt-5">
                <button
                    type="submit"
                    className="btn btn-primary px-5 py-2">
                    Save
                </button>
            </div>
        </form>
    )
}


export default reduxForm({
    form: 'resetPassword', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            confirmPassword: combine(
                validators.exists()('This field is required'),
                matchPassword(data.password, data.confirmPassword)()('The account password don\'t match'),
            ),
            password: validators.exists()('This field is required'),
        });
    },
})(ChangePasswordForm);
