import React, {useEffect, useState} from 'react';


const ImageInputSelect = (props) => {
    const [image, setImageUrl] = useState(null);
    const [loaded, setLoaded] = useState(false);

    const {
        input,
        field,
        disabled,
        setFile,
        className = '',
        initialUri = '',
        placeholder = 'Upload logo',
        meta: {touched, error},
    } = props;

    useEffect(() => {
        console.log('in mount!')
        if (!touched && input.value) {
            console.log('set value in mount!')
            console.log('input.value: ', input.value)
            setImageUrl(input.value)
            setLoaded(true)
        }
    }, [])


    const onFileChange = (e, file) => {
        file = e.target.files[0];
        console.log('----------------------------------------')
        console.log('----------------------------------------')
        const reader = new FileReader();
        if (file) {
            setFile(file)
            reader.onload = (e) => {
                setLoaded(true)
                setImageUrl(reader.result);
                input.onChange(reader.result);
            };
            reader.readAsDataURL(file);
        }
    }

    console.log('value: ', input.value)

    const invalid = touched && error;


    return (
        <div className="w-100">
            <label
                {...field}
                className={`uploader ${image ? 'uploader-start-2' : 'uploader-start'}`}>
                {
                    !image ?
                        <div
                            className="w-75 border-img row d-flex text-center border-img-input"
                            style={{height: '6.5rem'}}
                        >
                            <div className="my-auto col text-12">
                                <p className="text-12 my-auto">
                                    <i className="material-icons">file_upload</i>
                                </p>
                                {placeholder}
                            </div>
                        </div>
                        :
                        <div>
                            <img
                                src={image ? image : require('../../../../../assets/img/uploadLogo.PNG')}
                                title={placeholder}
                                className={loaded ? 'loaded' : undefined}
                                alt='img'
                            />
                        </div>
                }
                <input
                    {...field}
                    type="file"
                    accept="image/*"
                    disabled={disabled}
                    onChange={onFileChange}
                />
            </label>
            {invalid && (
                <div className="invalid-feedback w-100 ml-n3" style={{display: 'block'}}>
                    {error}
                </div>
            )}
        </div>
    )
};

export default ImageInputSelect;
