import {Collapse} from 'reactstrap';
import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {
    AGENT_COORDINATOR,
    MANAGING,
    MCA_FINANCE,
    OFFICE_MANAGER,
    PENDING,
    PRODUCTIVITY_COACH
} from "../../../../utility/constants";

class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            isStats: false,
        };
    }

    toggle = (isStats = false) => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
            isStats: isStats,
        });
    };

    customClick = () => {
        this.toggle(true);
        // this.props.navToggle();
    };

    customClickNav = () => {
        if (this.state.dropdownOpen) {
            this.toggle(false);
        }
        this.setState({isStats: false});
        this.props.navToggle();
    };

    render() {
        const {toggleOpen, navToggle, user, backgroundColor, textColor, url} = this.props;
        return (
            <aside className={`main-sidebar px-0 col-md-3 col-lg-x-2 ${toggleOpen ? '' : 'open'}`}>
                <div className="main-navbar my-0 py-0">
                    <nav className="align-items-stretch flex-md-nowrap navbar mb-5 pt-0">
                        <a href="#" className="w-100 h-100">
                            <div className="d-table m-auto">
                                <img id="main-logo"
                                     className="d-inline-block align-top mx-2 p-0 "
                                     src={require('assets/img/logo-01.png')}
                                     alt="Logo"/>
                            </div>
                        </a>
                        <a
                            className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
                            onClick={this.customClickNav}>
                            <i className="material-icons"></i>
                        </a>
                    </nav>
                </div>
                <div className="nav-wrapper mt-4">
                    <ul className="nav--no-borders flex-column nav">
                        <li className="nav-item" onClick={this.customClickNav}>
                            <NavLink
                                to="/"
                                exact
                                className="nav-link"
                                activeClassName={'active text-dark'}
                            >
                                <i className="material-icons mr-2">home</i>
                                Home
                            </NavLink>
                        </li>
                        {user.broker_verified !== PENDING &&
                        <React.Fragment>
                            {
                                (user.roles === MANAGING || user.roles === OFFICE_MANAGER) &&
                                <li className="nav-item" onClick={this.customClickNav}>
                                    <NavLink
                                        to="/broker-profile"
                                        className="nav-link"
                                        activeClassName={'active'}
                                        style={{color: textColor}}
                                    >
                                        <i className="material-icons mr-2">group</i>
                                        Broker
                                    </NavLink>
                                </li>
                            }
                            {
                                (user.roles === MANAGING || user.roles === OFFICE_MANAGER) &&
                                <li className="nav-item" onClick={this.customClickNav}>
                                    <NavLink
                                        to="/brokerage"
                                        className="nav-link"
                                        activeClassName={'active'}
                                        style={{color: textColor}}
                                    >
                                        <i className="material-icons mr-2">work</i>
                                        Offices
                                    </NavLink>
                                </li>
                            }
                            {
                                (user.roles === MANAGING || user.roles === AGENT_COORDINATOR) &&
                                <li className="nav-item" onClick={this.customClickNav}>
                                    <NavLink
                                        to="/agents"
                                        className="nav-link"
                                        activeClassName={'active'}
                                        style={{color: textColor}}
                                    >
                                        <i className="material-icons mr-2">account_box</i>
                                        Agents
                                    </NavLink>
                                </li>
                            }
                            {
                                (user.roles === MANAGING || user.roles === OFFICE_MANAGER) &&
                                <li className="nav-item" onClick={this.customClickNav}>
                                    <NavLink to="/agents-jobs" className="nav-link" activeClassName="active">
                                        <i className="material-icons mr-2">map</i>
                                        Agents & Jobs
                                    </NavLink>
                                </li>
                            }
                            {
                                (user.roles === MANAGING || user.roles === MCA_FINANCE || user.roles === PRODUCTIVITY_COACH) &&
                                <li className="nav-item">
                                    <a
                                        // this.state.isStats
                                        className={`nav-link ${`${url}`.includes('/stats') && 'active'}`}
                                        style={{
                                            color: textColor,
                                            cursor: 'pointer',
                                        }}
                                        id="stats-id"
                                        onClick={this.customClick}
                                    >
                                        <i className="material-icons mr-2">bar_chart</i>
                                        Stats
                                    </a>
                                    <Collapse isOpen={this.state.dropdownOpen}>
                                        <ul className="custom_nav_ul" id='collapse-1'>
                                            {!(user.roles === PRODUCTIVITY_COACH) &&
                                            <li className="nav-item" onClick={navToggle}>
                                                <NavLink
                                                    to="/stats/revenue"
                                                    className="nav-link pl-5 nav-white"
                                                    activeClassName={'active-2'}
                                                >
                                                    Revenue
                                                </NavLink>
                                            </li>
                                            }
                                            <li className="nav-item" onClick={navToggle}>
                                                <NavLink
                                                    className="nav-link pl-5"
                                                    to="/stats/agent-performance"
                                                    activeClassName={'active-2'}
                                                >
                                                    Agent Performance
                                                </NavLink>
                                            </li>
                                            <li className="nav-item" onClick={navToggle}>
                                                <NavLink
                                                    className="nav-link pl-5"
                                                    to="/stats/job-detail-raw-data"
                                                    activeClassName="active-2"
                                                >
                                                    Job Details Raw Data
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </Collapse>
                                </li>
                            }
                            {
                                (user.roles === MANAGING || user.roles === MCA_FINANCE) &&
                                <li
                                    className="nav-item text-danger"
                                    onClick={this.customClickNav}
                                >
                                    <NavLink
                                        to="/wallet"
                                        className={`nav-link ${!user.stripe_active && 'text-danger font-weight-bold'}`}
                                        activeClassName={'active text-white'}
                                    >
                                        <i className={`material-icons mr-2 ${!user.stripe_active && 'text-danger'}`}>account_balance_wallet</i>
                                        Wallet
                                    </NavLink>
                                </li>
                            }
                            {/* {
                                user.roles === MANAGING &&
                                <li className="nav-item" onClick={this.customClickNav}>
                                    <NavLink
                                        to="/users"
                                        className="nav-link"
                                        activeClassName={'active'}
                                    >
                                        <i className="material-icons mr-2">people</i>
                                        Users
                                    </NavLink>
                                </li>
                            } */}
                            <li className="nav-item" onClick={this.customClickNav}>
                                <NavLink
                                    to="/help-and-support"
                                    exact
                                    className="nav-link"
                                    activeClassName={'active text-dark'}
                                >
                                    <i className="material-icons mr-2">contact_support</i>
                                    Help & Support
                                </NavLink>
                            </li>
                        </React.Fragment>
                        }
                    </ul>
                </div>
            </aside>
        );
    }
}

export default SideBar;
