import React, { useEffect } from "react";
import './agentRegister.css';
import "react-step-progress-bar/styles.css";

export const StepProgressBar = (props) => {
    const { progress, showAllStep = true } = props;

    return (
        <div className="justify-content-center d-flex mb-4 pt-0 mt-0">
            <div className="circle ml-3 active-circle"> </div>
            <div className={`circle ${progress && progress >= 1 ? "active-circle" : ""}`}> </div>
            <div className={`circle ${progress && progress >= 2 ? "active-circle" : ""}`}> </div>
            <div className={`circle ${progress && progress >= 3 ? "active-circle" : ""}`}> </div>
            <div className={`circle ${progress && progress >= 4 ? "active-circle" : ""}`}> </div>
        </div>
    )
}

export const RegisterSuccess = (props) => {

    const { pendingValidation } = props;

    useEffect(() => {
        if (document.getElementById('AGENT-REGISTER')) {
            document.getElementById('AGENT-REGISTER').scrollIntoView({ behavior: 'smooth' });
        }
    }, [])

    return (
        <div className="row flex-1 justify-content-center pt-3">
            <div className="w-100 col-xl-10 col-lg-11 col-md-11 col-12 d-flex flex-column">
                <div className="flex-1 d-flex flex-column mt-0 mt-lg-3 mt=xl-5">
                    <div className="row">
                        <div className="col-12 d-flex flex-column justify-content-between">
                            <h3 className="mb-3 text-center agent-register-label-title">Thank you for registering</h3>
                            {pendingValidation ? (
                                <React.Fragment>
                                    <span className="mb-3 text-center agent-register-label-subtitle">
                                        Please allow up to 24 hours to activate your account
                                    </span>
                                    <span className="mb-3 text-center agent-register-label-subtitle">
                                        Please download the ShowIT Solutions app on your phone.  Once your account is activated, you will recieve on email with login cradentials to your account on ShowIT Solutions™ app
                                    </span>
                                </React.Fragment>
                            ) : (
                                <span className="mb-3 text-center agent-register-label-subtitle">
                                    Please download the ShowIT Solutions app on your phone and login using your registered email and password.
                                </span>
                            )}

                            <span className="mb-0 mt-4 font-weight-bold text-center d-flex align-items-center justify-content-center">
                                <p
                                    className="h4 mb-0 font-weight-bold"
                                    style={{ color: "#AA1711", marginRight: "10px" }}
                                >
                                    Download
                                </p>
                                <p className="h4 mb-0 font-weight-bold">Today</p>
                            </span>
                            <div className="w-100 d-flex flex-column flex-md-row align-items-center justify-content-center">
                                <img
                                    className="img-fluid"
                                    style={{ maxWidth: "230px" }}
                                    src={`https://chart.googleapis.com/chart?chs=400x400&cht=qr&chl=${encodeURIComponent(
                                        "http://onelink.to/yy2qau"
                                    )}&choe=UTF-8`}
                                    alt=""
                                />
                                <a className="a-link agent-register-link" href="http://onelink.to/yy2qau" target="_blank" rel="link"> http://onelink.to/yy2qau</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

