import React from 'react';
import {Field, reduxForm} from "redux-form";
import {renderField} from "../../../Utils/renderField";
import {validate, validators} from "validate-redux-form";


const FormCreateLink = (props) => {
    const {handleSubmit} = props;
    return (
        <form name="createLinkForm" onSubmit={handleSubmit}>
            <div className="form-group has-feedback mx-2">
                <label htmlFor="email" className="font-weight-bold text-12">Enter email</label>
                <Field
                    name="email"
                    component={renderField}
                    type="email"
                    className="form-control"
                    customClass="form-control-gray mx-0"
                />
            </div>
            <div className="w-100 text-center">
                <button type="submit" className="btn btn-primary mt-3 py-2 px-4">
                    Send instructions
                </button>
            </div>
        </form>
    )
}

export default reduxForm({
    form: 'createLinkForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            email: validators.exists()('This field is required'),
        });
    },
})(FormCreateLink);


