import {handleActions} from "redux-actions";
import {push} from "react-router-redux";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {api} from "api";

const SET_LOADER = "SET_LOADER_QUALIFYING";
const SET_SEND = "SET_SEND_SUCCESS";

const setLoader = (loader) => ({
    type: SET_LOADER,
    loader,
});

const setSend = (send) => ({
    type: SET_SEND,
    send,
});

export const verifyInviteQualifying = (data) => (dispatch) => {
    dispatch(setLoader(true));
    api.post("broker-user/verification_invite", {token: data})
        .then((response) => {
            dispatch(initializeForm('FormStep1Invited', response));
            dispatch(push("/register2"));
        })
        .catch((error) => {
            if (error && error.detail) {
                NotificationManager.error(error.detail, "ERROR", 4000);
            } else {
                NotificationManager.error("Error to verified invite", "ERROR", 4000);
            }
            dispatch(push("/login"));
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const sendInvitesQualifying = (data) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('broker-user/qualifying', data).then((response) => {
        NotificationManager.success('Data sent', 'SUCCESS', 2000);
        dispatch(initializeForm('inviteQualifying', {}))
        dispatch(setSend(true));
    }).catch(() => {
        NotificationManager.error('Error in transaction', 'ERROR', 2000)
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const logOut = () => (dispatch) => {
    api.post('/user/logout').then(() => {
    }).catch(() => {
    }).finally(() => {
    });
    dispatch(push("/login"));
    localStorage.removeItem('token');
};

export const actions = {
    verifyInviteQualifying,
    sendInvitesQualifying,
    setSend,
    logOut,
};


export const reducers = {
    [SET_LOADER]: (state, {loader}) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_SEND]: (state, {send}) => {
        return {
            ...state,
            send,
        };
    },
};

export const initialState = {
    loader: false,
    send: false,
};

export default handleActions(reducers, initialState);
