import React from "react";
import {Link} from 'react-router-dom';
import {Field, reduxForm} from 'redux-form';
import LoadMask from "../Utils/LoadMask/LoadMask";
import {customFieldFile, renderField, renderNumber, renderAddressField} from '../Utils/renderField';
import {validate, combine, validatorFromFunction, validators} from 'validate-redux-form';
import ImageInputSelect from "../Utils/renderField/ImageInputSelect";

const brokerageForm = (props) => {

    const {
        handleSubmit,
        is_update,
        item,
        setFiles,
        text,
        handleOnChange,
        active,
        setActive,
        loader2,
        closeModal,
        provideAgentsRoster=true,
        assignAddressData,
    } = props;

    return (
        <form
            name="brokerageForm"
            className="pt-2 flex col-sm-12 col-lg-8 mx-auto"
            onSubmit={handleSubmit}
            style={{ minHeight: is_update ? "33rem" : "36rem" }}
        >
            <LoadMask loading={loader2} light>
                {/*<div className="row w-75 px-5 mx-auto flex-1 ">*/}
                <div className="row w-100 flex-1 ">
                    <div className="form-group has-feedback col-lg-6 mx-0 col-md-8 col-sm-12">
                        {/*<p className="text-light text-12 my-0">Logo</p>*/}
                        <Field
                            name="logo"
                            photo={item.logo}
                            className="w-100"
                            component={ImageInputSelect}
                            type={"file"}
                            setFile={setFiles}
                        />
                    </div>
                </div>
                {/*<div className="row w-75 px-5 mx-auto flex-1">*/}
                <div className="row">
                    <div className="form-group has-feedback col-lg-6 col-sm-12">
                        {/*<label htmlFor="firm_name">Office name</label>*/}
                        <Field
                            name="firm_name"
                            label="Firm name"
                            placeholder={"Office name"}
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback col-lg-6 col-sm-12">
                        {/*<label htmlFor="license">Brokerage License Number</label>*/}
                        <Field
                            type="text"
                            name="license"
                            label="License"
                            component={renderField}
                            placeholder={"Office License Number"}
                            className="form-control"
                            disabled={is_update}
                        />
                    </div>
                </div>

                {/*<div className="row w-75 px-5 mx-auto">*/}
                <div className="row">
                    {/* <div className="form-group has-feedback col-lg-6 col-sm-12">
                        <Field
                            name="city"
                            label="City"
                            component={renderField}
                            type="text"
                            placeholder={"City"}
                            className="form-control"
                        />
                    </div> */}
                    <div className="form-group has-feedback col-lg-6 col-sm-12">
                        {/*<label htmlFor="telephone">Telephone</label>*/}
                        <Field
                            name="telephone"
                            type="number"
                            component={renderNumber}
                            numberFormat={"###-###-####"}
                            placeholder={"Telephone"}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback col-lg-6 col-sm-12">
                        <Field
                            name="address"
                            label="address"
                            placeholder='Address'
                            component={renderAddressField}
                            type="text"
                            className="form-control"
                            longStateName={true}
                            setCityState={(data) => {
                                console.log('ADDRESS DATA: ', data);
                                if (data) {
                                    assignAddressData(data, "brokerageForm")
                                }
                            }}
                        />
                    </div>
                </div>

                {/*<div className="row w-75 px-5 mx-auto">*/}
                <div className="row">
                    <div className="form-group has-feedback col-lg-6 col-sm-12">
                        {/*<label htmlFor="address">Address Line 2</label>*/}
                        <Field
                            name="address_line2"
                            label="Address line 2"
                            component={renderField}
                            type="text"
                            placeholder={"Address Line 2"}
                            className="form-control"
                        />
                    </div>
                </div>
                {provideAgentsRoster == true && (
                    <React.Fragment>
                        {!is_update && (
                            <p className="text-12 mt-1 mb-3 text-justify">
                                In order to give your agents access to all the 
                                perks on ShowIT Solutions™ platform, you will 
                                need to upload your agents roster. To simplify 
                                the process, please download agents roster 
                                template.
                            </p>
                        )}
                        {/*-------------------------------------------------------------------------------*/}
                        {/*-----------------        DOWNLOAD AGENT TEMPLATE            -------------------*/}
                        {/*-------------------------------------------------------------------------------*/}
                        <div className="flex-row text-center">
                            {!is_update && (
                                <div className="col-md-6 mx-auto">
                                    <label className="ml-1 text-12">
                                        Agent Roster Template
                                    </label>
                                    <a
                                        href={props.urlTemplate}
                                        download="template.xlsx"
                                        className="btn btn-success text-white col-lg-8"
                                    >
                                        Download
                                    </a>
                                </div>
                            )}
                        </div>
                        {/*<div className="row w-75 px-5 mx-auto flex-1">*/}
                        {/*-------------------------------------------------------------------------------*/}
                        {/*-----------------            UPLOAD AGENT ROSTER            -------------------*/}
                        {/*-------------------------------------------------------------------------------*/}
                        <div className="flex-row text-center mt-2">
                            {!is_update && (
                                <div className="col-md-6 mx-auto">
                                    <label className="text-12">
                                        Import your agent list
                                    </label>
                                    {/*----------------------------------------*/}
                                    {/*------------   if is create  -----------*/}
                                    {/*----------------------------------------*/}
                                    <Field
                                        id={"office_excel"}
                                        name="excel-office"
                                        setFile={handleOnChange}
                                        component={customFieldFile}
                                        title={"Import"}
                                        text={text}
                                        customClass="btn-primary col-lg-8 col-sm-8 py-1 mx-auto"
                                        type={"file"}
                                        active={active}
                                        accept={"*"}
                                        remove={() => setActive(true)}
                                    />
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                )}

                {/*-------------------------------------------------------------------------------*/}
                {/*-----------------        ACTIONS CREATE OR UPDATE           -------------------*/}
                {/*-------------------------------------------------------------------------------*/}
                <div className="w-100 my-4 mx-auto text-center">
                    {is_update ? (
                        <Link
                            to={`/brokerage/${item.id}/details`}
                            className={`btn button-03 mx-3 pt-1 col-lg-3 col-sm-10`}
                        >
                            Cancel
                        </Link>
                    ) : (
                        <label
                            className={`btn button-03 mx-3 col-lg-4 col-sm-10 my-0`}
                            onClick={closeModal}
                        >
                            Cancel
                        </label>
                    )}
                    <button
                        type="submit"
                        className="btn btn-primary mx-3 col-lg-4 col-sm-10 mt-sm-1 mt-lg-0 py-0"
                        disabled={loader2}
                    >
                        {!is_update ? "Create" : "Update"}
                    </button>
                </div>
            </LoadMask>
        </form>
    );
};

const numberPhone = (number) => validatorFromFunction(() => {
    return number.length === 10;
});

export default reduxForm({
    form: 'brokerageForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            firm_name: validators.exists()('This field is required'),
            license: validators.exists()('This field is required'),
            address: validators.exists()('This field is required'),
            logo: validators.exists()('This field is required'),
            city: validators.exists()('This field is required'),
            telephone: combine(
                validators.exists()('This field is required'),
                numberPhone(data.telephone)()('Must be at least 10 characters'),
            ),
        });
    },
})(brokerageForm);
