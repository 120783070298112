import React from 'react';
import {Link} from "react-router-dom";
import HeaderCard from "../HeaderCard";
import {Field, reduxForm} from 'redux-form';
import LoadMask from "../Utils/LoadMask/LoadMask";
import {customFieldFile} from '../Utils/renderField';
import {formatPhoneNumber} from "../Broker/ListGrid";
import {SIZE_PER_PAGE} from "../../../utility/constants";
import {renderFilePicker} from "../Utils/renderField/renderField";
import GridTableWithScroll from "../Utils/GridTableWithScroll";
import Avatar from "react-avatar";

const DetailsGrid = (props) => {
    const {
        data,
        text,
        loader,
        loader2,
        office,
        active,
        setLogo,
        agentList,
        setActive,
        urlTemplate,
        searchAction,
        isValidation,
        handleOnChange,
    } = props;

    const formatPhoneNumberOffice = (cell) => {
        let phone = ''
        if (cell.length > 10) {
            cell = cell.substr(cell.length - 10, 12)
        }
        phone = `${cell.substr(0, 3)}-${cell.substr(3, 3)}-${cell.substr(6, 4)}`
        return phone
    }

    return (
        <div>
            <div className="page-header pl-1 pt-3 no-gutters row">
                <div className="mb-2">
                    <div className="header-page">Office detail xx</div>
                </div>
            </div>
            <div className="card border-card-page py-0">
                <div className="w-75 mx-auto">
                    {(office && office.id) &&
                    <div className="mt-3 mb-1 d-flex" style={{minHeight: '130px'}}>
                        <div className="flex-column flex-1 justify-content-center">
                            <Field
                                photo={office.logo ? office.logo : require("../../../../assets/img/uploadLogo.PNG")}
                                name="logo"
                                component={renderFilePicker}
                                disabled={true}
                                className="pointer-event"
                            />
                        </div>
                        <div className="flex-column flex-1  pl-3 mx-0">
                            <p className="detail-title">Office name</p>
                            <p className="detail-description">
                                {office.firm_name ? office.firm_name : 'name'}
                            </p>
                            <p className="detail-title justify-content-between">Address</p>
                            <p className="detail-description">
                                {office.address ? office.address : 'address'}</p>
                        </div>
                        <div className="flex-column flex-1  pl-3 mx-0">
                            <p className="detail-title">License</p>
                            <p className="detail-description">
                                {office.license ? office.license : 'license'}</p>
                            <p className="detail-title justify-content-between">Telephone</p>
                            <p className="detail-description">
                                {office.telephone ?
                                    formatPhoneNumberOffice(office.telephone) :
                                    'xxx-xxx-xxxx'
                                }
                            </p>
                        </div>
                        <Link to={`/brokerage/${office.id}/edit`} className="btn button-03" style={{maxHeight:'1.7rem'}}>
                            <i className="material-icons mr-1" style={{fontSize:'14px'}}>edit</i>
                           Edit
                        </Link>
                    </div>
                    }
                    {/* -----------------------------------------------------------------------*/}
                    {/* -------- DOWNLOAD FILE AND IMPORT AGENT ROSTER FILE BUTTONS -----------*/}
                    {/* -----------------------------------------------------------------------*/}
                    <div className="row" style={{height: '2rem'}}>
                        <HeaderCard
                            id_header={'agentGridList'}
                            searchAction={(value) => searchAction(value)}
                            showFile={false}
                            // placeholder="Search Agent"
                            placeholder="Search Broker"
                            searchStyle={'col-ls-10 col-sm-12 img-fluid mr-lg-3 mb-sm-1'}
                            justify={"justify-content-start d-flex col-lg-5 col-sm-12 col-md-6 align-items-end"}
                        />
                        <div className="col-lg-1"/>
                        {/*<div className="form-group has-feedback col-lg-3 mb-0">*/}
                        {/*    <a*/}
                        {/*        title="Template"*/}
                        {/*        href={urlTemplate}*/}
                        {/*        download*/}
                        {/*        className={"btn btn-success col-11 mr-3 text-white"}*/}
                        {/*    >*/}
                        {/*        Download Template*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                        {/*<div className="form-group has-feedback col-lg-3 mb-0 px-0">*/}
                        {/*    <Field*/}
                        {/*        id={'id_header_excel'}*/}
                        {/*        name="excel"*/}
                        {/*        title={"Update Agent Roster"}*/}
                        {/*        setFile={handleOnChange}*/}
                        {/*        component={customFieldFile}*/}
                        {/*        text={text}*/}
                        {/*        type={"file"}*/}
                        {/*        active={active}*/}
                        {/*        customClass={"btn-primary py-1 mx-1"}*/}
                        {/*        remove={() => setActive(true)}*/}
                        {/*    />*/}
                        {/*</div>*/}
                    </div>
                    <div className="d-flex flex-1 mt-3" style={{minHeight: '150px'}}>
                        <div className="flex-1">
                            <LoadMask loading={loader2} light>
                                <GridTableWithScroll
                                    hover
                                    wrapperClasses={'table-scroll-2'}
                                    striped
                                    keyField={'id'}
                                    data={data}
                                    loading={loader}
                                    sizePerPage={SIZE_PER_PAGE}
                                    onPageChange={(value) => agentList(value)}
                                    columns={[
                                        {
                                            dataField: "user.photo",
                                            formatter: avatar(),
                                            text: " ",
                                        },
                                        {
                                            dataField: "user.first_name",
                                            sort: true,
                                            text: "First Name",
                                        },
                                        {
                                            // dataField: "license",
                                            dataField: "user.last_name",
                                            sort: true,
                                            text: "Last Name",
                                        },
                                        {
                                            dataField: "user.email",
                                            sort: true,
                                            text: "Email",
                                        },
                                        {
                                            // dataField: "phone",
                                            dataField: "user.mobile_number",
                                            formatter: formatPhoneNumber(),
                                            text: "Mobile Number",
                                        },
                                        // {
                                        //     dataField: "license",
                                        //     sort: true,
                                        //     text: "License",
                                        // },
                                    ]}
                                />
                            </LoadMask>
                        </div>
                    </div>

                    <div className={isValidation ?
                        'form-group has-feedback text-center w-75 mx-auto' :
                        "form-group has-feedback text-center w-50 mx-auto"
                    }>
                        <Link
                            to="/brokerage"
                            className={isValidation ?
                                "btn btn-sm btn-primary w-25 mx-3 text-white" :
                                "btn btn-sm btn-primary w-50 py-1 text-white"
                            }
                        >
                            Back
                        </Link>
                    </div>
                </div>
            </div>

        </div>
    );
};


function avatar() {
    return (cell, row) => {
        return (
            <div style={{width: '20px'}} className="mx-auto">
                <Avatar
                    size="23"
                    src={cell}
                    round={true}
                    name={row.user.first_name}
                />
            </div>
        );
    };
}
// export function formatPhoneNumber(){
//     return (cell, row) => {
//         console.log(row)
//         return (
//             <React.Fragment>
//                 <p className={'my-0'}>
//                     {`+(1)(${cell.substr(0,3)}) ${cell.substr(3,3)}-${cell.substr(6,4)}`}
//                 </p>
//             </React.Fragment>
//         )
//     };
// }

export default reduxForm({
    form: 'brokerageDetails', // a unique identifier for this form
})(DetailsGrid);
