import moment from 'moment'
import _ from 'lodash'
import React, {useState, useEffect} from 'react';
import {Field, reduxForm} from 'redux-form';
import ReactStars from "react-rating-stars-component";
import ProfileMap from '../../Utils/Map/profileMap';
import {renderField, renderNumber, renderSwitch, renderTextArea} from '../../Utils/renderField';
import {combine, validate, validatorFromFunction, validators} from 'validate-redux-form';
import {Link} from 'react-router-dom';
import {renderFilePicker} from "../../Utils/renderField/renderField";

const AgentRating = (props) => {
    const { rating } = props;
    const [starKeyForce, setStarKeyForce] = useState(0)

    useEffect(() => {
        setStarKeyForce(prev => prev + 1)
    }, [rating])


    return (
        <ReactStars
            size={28}
            count={5}
            value={rating}
            edit={false}
            isHalf={true}
            key={starKeyForce}
            onChange={() => console.log('')}
        />
    )
}

const UpdateForm = (props) => {

    const {
        handleSubmit,
        is_view,
        is_update,
        item,
        setAvatar,
        pendingAgent,
        center,
        remove,
        goPage,
    } = props;

    const [miles, setMiles] = useState(0);

    useEffect(() => {
        if (item && item.miles != "" && item.miles != null && item.miles != undefined)
        {
            setMiles(parseFloat(item.miles))
        }else {
            setMiles(0)
        }
    }, [item])

    return (

        <form name="updateAgentForm" className="my-1 col-sm-12 p-sm-0" style={{minHeight: "20rem"}}
              onSubmit={handleSubmit}>
            <div className="row mx-auto pt-2">
                <div className='col-xl-3 col-lg-3 col-md-5 col-sm-12'>
                    <div className="form-group has-feedback">
                        {/* <label htmlFor="photo">Photo</label> */}
                        <Field
                            photo={item.photo ? item.photo : null}
                            setFile={setAvatar}
                            name="photo"
                            component={renderFilePicker}
                            disabled={is_view || pendingAgent}
                            className="avatar-round"
                        />
                    </div>
                    <div className="col-sm-12 d-flex flex-column align-items-center px-0 mb-4">
                        <div className='d-flex align-items-center justify-content-start'>
                            <AgentRating rating={item.rating >= 0 && item.rating}/>
                        </div>
                        <span className="font-weight-bolder h4 mb-0" style={{color: '#F7D701'}}>{item.rating >= 0 ? item.rating: 0}</span>
                    </div>
                    <div className='col-sm-12 text-center px-0 d-flex flex-column mb-3'>
                        <span className="font-weight-bolder h6 mb-1">Sign-up Date</span>
                        <span className="font-weight-bolder text-primary">{moment(item.date_joined).format('ll')}</span>
                    </div>
                    <div className='col-sm-12 text-center px-0 d-flex flex-column mb-5'>
                        <span className="font-weight-bolder h6 mb-1">Status</span>
                        {item.blocked === true ? (
                            <span className={`text-danger font-weight-bold`}>
                            Blocked
                            </span>
                        ): (
                            item.locked === true ? (
                                <span className={`text-danger font-weight-bold`}>
                                    Locked
                                </span>
                            ) : (
                                <React.Fragment>
                                    {item.agent_account_status == 10 && (
                                        <span className="text-warning font-weight-bold">Pending</span>
                                    )}
                                    {item.agent_account_status == 20 && (
                                        <span className="text-success font-weight-bold">Active</span>
                                    )}
                                </React.Fragment>
                            )

                        )}
                    </div>
                    <div className='col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3'>
                        <span className="font-weight-bolder h6 mb-1">Job Performed</span>
                        <span className="font-weight-bolder text-primary">{item.total_performed}</span>
                    </div>
                    <div className='col-sm-12 text-center px-0 d-flex flex-column mb-3'>
                        <span className="font-weight-bolder h6 mb-1">Job Posted</span>
                        <span className="font-weight-bolder text-primary">{item.total_posted}</span>
                    </div>
                    <div className='col-sm-12 text-center px-0 d-flex flex-column mb-3'>
                        <span className="font-weight-bolder h6 mb-1">Job Reviews</span>
                        <span className="font-weight-bolder text-primary pointer" onClick={() => goPage(`/agents/agent-reviews/${item.id}`)}>{item.total_reviews}</span>
                    </div>
                </div>
                <div className='col-xl-9 col-lg-9 col-md-7 col-sm-12'>
                    <div className='row'>
                        <div className="form-group has-feedback col-md-6 col-sm-12">
                            <label htmlFor="first_name">First name</label>
                            <Field
                                name="first_name"
                                label="First name"
                                component={renderField}
                                type="text"
                                className="form-control"
                                disabled={is_view}
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 col-sm-12">
                            <label htmlFor="last_name">Last Name</label>
                            <Field
                                name="last_name"
                                label="Last Name"
                                type="text"
                                component={renderField}
                                className="form-control"
                                disabled={is_view}
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 col-sm-12">
                            <label htmlFor="mobile_number">Mobile number</label>
                            <Field
                                disabled={is_view}
                                name="mobile_number"
                                component={renderNumber}
                                className="form-control"
                                placeholder="mobile number"
                                numberFormat={"###-###-####"}
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 col-sm-12">
                            <label htmlFor="email">Email</label>
                            <Field
                                name="email"
                                label="email"
                                component={renderField}
                                type="email"
                                className="form-control"
                                disabled={is_view}
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 col-sm-12">
                            <label htmlFor="real_estate_licence">License number</label>
                            <Field
                                name="real_estate_licence"
                                label="real_estate_licence"
                                component={renderField}
                                type="text"
                                className="form-control"
                                disabled={is_view}
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 col-sm-12">
                            <label htmlFor="languages">Languages</label>
                            <Field
                                name="languages"
                                label="languages"
                                component={renderField}
                                type="text"
                                disabled={true}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="broker_id">Broker name</label>
                            <div className="form-control">
                                {item &&
                                item.brokerage_information &&
                                item.brokerage_information.broker
                                    ? item.brokerage_information.broker.name 
                                    : ""}
                            </div>
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="brokerage_id">Franchise</label>
                            <div className="form-control">
                                {item &&
                                item.brokerage_information
                                    ? item.brokerage_information.franchise
                                    : ""}
                            </div>
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="brokerage_id">Office name</label>
                            <div className="form-control">
                                {item &&
                                item.brokerage_information
                                    ? item.brokerage_information.firm_name
                                    : ""}
                            </div>
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="brokerage_license">Office License number</label>
                            <div className='form-control'>
                                {item &&
                                item.brokerage_information ? item.brokerage_information.license : ''}
                            </div>
                        </div>
                        
                        {/* <div className="col-md-12">
                            <label htmlFor="about">About</label>
                            <Field
                                name="about"
                                rows={10}
                                component={renderTextArea}
                                classNames=""
                                disabled={true}
                            />
                        </div> */}
                    </div>
                    <div className='row'>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label className='mb-0 mb-lg-4' htmlFor="about">About</label>
                            <Field
                                name="about"
                                rows={10}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                                disabled={is_view}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <React.Fragment>
                                <label htmlFor="street">Preferred working area</label>
                                <label>
                                    {item && item.street}
                                </label>
                                {/* <Field
                                    name="street"
                                    label="street"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                    disabled={is_view}
                                /> */}
                            </React.Fragment>
                            <div className="row">
                                <div className="col-sm-12" style={{ minHeight: '200px' }}>
                                    <ProfileMap center={center} radius={miles} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='row mt-5'>
                        <div className="form-group col-lg-6 col-md-12 col-sm-12 mb-0">
                            <label className="mt-n4 d-block" style={{fontSize: '1.05rem'}}> Account Settings</label>
                            <div className="row w-100 m-0 h-100">
                                <div className='rounded border col-sm-12'>
                                    <div className='row'>
                                        <div className="form-group has-feedback col-md-6 col-sm-12">
                                            <label htmlFor="is_active" className="mt-4 d-block ml-3">Password</label>
                                            {is_view ?
                                                <button
                                                    className="btn button-03 ml-3 px-4"
                                                    disabled={true}
                                                    style={{hover: 'none'}}
                                                >
                                                    Change Password
                                                </button> :
                                                <Link
                                                    className="btn button-03 ml-3 px-4"
                                                    to={`/agents/agent/${item.id && item.id}/edit/changed-password`}
                                                    disabled={is_view}
                                                >
                                                    Change Password
                                                </Link>
                                            }
                                        </div>
                                        <div className="form-group has-feedback col-md-6 pl-4">
                                            <label htmlFor="locked" className="mt-4 mb-3">Lock account</label>
                                            <Field
                                                name="locked"
                                                label=""
                                                component={renderSwitch}
                                                type="checked"
                                                disabled={is_view}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="row mx-auto mt-4 justify-content-center form-group has-feedback">
                        <Link to="/agents" className={`btn text-white ${is_view ? "button-03" : "btn-danger"} mx- col-2`}>
                            {is_view ? "Back" : "Cancel"}
                        </Link>
                        {item && (item.agent_account_status == 20 || item.agent_account_status == 40) ? (
                            <div className='col-lg-2 col-md-3 col-sm-12 my-md-0 my-1'>
                                <button type="button" onClick={(e) => remove(item)} className="w-100 btn btn-danger">
                                    Delete
                                </button>
                            </div>

                        ): null}
                    </div>
                </div>
            </div>
            <div className="row w-75 px-lg-5 mx-auto pt-2">
                

                {/*  ------------------------- BROKERAGE INFORMATION --------------------------------*/}
                {/* <div className="form-group has-feedback col-lg-6 col-sm-10 mb-1">
                    <label className="mt-0"> Brokerage Information</label>
                    <div className="form-group has-feedback col-md-12  col-sm-10 border mb-0">
                        <label htmlFor="broker" className="mb-0 mt-2">Broker Full Name</label>
                        <p className="mt-0 mb-2 text-12">
                            {(item && item.brokerage_information && item.brokerage_information.broker)
                                ? item.brokerage_information.broker.owner_first_name +' '+ item.brokerage_information.broker.owner_last_name
                                : ' '
                            }
                        </p>
                        <label htmlFor="brokerage_license" className="my-0">Brokerage Firm Name</label>
                        <p className="mt-0 mb-2 text-12">
                            {item && item.brokerage_information !== undefined && item.brokerage_information.firm_name}
                        </p>
                        <label htmlFor="brokerages_licence" className="my-0">Brokerage License number</label>
                        <p className="mt-0 mb-0 text-12 mb-2">
                            {item && item.brokerage_information !== undefined && item.brokerage_information.license}
                        </p>
                    </div>
                </div> */}
            </div>
            {/* ------------------------- SETTINGS ACCOUNT ------------------ */}
            {/* <div style={{width: '64%'}} className="mx-auto mt-5 border">
                
            </div> */}
            
        </form>
    );
};


export const minValue = (value) => validatorFromFunction(() => {
    return value.length >= 6;
});

const numberPhone = (number) => validatorFromFunction(() => {
    return number.length === 10;
});

export default reduxForm({
    form: 'updateAgentForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            first_name: validators.exists()('This field is required'),
            last_name: validators.exists()('This field is required'),
            email: validators.exists()('This field is required'),
            mobile_number: combine(
                validators.exists()('This field is required'),
                numberPhone(data.mobile_number)()('Invalid phone number'),
            ),
            office_phone_number: combine(
                validators.exists()('This field is required'),
                numberPhone(data.office_phone_number)()('Invalid phone number'),
            ),
            password: combine(
                validators.exists()('This field is required'),
                minValue(data.password)()('Must be at least 6')
            ),
        });
    },
})(UpdateForm);
