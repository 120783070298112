import { api } from "api";
import { getMe } from "../cuenta/login";
import { push } from "react-router-redux";
import { handleActions } from "redux-actions";
import { initialize as initializeForm } from "redux-form";
import { NotificationManager } from "react-notifications";

const SET_ITEM = "SET_ITEM_WALLET";
const BALANCE = "SET_BALANCE_WALLET";
const SET_LOADER = "SET_LOADER_WALLET";
const DATA_CARDS = "SET_DATA_CARDS_WALLET";
const OPEN_MODAL = "SET_OPEN_MODAL_WALLET";
const BANK_ACCOUNT = "SET_BANK_ACCOUNT_WALLET";
const PAYOUT_HISTORY = "SET_PAYOUT_HISTORY_WALLET";
const PAYMENT_PAGE = "SET_PAYMENT_PAGE_WALLET";
const PAYMENT_DATA = "SET_PAYMENT_DATA_WALLET";
const PAYMENT_LOADER = "SET_PAYMENT_LOADER_WALLET";

const setLoader = (loader) => ({
    type: SET_LOADER,
    loader,
});

//general function to set data
const setData = (data, type) => ({
    type,
    data,
});


const crateStripeAccounts = (openModalOnBoarding) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('broker-user/create_stripe_accounts', {})
        .then(response => {
            dispatch(getAccountStripe(openModalOnBoarding));
            dispatch(getMe());
        })
        .catch((error) => {
            console.log('true error: ', error)
            NotificationManager.error('Error creating your stripe account', 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const getAccountStripe = (openModalOnBoarding, openModalOnConfiguring = null) => (dispatch) => {
    dispatch(setLoader(true));
    api.get("broker-user/account_detail", {})
        .then((response) => {
            // dispatch(setData(response, SET_ITEM));
            // console.log('response', response);
            let validGetBalance = true;
            if (!response.tos_acceptance || !!(response.tos_acceptance && !response.tos_acceptance.date)) {
                openModalOnBoarding();
                validGetBalance = false;
                dispatch(setData(true, OPEN_MODAL));
            } else {
                if (openModalOnConfiguring && (!response.charges_enabled || !response.payouts_enabled)) {
                    validGetBalance = false;
                    openModalOnConfiguring();
                } else {
                    if (response.external_accounts && response.external_accounts.data) {
                        const banks = response.external_accounts.data;
                        if (banks.length > 0) {
                            dispatch(setData(banks, BANK_ACCOUNT));
                        }
                    }
                }
            }
            dispatch(setData(response, SET_ITEM));
            //BANK_ACCOUNT
            if (validGetBalance) {
                dispatch(getBalance());
                // dispatch(getPayoutHistory());
            }
        })
        .catch((error) => {
            NotificationManager.error('Error get detail of your stripe account', 'ERROR', 2000);
            console.log('error getAccountStripe ----- ', error);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const getBalance = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('broker-user/balance_account')
        .then(response => {
            dispatch(setData(response, BALANCE));
            // dispatch(listBank());
            dispatch(getPayoutHistory());
            // dispatch(getBankAccount());
        })
        .catch((error) => {
            console.log('getBalance error -----------------------', error);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};


const linkOnBoard = (setStateData) => (dispatch) => {
    api.get('broker-user/onboard_link')
        .then(response => {
            console.log('onboard_link: ', response);
            if (setStateData) {
                setStateData(response.url);
            }
        })
        .catch((error) => {
            console.log("linkOnBoard ", error);
            NotificationManager.error('Failed to get account details', 'ERROR', 2000);
        });
};

const goHome = () => (dispatch) => {
    dispatch(push('/'));
};

const getPayoutHistory = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('broker-user/payout_history')
        .then(response => {
            dispatch(setData(response, PAYOUT_HISTORY));
        })
        .catch((error) => {
            console.log('----- getPayoutHistory error -----', error);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const getBankAccount = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('broker-user/bank_accounts')
        .then(response => {
            // dispatch(setData(response, BANK_ACCOUNT));
            dispatch(getPayoutHistory());
        })
        .catch((error) => {
            console.log('----- getBalance error -----', error);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const AddBankAccount = (data, closeModal, showError) => (dispatch) => {
    delete data.confirm_account_number;
    api.post('payout_account', data)
        .then(response => {
            if (closeModal) closeModal();
            NotificationManager.success('Bank account added', 'SUCCESS', 3000);
            dispatch(getAccountStripe(null, null));
        })
        .catch((error) => {
            if (showError) showError('Create bank account failed, please verified your info');
            console.log('----- AddBankAccount error -----', error);
        })
        .finally(() => {
        });

    // api.post('broker-user/add_bank_account', data)
    //     .then(response => {
    //         if (closeModal) closeModal();
    //         dispatch(getAccountStripe(null, null));
    //         NotificationManager.success('Bank account added', 'SUCCESS', 3000);
    //     })
    //     .catch((error) => {
    //         if (showError) showError('Create bank account failed, please verified your info');
    //         console.log('----- AddBankAccount error -----', error);
    //     })
    //     .finally(() => {
    //     });
};

const createPayout = (data, closeModal, setError) => (dispatch) => {
    api.post('broker-user/create_payout', data)
        .then(response => {
            if (closeModal) closeModal();
            NotificationManager.success('Payout create', 'Success', 3000);
            dispatch(getBalance());
        })
        .catch((error) => {
            console.log('createPayout error--------', error);
            if (setError) setError(error.detail);
        });
};

const setInitialFormPayout = () => (dispatch) => {
    dispatch(initializeForm('PayoutForm', {}));
};

const listBank = () => (dispatch) => {
    api.get('broker-user/bank_accounts')
        .then(response => {
            dispatch(setData(response.data, BANK_ACCOUNT));
            dispatch(getPayoutHistory());
        })
        .catch((error) => {
            console.log('----- listBank error -----', error);
        })
        .finally(() => {
        });
};

const getUrlEdit = (setModal, setLink) => (dispatch) => {
    dispatch(setLoader(true))
    api.get('broker-user/url_edit_bank_account')
        .then(response => {
            let url = response.url + '#/account';
            console.log('new url', url)
            setLink && setLink(url);
            setModal && setModal(true);
        })
        .catch((error) => {
            NotificationManager.error('Get url edit bank account failed', 'Error', 3000);
            console.log('----- getUrlEdit error -----', error);
        })
        .finally(() => {
            dispatch(setLoader(false))
        });
};

const getPaymentsHistory = (page=1) => (dispatch, getStore) => {
    const store = getStore();
    const {me} = store.login
    if (me && me.broker_company) {
        dispatch(setData(true, PAYMENT_LOADER));
        api.get(`broker/${me.broker_company}/show_it_share`, {page})
            .then(response => {
                dispatch(setData(response, PAYMENT_DATA));
                dispatch(setData(page, PAYMENT_PAGE));
            })
            .catch((error) => {
                console.log('----- getPaymentsHistory error -----', error);
            })
            .finally(() => {
                dispatch(setData(false, PAYMENT_LOADER));
            });
    }
};


export const actions = {
    goHome,
    linkOnBoard,
    getAccountStripe,
    crateStripeAccounts,
    setInitialFormPayout,
    getPayoutHistory,
    getBankAccount,
    AddBankAccount,
    createPayout,
    getUrlEdit,
    getPaymentsHistory,
};


export const reducers = {
    [SET_LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_ITEM]: (state, { data }) => {
        return {
            ...state,
            item: data,
        };
    },
    [BALANCE]: (state, { data }) => {
        return {
            ...state,
            balance: data,
        };
    },
    [DATA_CARDS]: (state, { data }) => {
        return {
            ...state,
            cards: data,
        };
    },
    [OPEN_MODAL]: (state, { data }) => {
        return {
            ...state,
            modal: data,
        };
    },
    [PAYOUT_HISTORY]: (state, { data }) => {
        return {
            ...state,
            payout: data,
        };
    },
    [BANK_ACCOUNT]: (state, { data }) => {
        return {
            ...state,
            bankAccount: data,
        };
    },
    [PAYMENT_LOADER]: (state, { data }) => {
        return {
            ...state,
            paymentLoader: data,
        };
    },
    [PAYMENT_PAGE]: (state, { data }) => {
        return {
            ...state,
            paymentPage: data,
        };
    },
    [PAYMENT_DATA]: (state, { data }) => {
        return {
            ...state,
            paymentData: data,
        };
    },
};

export const initialState = {
    loader: false,
    item: {},
    cards: [],
    bankAccount: [],
    modal: false,
    balance: {
        available: [{ amount: 0 }],
        pending: null,
    },
    payout: { data: [] },
    paymentLoader: false,
    paymentPage: 1,
    paymentData: {
        broker: null,
        results: []
    }

};

export default handleActions(reducers, initialState);
