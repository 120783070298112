import React, {useEffect} from 'react';
import './wholeSalerRegister.css';
import { connect } from "react-redux";
import {Field, reduxForm, formValueSelector} from 'redux-form';
import {renderField, renderAddressField} from '../Utils/renderField';

const validate = (value) => {
    let error = {}
    if(!value.first_name) {
        error.first_name = "This field is required"
    }
    if(!value.last_name) {
        error.last_name = "This field is required"
    }
    if(!value.street) {
        error.street = "This field is required"
    }
    return error
}

const Form = (props) => {
    const {handleSubmit} = props;

    useEffect(() => {
        if (document.getElementById('WHOLESALER-REGISTER')) {
            document.getElementById('WHOLESALER-REGISTER').scrollIntoView({ behavior: 'smooth' });
        }
    }, [])

    return (
        <div className="row flex-1 justify-content-center pt-3">
            <form
                name="wholeSalerRegisterForm"
                className="w-100 col-xl-10 col-lg-11 col-md-11 col-12 d-flex flex-column"
                onSubmit={handleSubmit}
            >
                <div className="flex-1 d-flex flex-column justify-content-between">
                    <div className="row">
                        <div className="form-group has-feedback col-md-6 col-sm-12 mb-md-5 mb-5">
                            <label htmlFor="first_name" className="mb-0">
                                <strong className="question2">First Name</strong>
                            </label>
                            <Field
                                name="first_name"
                                label="First Name"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 col-sm-12 mb-md-5 mb-5">
                            <label htmlFor="last_name" className="mb-0">
                                <strong className="question2">Last Name</strong>
                            </label>
                            <Field
                                name="last_name"
                                label="Last Name"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-12 mb-md-3 mb-3">
                            <label htmlFor="street">
                                <strong className="question2">
                                    Address
                                </strong>
                            </label>
                            <Field
                                name="street"
                                placeholder=""
                                component={renderAddressField}
                                type="text"
                                className="form-control"
                                longStateName={true}
                            />
                        </div>
                    </div>
                    <div className="row mb-4 mt-3">
                        <div className="buttons-box justify-content-center col-12">
                            <span className='text-center'>
                                By proceeding, I affirm that I have read and agreed to the{' '}
                                <a href='https://showitsolutions.com/terms-and-conditions/' className='a-link' target='_blank'>Terms & Conditons</a> and the <a href='https://showitsolutions.com/privacy-policy/' className='a-link' target='_blank'>Privacy Policy</a>
                            </span>
                        </div>
                        <div className="buttons-box col-12 justify-content-center mx-auto py-md-3 py-4">
                            <button
                                type="submit"
                                className="btn wholesaler-register-button col-xl-4 col-lg-5 align-self-center"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    )

}

const WholeSalerInfoForm = reduxForm({
    form: 'wholeSalerRegisterForm', // a unique identifier for this form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
})(Form);

const selector = formValueSelector('wholeSalerRegisterForm');
const mstp = state => {
    return {
    };
};

export default connect(mstp, null)(WholeSalerInfoForm);
