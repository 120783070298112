import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { renderFilePicker } from "../../Utils/renderField/renderField";
import { renderField, renderNumber } from "../../Utils/renderField";

const validate = (values, props) => {
    let errors = {};

    if (!values.first_name) {
        errors.first_name = "This field is required"
    }
    if (!values.last_name) {
        errors.last_name = "This field is required"
    }
    if (!values.email) {
        errors.email = "This field is required"
    }

    return errors;
};

let influencerForm = (props) => {
    //  Status
    const { isView, item } = props;
    //  Bind
    const { handleSubmit, setAvatar, setLogo, goBack, toEdit } = props;
    const user = item && item.user ? item.user : {};

    // console.log("ITEM: ", item);

    return (
        <form
            name="influencerForm"
            className="my-1 col-sm-12 p-sm-0"
            style={{ minHeight: "20rem" }}
            onSubmit={handleSubmit}
        >
            <div className="row mx-auto pt-2">
                <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12">
                    <div className="form-group has-feedback">
                        {!isView && (
                            <label className="w-100 text-center" htmlFor="photo">Add your picture</label>
                        )}
                        <Field
                            photo={user && user.photo ? user.photo : null}
                            setFile={setAvatar}
                            name="photo"
                            component={renderFilePicker}
                            disabled={isView}
                            className="avatar-round"
                            placeholder="Upload Photo"
                        />
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Sign-up Date
                        </span>
                        <span className="font-weight-bolder text-primary">
                            {user && user.date_joined
                                ? moment(user.date_joined).format("ll")
                                : ""}
                        </span>
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mb-5">
                        <span className="font-weight-bolder h6 mb-1">
                            Status
                        </span>
                        {user.blocked === true ? (
                            <span className={`text-danger font-weight-bold`}>
                                Blocked
                            </span>
                        ) : user.locked === true ? (
                            <span className={`text-danger font-weight-bold`}>
                                Locked
                            </span>
                        ) : (
                            <React.Fragment>
                                {user.agent_account_status == 10 && (
                                    <span className="text-warning font-weight-bold">
                                        Pending
                                    </span>
                                )}
                                {user.agent_account_status == 20 && (
                                    <span className="text-success font-weight-bold">
                                        Active
                                    </span>
                                )}
                            </React.Fragment>
                        )}
                    </div>

                    <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Job Performed
                        </span>
                        <span className="font-weight-bolder text-primary">
                            {item && item.job_performed
                                ? item.job_performed
                                : 0}
                        </span>
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Revenue
                        </span>
                        <span className="font-weight-bolder text-primary">
                            $ {item && item.revenue
                                ? item.revenue.toFixed(2)
                                : '0.00'}
                        </span>
                    </div>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-7 col-sm-12">
                    <div className="row">
                        <div className="col-lg-8 col-sm-12 d-flex flex-column justify-content-center">
                            {isView && (
                                <button
                                    type="button"
                                    onClick={toEdit}
                                    className={`w-100 btn button-03 col-lg-3 col-sm-5`}
                                >
                                    <i className="fa fa-pencil mr-2" />
                                    Edit
                                </button>
                            )} 
                        </div>
                        <div className="col-lg-4 col-sm-12 d-flex flex-column justify-content-center">
                            {!isView && (
                                <label className="w-100 text-center" htmlFor="logo">Add your company logo</label>
                            )}
                            <Field
                                photo={item && item.logo ? item.logo : null}
                                setFile={setLogo}
                                name="logo"
                                component={renderFilePicker}
                                disabled={isView}
                                className="avatar"
                                placeholder="Upload Logo"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 form-group has-feedback">
                            <label htmlFor="first_name">First Name</label>
                            <Field
                                name="first_name"
                                label="first_name"
                                component={renderField}
                                type="text"
                                className="form-control"
                                disabled={isView}
                            />
                        </div>
                        <div className="col-lg-6 col-sm-12 form-group has-feedback">
                            <label htmlFor="last_name">Last Name</label>
                            <Field
                                name="last_name"
                                label="last_name"
                                component={renderField}
                                type="text"
                                className="form-control"
                                disabled={isView}
                            />
                        </div>
                        <div className="col-lg-6 col-sm-12 form-group has-feedback">
                            <label htmlFor="email">Email</label>
                            <Field
                                name="email"
                                label="email"
                                component={renderField}
                                type="email"
                                className="form-control"
                                disabled={isView}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-12 form-group has-feedback">
                            <label htmlFor="phone_number">Mobile number</label>
                            <Field
                                name="phone_number"
                                placeholder="Mobile number"
                                component={renderNumber}
                                className="form-control"
                                numberFormat={"###-###-####"}
                                disabled={isView}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-12 form-group has-feedback">
                            <label htmlFor="office_phone_number">
                                Office Phone number
                            </label>
                            <Field
                                name="office_phone_number"
                                placeholder="Office Phone number"
                                component={renderNumber}
                                className="form-control"
                                numberFormat={"###-###-####"}
                                disabled={isView}
                            />
                        </div>
                        <div className="col-lg-6 col-sm-12 form-group has-feedback">
                            <label htmlFor="address">Address</label>
                            <Field
                                name="address"
                                label="address"
                                component={renderField}
                                type="text"
                                className="form-control"
                                disabled={isView}
                            />
                        </div>
                        <div className="col-lg-6 col-sm-12 form-group has-feedback">
                            <label htmlFor="franchise_name">Franchise</label>
                            <Field
                                name="franchise_name"
                                label="franchise_name"
                                component={renderField}
                                type="text"
                                className="form-control"
                                disabled={isView}
                            />
                        </div>
                        <div className="col-lg-6 col-sm-12 form-group has-feedback">
                            <label htmlFor="re_license">RE License</label>
                            <Field
                                name="re_license"
                                label="re_license"
                                component={renderField}
                                type="text"
                                className="form-control"
                                disabled={isView}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-12 form-group has-feedback">
                            <label htmlFor="broker_office_name">
                                Broker Office Name
                            </label>
                            <Field
                                name="broker_office_name"
                                label="broker_office_name"
                                component={renderField}
                                type="text"
                                className="form-control"
                                disabled={isView}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-12 form-group has-feedback">
                            <label htmlFor="broker_office_license">
                                Broker License Number
                            </label>
                            <Field
                                name="broker_office_license"
                                label="broker_office_license"
                                component={renderField}
                                type="text"
                                className="form-control"
                                disabled={isView}
                            />
                        </div>
                    </div>
                    {item && item.team && (
                        <div className="row mt-3">
                            <div className="col-lg-5 col-sm-12 d-flex flex-column form-group has-feedback d-flex flex-column justify-content-center">
                                <label className="h5 mb-1" htmlFor="">
                                    Agent signup Link
                                </label>
                                <small>Provide this link to your real estate agents, so once registered to ShowIT Solutions™ app they will be part of your team and your ShowIT Share™.</small>
                            </div>
                            <div className="col-lg-5 col-sm-12 form-group has-feedback d-flex flex-column justify-content-center">
                                <a className="pointer text-primary" href={`https://share.showitsolutions.com/auth/register/?team_id=${item.team.id}`}>{`https://share.showitsolutions.com/auth/register/?team_id=${item.team.id}`}</a>
                            </div>
                            <div className="col-lg-2 col-sm-12 form-group has-feedback d-flex flex-column justify-content-center">
                                <img style={{width: '100%'}} src={`https://chart.googleapis.com/chart?chs=400x400&cht=qr&chl=https://share.showitsolutions.com/auth/register/?team_id=${item.team.id}&choe=UTF-8`} alt="" />
                            </div>
                        </div>
                    )}
                    {item && (
                        <div className="row mt-3">
                            <div className="col-lg-5 col-sm-12 d-flex flex-column form-group has-feedback d-flex flex-column justify-content-center">
                                <label className="h5 mb-1" htmlFor="">
                                    Broker signup Link
                                </label>
                                <small>Provide this link to brokers & office owners/managers, so once registered to ShowIT Solutions™ app their Brokerage/office will be part of your team and your ShowIT Share™.</small>
                            </div>
                            <div className="col-lg-5 col-sm-12 form-group has-feedback d-flex flex-column justify-content-center">
                                <a className="pointer text-primary" href={`${item.broker_sign_up_link}`}>{`${item.broker_sign_up_link}`}</a>
                            </div>
                            <div className="col-lg-2 col-sm-12 form-group has-feedback d-flex flex-column justify-content-center">
                                <img style={{width: '100%'}} src={`https://chart.googleapis.com/chart?chs=400x400&cht=qr&chl=${encodeURIComponent(item.broker_sign_up_link)}&choe=UTF-8`} alt="" />
                            </div>
                        </div>
                    )}
                    {item && item.team && (
                        <div className="row mt-3">
                            <div className="col-lg-5 col-sm-12 d-flex flex-column form-group has-feedback d-flex flex-column justify-content-center">
                                <label className="h5 mb-1" htmlFor="">
                                    Wholesaler signup Link
                                </label>
                                <small>Provide this link to your Wholesaler, once signing to ShowIT Solutions™ app they will be added to your team</small>
                            </div>
                            <div className="col-lg-5 col-sm-12 form-group has-feedback d-flex flex-column justify-content-center">
                                <a className="pointer text-primary" href={`${item.wholesaler_sign_up_link}`}>{`${item.wholesaler_sign_up_link}`}</a>
                            </div>
                            <div className="col-lg-2 col-sm-12 form-group has-feedback d-flex flex-column justify-content-center">
                                <img style={{width: '100%'}} src={`https://chart.googleapis.com/chart?chs=400x400&cht=qr&chl=${encodeURIComponent(item.wholesaler_sign_up_link)}&choe=UTF-8`} alt="" />
                            </div>
                        </div>
                    )}
                    <div className="row mt-5">
                        <div className="form-group col-sm-12 mb-0">
                            <label
                                className="mt-n4 d-block"
                                style={{ fontSize: "1.05rem" }}
                            >
                                {" "}
                                Account Settings
                            </label>
                            <div className="row w-100 m-0 h-100">
                                <div className="rounded border col-sm-12 py-2">
                                    <div className="row">
                                        {item && item.user && (
                                            <div className="form-group mb-0 has-feedback col-lg-4 col-md-6 col-sm-12">
                                                <label
                                                    htmlFor="is_active"
                                                    className="d-block"
                                                >
                                                    Password
                                                </label>
                                                <Link
                                                    className="btn button-03 px-4"
                                                    to={`/influencer/${item.user.id}/changed-password`}
                                                    disabled={isView}
                                                >
                                                    Change Password
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {!isView && (
                <div className="row justify-content-center mx-auto my-3">
                    <button
                        type="button"
                        onClick={goBack}
                        className={`w-100 btn btn-secondary text-white col-lg-3 col-sm-5 mx-4 mt-2`}
                    >
                        Back
                    </button>
                    <button
                        type="submit"
                        className="btn btn-primary col-lg-3 col-sm-5 mx-4 mt-2"
                    >
                        Update
                    </button>
                </div>
            )}
        </form>
    );
};

influencerForm = reduxForm({
    form: "influencerForm", // a unique identifier for this form
    validate,
})(influencerForm);

const selector = formValueSelector("influencerForm");

const mstp = (state) => {
    return {};
};

export default connect(mstp, null)(influencerForm);
