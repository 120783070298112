import { connect } from 'react-redux';
import { listLockNotes, createLockNotes, readLockNotes, editLockNotes, deleteLockNotes, setNotesItem } from '../../../../redux/modules/agent/userAgents';
import LockNotes from './LockNotes';

const ms2p = (state) => {
    const { notesLoader, notesPage, notesData, notesItem, notesItemLoader } = state.agentRegistered;
    return{
        loader: notesLoader,
        itemLoader: notesItemLoader,
        page: notesPage,
        data: notesData,
        item: notesItem,
    };
};

const md2p = {
    list: listLockNotes,
    create: createLockNotes,
    read: readLockNotes,
    edit: editLockNotes,
    remove: deleteLockNotes,
    setItem: setNotesItem,
};

export default connect(ms2p, md2p)(LockNotes);