import '../register.css';
import React, {useState, useEffect} from 'react';
import {Field, reduxForm} from "redux-form";
import {fieldRadio} from "../../../Utils/renderField";
import {OPEN_MARKETING, WITHIN_YOUR_BROKERAGE, WITHIN_YOUR_FRANCHISE} from "../../../../../utility/constants";


const OperationAccess = (props) => {
    const {option, setOption} = props;
    // const [option, setOption] = useState(1)
    useEffect(() => {
        if (document.getElementById('BROKER-REGISTER')) {
            document.getElementById('BROKER-REGISTER').scrollIntoView({ behavior: 'smooth' });
        }
    }, [])

    return (
        <div className="mx-auto justify-content-center text-center text-dark">
            <div className="col-10 mx-auto text-center px-0">
                <h5 className="broker-register-form-title mt-0 mb-3">Operation Access</h5>
                <p className="text-12 mb-1 ">
                    There are several options for you to maximize your agents’ productivity within the ShowIT Solutions™
                    platform.
                </p>
                <p className="py-0 my-0 text-12 ">
                    Yours agents will be able to Post and Perform jobs only within the criteria you will be choosing
                    below
                </p>
            </div>
            <div className="mx-auto col-10 justify-content-start mt-4 mb-4 px-0">
                <p className="pt-2 text-center font-weight-bold text-black mb-3">
                    Please choose one:
                </p>
                <div className="row w-100 mx-auto py-1 justify-content-between">
                    <Field
                        name={'option1'}
                        valueOp={WITHIN_YOUR_BROKERAGE}
                        checked={option === WITHIN_YOUR_BROKERAGE}
                        set_value={setOption}
                        component={fieldRadio}
                    />
                    <label htmlFor="option1" className="text-12-2 col">
                        Within your Brokerage: Your affiliated licensees
                        can only obtain or provide support personnel services within your brokerage. Multiple offices
                        within the same brokerage are all considered to be a part of the same brokerage.
                    </label>
                </div>
                {/* ----------------------------------------------------------------*/}
                {/* -----------------------    OPTION 2    -------------------------*/}
                {/* ----------------------------------------------------------------*/}
                <div className="row w-100 mx-auto py-1 ">
                    <Field
                        name='option2'
                        valueOp={WITHIN_YOUR_FRANCHISE}
                        checked={option === WITHIN_YOUR_FRANCHISE}
                        set_value={setOption}
                        component={fieldRadio}
                    />
                    <label htmlFor="option2" className="text-12-2 col">
                        Within your Franchise:  Your affiliated licensees can
                        only obtain or provide support personnel services from other Member Brokers who share the same
                        franchisor as you.
                    </label>
                </div>
                {/* ----------------------------------------------------------------*/}
                {/* -----------------------    OPTION 3    -------------------------*/}
                {/* ----------------------------------------------------------------*/}
                <div className="row w-100 mx-auto py-1">
                    <Field
                        name='option3'
                        valueOp={OPEN_MARKETING}
                        checked={option === OPEN_MARKETING}
                        set_value={setOption}
                        component={fieldRadio}
                    />
                    <label htmlFor="option3" className="text-12-2 col">
                        Open market:  Your affiliated licensees can obtain or provide support personnel services
                        from all other Member Brokers in the ShowIT Solutions™ network.
                    </label>
                </div>
            </div>
            <div className="mx-auto col-10 px-0">
                <p className="text-center font-weight-bold text-black mb-2">
                    ShowIT Share™
                </p>
                <p className="text-11 mb-0">
                    ShowIT Solutions™ is honored to offer your Brokerage the opportunity to earn passive income from
                    your licensees’ activities. Participation in ShowIT Share™ is automatic for brokers who allow their
                    affiliated licensees to obtain or provide support personnel services with all other Member Brokers
                    in the ShowIT Solutions™ network and for brokers who allow their affiliated licensees to obtain or
                    provide support personnel services with other Member Brokers who share the same franchisor.
                    Brokerages that choose to limit support personnel services to just their own brokerage can still
                    benefit from ShowIT Share™ if they have 200 or more affiliated licensees signed up to the ShowIT
                    Solutions™ platform.
                </p>
                <p className="text-11 my-0">
                    For each job completed by your licensees (per side), you will earn 3% of ShowIT Solutions™ profit
                </p>
            </div>
            <button type={'button'} className="btn vendor-register-button col-xl-4 col-lg-5 align-self-center mt-4" onClick={()=>props.ActionClick(option)}>
                Next
            </button>

        </div>
    );
};


export default reduxForm({
    form: 'operationAccess', // a unique identifier for this form
})(OperationAccess);

