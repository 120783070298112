import React from "react";
import {connect} from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { renderSearchField } from "../../../Utils/renderField";

let InfluencerAgentFiltersForm = (props) => {
    //  State

    //  Bind
    const {changeFilters} = props;

    return (
        <div className="row">
            <div className={`col-xl-2 col-lg-4 col-sm-12 col-md-12 mt-2 d-flex flex-row align-items-end`}>
                <div className="form-group has-feedback w-100">
                    <Field
                        name="search"
                        label="Search"
                        placeholder={'Search Agent'}
                        component={renderSearchField}
                        onChange={(e) => {
                            changeFilters(
                                "search"
                            )
                        }}
                        type="text"
                    />
                </div>
            </div>
        </div> 
    );
};

InfluencerAgentFiltersForm = reduxForm({
    form: 'InfluencerAgentFiltersForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(InfluencerAgentFiltersForm);

const selector = formValueSelector('InfluencerAgentFiltersForm');

const mstp = state => {

    return {
    }
}

export default connect(mstp, null)(InfluencerAgentFiltersForm);
