import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/wallet';
import { getMe } from '../../../redux/modules/cuenta/login';
import Wallet from "./Wallet";

const ms2p = (state) => {
    return {
        ...state.wallet,
        login: state.login,
    };
};

const md2p = {
    ...actions,
    getMe,
};

export default connect(ms2p, md2p)(Wallet);
