import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { initialize as initializeForm, change } from 'redux-form';
import { api } from "api";

// ------------------------------------
//  CONSTATNS
// ------------------------------------
const LOADER = 'LOADER_WHOLE_SaLER_REGISTER';
const PROGRESS_STEP = 'PROGRESS_STEP_WHOLE_SaLER_REGISTER';
const ALL_DATA_FORM = 'ALL_DATA_FORM_WHOLE_SaLER_REGISTER';
const SET_CODE_VALIDATE = 'SET_CODE_VALIDATE_WHOLE_SaLER_REGISTER';
const SET_EMAIL_CONFIRMED = 'SET_EMAIL_CONFIRMED_WHOLE_SaLER_REGISTER';
const SET_PHONE_CONFIRMED = 'SET_PHONE_CONFIRMED_WHOLE_SaLER_REGISTER';
const SET_STEP_BACK = 'SET_STEP_BACK_WHOLE_SaLER_REGISTER';
const TEAMS = 'TEAMS_WHOLE_SaLER_REGISTER';
const SET_SUCCESS = 'SET_SUCCESS_WHOLE_SaLER_REGISTER';
const DISABLED_TEAM = 'DISABLED_TEAM_WHOLE_SaLER_REGISTER';

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});
export const setCode = code => ({
    type: SET_CODE_VALIDATE,
    code,
});
export const setEmailConfirmed = email_confirmed => ({
    type: SET_EMAIL_CONFIRMED,
    email_confirmed,
});
export const setPhoneConfirmed = phone_confirmed => ({
    type: SET_PHONE_CONFIRMED,
    phone_confirmed,
});

const setStep = step => ({
    type: PROGRESS_STEP,
    step: step,
});

const setAllDataForm = (data) => ({
    type: ALL_DATA_FORM,
    data,
});

export const setStepBack = stepBack => ({
    type: SET_STEP_BACK,
    stepBack,
});

const setTeams = teams => ({
    type: TEAMS,
    teams,
});
const setSuccess = (success) => ({
    type: SET_SUCCESS,
    success,
});
const setDisabledTeam = (disabledTeam) => ({
    type: DISABLED_TEAM,
    disabledTeam,
});

// ------------------------------------
// Actions
// ------------------------------------
const resetInitialData = (teamId = null) => (dispatch) => {
    dispatch(setLoader(false));
    dispatch(setStep(0));
    dispatch(setCode(false));
    dispatch(setEmailConfirmed(false));
    dispatch(setPhoneConfirmed(false));
    dispatch(setAllDataForm({}));
    dispatch(setStepBack(false));
    dispatch(setSuccess(false));
    dispatch(cleanForm())
    if (teamId) {
        dispatch(setTeamId(teamId))
    } else {
        dispatch(setDisabledTeam(false))
    }
}

const getTeams = () => (dispatch) => {
    api.get(`general/option_team`, {})
        .then((response) => {
            if (response) {
                const results = [{ label: 'Not Listed', value: 'not_listed' }, ...response.results]
                dispatch(setTeams(results))
            } else {
                dispatch(setTeams([]))
            }
        }).catch(error => {
            dispatch(setTeams([]))
        })
}

const insertEmail = (email) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post('confirmation-code/create_code', { 'email': email })
        .then(response => {
            dispatch(setStepBack(true));
            NotificationManager.success('Verification code sent to your email', 'Success', 2000)
        })
        .catch((error) => {
            const _message = "Fail to send code, please verify your email and try again"
            const message = error.detail ? error.detail : _message
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
}

const insertPhone = (mobile_number) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    let _mobile_number = mobile_number.replace(/-/g, '');
    api.post('confirmation-code', { 'phone_number': _mobile_number })
        .then(response => {
            dispatch(setStepBack(true));
            NotificationManager.success('Verification code sent to your cell phone', 'Success', 2000)
        })
        .catch((error) => {
            const _message = "The message could not be sent, please try again"
            const message = error.detail ? error.detail : _message
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
}

const resendCodeEmail = () => (dispatch, getStore) => {
    dispatch(setLoader(true))
    const store = getStore();
    const form = store.form.wholeSalerRegisterForm;
    const values = form.values ? form.values : {};
    const email = values.email ? values.email : '';
    if (email != '' && email != undefined && email != null) {
        api.post('confirmation-code/resend_code_email', { 'email': email })
            .then(() => {
                NotificationManager.success('Verification code resent to your email', 'Success', 2000)
            })
            .catch(() => {
                NotificationManager.error('The code could not be sent, please try again', 'Error', 2000)
            })
            .finally(() => {
                dispatch(setLoader(false));
            })
    } else {
        NotificationManager.error('The email is required', 'Error', 2000)
    }
};

const resendCodePhone = () => (dispatch, getStore) => {
    dispatch(setLoader(true))
    const store = getStore();
    const form = store.form.wholeSalerRegisterForm;
    const values = form.values ? form.values : {};
    const phone = values.mobile_number ? values.mobile_number : '';
    let _mobile_number = phone.replace(/-/g, '');
    api.post('confirmation-code/resend_code_phone', { 'phone_number': _mobile_number })
        .then(() => {
            NotificationManager.success('Code sent', 'Success', 2000)
        })
        .catch(() => {
            NotificationManager.error('Code send Failed', 'Error', 2000)
        })
        .finally(() => {
            dispatch(setLoader(false));
        })
};

const validateCode = (value) => (dispatch, getStore) => {
    const store = getStore();
    const resource = store.wholeSalerRegister;
    const { step } = resource;
    const form = store.form.wholeSalerRegisterForm;
    const values = form.values ? form.values : {};
    const email = values.email ? values.email : '';
    const url = 'confirmation-code/validate_code_email';
    const body = { email, 'code': value };
    dispatch(setLoader(true));
    if (email != '' && email != undefined && email != null) {
        api.post(`${url}`, body)
            .then(response => {
                dispatch(setEmailConfirmed(true));
                dispatch(setStep(step + 1));
                dispatch(setCode(false)); // for view the code_number form
                dispatch(setStepBack(false));
            })
            .catch((error) => {
                dispatch(setCode('error'))
            })
            .finally(() => {
                dispatch(setLoader(false))
            })
    } else {
        NotificationManager.error('The email is required', 'Error', 2000)
    }
};

const validatePhoneCode = (value) => (dispatch, getStore) => {
    const store = getStore();
    const resource = store.wholeSalerRegister;
    const { step } = resource;
    const form = store.form.wholeSalerRegisterForm;
    const values = form.values ? form.values : {};
    const mobile_number = values.mobile_number ? values.mobile_number : '';
    const url = 'confirmation-code/validate_code';
    let _mobile_number = mobile_number.replace(/-/g, '');
    const body = { phone_number: _mobile_number, code: value };
    dispatch(setLoader(true));
    if (_mobile_number != '' && _mobile_number != undefined && _mobile_number != null) {
        api.post(`${url}`, body)
            .then(response => {
                dispatch(setPhoneConfirmed(true));
                dispatch(setStep(step + 1));
                dispatch(setCode(false)); // for view the code_number form
                dispatch(setStepBack(false));
            })
            .catch((error) => {
                dispatch(setCode('error'))
            })
            .finally(() => {
                dispatch(setLoader(false))
            })
    } else {
        NotificationManager.error('The email is required', 'Error', 2000)
    }
};

const onSubmitStep = () => (dispatch, getStore) => {
    const store = getStore();
    const source = store.wholeSalerRegister;
    const { step, email_confirmed, phone_confirmed, disabledTeam } = source;
    let data = source.data;
    if (step === 0 || step === 1) {
        dispatch(setStep(step + 1));
    }
    if (step === 2 && !disabledTeam) {
        dispatch(setStep(step + 1));
    }

    //  Phone number
    if ((step === 2 && disabledTeam) || (step === 3 && !disabledTeam)) {
        const form = store.form.wholeSalerRegisterForm;
        const values = form.values ? form.values : {};
        if (phone_confirmed) {
            dispatch(setStep(step + 1));
        } else {
            dispatch(insertPhone(values.mobile_number));
        }
    }

    //  Email
    if ((step === 3 && disabledTeam) || (step === 4 && !disabledTeam)) {
        const form = store.form.wholeSalerRegisterForm;
        const values = form.values ? form.values : {};
        if (email_confirmed) {
            dispatch(setStep(step + 1));
        } else {
            dispatch(insertEmail(values.email));
        }
    }

    if ((step === 4 && disabledTeam) || (step === 5 && !disabledTeam)) {
        dispatch(setStep(step + 1));
    }

}

const goBack = () => (dispatch, getStore) => {
    const store = getStore();
    const source = store.wholeSalerRegister;
    const { step, stepBack, disabledTeam } = source;

    if (step === 1 || (step === 2 && !disabledTeam)) {
        dispatch(setStep(step - 1));
    }

    if ((step == 2 && disabledTeam) || (step === 3 && !disabledTeam)) {
        dispatch(setStep(step - 1));
        dispatch(setStepBack(false));
        dispatch(setCode(false));
        dispatch(setPhoneConfirmed(false));
    }

    if ((step === 3 && disabledTeam) || (step === 4 && !disabledTeam)) {
        dispatch(setStep(step - 1));
        dispatch(setStepBack(false));
        dispatch(setCode(false));
        dispatch(setEmailConfirmed(false));
    }

    if ((step === 4 && disabledTeam) || (step === 5 && !disabledTeam)) {
        dispatch(setStep(step - 1));
    }
}

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('wholeSalerRegisterForm', {}));
}

const setTeamId = (team_id) => (dispatch) => {
    dispatch(setDisabledTeam(true))
    dispatch(change('wholeSalerRegisterForm', 'team', team_id))
}

const skipTeam = () => (dispatch, getStore) => {
    const store = getStore();
    const source = store.wholeSalerRegister;
    const { step } = source;
    dispatch(setStep(step + 1))
}

const signUp = () => (dispatch, getStore) => {
    const store = getStore();
    const source = store.wholeSalerRegister;
    const { step } = source;
    const form = store.form.wholeSalerRegisterForm;
    const values = form.values ? form.values : {};

    const data = {
        'first_name': values.first_name,
        'last_name': values.last_name,
        'email': values.email,
        'password': values.password,
        'mobile_number': values.mobile_number,
        'team': values.team,
        'street': values.street,
    }

    dispatch(setLoader(true));
    api.post('agent-user/wholesaler_sign_up', data)
        .then(() => {
            dispatch(setStep(step + 1));
            NotificationManager.success('Signup complete', 'Success', 2000)
            dispatch(setSuccess(true));
            dispatch(cleanForm());

        })
        .catch((error) => {
            let message = 'Error to register, please try again';
            if (error && error.detail) {
                message = error.detail
            }
            NotificationManager.error(message, 2000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
}

export const actions = {
    resetInitialData,
    getTeams,
    onSubmitStep,
    goBack,
    resendCodeEmail,
    resendCodePhone,
    validateCode,
    validatePhoneCode,
    setDisabledTeam,
    setTeamId,
    skipTeam,
    signUp,
}

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_CODE_VALIDATE]: (state, { code }) => {
        return {
            ...state,
            code,
        };
    },
    [SET_EMAIL_CONFIRMED]: (state, { email_confirmed }) => {
        return {
            ...state,
            email_confirmed,
        };
    },
    [SET_PHONE_CONFIRMED]: (state, { phone_confirmed }) => {
        return {
            ...state,
            phone_confirmed,
        };
    },
    [PROGRESS_STEP]: (state, { step }) => {
        return {
            ...state,
            step,
        };
    },
    [ALL_DATA_FORM]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [SET_STEP_BACK]: (state, { stepBack }) => {
        return {
            ...state,
            stepBack,
        };
    },
    [TEAMS]: (state, { teams }) => {
        return {
            ...state,
            teams,
        };
    },
    [SET_SUCCESS]: (state, { success }) => {
        return {
            ...state,
            success,
        };
    },
    [DISABLED_TEAM]: (state, { disabledTeam }) => {
        return {
            ...state,
            disabledTeam,
        };
    },
};

export const initialState = {
    loader: false,
    step: 0,
    code: false,
    email_confirmed: false,
    phone_confirmed: false,
    data: {},
    stepBack: false,
    teams: [],
    success: false,
    disabledTeam: false,
};

export default handleActions(reducers, initialState);
