import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";
import {NotificationManager} from "react-notifications";
import {api} from "api";
import {push} from "react-router-redux";
import {initialize as initializeForm, change} from "redux-form";
import {getMe} from "../cuenta/login";

const DATA_ROSTER_LIST = 'DATA_ROSTER_LIST_DETAILS';
const SEARCH_VALUE = 'SEARCH_BROKERAGE';
const SET_FILES = 'SET_FILES_BROKERAGE';
const SET_EXCEL_DATA = 'SET_EXCEL_DATA';
const SET_LOADER_2 = 'SET_LOADER_2_CREATE';
const SET_SEARCH_IN_DETAILS = 'SET_SEARCH_IN_DETAILS';
const SET_URL_EXCEL_TEMPLATE = 'SET_URL_EXCEL_TEMPLATE';
const SET_PAGE_AGENT_IN_DETAILS = 'SET_PAGE_AGENT_IN_DETAILS';
const SET_USER_ITEM = 'SET_USER_ITEM_BROKERAGE';

const baseReducer = createReducer(
    "brokerage", //unique identified on store.
    "brokerage", //endpoint for request.
    "brokerageForm", //form name.
    "/brokerage", //url of component in frontend
);

// ------------------------------------
// Pure Actions
// ------------------------------------


const setRosterList = roster => ({
    type: DATA_ROSTER_LIST,
    roster,
});

const setSearchAgent = searchAgent => ({
    type: SET_SEARCH_IN_DETAILS,
    searchAgent,
});

const setPageAgentList = pageAgent => ({
    type: SET_PAGE_AGENT_IN_DETAILS,
    pageAgent,
});

const setExcel = excel => ({
    type: SET_EXCEL_DATA,
    excel,
});

const setLoader2 = loader2 => ({
    type: SET_LOADER_2,
    loader2,
});

const setUrlExcelTemplate = urlTemplate => ({
    type: SET_URL_EXCEL_TEMPLATE,
    urlTemplate,
});

const setFiles = (files) => (dispatch) => {
    dispatch({
        type: SET_FILES,
        files,
    });
};

const setUserItem = (userItem) => ({
    type: SET_USER_ITEM,
    userItem,
});

// ------------------------------------
// Actions
// ------------------------------------

const onChangeList = (data) => (dispatch, getStore) => {
    const resource = getStore()['brokerage'];
    dispatch(baseReducer.actions.setLoader(true));
    api.postAttachments('brokerage/upload_agent', {'brokerage_id': resource.item.id}, [{
        name: "agents",
        file: data,
    }])
        .then((response) => {
            dispatch(agentList(1));
        })
        .catch((error) => {
            if (error.details === "already exist") {
                NotificationManager.info('Agent roster already exist', 'NOTIFICATIONS', 0);
            } else {
                NotificationManager.error('Error in transaction', 'ERROR', 3000);
            }
        })
        .finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        });
};

const agentList = (page = 1, brokerage_id = '') => (dispatch, getStore) => {
    const resource = getStore()['brokerage'];
    const params = {page};
    params.ordering = resource.ordering;
    params.search = resource.searchAgent;
    params.company_id = brokerage_id !== '' ? brokerage_id : resource.item.id;
    dispatch(setLoader2(true));

    api.get('broker-invite', params)
        .then((response) => {
            dispatch(setPageAgentList(page));
            dispatch(setRosterList(response));
        })
        .catch(() => {
        })
        .finally(() => {
            dispatch(setLoader2(false));
        });


    // const resource = getStore()['brokerage'];
    // const params = {page};
    // // params.account_status = '';
    // params.ordering = resource.ordering;
    // params.search = resource.searchAgent;
    // params.brokerage_id = brokerage_id !== '' ? brokerage_id : resource.item.id;
    // dispatch(setLoader2(true));
    // api.get('brokerage-user', params)
    //     .then((response) => {
    //         dispatch(setPageAgentList(page));
    //         dispatch(setRosterList(response));
    //     })
    //     .catch(() => {
    //     })
    //     .finally(() => {
    //         dispatch(setLoader2(false));
    //     });
};

const searchAgent = search => (dispatch) => {
    dispatch(setSearchAgent(search));
    dispatch(agentList());
};

const getUrlExelTemplate = () => (dispatch) => {
    api.get('brokerage/template_excel')
        .then((response) => {
            dispatch(setUrlExcelTemplate(response.url));
        })
        .catch(() => {
        })
        .finally(() => {
        });
};

const createAttachment = (data = {}, restartState, minOffice = true, provideAgentsRoster=true) => (dispatch, getStore) => {
    dispatch(setLoader2(true));
    delete data.logo;
    delete data.offfice_w9;
    const logo = getStore().brokerage.files;

    const archive = getStore().brokerage.excel;

    // if (!archive && provideAgentsRoster == true) {
    //     NotificationManager.error('Agent list ir required', 'ERROR', 3000);
    // } else {
    if (logo.length !== 0 || archive.length !== 0) {
        let _attachments = []
        if (logo.length !== 0) {
            _attachments.push({name: 'logo', file: logo})
        }
        if (archive.length !== 0) {
            _attachments.push({name: 'office_w9', file: archive})
        }
        console.log("ARCHIVE: ", archive);
        api.postAttachments('brokerage', data, _attachments,
            {})
            .then((response) => {
                dispatch(setFiles([]));
                dispatch(setExcel([]));
                (restartState) && restartState();
                (minOffice === false) && dispatch(getMe());
                dispatch(baseReducer.actions.toList())
                NotificationManager.success('Office created', 'SUCCESS', 3000);
            })
            .catch((error) => {
                console.log("ERROR: ", error);
                if (error && error.detail) {
                    NotificationManager.error(error.detail, 'ERROR', 0);
                } else {
                    NotificationManager.error('Create failed', 'ERROR', 3000);
                }
            })
            .finally(() => {
                dispatch(setLoader2(false));
            });
    }
    // }

};

const updateAttachment = (id = null, data = {}) => (dispatch, getStore) => {
    delete data.logo;
    delete data.office_w9;
    const logo = getStore().brokerage.files;
    const archive = getStore().brokerage.excel;
    if (logo.length !== 0 || archive.length !== 0) {
        dispatch(baseReducer.actions.setLoader(true));
        api.putAttachments(`brokerage/${id}`, data,
            [logo.length !== 0 && {name: 'logo', file: logo},
                archive.length !== 0 && {name: 'office_w9', file: archive}],
            {})
            .then((response) => {
                NotificationManager.success('Office updated', 'SUCCESS', 3000);
                dispatch(setFiles([]));
                dispatch(setExcel([]));
                dispatch(push(`/brokerage`));
            })
            .catch(() => {
                NotificationManager.error('Update failed', 'ERROR');
            })
            .finally(() => {
                dispatch(baseReducer.actions.setLoader(false));
            });
    } else {
        api.put(`brokerage/${id}`, data)
            .then((response) => {
                NotificationManager.success('Office updated', 'SUCCESS', 3000);
                dispatch(push(`/brokerage`));
            })
            .catch(() => {
                NotificationManager.error('Update failed', 'ERROR');
            })
            .finally(() => {
                dispatch(baseReducer.actions.setLoader(false));
            });
    }

};

const setItemToNull = () => (dispatch) => {
    dispatch(initializeForm('brokerageForm', {}))
    dispatch(baseReducer.actions.setItem({}));
};

const readUser = (id) =>  (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));
    api.get(`user/${id}`).then((response) => {
        if (response) {
            dispatch(setUserItem(response))
        }
    }).catch(() => {
        NotificationManager.error('Error', 'ERROR', 0);
    }).finally(() => {
        dispatch(baseReducer.actions.setLoader(false));
    });
}

const changeUserPassword = (data, goToBack=null) =>  (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));
    api.post(`brokerage/changed_user_password`, data).then((response) => {
        if (response) {
            NotificationManager.success('Password updated', 'Success', 3000);
            if (goToBack) {
                goToBack()
            }
        }
    }).catch(() => {
        NotificationManager.error('Password reset failed', 'ERROR', 0);
    }).finally(() => {
        dispatch(baseReducer.actions.setLoader(false));
    });
}

const removeInvite = id => (dispatch) => {
    dispatch(setLoader2(true));
    api.eliminar(`broker-invite/${id}`).then(() => {
        dispatch(agentList());
        NotificationManager.success('Record removed', 'Success', 3000);
    }).catch(() => {
        NotificationManager.error('Transaction error', 'Error', 3000);
    }).finally(() => {
        dispatch(setLoader2(false));
    });
};

const assignAddressData = (data, form) => (dispatch) => {
    if (data) {
        const {state, city, zip_code} = data
        dispatch(change(form, 'state', state))
        dispatch(change(form, 'city', city))
        dispatch(change(form, 'zipcode', zip_code))
    }

}

export const actions = {
    onChangeList,
    setFiles,
    agentList,
    searchAgent,
    createAttachment,
    updateAttachment,
    getUrlExelTemplate,
    setItemToNull,
    setExcel,
    readUser,
    changeUserPassword,
    ...baseReducer.actions,
    removeInvite,
    assignAddressData,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    ...baseReducer.reducers,
    [SEARCH_VALUE]: (state, {search}) => {
        return {
            ...state,
            search,
        };
    }
    ,
    [SET_FILES]: (state, {files}) => {
        return {
            ...state,
            files,
        };
    },
    [SET_EXCEL_DATA]: (state, {excel}) => {
        return {
            ...state,
            excel,
        };
    },
    [SET_URL_EXCEL_TEMPLATE]: (state, {urlTemplate}) => {
        return {
            ...state,
            urlTemplate,
        };
    },
    [SET_LOADER_2]: (state, {loader2}) => {
        return {
            ...state,
            loader2,
        };
    },
    [DATA_ROSTER_LIST]: (state, {roster}) => {
        return {
            ...state,
            roster,
        };
    },
    [SET_SEARCH_IN_DETAILS]: (state, {searchAgent}) => {
        return {
            ...state,
            searchAgent,
        };
    },
    [SET_PAGE_AGENT_IN_DETAILS]: (state, {pageAgent}) => {
        return {
            ...state,
            pageAgent,
        };
    },
    [SET_USER_ITEM]: (state, {userItem}) => {
        return {
            ...state,
            userItem,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    files: [],
    excel: [],
    option: [],
    //values of one office details
    roster: {
        results: [],
        count: 0
    },
    urlTemplate: '',
    loader2: false,
    permissions: {},
    pageAgent: 1,
    searchAgent: '',
    userItem: null,
    ...baseReducer.initialState,
};

export default handleActions(reducers, initialState);
