import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";
import {NotificationManager} from "react-notifications";
import {api} from "api";
import {push} from "react-router-redux";
import {toList2 as toListRegistered, resetData as resetDataRegistered} from './userAgents'
import { change as changeForm, initialize } from "redux-form";
import {SELECT_ALL_OPTION} from '../../../utility/constants'

// ------------------------------------
// Constants
// ------------------------------------
const LOADER = 'LOADER_AGENT';
const SET_FILES_AGENT = 'SET_FILES_AGENT';
const SET_OPTION_FILTER = 'SET_OPTION_FILTER_AGENT';
const SET_CATEGORY_FILTER = 'SET_CATEGORY_FILTER';
const SET_NAME_OFFICE__FILTER = 'SET_NAME_OFFICE__FILTER';
const SET_URL_TEMPLATE = 'SET_URL_TEMPLATE__AGENT';
const TAB = 'TAB__AGENT';
const SET_STATS = 'SET_STATS_AGENT';
const SET_PROVIDE_AGENTS_ROSTER = 'SET_PROVIDE_AGENTS_ROSTER_IN_AGENT_ROSTER';
const SET_BROKERAGES = 'SET_BROKERAGES_AGENT';
const SET_DOWNLOAD_LOADER = 'SET_DOWNLOAD_LOADER_IN_AGENT_ROSTER';

//-------------------------------------
// Base reducer
//-------------------------------------
const basicReducer = createReducer(
    "agent",
    "agent",
    "createAgentRosterForm",
    "/agents",
);

// ------------------------------------
// Pure Actions
// ------------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setOption = offices => ({
    type: SET_OPTION_FILTER,
    offices,
});

const setFilterCategory = category => ({
    type: SET_CATEGORY_FILTER,
    category,
});

const setFilterOffice = idOffice => ({
    type: SET_NAME_OFFICE__FILTER,
    idOffice,
});

const setUrl = urlTemp => ({
    type: SET_URL_TEMPLATE,
    urlTemp,
});

export const setTab = tab => ({
    type: TAB,
    tab,
});

const setStats = (stats) => ({
    type: SET_STATS,
    stats,
});

export const setProvideAgentsRoster = provide_agents_roster => ({
    type: SET_PROVIDE_AGENTS_ROSTER,
    provide_agents_roster,
});

const setBrokerages = (brokerages) => ({
    type: SET_BROKERAGES,
    brokerages,
});

const setDownloadLoader = (downloadLoader) => ({
    type: SET_DOWNLOAD_LOADER,
    downloadLoader,
});

// ------------------------------------
// Actions
// ------------------------------------

const onChange = (data) => (dispatch) => {
    dispatch(setLoader(true));
    api.postAttachments('agent', {}, [{
        name: "rosters",
        file: data,
    }])
        .then((response) => {
            if (response.results === "already exist") {
                NotificationManager.info('File already exist', 'NOTIFICATIONS', 0);
            } else {
                dispatch(basicReducer.actions.toList());
            }
        })
        .catch(() => {
            NotificationManager.error('Upload file failed', 'ERROR', 0);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const toList2 = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore()
    const resource = store['agent'];
    let params = {page};
    params.ordering = resource.ordering;
    // params.search = resource.search;
    // params.account_status = resource.category;
    // params.brokerage__id = resource.idOffice;
    const { AgentFiltersForm } = store.form;
    if (AgentFiltersForm && AgentFiltersForm.values) {
        const { brokerage, unregistered_status, search } =
            AgentFiltersForm.values;
    
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            if (brokerage.length == 0) {
                params.brokerage__id__in = 0
            }else {
                params.brokerage__id__in = brokerage;
            }
        }
        if (unregistered_status !== "" && unregistered_status !== null && unregistered_status !== undefined && unregistered_status !== SELECT_ALL_OPTION["value"]) {
            if (unregistered_status.length == 0) {
                params.account_status__in = 0
            }else {
                params.account_status__in = unregistered_status;
            }
        }
        if (search !== "" && search !== null && search !== undefined) {
            params.search = search;
        }
    }
    api.get('agent', params)
        .then((response) => {
            dispatch(basicReducer.actions.setData(response));
            dispatch(basicReducer.actions.setPage(page));
        })
        .catch(() => {
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const getAgentUser = (id) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.get('agent/agent_user', {'id': id})
        .then((response) => {
            dispatch(basicReducer.actions.setItem(response));
        })
        .catch((error) => {
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const changedPasse = (id, data, history) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.post(`agent/changed_passe`, {
        ...data,
        'id': id,
    })
        .then((response) => {
            NotificationManager.success('Password updated', 'SUCCESS', 1000);
            dispatch(history.goBack());
        })
        .catch(() => {
            console.log('error');
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const update = (data = {}) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`agent/${data.id}`, {...data, signed: false})
        .then((response) => {
            dispatch(push('/agents'));
            NotificationManager.success('Agent updated successfully', 'SUCCESS', 1000);
        })
        .catch((error) => {
            if (error && error.detail) {
                NotificationManager.error(error.detail, "Error", 3000);
            } else {
                NotificationManager.error(
                    "The agent roster could not be edited, try again later",
                    "Error",
                    3000
                );
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const setFilesAgent = (files) => (dispatch) => {
    dispatch({
        type: SET_FILES_AGENT,
        files,
    });
};

const getOffices = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('brokerage/option_select')
        .then(response => {
            let option = [];
            if (response.results.length > 1) {
                option = [{
                    label: 'All',
                    value: '',
                }, ...response.results];
            }else {
                option = [...response.results];
                dispatch(setFilterOffice(response.results[0].value));
                dispatch(changeForm('agentForm', 'filterOffice', response.results[0].value))
            }
            dispatch(setOption({results: option}));
        })
        .catch(() => {
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const setCategoryFilter = (category) => (dispatch, getStore) => {
    const page = getStore()['agent'].page;
    dispatch(setFilterCategory(category.value));
    setTimeout(() => {
        dispatch(toList2(page));
    }, 50);
};

const setOfficeFilter = (office) => (dispatch, getStore) => {
    const page = getStore()['agent'].page;
    // let ids = [];
    // if (office.length > 0) {
    //     office.map(option => {
    //         ids.push(option.value);
    //     });
    // }
    // console.log(office)
    // dispatch(setFilterOffice(ids));
    dispatch(setFilterOffice(office.value));
    dispatch(toList2(page));
};

const blockAgent = (id) => (dispatch, getStore) => {
    const page = getStore()['agent'].page;
    dispatch(setLoader(true));
    api.put(`agent/${id}/to_block`)
        .then(response => {
            NotificationManager.success('block user successfully', 'SUCCESS', 2000);
            dispatch(toList2(page));
        })
        .catch((error) => {
            console.log('error:', error);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const unblockAgent = (id) => (dispatch, getStore) => {
    const page = getStore()['agent'].page;
    dispatch(setLoader(true));
    api.put(`agent/${id}/unblock`)
        .then(response => {
            NotificationManager.success('Unblock user successfully', 'SUCCESS', 2000);
            dispatch(toList2(page));
        })
        .catch((error) => {
            console.log('error:', error);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const sendAgentInvite = (id) => (dispatch, getStore) => {
    const page = getStore()['agent'].page;
    dispatch(setLoader(true));
    api.put(`agent/${id}/send_invite`)
        .then(response => {
            NotificationManager.success('Invite sent success', 'SUCCESS', 1000);
            dispatch(toList2(page));
        })
        .catch((error) => {
            console.log('error:', error);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const getUrlFileTemplate = () => (dispatch) => {
    api.get('brokerage/template_excel')
        .then((response) => {
            dispatch(setUrl(response.url));
        })
        .catch(() => {
        })
        .finally(() => {
        });
};

const uploadAgentList = (brokerage_id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.postAttachments('brokerage/upload_agent', {'brokerage_id': brokerage_id}, [{
        name: "agents",
        file: data,
    }])
        .then((response) => {
            NotificationManager.success('Agent Roster updated', 'SUCCESS', 3000);
            dispatch(toList2(1));
        })
        .catch((error) => {
            if (error && error.detail) {
                NotificationManager.error(error.detail, 'Error', 0);
            } else {
                NotificationManager.error('Error in transaction', 'ERROR', 3000);
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const updateRosterList = (brokerage_id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.postAttachments('brokerage/update_roster', {'brokerage_id': brokerage_id}, [{
        name: "file",
        file: data,
    }])
        .then((response) => {
            dispatch(toList2(1));
        })
        .catch((error) => {
            NotificationManager.error('Error updating roster list', 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const sendInviteToAllAgent = () => (dispatch, getStore) => {
    const resource = getStore()['agent'];
    let brokerage_id = resource.idOffice;
    console.log('BROKERAGE_ID: ', brokerage_id)
    dispatch(setLoader(true));
    api.post(`brokerage/invite_all_agent`, {brokerage_id})
        .then(() => {
            NotificationManager.success('Invites sent success', 'SUCCESS', 1000);
        })
        .catch((error) => {
            if (error && error.detail) {
                NotificationManager.error(error.detail, 'Error', 3000);
            } else {
                NotificationManager.error('Invite all agent failed', 'Error', 3000);
            }
        }).finally(() => {
        dispatch(setLoader(false));
    })
}

const searchChange2 = search => (dispatch) => {
    dispatch(basicReducer.actions.setSearch(search));
    dispatch(toList2());
};

const newAgentRoster = (data, brokerage_id) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`agent`, data, { brokerage_id })
        .then(() => {
            NotificationManager.success(
                "The agent roster was created successfully",
                "SUCCESS",
                1000
            );
            dispatch(push("/agents"));
        })
        .catch((error) => {
            if (error && error.detail) {
                NotificationManager.error(error.detail, "Error", 3000);
            } else {
                NotificationManager.error(
                    "The agent roster could not be created, try again later",
                    "Error",
                    3000
                );
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const removeAgentRoster = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`agent/${id}`).then(() => {
        NotificationManager.success('Agent roster removed', 'Success', 3000);
        dispatch(push("/agents"));
    }).catch((error) => {
        console.log("ERROR: ", error);
        NotificationManager.error('Agent roster was not removed, try again later', 'Error', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const initializeFiltersForm = () => (dispatch, getStore) => {
    const resource = getStore()['agent'];
    dispatch(initialize('agentForm', {
        filterCategory: resource.category,
        filterOffice: resource.idOffice ? resource.idOffice : null
    }));
}

export const getStats = () => (dispatch, getStore) => {
    const store = getStore()
    let params = {};
    const { AgentFiltersForm } = store.form;
    if (AgentFiltersForm && AgentFiltersForm.values) {
        const { brokerage } =
            AgentFiltersForm.values;
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            // params.brokerage = brokerage;
            if (brokerage.length == 0) {
                params.brokerage = 0
            }else {
                params.brokerage = brokerage;
            }
        }
    }
    api.get('registered_agents/stats', params)
        .then((response) => {
            if (response) {
                dispatch(setStats(response.results))
            }else {
                dispatch(setStats({
                    total_agent: 0,
                    total_registered: 0,
                    total_user_active: 0
                }))
            }
        })
        .catch(() => {
            dispatch(setStats({
                total_agent: 0,
                total_registered: 0,
                total_user_active: 0
            }))
        })
};

export const getBrokeragesOption =
    (getData = null, selectAll=false) =>
    (dispatch, getStore) => {
        const store = getStore();
        const { AgentFiltersForm } = store.form;
        let params = {};
        let brokerageValue = null;
        if (AgentFiltersForm && AgentFiltersForm.values) {
            const { brokerage } = AgentFiltersForm.values;
            if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
                if (brokerage.length == 0) {
                    brokerageValue = SELECT_ALL_OPTION["value"]
                }else {
                    brokerageValue = brokerage
                }
            }
        }

        api.get(`brokerage/option_select`, params)
            .then((response) => {
                let option = [];
                if (response.results.length > 1) {
                    option = response.results;
                    if (selectAll && brokerageValue == null) {
                        dispatch(
                            changeForm(
                                "AgentFiltersForm",
                                "brokerage",
                                SELECT_ALL_OPTION["value"]
                            )
                        );
                    }

                    if(brokerageValue !== null) {
                        dispatch(
                            changeForm(
                                "AgentFiltersForm",
                                "brokerage",
                                brokerageValue
                            )
                        );
                    }
                } else {
                    option = [...response.results];
                    dispatch(
                        changeForm(
                            "AgentFiltersForm",
                            "brokerage",
                            SELECT_ALL_OPTION["value"]
                        )
                    );
                    if (option.length) {
                        if (option[0].provide_agents_roster) {
                            dispatch(setProvideAgentsRoster(true))
                        }else {
                            dispatch(setProvideAgentsRoster(null))
                        }
                    }else {
                        dispatch(setProvideAgentsRoster(null))
                    }
                }
                dispatch(setBrokerages(option));
            })
            .catch(() => {
                dispatch(setBrokerages([]));
                dispatch(changeForm("AgentFiltersForm", "brokerage", ""));
            })
            .finally(() => {
                if (getData) {
                    setTimeout(() => {
                        dispatch(toList2())
                        dispatch(toListRegistered())
                        dispatch(getStats())
                    }, 500)
                }
            });
    };


export const changeFilters = (type, value=null) => (dispatch, getStore) => {
    console.log("TYPE:, ",  type);
    switch (type) {
        case 'brokerage':
            setTimeout(() => {
                const {brokerages} = getStore()['agent']
                dispatch(toList2())
                dispatch(toListRegistered())
                dispatch(getStats())
                if (value && value.length) {
                    const _value = value[0]
                    if (_value != SELECT_ALL_OPTION["value"]) {
                        const brokerage = _.find(brokerages, {value: _value})
                        if (brokerage && brokerage.provide_agents_roster) {
                            dispatch(setProvideAgentsRoster(true))
                        }else {
                            dispatch(setProvideAgentsRoster(null))
                        }
                    }
                }else {
                    dispatch(setProvideAgentsRoster(null))
                }
            }, 500)
            break;
        case 'registered_status':
            setTimeout(() => {
                dispatch(toListRegistered())
            }, 500)
            break;
        case 'unregistered_status':
            setTimeout(() => {
                dispatch(toList2())
            }, 500)
            break;
        case 'search':
            dispatch(basicReducer.actions.setSearch(value));
            setTimeout(() => {
                dispatch(toListRegistered());
                dispatch(searchChange2(value))
            }, 400);
            break;
        case 'operation_access':
            setTimeout(() => {
                dispatch(toListRegistered())
            }, 500)
            break;
        default:
            break;
    }
}

const downloadRosterList = () => (dispatch, getStore) => {
    dispatch(setDownloadLoader(true));
    const store = getStore();
    const resource = store['agent'];
    let params = {
        ordering: resource.ordering
    };
    const { AgentFiltersForm } = store.form;
    if (AgentFiltersForm && AgentFiltersForm.values) {
        const { brokerage, unregistered_status, search } =
            AgentFiltersForm.values;
        
        const {me} = store['login']
        if (me && me.broker_company) {
            params.broker = me.broker_company
        }else {
            params.broker = 0
        }
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            if (brokerage.length == 0) {
                params.brokerage = 0
            }else {
                params.brokerage = brokerage;
            }
        }
        if (unregistered_status !== "" && unregistered_status !== null && unregistered_status !== undefined && unregistered_status !== SELECT_ALL_OPTION["value"]) {
            if (unregistered_status.length == 0) {
                params.status = 0
            }else {
                params.status = unregistered_status;
            }
        }
        if (search !== "" && search !== null && search !== undefined) {
            params.search = search;
        }
    }
    api.getBlob('brokerage/roster_excel', params)
        .then((blob) => {
            if (blob) {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = "roster_list.xlsx";
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        })
        .catch((error) => {
            console.log('--- error downloadAgentRawDataReport ---', error);
            let message = "Error obtaining data"
            if (error.detail) message = error.detail;
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setDownloadLoader(false));
        });
};

export const resetData = () => (dispatch) => {
    dispatch(basicReducer.actions.setData({
        results: [],
        count: 0
    }))
    dispatch(resetDataRegistered())
}

// ------------------------------------
// Actions
// ------------------------------------

export const actions = {
    onChange,
    setFilesAgent,
    getAgentUser,
    changedPasse,
    update,
    toList2,
    getOffices,
    uploadAgentList,
    getUrlFileTemplate,
    setCategoryFilter,
    setOfficeFilter,
    sendAgentInvite,
    blockAgent,
    unblockAgent,
    searchChange2,
    sendInviteToAllAgent,
    newAgentRoster,
    removeAgentRoster,
    setTab,
    ...basicReducer.actions,
    initializeFiltersForm,
    getStats,
    getBrokeragesOption,
    changeFilters,
    downloadRosterList,
    updateRosterList,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    ...basicReducer.reducers,
    [LOADER]: (state, {loader}) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_FILES_AGENT]: (state, {files}) => {
        return {
            ...state,
            files,
        };
    },
    [SET_OPTION_FILTER]: (state, {offices}) => {
        return {
            ...state,
            offices,
        };
    },
    [SET_CATEGORY_FILTER]: (state, {category}) => {
        return {
            ...state,
            category,
        };
    },
    [SET_NAME_OFFICE__FILTER]: (state, {idOffice}) => {
        return {
            ...state,
            idOffice,
        };
    },
    [SET_URL_TEMPLATE]: (state, {urlTemp}) => {
        return {
            ...state,
            urlTemp,
        };
    },
    [TAB]: (state, {tab}) => {
        return {
            ...state,
            tab,
        };
    },
    [SET_STATS]: (state, {stats}) => {
        return {
            ...state,
            stats,
        };
    },
    [SET_PROVIDE_AGENTS_ROSTER]: (state, {provide_agents_roster}) => {
        return {
            ...state,
            provide_agents_roster,
        };
    },
    [SET_BROKERAGES]: (state, {brokerages}) => {
        return {
            ...state,
            brokerages,
        };
    },
    [SET_DOWNLOAD_LOADER]: (state, {downloadLoader}) => {
        return {
            ...state,
            downloadLoader,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    files: [],
    offices: {results: []},
    category: '',
    idOffice: '',
    urlTemp: '',
    tab: 'Registered',
    stats: {
        total_agent: 0,
        total_registered: 0,
        total_user_active: 0
    },
    ...basicReducer.initialState,
    provide_agents_roster: null,
    brokerages: [],
    downloadLoader: false,

};

export default handleActions(reducers, initialState);

