import React, { useEffect } from "react";
import './wholeSalerRegister.css';
import "react-step-progress-bar/styles.css";

export const StepProgressBar = (props) => {
    const { progress, disabledTeam=flase } = props;

    return (
        <div className="justify-content-center d-flex mb-3 pt-0 mt-0">
            <div className="circle ml-3 active-circle"> </div>
            <div className={`circle ${progress && progress >= 1 ? "active-circle" : ""}`}> </div>
            {!disabledTeam && (
                <div className={`circle ${progress && (progress >= 2 && !disabledTeam) ? "active-circle" : ""}`}> </div>
            )}
            <div className={`circle ${progress && ((progress >= 3 && !disabledTeam) || (progress >= 2 && disabledTeam)) ? "active-circle" : ""}`}> </div>
            <div className={`circle ${progress && ((progress >= 4 && !disabledTeam) || (progress >= 3 && disabledTeam)) ? "active-circle" : ""}`}> </div>
            <div className={`circle ${progress && ((progress >= 5 && !disabledTeam) || (progress >= 4 && disabledTeam)) ? "active-circle" : ""}`}> </div>
        </div>
    )
}

export const RegisterSuccess = (props) => {

    useEffect(() => {
        if (document.getElementById('WHOLESALER-REGISTER')) {
            document.getElementById('WHOLESALER-REGISTER').scrollIntoView({ behavior: 'smooth' });
        }
    }, [])

    return (
        <div className="row flex-1 justify-content-center pt-3">
            <div className="w-100 col-xl-10 col-lg-11 col-md-11 col-12 d-flex flex-column">
                <div className="flex-1 d-flex flex-column mt-0 mt-lg-3 mt=xl-5">
                    <div className="row">
                        <div className="col-12 d-flex flex-column justify-content-between">
                            <h3 className="mb-3 text-center wholesaler-register-label-title">Thank you for registering</h3>

                            <React.Fragment>
                                <span className="mb-3 text-center wholesaler-register-label-subtitle">
                                    Please allow up to 24 hours to activate your account
                                </span>
                                <span className="mb-3 text-center wholesaler-register-label-subtitle">
                                    Please download the ShowIT Solutions app on your phone.  Once your account is activated, you will recieve on email with login cradentials to your account on ShowIT Solutions™ app
                                </span>
                            </React.Fragment>

                            <span className="mb-0 mt-4 font-weight-bold text-center d-flex align-items-center justify-content-center">
                                <p
                                    className="h4 mb-0 font-weight-bold"
                                    style={{ color: "#AA1711", marginRight: "10px" }}
                                >
                                    Download
                                </p>
                                <p className="h4 mb-0 font-weight-bold">Today</p>
                            </span>
                            <div className="w-100 d-flex flex-column flex-md-row align-items-center justify-content-center">
                                <img
                                    className="img-fluid"
                                    style={{ maxWidth: "230px" }}
                                    src={`https://chart.googleapis.com/chart?chs=400x400&cht=qr&chl=${encodeURIComponent(
                                        "http://onelink.to/yy2qau"
                                    )}&choe=UTF-8`}
                                    alt=""
                                />
                                <a className="a-link wholesaler-register-link" href="http://onelink.to/yy2qau" target="_blank" rel="link"> http://onelink.to/yy2qau</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const SignUpInfo = (props) => {
    const {onSubmit} = props

    useEffect(() => {
        if (document.getElementById('WHOLESALER-REGISTER')) {
            document.getElementById('WHOLESALER-REGISTER').scrollIntoView({ behavior: 'smooth' });
        }
    }, [])

    return (
        <div className="row flex-1 justify-content-center">
            <div className="w-100 col-xl-10 col-lg-11 col-md-11 col-12 d-flex flex-column">
                <div className="flex-1 d-flex flex-column justify-content-between">
                    <div className="row">
                        <div className="col-12">
                            <h3 className="mb-3 text-center wholesaler-register-label-title">Your Showing Assistant App</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-flex flex-column">
                            <span className="mb-0 wholesaler-register-info-text">
                                Welcome to the Showit Solutions App platform! We are delighted to welcome you as a wholesaler and are enthusiastic about the prospect of collaborating with you.
                            </span>
                            <br />
                            <span className="mb-0 wholesaler-register-info-text">
                                By joining the ShowIt Solutions™ platform, you not only become a valued member of our community but also unlock a wealth of opportunities. This platform is meticulously crafted to empower you, allowing you to boost productivity by optimizing your time.
                            </span>
                            <br />
                            <span className="mb-0 wholesaler-register-info-text">
                                With just a few clicks, you can connect with licensed real estate agents to assist you in showcasing properties to your clients, organize open houses, manage Lockbox installations and removals, capture videos and photos of properties, and fulfill various delivery requests. Furthermore, you can easily find any vendor you need to support you in selling or enhancing your properties.
                            </span>
                            <br />
                            <span className="mb-0 wholesaler-register-info-text">
                                To kickstart your journey, please follow the next steps outlined. If you have any questions or concerns, feel free to reach out. Your satisfaction is our top priority, and we are committed to providing the support you require for a positive experience on our platform.
                            </span>
                            <br />
                            <span className="mb-0 wholesaler-register-info-text">
                            Thank you for choosing to be a part of the Showit Solutions app. We eagerly anticipate a successful partnership and can't wait to embark on this journey together.
                            </span>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="buttons-box col-12 justify-content-center mx-auto py-md-3 py-4">
                            <button
                                onClick={onSubmit}
                                type="button"
                                className="btn wholesaler-register-button col-xl-4 col-lg-5 col-sm-8 align-self-center"
                            >
                                Get Started
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
