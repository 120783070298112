import React from 'react';
import moment from "moment";
import {connect} from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import {renderDayPicker} from "../../Utils/renderField/renderField";
import {SelectField, MultiSelectField} from "../../Utils/renderField";
import {AGENT_PERFORMANCE_TIME} from '../../../../utility/constants'

let FilterStatsRevenueForm = (props) => {
    //  State
    const {
        optionBrokerages=[],
        optionJobTypes=[],
        optionAgents=[],
        timeValue,
        start_dateValue,
        end_dateValue,
    } = props;

    //  Bind
    const {changeFilters} = props;
    return (
        <div className="row">
            <div className={`col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2`}>
                <div className="form-group has-feedback w-100">
                    <label htmlFor="brokerage">Office</label>
                    <Field
                        name="brokerage"
                        options={optionBrokerages}
                        set_value={(value) => {
                            changeFilters("brokerage", value);
                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div>
            <div
                className={`col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2`}
            >
                <div className="form-group has-feedback w-100">
                    <label htmlFor="job_type">Job Type</label>
                    <Field
                        name="job_type"
                        options={optionJobTypes}
                        set_value={(value) => {
                            changeFilters("job_type", value);
                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div>
            <div className={`col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2`}>
                <div className="form-group has-feedback w-100">
                    <label htmlFor="agent">Agent</label>
                    <Field
                        name="agent"
                        options={optionAgents}
                        set_value={(value) => {
                            changeFilters("agent", value);
                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2">
                <div className="form-group has-feedback w-100">
                    <label htmlFor="time">Time</label>
                    <Field
                        name="time"
                        isMulti={false}
                        options={AGENT_PERFORMANCE_TIME}
                        set_value={(option) => {
                            changeFilters("time", option ? option.value : "");
                        }}
                        component={SelectField}
                        className="form-control"
                    />
                </div>
            </div>
            {timeValue == "custom" && (
                <React.Fragment>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2">
                        <div className="form-group has-feedback w-100">
                            <label htmlFor="start_date">Start Date</label>
                            <Field
                                name="start_date"
                                initialDate={moment()
                                    .format("YYYY-MM-DD")
                                    .toString()}
                                component={renderDayPicker}
                                maxDate={
                                    end_dateValue
                                        ? moment(end_dateValue).toDate()
                                        : new Date()
                                }
                                onChange={(e) => {
                                    changeFilters("start_date", "");
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2">
                        <div className="form-group has-feedback w-100">
                            <label htmlFor="end_date">End Date</label>
                            <Field
                                name="end_date"
                                initialDate={moment()
                                    .format("YYYY-MM-DD")
                                    .toString()}
                                component={renderDayPicker}
                                minDate={
                                    start_dateValue
                                        ? moment(start_dateValue).toDate()
                                        : new Date()
                                }
                                onChange={(e) => {
                                    changeFilters("end_date", "");
                                }}
                            />
                        </div>
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

const FilterStats = (props) => {
    const {
        dataJobType,
        dataAgentName,
        dataOfficeName,
        setFilterJobType,
        getAgents,
    } = props;
    const today = moment().date()
    const monthBefore = moment(Date.now()).subtract(today - 1, 'days').subtract(5, "months")
    // const daysInMonth = moment().daysInMonth()
    const initialMonth = moment(Date.now()).subtract(today - 1, "days")
    const monthAfter = moment(Date.now())
    return (
        <div className="row w-100 mx-auto pt-1 pb-3">
            <div className="col-sm-12 col-lg-2 pr-lg-1 pr-sm-0 mt-lg-0 mt-sm-1">
                <label htmlFor='job_type_stats'> Job type</label>
                <Field
                    isMulti={false}
                    className="w-100"
                    name="job_type_stats"
                    options={dataJobType}
                    component={SelectField}
                    set_value={setFilterJobType}
                />
            </div>
            <div className="col-sm-12 col-lg-2 pr-lg-1 pr-sm-0 mt-lg-0 mt-sm-1">
                <label htmlFor="office_stats"> Office </label>
                <Field
                    options={dataOfficeName}
                    isMulti={false}
                    disabled={false}
                    className="w-100"
                    name="office_stats"
                    set_value={({value})=>getAgents(value)}
                    component={SelectField}
                />
            </div>
            <div className="col-sm-12 col-lg-2 pr-lg-1 pr-sm-0 mt-lg-0 mt-sm-1">
                <label htmlFor="agent_stats"> Agent </label>
                <Field
                    options={dataAgentName}
                    isMulti={false}
                    disabled={false}
                    className="w-100"
                    name="agent_stats"
                    component={SelectField}
                />
            </div>
            <div className="col-sm-12 col-lg-2 pr-lg-1 pr-sm-0 mt-lg-0 mt-sm-1">
                <label htmlFor="start_date"> Start Date </label>
                <Field
                    name="start_date"
                    className="w-100"
                    component={renderDayPicker}
                    maxDate={monthAfter.toDate()}
                    minDate={monthBefore.toDate()}
                    // initialDate={monthBefore.toISOString()}
                    initialDate={initialMonth.toISOString()}
                />
            </div>
            <div className="col-sm-12 col-lg-2 pr-lg-1 pr-sm-0 mt-lg-0 mt-sm-1">
                <label htmlFor="end_date"> End Date </label>
                <Field
                    name="end_date"
                    className="w-100"
                    maxDate={monthAfter.toDate()}
                    minDate={monthBefore.toDate()}
                    initialDate={moment(Date.now()).format('YYYY-MM-DD')}
                    component={renderDayPicker}
                />
            </div>
            <div className="col-sm-12 col-lg-2 text-center align-self-end mt-lg-0 mt-sm-1">
                <button
                    type="button"
                    className="btn btn-primary px-5 py-1"
                    onClick={() => props.applyFilter(1)}
                >
                    Apply
                </button>
            </div>
        </div>
    );
};

// export default FilterStats;
FilterStatsRevenueForm = reduxForm({
    form: 'FilterStatsRevenueForm', // a unique identifier for this form
})(FilterStatsRevenueForm);

const selector = formValueSelector('FilterStatsRevenueForm');

const mstp = state => {
    const timeValue = selector(state, 'time');
    const start_dateValue = selector(state, 'start_date');
    const end_dateValue = selector(state, 'end_date');

    return {
        timeValue,
        start_dateValue,
        end_dateValue,
    }
}

export default connect(mstp, null)(FilterStatsRevenueForm);
