import React, {Component} from "react";
import {Route, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {logOut, getMe} from "./redux/modules/cuenta/login";

// structure base
import SideBar from './common/components/layout/Sidebar/SideBar';
import SideBarOfficeManager from './common/components/layout/Sidebar/SideBarOfficeManager';
import SideBarAgentCoordinator from './common/components/layout/Sidebar/SideBarAgentCoordinator';
import SideBarMCAFinance from './common/components/layout/Sidebar/SideBarMCAFinance';
import SideBarProductionCoach from './common/components/layout/Sidebar/SideBarProductionCoach';
import InfluencerSideBar from "./common/components/layout/Sidebar/InfluencerSideBar";

import Navbar from "./common/components/layout/Navbar/Navbar";
import {VerifyLogin} from "./common/components/layout";
import {
    AGENT_COORDINATOR,
    MANAGING,
    MCA_FINANCE,
    OFFICE_MANAGER,
    PRODUCTIVITY_COACH,
    QUALIFYING
} from "./utility/constants";


class PrivateRouteBase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toggleOpen: true,
        };
    }

    navToggle = () => {
        this.setState({toggleOpen: !this.state.toggleOpen});
    };

    isAuthenticated = () => {
        const token = localStorage.getItem("token");
        const {getMe, login: {me}} = this.props;
        if (!!token && !!me.email) {
            return true;
        } else if (token) {
            getMe();
            return "Verifying";
        }
        return false;
    };

    isInfluencerUser = () => {
        const userType = localStorage.getItem("user_type");
        const influencerType = 20;

        return userType == influencerType
    }

    render() {
        const {component: Component, logOut, login: {me}, ...rest} = this.props;
        const isAuthenticated = this.isAuthenticated();
        return (
            <Route
                {...rest}
                render={(props) =>
                    isAuthenticated ? (
                        isAuthenticated === true ? (
                            !this.isInfluencerUser() ? (
                                <div>
                                    {me.roles === MANAGING && (
                                        <SideBar
                                            toggleOpen={this.state.toggleOpen}
                                            navToggle={this.navToggle}
                                            user={me}
                                            url={this.props.location.pathname}
                                            textColor={"#000000 !important"}
                                        />
                                    )}
                                    {me.roles === OFFICE_MANAGER && (
                                        <SideBarOfficeManager
                                            toggleOpen={this.state.toggleOpen}
                                            navToggle={this.navToggle}
                                            user={me}
                                            url={this.props.location.pathname}
                                            textColor={"#000000 !important"}
                                        />
                                    )}
                                    {me.roles === AGENT_COORDINATOR && (
                                        <SideBarAgentCoordinator
                                            toggleOpen={this.state.toggleOpen}
                                            navToggle={this.navToggle}
                                            user={me}
                                            url={this.props.location.pathname}
                                            textColor={"#000000 !important"}
                                        />
                                    )}
                                    {me.roles === MCA_FINANCE && (
                                        <SideBarMCAFinance
                                            toggleOpen={this.state.toggleOpen}
                                            navToggle={this.navToggle}
                                            user={me}
                                            url={this.props.location.pathname}
                                            textColor={"#000000 !important"}
                                        />
                                    )}
                                    {(me.roles === PRODUCTIVITY_COACH ||
                                        me.roles === QUALIFYING) && (
                                        <SideBarProductionCoach
                                            toggleOpen={this.state.toggleOpen}
                                            navToggle={this.navToggle}
                                            user={me}
                                            url={this.props.location.pathname}
                                            textColor={"#000000 !important"}
                                        />
                                    )}
                                    <main className="main-content p-0 col-sm-12 col-md-9 offset-md-3 col-lg-x-10 offset-lg-2">
                                        <div className="main-navbar sticky-top">
                                            <Navbar
                                                navToggle={this.navToggle}
                                                logOut={logOut}
                                                user={me}
                                                isManaging={
                                                    me.roles === MANAGING
                                                }
                                            />
                                        </div>
                                        <div className="main-content-container px-4 container-fluid">
                                            <Component {...props} />
                                        </div>
                                        {/*<Footer backgroundColor={'#FFFFFF'} />*/}
                                    </main>
                                </div>
                            ) : (
                                <div>
                                    <InfluencerSideBar
                                        toggleOpen={this.state.toggleOpen}
                                        navToggle={this.navToggle}
                                        user={me}
                                        url={this.props.location.pathname}
                                        textColor={"#000000 !important"}
                                    />
                                    <main className="main-content p-0 col-sm-12 col-md-9 offset-md-3 col-lg-x-10 offset-lg-2">
                                        <div className="main-navbar sticky-top">
                                            <Navbar
                                                navToggle={this.navToggle}
                                                logOut={logOut}
                                                user={me}
                                                isManaging={
                                                    me.roles === MANAGING
                                                }
                                            />
                                        </div>
                                        <div className="main-content-container px-4 container-fluid">
                                            <Component {...props} />
                                        </div>
                                    </main>
                                </div>
                            )
                        ) : (
                            <VerifyLogin />
                        )
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location },
                            }}
                        />
                    )
                }
            />
        );
    }
}

const mstp = state => ({...state});

const mdtp = {
    logOut,
    getMe,
};

const ProtectedRoute = connect(
    mstp,
    mdtp,
)(PrivateRouteBase);

export default ProtectedRoute;
