import React from "react";
import moment from "moment";
import {Link} from 'react-router-dom';
import HeaderCard from '../HeaderCard'
import {Field, reduxForm} from 'redux-form';
import LoadMask from "../Utils/LoadMask/LoadMask";
import { standardActions } from "../Utils/Grid/StandardActions";
import GridTableWithScroll from "../Utils/GridTableWithScroll";
import { renderFilePicker } from "../Utils/renderField/renderField";
import {customFieldFile, renderField, renderNumber, renderAddressField} from '../Utils/renderField';
import {validate, combine, validatorFromFunction, validators} from 'validate-redux-form';
import ImageInputSelect from "../Utils/renderField/ImageInputSelect";
import {
    BROKERAGE_STATUSES,
    BROKERAGE_ACTIVE,
    BROKERAGE_BLOCKED,
    BROKERAGE_LOCKED,
    STATE_USA_LIST,
    USER_ROLES_LIST,
    STAFF,
    SIZE_PER_PAGE,
} from "../../../utility/constants";

const brokerageForm = (props) => {

    const {
        handleSubmit,
        is_update,
        office,
        setFiles,
        text,
        handleOnChange,
        active,
        setActive,
        loader,
        closeModal,
        provideAgentsRoster=true,
        goToAgents,
        goBack,
        searchAction,
        loader2,
        agentList,
        data,
        removeInvite,
        assignAddressData,
    } = props;

    const getStatus = (status) => {
        let text = "";
        BROKERAGE_STATUSES.map((item) => {
            if (status === item.value) {
                text = item.label;
            }
        });
        return text;
    };

    const getColor = (status) => {
        let colorText = "black";
        if (status === BROKERAGE_ACTIVE) {
            colorText = "success";
        } else if (status === BROKERAGE_LOCKED) {
            colorText = "danger";
        } else if (status === BROKERAGE_BLOCKED) {
            colorText = "danger";
        }
        return colorText;
    };

    return (
        <form
            name="brokerageForm"
            className="my-1 col-sm-12 p-sm-0"
            onSubmit={handleSubmit}
            style={{ minHeight: is_update ? "33rem" : "36rem" }}
        >
            <div className="row mx-auto pt-2">
                <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12">
                    <div className="form-group has-feedback">
                        <Field
                            photo={office.logo ? office.logo : null}
                            setFile={setFiles}
                            name="logo"
                            component={renderFilePicker}
                            // disabled={true}
                            // className="avatar-round"
                        />
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Status
                        </span>
                        {office && (
                            <span
                                className={`text-${getColor(
                                    office.status
                                )} font-weight-bold`}
                            >
                                {getStatus(office.status)}
                            </span>
                        )}
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mb-5">
                        <span className="font-weight-bolder h6 mb-1">
                            Sign-up Date
                        </span>
                        <span className="font-weight-bolder text-primary">
                            {moment(office.created).format("ll")}
                        </span>
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Total Agents
                        </span>
                        {office && office.agents_stats && (
                            <span className="font-weight-bolder text-primary pointer" onClick={(e) => goToAgents(office.id)}>
                                {office.agents_stats.total_agents
                                    ? office.agents_stats.total_agents
                                    : 0}
                            </span>
                        )}
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Registered Agents
                        </span>
                        {office && office.agents_stats && (
                            <span className="font-weight-bolder text-primary pointer" onClick={(e) => goToAgents(office.id)}>
                                {office.agents_stats.total_user
                                    ? office.agents_stats.total_user
                                    : 0}
                            </span>
                        )}
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Unregistered Agents
                        </span>
                        {office && office.agents_stats && (
                            <span className="font-weight-bolder text-primary pointer" onClick={(e) => goToAgents(office.id)}>
                                {office.agents_stats.total_unregistered
                                    ? office.agents_stats.total_unregistered
                                    : 0}
                            </span>
                        )}
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Active Agents
                        </span>
                        {office && office.agents_stats && (
                            <span className="font-weight-bolder text-primary pointer" onClick={(e) => goToAgents(office.id)}>
                                {office.agents_stats.total_user_active
                                    ? office.agents_stats.total_user_active
                                    : 0}
                            </span>
                        )}
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Total ShowIT Share
                        </span>
                        {office && (
                            <span className="font-weight-bolder text-primary">
                                ${" "}
                                {office.total_show_it_share
                                    ? office.total_show_it_share.toFixed(2)
                                    : "0.00"}
                            </span>
                        )}
                    </div>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-7 col-sm-12">
                    <div className="row">
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="firm_name">Office name</label>
                            <Field
                                name="firm_name"
                                label="Firm name"
                                placeholder={"Office name"}
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="broker">Broker</label>
                            <div className="form-control">
                                {office && office.broker_info
                                    ? office.broker_info.name
                                    : ""}
                            </div>
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="franchise">Franchise</label>
                            <div className="form-control">
                                {office && office.broker_info && office.broker_info.franchise
                                    ? office.broker_info.franchise.name
                                    : ""}
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="row">
                                <div className="form-group has-feedback col-lg-6 col-sm-12">
                                    <label htmlFor="license">Office License #</label>
                                    <Field
                                        type="text"
                                        name="license"
                                        label="License"
                                        component={renderField}
                                        placeholder={"Office License Number"}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group has-feedback col-lg-6 col-sm-12">
                                    <label htmlFor="telephone">Phobe #</label>
                                    <Field
                                        name="telephone"
                                        type="number"
                                        component={renderNumber}
                                        numberFormat={"###-###-####"}
                                        placeholder={"Telephone"}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="address">Address</label>
                            <Field
                                name="address"
                                label="address"
                                placeholder='Address'
                                component={renderAddressField}
                                type="text"
                                className="form-control"
                                longStateName={true}
                                setCityState={(data) => {
                                    console.log('ADDRESS DATA: ', data);
                                    if (data) {
                                        assignAddressData(data, "brokerageForm")
                                    }
                                }}
                            />
                            {/* <Field
                                name="address"
                                label="Address"
                                component={renderField}
                                type="text"
                                // initialImg={}
                                placeholder={"Address"}
                                className="form-control"
                            /> */}
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="address_line2">Address line 2</label>
                            <Field
                                name="address_line2"
                                label="Address line 2"
                                component={renderField}
                                type="text"
                                placeholder={"Address Line 2"}
                                className="form-control"
                            />
                        </div>
                        {/* <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="city">City</label>
                            <Field
                                name="city"
                                label="City"
                                component={renderField}
                                type="text"
                                placeholder={"City"}
                                className="form-control"
                            />
                        </div> */}
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="provide_roster">
                                Broker Provide agent's roster list?
                            </label>
                            <div className="form-control">
                                {office &&
                                office.broker_info &&
                                office.broker_info.provide_agents_roster
                                    ? "YES"
                                    : "NO"}
                            </div>
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="operational">
                                Operational Mode
                            </label>
                            <div className="form-control">
                                {office && office.broker_info
                                    ? office.broker_info.operation_access_name
                                    : ""}
                            </div>
                        </div>
                        <div className="form-group has-feedback col-sm-12 mt-4">
                            <label htmlFor="Autorized" className="h5">
                                Authorized Personnel
                            </label>
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-12">
                                        <HeaderCard
                                            id_header={"agentGridList"}
                                            searchAction={(value) =>
                                                searchAction(value)
                                            }
                                            showFile={false}
                                            // placeholder="Search Agent"
                                            placeholder="Search"
                                            searchStyle={
                                                "col-lg-5 col-sm-12 img-fluid mr-lg-3 mb-sm-1"
                                            }
                                            // justify={"justify-content-start d-flex col-lg-5 col-sm-12 col-md-6 align-items-end"}
                                            showLink={true}
                                            active_link={true}
                                            link={`users/invite`}
                                            text_link={"Invite"}
                                        />
                                    </div>
                                    <div
                                        className="d-flex flex-column flex-1 mt-3 col-sm-12"
                                    >
                                        <div className="flex-1 d-flex flex-column">
                                            <GridTableWithScroll
                                                height={355}
                                                hover
                                                wrapperClasses={
                                                    "table-scroll-auto"
                                                }
                                                striped
                                                keyField={"id"}
                                                data={data}
                                                loading={loader2}
                                                sizePerPage={SIZE_PER_PAGE}
                                                onPageChange={(value) =>
                                                    agentList(value)
                                                }
                                                columns={[
                                                    // {
                                                    //     dataField: "user.photo",
                                                    //     formatter: avatar(),
                                                    //     text: " ",
                                                    // },
                                                    {
                                                        dataField: "id",
                                                        text: "",
                                                        formatter:
                                                            standardActions({
                                                                remove: removeInvite,
                                                                // view: "users",
                                                                edit: `users`,
                                                            }),
                                                    },
                                                    {
                                                        dataField:
                                                            "account_status",
                                                        formatter:
                                                            formatStatus(),
                                                        text: "Status",
                                                    },
                                                    // {
                                                    //     dataField:
                                                    //         "first_name",
                                                    //     formatter:
                                                    //         fillName(),
                                                    //     text: "Full Name",
                                                    // },
                                                    {
                                                        dataField: "first_name",
                                                        // formatter:
                                                        //     fillName(),
                                                        text: "First Name",
                                                    },
                                                    {
                                                        dataField: "last_name",
                                                        // formatter:
                                                        //     fillName(),
                                                        text: "Last Name",
                                                    },
                                                    {
                                                        dataField: "email",
                                                        text: "E-mail",
                                                    },
                                                    {
                                                        dataField: "phone",
                                                        formatter:
                                                            formatPhoneNumber(),
                                                        text: "Mobile number",
                                                    },
                                                    {
                                                        dataField: "roles",
                                                        formatter:
                                                            formatRoles(),
                                                        text: "Role",
                                                    },
                                                    {
                                                        dataField: "password",
                                                        formatter:
                                                            resetPassword(),
                                                        text: "",
                                                        headerStyle: (colum, colIndex) => {
                                                            return {
                                                                minWidth: "160px",
                                                            };
                                                        },
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mx-auto ">
                        <button
                            type="button"
                            onClick={goBack}
                            className={`w-100 btn btn-secondary text-white col-lg-3 col-sm-5 mx-4 mt-2`}
                        >
                            Back
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primary col-lg-3 col-sm-5 mx-4 mt-2"
                            disabled={loader}
                        >
                            Update
                        </button>
                    </div>
                </div>
            </div>

            {/* <LoadMask loading={loader2} light>
                <div className="row w-100 flex-1 ">
                    <div className="form-group has-feedback col-lg-6 mx-0 col-md-8 col-sm-12">
                        <Field
                            name="logo"
                            photo={item.logo}
                            className="w-100"
                            component={ImageInputSelect}
                            type={"file"}
                            setFile={setFiles}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group has-feedback col-lg-6 col-sm-12">
                        <Field
                            name="firm_name"
                            label="Firm name"
                            placeholder={"Office name"}
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback col-lg-6 col-sm-12">
                        <Field
                            type="text"
                            name="license"
                            label="License"
                            component={renderField}
                            placeholder={"Office License Number"}
                            className="form-control"
                            disabled={is_update}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group has-feedback col-lg-6 col-sm-12">
                        <Field
                            name="city"
                            label="City"
                            component={renderField}
                            type="text"
                            placeholder={"City"}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback col-lg-6 col-sm-12">
                        <Field
                            name="telephone"
                            type="number"
                            component={renderNumber}
                            numberFormat={"###-###-####"}
                            placeholder={"Telephone"}
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group has-feedback col-lg-6 col-sm-12">
                        <Field
                            name="address"
                            label="Address"
                            component={renderField}
                            type="text"
                            // initialImg={}
                            placeholder={"Address"}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback col-lg-6 col-sm-12">
                        <Field
                            name="address_line2"
                            label="Address line 2"
                            component={renderField}
                            type="text"
                            placeholder={"Address Line 2"}
                            className="form-control"
                        />
                    </div>
                </div>
                {provideAgentsRoster == true && (
                    <React.Fragment>
                        {!is_update && (
                            <p className="text-12 mt-1 mb-3 text-justify">
                                In order to give your agents access to all the 
                                perks on ShowIT Solutions™ platform, you will 
                                need to upload your agents roster. To simplify 
                                the process, please download agents roster 
                                template.
                            </p>
                        )}
                        <div className="flex-row text-center">
                            {!is_update && (
                                <div className="col-md-6 mx-auto">
                                    <label className="ml-1 text-12">
                                        Agent Roster Template
                                    </label>
                                    <a
                                        href={props.urlTemplate}
                                        download="template.xlsx"
                                        className="btn btn-success text-white col-lg-8"
                                    >
                                        Download
                                    </a>
                                </div>
                            )}
                        </div>
                        <div className="flex-row text-center mt-2">
                            {!is_update && (
                                <div className="col-md-6 mx-auto">
                                    <label className="text-12">
                                        Import your agent list
                                    </label>
                                    <Field
                                        id={"office_excel"}
                                        name="excel-office"
                                        setFile={handleOnChange}
                                        component={customFieldFile}
                                        title={"Import"}
                                        text={text}
                                        customClass="btn-primary col-lg-8 col-sm-8 py-1 mx-auto"
                                        type={"file"}
                                        active={active}
                                        accept={"*"}
                                        remove={() => setActive(true)}
                                    />
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                )}
                <div className="w-100 my-4 mx-auto text-center">
                    {is_update ? (
                        <Link
                            to={`/brokerage/${item.id}/details`}
                            className={`btn button-03 mx-3 pt-1 col-lg-3 col-sm-10`}
                        >
                            Cancel
                        </Link>
                    ) : (
                        <label
                            className={`btn button-03 mx-3 col-lg-4 col-sm-10 my-0`}
                            onClick={closeModal}
                        >
                            Cancel
                        </label>
                    )}
                    <button
                        type="submit"
                        className="btn btn-primary mx-3 col-lg-4 col-sm-10 mt-sm-1 mt-lg-0 py-0"
                        disabled={loader2}
                    >
                        {!is_update ? "Create" : "Update"}
                    </button>
                </div>
            </LoadMask> */}
        </form>
    );
};

const numberPhone = (number) => validatorFromFunction(() => {
    return number.length === 10;
});

export function formatPhoneNumber() {
    return (cell, row) => {
        let newCell = cell
        if (newCell && newCell.includes('+')) {
            newCell = newCell.replace('+', '')
        }
        if (newCell && newCell.includes('-')) {
            newCell = newCell.replace('-', '')
        }
        if (newCell && newCell.length > 10) {
            newCell = newCell.substr(newCell.length - 10, 12)
        }
        if (newCell) {
            newCell = `${newCell.substr(0, 3)}-${newCell.substr(3, 3)}-${newCell.substr(6, 4)}`
        }
        return (
            <p className={'my-0 mx-0 px-0'} style={{minWidth: '100px'}}>
                {newCell}
            </p>
        )
    };
}

export function formatStatus() {
    return (cell, row) => {
        return (
            <React.Fragment>
                {row.roles !== STAFF && (
                    <p
                        className={`my-0 text-capitalize fw-bold text-${
                            cell === 1 ? "danger" : "success"
                        }`}
                    >
                        {cell === 1 ? "Sent" : "Registered"}
                    </p>
                )}
            </React.Fragment>
        );
    };
}

export function formatRoles() {
    return (cell, row) => {
        const getStatus = () => {
            let text = "";
            USER_ROLES_LIST.map((item) => {
                if (cell === item.value) {
                    if (cell == STAFF) {
                        text = `${item.label} (${row.staff_name})`;
                    }else {
                        text = item.label;
                    }
                }
            });
            return text;
        };
        return <div className="m-0 p-0">{getStatus()}</div>;
    };
}

const resetPassword = () => (cell, row) => {
    return (
        <div className="w-100 d-flex flex-row align-items-center justify-content-center">
            {row.account_status == 5 && (
                <Link to={`/brokerage/users/${row.user.id}/change-password`} className="btn btn-success text-white">
                    Reset Password
                </Link>
            )}
        </div>
    )
}

export default reduxForm({
    form: 'brokerageForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            firm_name: validators.exists()('This field is required'),
            license: validators.exists()('This field is required'),
            address: validators.exists()('This field is required'),
            logo: validators.exists()('This field is required'),
            city: validators.exists()('This field is required'),
            telephone: combine(
                validators.exists()('This field is required'),
                numberPhone(data.telephone)()('Must be at least 10 characters'),
            ),
        });
    },
})(brokerageForm);
