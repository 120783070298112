import React from 'react';
import { Modal } from "react-responsive-modal";

const ModalWallet = (props) => {
    const { open, url, modalOnConfiguring } = props;
    let text1 = "Before proceeding you must complete your Stripe registration.";
    let text2 = "Please click on the button to view the registration.";

    const isInfluencer = () => {
        const userType = localStorage.getItem('user_type')
        
        return userType == 20
    }

    if (modalOnConfiguring) {
        text1 = "Before continuing, you must complete the settings to enable payouts and charges.";
        text2 = "Please click on the button to configure.";
    }

    return (
        <Modal
            center
            closeOnEsc
            open={open}
            showCloseIcon={false}
            closeOnOverlayClick={false}
            classNames={{ modal: 'col-lg-6 col-sm-10 px-0 py-5' }}
            // initialFocusRef={document.getElementById('app-container')}
        >
            <div className="w-100 row py-2 px-0 mx-auto">
                <div className="row justify-content-center w-100 px-0 mx-auto">
                    <div className='swal2-icon swal2-warning swal2-animate-warning-icon d-flex'/>
                </div>
                <div className="w-100 text-center py-1">
                    <p className="mb-0">
                        {text1}
                    </p>
                    <p className="mb-0">
                        {text2}
                    </p>
                </div>
                <div className="row w-100 justify-content-center mx-auto mt-3">
                    <a className='btn btn-primary px-4 text-white' href={url} target="_blank">
                        Register on stripe
                    </a>
                    <a className="btn btn-danger px-5 ml-lg-2 text-white" href={!isInfluencer() ? "#/": "#/influencer/profile"}>
                        {!isInfluencer() ? "Go Home" : "Go to Profile"}
                    </a>
                </div>


            </div>
        </Modal>
    );
};

export default ModalWallet;
