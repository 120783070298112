import React, {Component} from 'react';
import InvitesQualifyingForm from "./InvitesQualifyingForm";
import {FullModal} from "../Utils/Modal/FullModal";

class InvitesQualifying extends Component {

    onActionSubmit = (data) => {
        this.props.sendInvitesQualifying(data)
    }

    componentWillMount() {
        this.props.showModal(true);                 // enable show modal
    }

    render() {
        const {send, is_open, showModal, logOut} = this.props;

        return (
            <FullModal
                isOpen={is_open}                    // initial status
                showModal={showModal}               // is boolean
                allowOutsideClick={false}           // close from outside click
                showClose={false}                   // icon close
            >
                <InvitesQualifyingForm
                    onSubmit={this.onActionSubmit}
                    send={send}
                    logOut={logOut}
                />
            </FullModal>
        )
    }
}

export default InvitesQualifying;
