import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/agent/agent';
import Agent from "./Agent";
import formValueSelector from "redux-form/lib/formValueSelector";
import {SELECT_ALL_OPTION} from '../../../../utility/constants'
const selector = formValueSelector('AgentFiltersForm');

const ms2p = (state) => {
    const {brokerages} = state.agent
    let brokerage = selector(state, 'brokerage')
    if (brokerage == SELECT_ALL_OPTION['value'] && brokerages && brokerages.length == 1) {
        brokerage = [brokerages[0].value]
    }
    return{
        ...state.agent,
        office_selected: brokerage,
        provideAgentsRoster: state.login.me.provide_agents_roster,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Agent);

