import _ from "lodash";
import { api } from "api";
import moment from "moment";
import { push } from "react-router-redux";
import { handleActions } from "redux-actions";
import { change, initialize } from "redux-form";
import { createReducer } from "../baseReducer/baseReducer";
import { NotificationManager } from "react-notifications";
import {
    SELECT_ALL_OPTION,
    getDashboardDateRange,
} from "../../../utility/constants";

// ------------------------------------
// Constants
// ------------------------------------
const SET_FRANCHISES = "SET_FRANCHISES_AGENTPERFORMANCE";
const SET_BROKERS = "SET_BROKERS_AGENTPERFORMANCE";
const SET_BROKERAGES = "SET_BROKERAGES_AGENTPERFORMANCE";
const SET_DATA_DETAIL = "SET_DATA_DETAIL_AGENTPERFORMANCE";
const SET_PAGE_DETAIL = "SET_PAGE_DETAIL_AGENTPERFORMANCE";
const SET_JOB_TYPE = "SET_JOB_TYPE_DETAIL_AGENTPERFORMANCE"

//-------------------------------------
// Base reducer
//-------------------------------------
const basicReducer = createReducer(
    "statsAgentPerformance",
    "agent/stats_performance",
    "statsAgentPerformanceForm",
    "/agent-performance"
);

// ------------------------------------
// Pure Actions
// ------------------------------------
const setOptionsFranchises = (franchises) => ({
    type: SET_FRANCHISES,
    franchises,
});
const setBrokers = (brokers) => ({
    type: SET_BROKERS,
    brokers,
});

const setBrokerages = (brokerages) => ({
    type: SET_BROKERAGES,
    brokerages,
});
const setDataDetail = dataDetail => ({
    type: SET_DATA_DETAIL,
    dataDetail,
});

const setPageDetail = pageDetail => ({
    type: SET_PAGE_DETAIL,
    pageDetail,
});

const setJobTypes = (jobTypes) => ({
    type: SET_JOB_TYPE,
    jobTypes,
});

// ------------------------------------
// Actions
// ------------------------------------
export const searchChange = (search) => (dispatch) => {
    dispatch(basicReducer.actions.setSearch(search));
    dispatch(toList());
};
export const changeFilters =
    (type, value = null) =>
    (dispatch, getStore) => {
        console.log("TYPE:, ", type);
        switch (type) {
            case "brokerage":
                setTimeout(() => {
                    dispatch(toList());
                }, 500);
                break;
            case "search":
                dispatch(searchChange(value));
                break;
            case "time":
                setTimeout(() => {
                    dispatch(toList());
                    dispatch(assignTimeInDetailFilter())
                }, 500);
                break;
            case "start_date":
                setTimeout(() => {
                    dispatch(toList());
                    dispatch(assignTimeInDetailFilter())
                }, 500);
                break;
            case "end_date":
                setTimeout(() => {
                    dispatch(toList());
                    dispatch(assignTimeInDetailFilter())
                }, 500);
                break;
            default:
                break;
        }
    };

export const getBrokeragesOption =
    (getData = null, selectAll = false) =>
    (dispatch, getStore) => {
        const store = getStore();
        const { AgentPerformanceFilterForm } = store.form;
        let brokerageValue = null;
        if (AgentPerformanceFilterForm && AgentPerformanceFilterForm.values) {
            const { brokerage } =
                AgentPerformanceFilterForm.values;
            if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
                if (brokerage.length == 0) {
                    brokerageValue = SELECT_ALL_OPTION["value"]
                }else {
                    brokerageValue = brokerage
                }
            }
        }
        api.get(`brokerage/option_select`)
            .then((response) => {
                let option = [];
                if (response.results.length > 1) {
                    option = response.results;
                    if (selectAll && brokerageValue == null) {
                        dispatch(
                            change(
                                "AgentPerformanceFilterForm",
                                "brokerage",
                                SELECT_ALL_OPTION["value"]
                            )
                        );
                    }
                    if(brokerageValue !== null) {
                        dispatch(
                            change(
                                "AgentPerformanceFilterForm",
                                "brokerage",
                                brokerageValue
                            )
                        );
                    }
                } else {
                    option = [...response.results];
                    const brokerage_id = option.length ? option[0].value : "";
                    dispatch(
                        change("AgentPerformanceFilterForm", "brokerage", SELECT_ALL_OPTION["value"])
                    );
                }
                dispatch(setBrokerages(option));
            })
            .catch(() => {
                dispatch(setBrokerages([]));
                dispatch(change("AgentPerformanceFilterForm", "brokerage", ""));
            })
            .finally(() => {
                if (getData) {
                    setTimeout(() => {
                        dispatch(toList());
                    }, 500);
                }
            });
    };

const toList =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(basicReducer.actions.setLoader(true));
        const store = getStore();
        const resource = store["statsAgentPerformance"];
        let params = { page };
        params.ordering = resource.ordering;
        params.search = resource.search;
        const { AgentPerformanceFilterForm } = store.form;
        if (AgentPerformanceFilterForm && AgentPerformanceFilterForm.values) {
            const {
                brokerage,
                time,
                start_date,
                end_date,
            } = AgentPerformanceFilterForm.values;
            
            if (
                brokerage !== "" &&
                brokerage !== null &&
                brokerage !== undefined &&
                brokerage !== SELECT_ALL_OPTION["value"]
            ) {
                if (brokerage.length == 0) {
                    params.brokerage = 0;
                } else {
                    params.brokerage = brokerage;
                }
            }
            if (time !== "" && time !== null && time !== undefined) {
                if (time === "custom") {
                    let startDate = moment().format("DD-MM-YYYY");
                    let endDate = moment().format("DD-MM-YYYY");

                    if (
                        start_date &&
                        (start_date !== "") & (start_date !== null) &&
                        start_date !== undefined
                    ) {
                        startDate = moment(start_date).format("DD-MM-YYYY");
                    }
                    if (
                        end_date &&
                        (end_date !== "") & (end_date !== null) &&
                        end_date !== undefined
                    ) {
                        endDate = moment(end_date).format("DD-MM-YYYY");
                    }
                    params.time = [startDate, endDate];
                } else {
                    params.time = time;
                }
            } else {
                params.time = getDashboardDateRange(1, "months");
            }
        } else {
            params.time = getDashboardDateRange(1, "months");
        }
        api.get("agent/stats_performance", params)
            .then((response) => {
                dispatch(basicReducer.actions.setData(response));
                dispatch(basicReducer.actions.setPage(page));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(basicReducer.actions.setLoader(false));
            });
    };

const read =
    (agent = "", page = 1) =>
    (dispatch, getStore) => {
        const store = getStore();
        dispatch(basicReducer.actions.setLoader(true));
        const { AgentPerformanceDetailFilterForm } = store.form;
        let params = { id: agent, page};

        if (AgentPerformanceDetailFilterForm && AgentPerformanceDetailFilterForm.values) {
            const {time, start_date, end_date, category, rating, job_type} = AgentPerformanceDetailFilterForm.values
            if (
                category !== "" &&
                category !== null &&
                category !== undefined &&
                category !== SELECT_ALL_OPTION["value"]
            ) {
                if (category.length == 0) {
                    params.category = "null";
                } else {
                    params.category = category;
                }
            }
            if (
                rating &&
                (rating !== "") & (rating !== null) &&
                rating !== undefined
            ) {
                params.rating = rating
            }
            if (time !== "" && time !== null && time !== undefined) {
                if (time === "custom") {
                    let startDate = moment().format("DD-MM-YYYY");
                    let endDate = moment().format("DD-MM-YYYY");

                    if (
                        start_date &&
                        (start_date !== "") & (start_date !== null) &&
                        start_date !== undefined
                    ) {
                        startDate = moment(start_date).format("DD-MM-YYYY");
                    }
                    if (
                        end_date &&
                        (end_date !== "") & (end_date !== null) &&
                        end_date !== undefined
                    ) {
                        endDate = moment(end_date).format("DD-MM-YYYY");
                    }
                    params.time = [startDate, endDate];
                } else {
                    params.time = time;
                }
            } else {
                params.time = getDashboardDateRange(1, "months");
            }
            if (
                job_type !== "" &&
                job_type !== null &&
                job_type !== undefined &&
                job_type !== SELECT_ALL_OPTION["value"]
            ) {
                if (job_type.length == 0) {
                    params.job_type = 0;
                    params.job_type_option = 0;
                } else {
                    const _jobTypes = _.filter(job_type, function (o) {
                        return !o.toString().toUpperCase().includes("OTH");
                    });
                    params.job_type = _jobTypes.length ? _jobTypes : 0;
                    const _jobTypeOptions = _.filter(job_type, function (o) {
                        if (o.toString().toUpperCase().includes("OTH")) {
                            return true;
                        }
                        return false;
                    });
                    params.job_type_option = _jobTypeOptions.length
                        ? _jobTypeOptions.map((item) => {
                              return parseInt(item.toString().replace("OTH-", ""));
                          })
                        : 0;
                }
            }
        }else {
            params.time = getDashboardDateRange(1, "months");
        }

        api.get("agent/stats_performance_detail", params)
            .then((response) => {
                if (response) {
                    const agent = response.agent ? response.agent : null;
                    dispatch(basicReducer.actions.setItem(agent));
                    dispatch(setDataDetail(response))
                    dispatch(setPageDetail(page));
                }
            })
            .catch((error) => {})
            .finally(() => {
                dispatch(basicReducer.actions.setLoader(false));
            });
    };

const changeDetailFilters = (type, agent, value = null) =>
(dispatch, getStore) => {
    console.log("TYPE:, ", type);
    switch (type) {
        case "rating":
            setTimeout(() => {
                dispatch(read(agent));
            }, 500);
            break;
        case "category":
            setTimeout(() => {
                dispatch(read(agent));
            }, 500);
            break;
        case "time":
            setTimeout(() => {
                dispatch(read(agent));
            }, 500);
            break;
        case "start_date":
            setTimeout(() => {
                dispatch(read(agent));
            }, 500);
            break;
        case "end_date":
            setTimeout(() => {
                dispatch(read(agent));
            }, 500);
            break;
        case "job_type":
            setTimeout(() => {
                dispatch(read(agent));
            }, 500);
            break;
        default:
            break;
    }
};

export const getJobTypesOption =
    (selectAll = false) =>
    (dispatch, getStore) => {
        const store = getStore();
        const { AgentPerformanceDetailFilterForm } = store.form;
        let jobTypeValue = null;
        if (AgentPerformanceDetailFilterForm && AgentPerformanceDetailFilterForm.values) {
            const {job_type} = AgentPerformanceDetailFilterForm.values;
            if (job_type !== "" && job_type !== null && job_type !== undefined && job_type !== SELECT_ALL_OPTION["value"]) {
                if (job_type.length == 0) {
                    jobTypeValue = SELECT_ALL_OPTION["value"]
                }else {
                    jobTypeValue = job_type
                }
            }
        }
        api.get("general/option_job_types", {})
            .then((response) => {
                let options = [];
                if (response.results.length > 1) {
                    options = [...response.results];
                    if (selectAll && jobTypeValue == null) {
                        dispatch(
                            change(
                                "AgentPerformanceDetailFilterForm",
                                "job_type",
                                SELECT_ALL_OPTION["value"]
                            )
                        );
                    }
                    if (jobTypeValue !== null) {
                        dispatch(
                            change(
                                "AgentPerformanceDetailFilterForm",
                                "job_type",
                                jobTypeValue
                            )
                        );
                    }
                } else {
                    options = [...response.results];
                    const job_type = options.length ? options[0].value : "";
                    dispatch(change("AgentPerformanceDetailFilterForm", "job_type", [job_type]));
                }
                dispatch(setJobTypes(options));
            })
            .catch(() => {
                dispatch(setJobTypes([]));
                dispatch(change("AgentPerformanceDetailFilterForm", "job_type", ""));
            })
            .finally(() => {});
    };

const goPage = (page) => (dispatch) => {
    dispatch(push(page))
}

const setCategorySelectAll = () => (dispatch, getStore) => {
    const store = getStore()
    const { AgentPerformanceDetailFilterForm } = store.form;
    if (AgentPerformanceDetailFilterForm.values == null || (AgentPerformanceDetailFilterForm.values && (AgentPerformanceDetailFilterForm.values.category == undefined || AgentPerformanceDetailFilterForm.values.category == null))) {
        dispatch(change('AgentPerformanceDetailFilterForm', 'category', SELECT_ALL_OPTION['value']))
    }
}

const assignTimeInDetailFilter = () => (dispatch, getStore) => {
    const store = getStore()
    const { AgentPerformanceFilterForm } = store.form;
    let timeValue = ''
    let startDateValue = ''
    let endDateValue = ''
    if (AgentPerformanceFilterForm.values) {
        const {time, start_date, end_date} = AgentPerformanceFilterForm.values

        timeValue = time
        if (time == 'custom') {
            startDateValue = start_date
            endDateValue = end_date
        }
    }

    dispatch(change('AgentPerformanceDetailFilterForm', 'time', timeValue))
    dispatch(change('AgentPerformanceDetailFilterForm', 'start_date', startDateValue))
    dispatch(change('AgentPerformanceDetailFilterForm', 'end_date', endDateValue))
}

export const actions = {
    ...basicReducer.actions,
    searchChange,
    changeFilters,
    getBrokeragesOption,
    toList,
    read,
    changeDetailFilters,
    getJobTypesOption,
    goPage,
    setCategorySelectAll,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    ...basicReducer.reducers,
    [SET_FRANCHISES]: (state, { franchises }) => {
        return {
            ...state,
            franchises,
        };
    },
    [SET_BROKERS]: (state, { brokers }) => {
        return {
            ...state,
            brokers,
        };
    },
    [SET_BROKERAGES]: (state, { brokerages }) => {
        return {
            ...state,
            brokerages,
        };
    },
    [SET_DATA_DETAIL]: (state, { dataDetail }) => {
        return {
            ...state,
            dataDetail,
        };
    },
    [SET_PAGE_DETAIL]: (state, { pageDetail }) => {
        return {
            ...state,
            pageDetail,
        };
    },
    [SET_JOB_TYPE]: (state, { jobTypes }) => {
        return {
            ...state,
            jobTypes,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    ...basicReducer.initialState,
    brokers: [],
    brokerages: [],
    franchises: [],
    dataDetail: {
        results: [],
        count: 0,
    },
    pageDetail: 1,
    jobTypes: [],
};

export default handleActions(reducers, initialState);
