import './help.css';
import _ from 'lodash'
import React, { Component } from 'react'
import HelpSupportForm from './HelpSupportForm'
import LoadMask from '../../Utils/LoadMask/LoadMask';

const helpConstants = {
    CONTACT: 'Contact',
    SUGGESTION: 'Suggestion',
    ISSUE: 'Issue',
}

class HelpAndSupport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isAdmin: false,
            file: null,
            optionSelected: null,
            options: [
                {
                    type: helpConstants.CONTACT,
                    title: 'Contact Us',
                    subtitle: 'How can we help?',
                    description: 'Please provide details about why are you contacting us today and we will respond back to you via email'
                },
                {
                    type: helpConstants.SUGGESTION,
                    title: 'Suggest Improvement',
                    subtitle: null,
                    description: 'We always like to improve and hear from our users how we can make our solution better for them. \n\nPlease provide below your improvement and/or feedback. We will review and contact you for further details if needed.'
                },
                {
                    type: 'legal',
                    title: 'Legal',
                    description: 'Sales Contract Agreement',
                    link: null
                }
            ]
        }
    }

    componentWillMount() {
        const {match} = this.props

        if (match.path === '/help-and-support') {
            this.setState({isAdmin: true})
        }
    }

    componentDidMount() {

    }

    changeOption = (item) => {
        this.setState({optionSelected: item})
    }
    setFile = (file) => {
        console.log("FILE: ", file);
        this.setState({file})
    }

    goBack = () => {
        this.setState({optionSelected: null})
    }

    submit = (data) => {
        const {optionSelected, file} = this.state;
        const {create} = this.props;
        if (optionSelected && optionSelected.type != 'legal') {
            let _data = _.cloneDeep(data)
            _data.ticket_type = optionSelected.type;
            let attachments = []
            if (file) {
                delete _data.attachments;
                attachments.push(file)
            }
            create(_data, attachments, this.goBack)
        }
    }

    render() {
        //  State
        const {optionSelected, options, isAdmin} = this.state;
        const {loader, agent, agreement} = this.props;
        //  Bind
        return (
            <div id="PAGE-CONTAINER">
                <div
                    id="INFLUENCER-AGENTS-TITLE"
                    className="page-header pl-1 pt-3 no-gutters row w-100"
                >
                    <div>
                        <div className="header-page">Help & Support</div>
                    </div>
                </div>
                <div className="container-list card border-dark border-card-page px-4 py-2 flex-1">
                    <div className="card-body px-0 pt-2 mb-0 pb-0 d-flex flex-column flex-1">
                        <div className="row">
                            <div className="col-md-3 col-sm-12">
                                {options.map((item, index) => {
                                    return (
                                        <div key={`${index}`} className={`pointer show-it-btn ${optionSelected && item.type == optionSelected.type ? "selected": ""}`} onClick={(e) => this.changeOption(item)}>
                                            {item.title}
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="col-md-9 col-sm-12">
                                <LoadMask loader={loader}>
                                    {optionSelected && (optionSelected.type != 'legal' ? (
                                        <div className="row">
                                            <HelpSupportForm
                                                onSubmit={this.submit}
                                                agent={agent}
                                                loader={loader}
                                                option={optionSelected}
                                                setFile={this.setFile}
                                                goBack={this.goBack}
                                            />
                                        </div>
                                    ): (
                                        <div className={`row mt-2 ${isAdmin ? 'mt-md-2' : 'mt-md-5'}`}>
                                            <div className="col-sm-12">
                                                {isAdmin ? (
                                                    <div className='w-100 d-flex flex-column align-items-start justify-content-start pl-0 pl-md-3 pl-lg-5'>
                                                        <a className='font-weight-bolder text-primary my-1' href="https://showitsolutions.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
                                                        <a className='font-weight-bolder text-primary my-1' href="https://showitsolutions.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                                                        <a className='font-weight-bolder text-primary my-1' href="https://showitsolutions.com/cancellation-policy/" target="_blank" rel="noopener noreferrer">Cancellation Policy</a>
                                                        <a className='font-weight-bolder text-primary my-1' href="https://showitsolutions.com/no-show-policy/" target="_blank" rel="noopener noreferrer">No Show Policy</a>
                                                        <a className='font-weight-bolder text-primary my-1' href="https://showitsolutions.com/brokerage-and-licensee-consent/" target="_blank" rel="noopener noreferrer">Brokerage and Licensee Consent</a>
                                                        <a className='font-weight-bolder text-primary my-1' href="https://showitsolutions.com/faqs/" target="_blank" rel="noopener noreferrer">FAQ's</a>
                                                    </div>
                                                ): (
                                                    <a className='font-weight-bolder text-primary' href={agreement} target="_blank" rel="noopener noreferrer">{optionSelected.description}</a>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </LoadMask>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HelpAndSupport