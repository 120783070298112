import _ from 'lodash';
import {handleActions} from 'redux-actions';
import {NotificationManager} from "react-notifications";
import {initialize as initializeForm, change} from 'redux-form';
import {api} from "api";

// ------------------------------------
//  CONSTATNS
// ------------------------------------
const LOADER = 'LOADER_AGENT_REGISTER';
const PROGRESS_STEP = 'PROGRESS_STEP_AGENT_REGISTER';
const ALL_DATA_FORM = 'ALL_DATA_FORM_AGENT_REGISTER';
const SET_CODE_VALIDATE = 'SET_CODE_VALIDATE_AGENT_REGISTER';
const SET_EMAIL_CONFIRMED = 'SET_EMAIL_CONFIRMED_AGENT_REGISTER';
const SET_STEP_BACK = 'SET_STEP_BACK_AGENT_REGISTER';
const OFFICES = 'OFFICES_AGENT_REGISTER';
const SET_SUCCESS = 'SET_SUCCESS_AGENT_REGISTER';
const PENDING_VALIDATION = 'PENDING_VALIDATION_AGENT_REGISTER';

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});
export const setCode = code => ({
    type: SET_CODE_VALIDATE,
    code,
});
export const setEmailConfirmed = email_confirmed => ({
    type: SET_EMAIL_CONFIRMED,
    email_confirmed,
});
const setStep = step => ({
    type: PROGRESS_STEP,
    step: step,
});

const setAllDataForm = (data) => ({
    type: ALL_DATA_FORM,
    data,
});

export const setStepBack = stepBack => ({
    type: SET_STEP_BACK,
    stepBack,
});

const setOffices = offices => ({
	type: OFFICES,
	offices,
});
const setSuccess = (success) => ({
    type: SET_SUCCESS,
    success,
});

const setPendingValidation = (pendingValidation) => ({
    type: PENDING_VALIDATION,
    pendingValidation,
});

// ------------------------------------
// Actions
// ------------------------------------
const resetInitialData = () => (dispatch) => {
    dispatch(setLoader(false));
    dispatch(setStep(0));
    dispatch(setCode(false));
    dispatch(setEmailConfirmed(false));
    dispatch(setAllDataForm({}));
    dispatch(setStepBack(false));
    dispatch(setSuccess(false));
    dispatch(setPendingValidation(false));
    dispatch(cleanForm())
}

const getOffices = () => (dispatch) => {
	api.get(`general/option_brokerage`, {})
            .then((response) => {
				if (response) {
					const results = [{label: 'Not Listed', value: 'not_listed'},...response.results]
					dispatch(setOffices(results))
				}else {
					dispatch(setOffices([]))
				}
			}).catch(error => {
				dispatch(setOffices([]))
			})
}

const insertEmail = (email) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post('confirmation-code/create_code', {'email': email})
        .then(response => {
            dispatch(setStepBack(true));
            NotificationManager.success('Verification code sent to your email', 'Success', 2000)
        })
        .catch((error) => {
            const  _message = "Fail to send code, please verify your email and try again"
            const message = error.detail ? error.detail : _message
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
}

const onSubmitStep = () => (dispatch, getStore) => {
    const store = getStore();
    const source = store.agentRegister;
    const { step, email_confirmed } = source;
    let data = source.data;
    if (step === 0) {
        dispatch(setStep(step + 1));
    }
    if (step === 1) {
        dispatch(validateBrokerageLicence());
    }
    if (step === 2) {
        const form = store.form.agentRegisterForm;
        const values = form.values ? form.values : {};
        if (email_confirmed) {
            dispatch(setStep(step + 1));
        } else {
            dispatch(insertEmail(values.email));
        }
    }
    if (step === 3) {
        dispatch(setStep(step + 1));
    }

}

const goBack = () => (dispatch, getStore) => {
    const store = getStore();
    const source = store.agentRegister;
    const { step, stepBack } = source;

    if (step >= 1 && step <= 2) {
        dispatch(setStep(step - 1));
    }

    if (step === 2) {
        dispatch(setStep(step - 1));
        dispatch(setStepBack(false));
        dispatch(setCode(false));
        dispatch(setEmailConfirmed(false));
    }
    if (step === 3) {
        dispatch(setStep(step - 1));
    }

    if (step === 4) {
        dispatch(setStep(step - 1));
    }
}

const resendCodeEmail = () => (dispatch, getStore) => {
    dispatch(setLoader(true))
    const store = getStore();
    const form = store.form.agentRegisterForm;
    const values = form.values ? form.values : {};
    const email = values.email ? values.email : '';
    if (email!= '' && email != undefined && email != null) {
        api.post('confirmation-code/resend_code_email', {'email': email})
            .then(() => {
                NotificationManager.success('Verification code resent to your email', 'Success', 2000)
            })
            .catch(() => {
                NotificationManager.error('The code could not be sent, please try again', 'Error', 2000)
            })
            .finally(() => {
                dispatch(setLoader(false));
            })
    } else {
        NotificationManager.error('The email is required', 'Error', 2000)
    }
};

const validateBrokerageLicence = () => (dispatch, getStore) => {
    const store = getStore();
    const resource = store.agentRegister;
    const form = store.form.agentRegisterForm;

    const {offices, step} = resource;
    // send the agent data and broker licence to validate
    const values = form.values;
    const {office} = values
    const data = {
        type: 'BROKER',
        brokerages_licence: ''
    }

    if ( office != null && office != undefined && office != '') {
        if (office != 'not_listed') {
            const _office = _.find(offices, {value: office})
            if (_office) {
                data.brokerages_licence = _office.license ? _office.license : ''
            }
        }
        api.post('agent-user/validate_info', data).then(response => {
            if (response) {
                dispatch(setStep(step + 1))
                //  Set brokerage's license
                dispatch(change('agentRegisterForm', 'brokerages_licence',  data.brokerages_licence))
            }
        }).catch((error) => {
            let errTxt = `Your info couldn't be matched to your broker records, please re-enter`;
            if (error && error.detail) {
                errTxt = error.detail
            }
            NotificationManager.error(errTxt, 'Error', 2000)
        });
    } else {
        if (valid_data) {
            NotificationManager.error('Please select your office name', 'Error', 2000)
        }else {
            NotificationManager.error('Please enter your office name', 'Error', 2000)
        }
    }
};

const validateCode = (value) => (dispatch, getStore) => {
    const store = getStore();
    const resource = store.agentRegister;
    const {step} = resource;
    const form = store.form.agentRegisterForm;
    const values = form.values ? form.values : {};
    const email = values.email ? values.email : '';
    const url = 'confirmation-code/validate_code_email';
    const body = {email, 'code': value};
    dispatch(setLoader(true));
    if (email!= '' && email != undefined && email != null) {
        api.post(`${url}`, body)
            .then(response => {
                dispatch(setEmailConfirmed(true));
                dispatch(setStep(step + 1));
                dispatch(setCode(false)); // for view the code_number form
            })
            .catch((error) => {
                dispatch(setCode('error'))
            })
            .finally(() => {
                dispatch(setLoader(false))
            })
    } else {
        NotificationManager.error('The email is required', 'Error', 2000)
    }
};

const signUp = (source=null) => (dispatch, getStore) => {
	dispatch(setLoader(true));
    const store = getStore();
    const resource = store.agentRegister;
    const {step} = resource;
    const form = store.form.agentRegisterForm;
    const values = form.values ? form.values : {};
    const data = {
        'first_name': values.first_name,
        'last_name': values.last_name,
        'email': values.email,
        'password': values.password,
        'mobile_number': values.mobile_number,
        'office': values.office,
        'real_estate_licence': values.real_estate_licence,
        'open_market_office': values.open_market_office,
        'brokerages_licence': values.brokerages_licence,
        'geolocation': {
            latitude_center: values.geolocation && values.geolocation.latitude_center ? values.geolocation.latitude_center : 0,
            longitude_center: values.geolocation && values.geolocation.longitude_center ? values.geolocation.longitude_center : 0,
            miles_ratio: values.miles,
        },
        'street': values.street,
        'source': source,
    };

	api.post('agent-user/sign_up', data).then(response => {
		if(response) {
			const isPendingValidation = response.agent_account_status && response.agent_account_status == 10 ? true : false;
            dispatch(setPendingValidation(isPendingValidation));
            dispatch(setStep(step + 1));
            NotificationManager.success('Signup complete', 'Success', 2000)
            dispatch(setSuccess(true));
			dispatch(cleanForm());
		}
	}).catch((error) => {
		let message = 'Error to register, please try again';
		if (error && error.detail) {
			message = error.detail
		}
		NotificationManager.error(message, 2000);
	})
	.finally(() => {
		dispatch(setLoader(false));
	});
};

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('agentRegisterForm', {}));
}

const setWorkingArea = (data) => (dispatch) => {
    const {latitude, longitude} = data;
    if (latitude && longitude) {
        dispatch(change("agentRegisterForm", "geolocation", {
            latitude_center: latitude,
            longitude_center: longitude
        }))
    } else {
        dispatch(change("agentRegisterForm", "geolocation", {
            latitude_center: 0,
            longitude_center: 0,
        }))
    }
}

export const actions = {
    resetInitialData,
    getOffices,
    onSubmitStep,
    goBack,
    resendCodeEmail,
    validateCode,
    signUp,
    setWorkingArea,
}

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_CODE_VALIDATE]: (state, { code }) => {
        return {
            ...state,
            code,
        };
    },
    [SET_EMAIL_CONFIRMED]: (state, { email_confirmed }) => {
        return {
            ...state,
            email_confirmed,
        };
    },
    [PROGRESS_STEP]: (state, { step }) => {
        return {
            ...state,
            step,
        };
    },
    [ALL_DATA_FORM]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [SET_STEP_BACK]: (state, { stepBack }) => {
        return {
            ...state,
            stepBack,
        };
    },
    [OFFICES]: (state, { offices }) => {
        return {
            ...state,
            offices,
        };
    },
    [SET_SUCCESS]: (state, { success }) => {
        return {
            ...state,
            success,
        };
    },
    [PENDING_VALIDATION]: (state, { pendingValidation }) => {
        return {
            ...state,
            pendingValidation,
        };
    },
};

export const initialState = {
    loader: false,
    step: 0,
    code: false,
    email_confirmed: false,
    data: {},
    stepBack: false,
    offices: [],
    success: false,
    pendingValidation: false,
};

export default handleActions(reducers, initialState);
