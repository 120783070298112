import _ from 'lodash'
import { api } from "api";
import { handleActions } from "redux-actions";
import { createReducer } from "../baseReducer/baseReducer";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import {getMe} from "../cuenta/login"
import { initialize as initializeForm } from "redux-form";

// ------------------------------------
// CONSTANTS
// ------------------------------------
const endpoint = "influencer";
const TAB = "TAB_INFLUENCER_SITE"
const DATA_UNREGISTER = "DATA_UNREGISTER_INFLUENCER_SITE"
const PAGE_UNREGISTER = "PAGE_UNREGISTER_INFLUENCER_SITE"

// ------------------------------------
// BASE ACTION
// ------------------------------------
const baseReducer = createReducer(
    "influencerAgent",
    endpoint,
    "",
    ""
);

// ------------------------------------
// Pure Actions
// ------------------------------------
export const setTab = tab => ({
    type: TAB,
    tab,
});

const setDataUnregister = dataUnregister => ({
    type: DATA_UNREGISTER,
    dataUnregister,
});
const setPageUnregister = pageUnregister => ({
    type: PAGE_UNREGISTER,
    pageUnregister,
});

// ------------------------------------
// Actions
// ------------------------------------
const toList =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(baseReducer.actions.setLoader(true));
        const store = getStore();
        const { search } = store.influencerAgent;

        let params = { page };
        const { InfluencerAgentFiltersForm } = store.form;
        if (InfluencerAgentFiltersForm && InfluencerAgentFiltersForm.values) {
            const { search } = InfluencerAgentFiltersForm.values;
            if (search != "" && search != undefined && search != null) {
                params.search = search;
            }
        }

        api.get(`${endpoint}/agents`, params)
            .then((response) => {
                if (response) {
                    dispatch(baseReducer.actions.setData(response));
                    dispatch(baseReducer.actions.setPage(page));
                } else {
                    dispatch(
                        baseReducer.actions.setData({
                            results: [],
                            count: 0,
                        })
                    );
                }
            })
            .catch((err) => {
                let msg = "Failed to fetch data, please try again later";
                if (err && err.detail) {
                    msg = err.detail;
                }
                console.log("ERROR", err);
                NotificationManager.error(msg, "ERROR");
            })
            .finally(() => {
                dispatch(baseReducer.actions.setLoader(false));
            });
    };

export const searchChange = (search) => (dispatch) => {
    dispatch(baseReducer.actions.setSearch(search));
    setTimeout(() => {
        dispatch(toList());
        dispatch(toListUnregister())
    }, 400)
};

const toListUnregister =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(baseReducer.actions.setLoader(true));
        const store = getStore();
        const { search } = store.influencerAgent;

        let params = { page };
        const { InfluencerAgentFiltersForm } = store.form;
        if (InfluencerAgentFiltersForm && InfluencerAgentFiltersForm.values) {
            const { search } = InfluencerAgentFiltersForm.values;
            if (search != "" && search != undefined && search != null) {
                params.search = search;
            }
        }

        api.get(`${endpoint}/unregisters`, params)
            .then((response) => {
                if (response) {
                    dispatch(setDataUnregister(response));
                    dispatch(setPageUnregister(page));
                } else {
                    dispatch(
                        setDataUnregister({
                            results: [],
                            count: 0,
                        })
                    );
                }
            })
            .catch((err) => {
                let msg = "Failed to fetch data, please try again later";
                if (err && err.detail) {
                    msg = err.detail;
                }
                console.log("ERROR", err);
                NotificationManager.error(msg, "ERROR");
            })
            .finally(() => {
                dispatch(baseReducer.actions.setLoader(false));
            });
    };

export const changeFilters =
    (type, value = null) =>
    (dispatch, getStore) => {
        console.log("TYPE:, ", type);
        switch (type) {
            case "search":
                dispatch(searchChange(value));
                break;
            default:
                break;
        }
    }


export const actions = {
    ...baseReducer.actions,
    toList,
    changeFilters,
    setTab,
    toListUnregister,
}

export const reducers = {
    ...baseReducer.reducers,
    [TAB]: (state, {tab}) => {
        return {
            ...state,
            tab,
        };
    },
    [DATA_UNREGISTER]: (state, {dataUnregister}) => {
        return {
            ...state,
            dataUnregister,
        };
    },
    [PAGE_UNREGISTER]: (state, {pageUnregister}) => {
        return {
            ...state,
            pageUnregister,
        };
    },
}

export const initialState = {
    ...baseReducer.initialState,
    tab: 'Registered',
    dataUnregister: {
        results: [],
        count: 0,
    },
    pageUnregister: 1

}

export default handleActions(reducers, initialState);
