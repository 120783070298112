import React, {Component} from 'react';
import DetailsForm from "./DetailsGrid";
import LoadMask from "../Utils/LoadMask/LoadMask";

class RosterDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            photo: null,
            brokerage_id: 0,
            license: '',
            nameFile: '',
            file: null,
            active: true,
        };
    }

    componentDidMount() {
        const {match, page} = this.props;
        if (match.params.id) {
            const id = match.params.id;
            this.props.read(id)
            this.props.agentList(page, id);
        }
        this.props.getUrlExelTemplate()
    }

    UNSAFE_componentWillUnMount() {
        this.setState({photo: null})
        this.props.setItemToNull()
    }

    setLogo = (photo) => {
        this.setState({photo});
    };

    onChanged = (file) => {
        const {match} = this.props
        this.setState({nameFile: file.name, file: file, active: false});
        this.props.onChangeList(file, match.params.id);
    };

    actionFile = (data) => {
        const {setFiles} = this.props;
        setFiles(data)
        this.setState({photo: data});
    }

    setActive = (value) => {
        this.setState({active: value, file: null, nameFile: ''});
    }

    render() {
        const {
            loader,
            item,
            roster,
            loader2,
            agentList,
            urlTemplate,
            searchAgent,
        } = this.props;

        return (
            <LoadMask loading={loader} light>
                <DetailsForm
                    data={roster}
                    office={item}
                    setLogo={this.setLogo}
                    loader={loader}
                    searchAction={searchAgent}
                    loader2={loader2}
                    agentList={agentList}
                    handleOnChange={this.onChanged}
                    text={this.state.nameFile}
                    active={this.state.active}
                    setActive={this.setActive}
                    urlTemplate={urlTemplate}
                />
            </LoadMask>
        );
    }

}

export default RosterDetails;
