import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/influencer/helpSupport';
import HelpSupport from "./HelpSupport";

const ms2p = (state) => {
    return{
        ...state.helpSupport,
        agent: state.login && state.login.me ? state.login.me.first_name : '',
        agreement: state.login && state.login.me ? state.login.me.agreement : ''
    };
};

const md2p = {
    ...actions
};

export default connect(ms2p, md2p)(HelpSupport);