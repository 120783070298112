import {connect} from 'react-redux';
import {actions} from '../../../../redux/modules/cuenta/profile';
import BrokerProfile from './BrokerProfile';


const ms2p = (state) => {
    return {
        me: state.login.me,
        ...state.profile,
    };
};

const md2p = {...actions};

export default connect(ms2p, md2p)(BrokerProfile);
