import React, {useState, useRef, useEffect} from "react";
import { Link } from "react-router-dom";
import {Field, reduxForm} from 'redux-form';
import {customFieldFile, SelectField} from "../../Utils/renderField";
import {formatPhoneNumber} from "../../Broker/ListGrid";
import { standardActions } from "../../Utils/Grid/StandardActions";
import {
    PENDING,
    DISABLED,
    REGISTERED,
    DEFAULT_CATEGORY_UNREGISTERED
} from "../../../../utility/constants";
import HeaderCard from "../../HeaderCard";
import GridTableWithScroll from "../../Utils/GridTableWithScroll";

const RosterListForm = (props) => {
    const {
        data,
        page,
        toList,
        loader,
        remove,
        text,
        active,
        urlTemp,
        setActive,
        sendInvite,
        unblockUser,
        handleOnChange,
        blockUserModal,
        sendInvites2,
        provideAgentsRoster,
        officeSelected,

        tabContainerHeight,
        downloadLoader,
        downLoadRosterList,
        onChangeUpdateRoster,
        textFileUpdate,
        activeFileUpdate,
        setActiveFileUpdate,

    } = props;

    const [tabHeight, setTabHeight] = useState(tabContainerHeight);
    const refBtnContainer = useRef(null)

    useEffect(() => {
      setTabHeight(tabContainerHeight)
    }, [tabContainerHeight])

    useEffect(() => {
        // setTabHeight(tabContainerHeight);
        if (refBtnContainer.current) {
            const _height = refBtnContainer.current.clientHeight;
            console.log("H1: ", _height);
            console.log("H2: ", tabContainerHeight);
            setTabHeight(tabContainerHeight - _height)
        }
    }, [refBtnContainer]);

    return (
        <div className="d-flex flex-column flex-1">
            
            <div className="w-100 row mx-auto justify-content-between" ref={refBtnContainer}>
                {officeSelected && (
                    <div className="col-xl-2 col-lg-3 col-sm-12 col-md-6 py-1">
                        <Link
                            to={`agents/${officeSelected}/create-agent-roster`}
                            className="w-100 btn btn-success text-white"
                        >
                            {" "}
                            Add an agent{" "}
                        </Link>
                    </div>
                )}
                {officeSelected && provideAgentsRoster == true && (
                    <React.Fragment>
                        <div className="col-xl-2 col-lg-3 col-sm-12 col-md-6 py-1">
                            <a
                                title="Template"
                                href={urlTemp}
                                download
                                className={
                                    "w-100 btn btn-success text-white"
                                }
                            >
                                Download roster template
                            </a>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-sm-12 col-md-6 pt-1">
                            <Field
                                    id={"id_header_roster_excel"}
                                    name="roster_excel"
                                    title={"Update roster list"}
                                    setFile={onChangeUpdateRoster}
                                    component={customFieldFile}
                                    text={textFileUpdate}
                                    type={"file"}
                                    active={activeFileUpdate}
                                    customClass={
                                        "btn-primary py-1 mt-1 mt-lg-0"
                                    }
                                    remove={() => {
                                        handleOnChange(null)
                                        setActiveFileUpdate(true)
                                    }}
                                />
                            <Field
                                id={"id_header_excel"}
                                name="excel"
                                title={"Upload new roster"}
                                setFile={handleOnChange}
                                component={customFieldFile}
                                text={text}
                                type={"file"}
                                active={active}
                                customClass={
                                    "btn-primary py-1 mt-1 mt-lg-0"
                                }
                                remove={() => {
                                    handleOnChange(null)
                                    setActive(true)
                                }}
                            />
                        </div>
                    </React.Fragment>
                )}
                <div className="col-xl-2 col-lg-3 col-sm-12 col-md-6 py-1">
                    <button
                        type="button"
                        disabled={downloadLoader}
                        className={"w-100 btn btn-success text-white"}
                        onClick={downLoadRosterList}
                    >
                        Download current roster
                    </button>
                </div>
                <div className="col-xl-2 col-lg-3 col-sm-12 col-md-6 py-1">
                    <button
                        type="button"
                        disabled={!!(!data.count || loader)}
                        className={"w-100 btn btn-info"}
                        onClick={sendInvites2}
                    >
                        Invite all agents
                    </button>
                </div>
            </div>
            <div className="card border-dark border-card-page px-4 pt-2 pb-0 flex-1">
                <div className="card-body px-0 pt-2 mb-0 pb-0 d-flex flex-column flex-1">
                    <GridTableWithScroll
                        hover
                        height={tabHeight}
                        page={page}
                        keyField={'id'}
                        data={data}
                        loading={loader}
                        sizePerPage={50}
                        striped
                        wrapperClasses='table-scroll-auto'
                        onPageChange={(value) => toList(value)}
                        columns={[
                            {
                                dataField: "id",
                                text: "",
                                formatter:
                                    standardActions({
                                        edit: "/agents/agent-roster",
                                    }),
                            },
                            {
                                dataField: "first_name",
                                formatter: formatName(),
                                text: "First Name",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                    };
                                },
                            },
                            {
                                dataField: "last_name",
                                formatter: formatName(),
                                text: "Last Name",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                    };
                                },
                            },
                            {
                                dataField: "email",
                                short: true,
                                text: "E-mail",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                    };
                                },
                            },
                            {
                                dataField: "phone",
                                formatter: formatPhoneNumber(),
                                text: "phone",
                            },
                            {
                                dataField: "license",
                                formatter: formatText(),
                                text: "License",
                            },
                            {
                                dataField: "brokerage__firm_name",
                                sort: true,
                                formatter: brokerageName(),
                                text: "Office",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                    };
                                },
                            },
                            {
                                dataField: "brokerage_license",
                                sort: false,
                                text: "Office License",
                            },
                            {
                                dataField: "account_status",
                                formatter: formatStatus(sendInvite, blockUserModal, unblockUser),
                                text: "",
                            },
                            // {
                            //     dataField: "brokerage",
                            //     text: "Brokerage",
                            // },
                        ]}
                    />
                </div>
            </div>
        </div>
    );

};

export function formatStatus(sendInvite, blockUser, unblockUser) {
    return (cell, row) => {
        return (
            <div className="row w-100" style={{width: '13rem'}}>
                <button
                    onClick={() => sendInvite(row.id)}
                    disabled={cell === REGISTERED || cell === DISABLED}
                    className={`btn btn-primary w-50 d-inline`}
                >
                    {cell !== PENDING ? 'Registered' : 'Send Invite'}
                </button>
            </div>
        );
    };
}

export function formatText() {
    return (cell) => {
        return (
            <div>
                <p className="p-0 m-0" style={{width: '4.5rem'}}>
                    {cell}
                </p>
            </div>
        );
    };
}

export function formatName() {
    return (cell) => {
        return (
            <div>
                <p className="p-0 m-0" style={{width: '6.5rem'}}>
                    {cell}
                </p>
            </div>
        );
    };
}

export function brokerageName() {
    return (cell, row) => {
        return (
            <p className="row w-100 mb-0">
                {row.brokerage ? row.brokerage : ''}
            </p>
        );
    };
}

export default reduxForm({
    form: 'agentForm', // a unique identifier for this form
})(RosterListForm);
