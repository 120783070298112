import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form'
import login from './modules/cuenta/login';
import register from './modules/cuenta/register';
import vendorRegister from './modules/cuenta//vendor_register';
import profile from './modules/cuenta/profile';
import users from './modules/usuarios/usuarios';
import notifications from './modules/notificaciones/notificaciones';
import agent from "./modules/agent/agent";
import agentRegistered from "./modules/agent/userAgents";
import agentReviews from "./modules/agent/agentReviews";
import brokerage from "./modules/brokerage/brokerage";
import broker from "./modules/broker/broker";
import verifyTokenInvite from "./modules/invitesQualifiyng/qualifying";
import modal from "./modules/modal/modal";
import dashboard from "./modules/dashboard";
import wallet from "./modules/wallet";
import stats from "./modules/stats";
import statsRevenue from "./modules/stats/revenue"
import statsAgentPerformance from "./modules/stats/agentPerformance";
import influencerProfile from './modules/influencer/profile';
import influecerLeading from './modules/influencer/leadingList'
import influencerAgent from './modules/influencer/agent'
import agentsJobs from './modules/agentsJobs'
import helpSupport from './modules/influencer/helpSupport'
import agentRegister from './modules/cuenta/agent_register';
import wholeSalerRegister from './modules/cuenta/whole_saler_register';

export default combineReducers({
    form: formReducer,
    login,
    register,
    vendorRegister,
    profile,
    users,
    routing,
    agent,
    brokerage,
    broker,
    notifications,
    verifyTokenInvite,
    dashboard,
    wallet,
    modal,
    stats,
    statsRevenue,
    statsAgentPerformance,
    agentRegistered,
    agentReviews,
    influencerProfile,
    influecerLeading,
    influencerAgent,
    agentsJobs,
    helpSupport,
    agentRegister,
    wholeSalerRegister,
});
