import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {renderField} from '../../Utils/renderField';
import ReactCodeInput from "react-verification-code-input";
import {validate, validators} from 'validate-redux-form';

const SignupFormEmail = (props) => {
    //  State
    const {
        stepBack,
        validateCode,
        code,
        resendCode,
        register,
        actionSubmit,
    } = props;
    //  Bind
    const {handleSubmit} = props;
    return (
        <form name="vendorRegisterStep6" className="row" onSubmit={handleSubmit} style={{minHeight:'20rem'}}>
            <div className="form-group has-feedback col-sm-12 mb-lg-5 mb-3">
                <span className='vendor-register-label-title'>Confirming your email address</span>
                <br />
                <span className='vendor-register-label-subtitle'>We will send you a confirmation code to your email</span>
                <br />
                <label htmlFor="email" className='mt-4'>
                    <strong className="question2">Email address</strong>
                </label>
                <Field
                    name="email"
                    label="email"
                    component={renderField}
                    type="email"
                    className="form-control"
                    disabled={register}
                />
            </div>
            {!stepBack ? (
                <div className="buttons-box col-12 justify-content-center mx-auto pt-lg-5 mb-4">
                    <button type="submit" className="btn vendor-register-button col-xl-4 col-lg-5 align-self-center">
                        Send the code
                    </button>
                </div>
            ): (
                <React.Fragment>
                    <div className="col-12">
                        <div className="row">
                            <div className="form-group has-feedback col-lg-8  mx-auto justify-content-center text-center">
                                <label htmlFor="mobile_number" className="text-left">
                                    <p className="vendor-register-label-subtitle mb-1">
                                        Enter the 4-digit code bellow
                                    </p>
                                </label>
                                <div style={code === 'error' ?
                                    {} : { width: '300px !important' }}
                                    className={`p-0 w-100 ${code === 'error' && "is-invalid mx-auto"} `}
                                >
                                    <ReactCodeInput
                                        onComplete={validateCode}
                                        fieldWidth={50}
                                        fieldHeight={33}
                                        title={''}
                                        className={` w-100 ${code === 'error' && "is-invalid"} `}
                                        fields={4}
                                        required={true}
                                        type={"number"}
                                        disabled={register}
                                    />
                                </div>
                                {code === 'error' &&
                                    <strong className="invalid-feedback"> Wrong code, please check your code</strong>}
                            </div>
                        </div>
                    </div>
                    <div className="buttons-box col-12 justify-content-center mx-auto mb-3">
                        <button
                            disabled={register}
                            type="button"
                            className="btn button-03 col-xl-2 col-lg-3 col-6 align-self-center text-12"
                            style={{ fontSize: "12px !important" }}
                            onClick={() => resendCode()}
                        >
                            Resend code
                        </button>
                    </div>
                    {register && (
                        <div className="buttons-box col-12 justify-content-center mx-auto mb-3">
                            <button
                                type="button"
                                className="btn vendor-register-button col-xl-4 col-lg-5 align-self-center"
                                style={{ fontSize: "12px !important" }}
                                onClick={() => actionSubmit()}
                            >
                                Register
                            </button>
                        </div>
                    )}
                </React.Fragment>
            )}
        </form>
    );
};


export default reduxForm({
    form: 'vendorRegisterStep6', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            email: validators.exists()('This field is required'),
        });
    },
})(SignupFormEmail);
