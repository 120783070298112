import React from 'react';
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { customFieldFile } from "../Utils/renderField";

const HeaderCard = (props) => {
    const {
        searchAction,
        justify,
        showFile,
        setFiles,
        handleOnChange,
        text,
        id_header,
        link,
        active_link,
        text_link,
        placeholder = "Search",
        btnModal,
        activeModal,
        showLink,
        searchStyle="col-lg-3 col-sm-12 img-fluid mr-lg-3 mb-sm-1",
    } = props;
    return (
        <div className={`${justify ? justify : "justify-content-between"} row w-100 mb-3`}>
            {showFile &&
            <Field
                id={id_header}
                name="doc"
                setFile={setFiles}
                component={customFieldFile}
                onChange={handleOnChange}
                handleOnChange={handleOnChange}
                text={text}
                type={"file"}
            />
            }
            <div className={searchStyle}>
                <img
                    className="custom-search-icon"
                    src={require('../../../../assets/img/search.svg')}
                    alt="searchIcon"
                />
                <input
                    type="text"
                    className="search-input"
                    onChange={(e) => searchAction(e.target.value)}
                    placeholder={placeholder}
                />
            </div>
            {btnModal &&
            <button className="btn btn-primary text-white col-lg-2 col-sm-10" onClick={activeModal}>
                {text_link}
            </button>
            }
            {active_link && showLink &&
            <Link to={link} className="btn btn-primary px-3 col-lg-3 col-sm-10 pt-2 text-white"> {text_link}</Link>
            }
        </div>
    );

};

export default reduxForm({
    form: 'headerCard', // a unique identifier for this form
})(HeaderCard);

