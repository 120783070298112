import React, { Component } from 'react'
import LoadMask from "../../Utils/LoadMask/LoadMask";
import InfluencerForm from './influencerProfileForm';

class InfluencerProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isView: true,
        }
    }

    componentWillMount() {
        this.props.getProfile()
    }

    componentDidMount() {

    }

    goBack = () => {
        this.setState({isView: true})
    }

    submit = (data) => {
        const {data: item, edit} = this.props;
        console.log("DATA: ", data);

        if (item && item.id) {
            edit(item.id, data, this.goBack)
        }
    }

    render() {
        //  State
        const {isView} = this.state;
        const {loader, data} = this.props;
        //  Bind
        const {setAvatar, setLogo} = this.props;

        return (
            <div className='flex-1 d-flex flex-column'>
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div>
                        <div className="header-page">Profile</div>
                    </div>
                </div>
                
                <div
                    className="flex-1 card border-card-page px-lg-4 py-2"
                    style={{
                        minWidth: '99%',
                        minHeight: '15%',
                    }}
                >
                    <LoadMask loading={loader} light>
                        <InfluencerForm
                            item={data}
                            isView={isView}
                            toEdit={() => this.setState({isView: false})}
                            goBack={this.goBack}
                            onSubmit={this.submit}
                            setAvatar={setAvatar}
                            setLogo={setLogo}
                        />
                    </LoadMask>
                </div>
            </div>
        )
    }
}

export default InfluencerProfile