import React from "react";
import Modal from "react-modal";
import "./modal.css";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        borderRadius: 5,
        boxShadow: "2px 3px 10px #33313B80",
    },
};

Modal.setAppElement("#app-container");

export const FullModal = (props) => {

    const closeModal = () => {
        props.showModal(false);
    };

    const {modalStyles} = props;

    return (
        <Modal
            isOpen={props.isOpen}
            onRequestClose={closeModal}
            style={modalStyles? modalStyles: customStyles}
            closeTimeoutMS={200}
            overlayClassName="swal2-container swal2-center swal2-shown"
            shouldCloseOnOverlayClick={props.allowOutsideClick}
        >
            <div
                className="d-flex justify-content-end"
                style={{
                    marginTop: "-20px",
                    marginRight: "-5px",
                }}
            >
                {props.showClose && <span
                    onClick={closeModal}
                    className="txt-16-n color-FF4"
                    style={{
                        cursor: "pointer",
                    }}
                >
                    x
                </span>}
            </div>

            {props.children}

        </Modal>
    );
};

