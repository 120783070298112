import React, {Component} from 'react';
import PropTypes from "prop-types";
import AgentGrid from "./AgentGrid";
import Swal from "sweetalert2";
import {SELECT_ALL_OPTION} from '../../../../utility/constants'


class Agent extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    state = {
        file: null,
        fileUpdate: null,
        license: '',
        photo: null,
        nameFile: '',
        nameFileUpdate: '',
        active: true,
        activeFileUpdate: true,
        brokerage_id: 0,
    };

    static defaultProps = {
        loading: false,
    };

    componentDidMount() {
        this.props.getUrlFileTemplate();
    }

    blockUserModal = (item) => {
        Swal.fire({
            title: '',
            text: '',
            html: `<p style="font-size: 20px; font-weight: bold"> Are you sure to block agent ${item.first_name}?</p>`,
            type: 'warning',
            reverseButtons: true,
            customClass: 'text-12',
            showCancelButton: true,
            cancelButtonColor: '#AA1111',
            confirmButtonColor: '#007bff',
            confirmButtonText: 'Yes, block user!',
        })
            .then((result) => {
                if (result.value) {
                    this.props.blockAgent(item.id);
                }
            });
    };

    unblockUserModal = (item) => {
        Swal.fire({
            title: '',
            text: '',
            html: `<p style="font-size: 20px; font-weight: bold"> Are you sure to unblock agent ${item.first_name}?</p>`,
            type: 'warning',
            reverseButtons: true,
            customClass: 'text-12',
            showCancelButton: true,
            cancelButtonColor: '#AA1111',
            confirmButtonColor: '#007bff',
            confirmButtonText: 'Yes, unblock user!',
        })
            .then((result) => {
                if (result.value) {
                    this.props.unblockAgent(item.id);
                }
            });
    };

    actionFile = (data) => {
        const {setFiles} = this.props;
        setFiles(data)
        this.setState({photo: data});
    }

    setActive = (value) => {
        this.setState({active: value, file: null, nameFile: ''});
    }

    onChanged = (file) => {
        this.setState({nameFile: file ? file.name : "", file: file, active: file ? false : true});
        if (file) {
            const id = this.idSelectedOffice()
            this.props.uploadAgentList(id, file);
        }
    };

    sendInviteByOffice = () => {
        this.props.sendInviteToAllAgent();
    }

    idSelectedOffice = () => {
        const office_selected = this.props.office_selected
        let idOffice = null;
        if (office_selected && office_selected.length == 1) {
            if (office_selected[0] != SELECT_ALL_OPTION["value"]) {
                idOffice = office_selected[0]
            }
        }

        return idOffice;
    }

    downLoadRosterList = () => {
        this.props.downloadRosterList()
    }

    setActiveFileUpdate = (value) => {
        this.setState({activeFileUpdate: value, fileUpdate: null, nameFileUpdate: ''});
    }

    onChangeUpdateRoster = (file) => {
        this.setState({nameFileUpdate: file ? file.name : "", fileUpdate: file, activeFileUpdate: file ? false : true});
        if (file) {
            const id = this.idSelectedOffice()
            this.props.updateRosterList(id, file);
        }
    }

    render() {
        const {
            loader,
            page,
            data,
            urlTemp,
            toList,
            remove,
            provideAgentsRoster,
            
            downloadLoader,
            tabContainerHeight,
            onSortChange,
        } = this.props;

        return (
            <AgentGrid
                loader={loader}
                data={data}
                page={page}
                toList={toList}
                urlTemp={urlTemp}
                remove={remove}
                text={this.state.nameFile}
                active={this.state.active}
                setActive={this.setActive}
                handleOnChange={this.onChanged}
                blockUserModal={this.blockUserModal}
                unblockUser={this.unblockUserModal}
                sendInvite={this.props.sendAgentInvite}
                sendInvites2={this.sendInviteByOffice}
                provideAgentsRoster={provideAgentsRoster}
                
                officeSelected={this.idSelectedOffice()}
                downloadLoader={downloadLoader}
                downLoadRosterList={this.downLoadRosterList}
                tabContainerHeight={tabContainerHeight}
                onSortChange={onSortChange}
                textFileUpdate={this.state.nameFileUpdate}
                activeFileUpdate={this.state.activeFileUpdate}
                onChangeUpdateRoster={this.onChangeUpdateRoster}
                setActiveFileUpdate={this.setActiveFileUpdate}
            />
        );
    }
}

export default Agent;
