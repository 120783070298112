import React from 'react';
import './vendorRegister.css';
import {Field, reduxForm} from 'redux-form';
import {renderNumber} from '../../Utils/renderField';
import ReactCodeInput from "react-verification-code-input";
import {combine, validate, validatorFromFunction, validators} from 'validate-redux-form';

const SignupFormPhone = (props) => {
    //  State
    const {stepBack, code, resendCode} = props;
    //  Bind
    const {handleSubmit, validateCode} = props;

    return (
        <form name="vendorRegisterStep5" className="row" onSubmit={handleSubmit}>
            <div className="form-group has-feedback col-sm-12 mb-lg-5 mb-3">
                <span className='vendor-register-label-title'>Confirming your cell phone</span>
                <br />
                <span className='vendor-register-label-subtitle'>We will send you a confirmation code to your cell phone</span>
                <br />
                <label htmlFor="mobile_number" className='mt-4'>
                    <strong className="question2">Cell Phone Number</strong>
                </label>
                <Field
                    name="mobile_number"
                    type="tel"  
                    component={renderNumber}
                    numberFormat={"###-###-####"}
                    className="form-control"
                />
            </div>
            

            {!stepBack ? (
                <div className="buttons-box col-12 justify-content-center mx-auto pt-lg-5 mb-4">
                    <button type="submit" className="btn vendor-register-button col-xl-4 col-lg-5 align-self-center">
                        Send the code
                    </button>
                </div>
            ) : (
                <React.Fragment>
                    <div className="col-12">
                        <div className="row">
                            <div className="form-group has-feedback col-lg-8  mx-auto justify-content-center text-center">
                                <label htmlFor="mobile_number" className="text-left">
                                    <p className="vendor-register-label-subtitle mb-1">
                                        Enter the 4-digit code bellow
                                    </p>
                                </label>
                                <div style={code === 'error' ?
                                    {} : { width: '300px !important' }}
                                    className={`p-0 w-100 ${code === 'error' && "is-invalid mx-auto"} `}
                                >
                                    <ReactCodeInput
                                        onComplete={validateCode}
                                        fieldWidth={50}
                                        fieldHeight={33}
                                        title={''}
                                        className={` w-100 ${code === 'error' && "is-invalid"} `}
                                        fields={4}
                                        required={true}
                                        type={"number"}
                                    />
                                </div>
                                {code === 'error' &&
                                    <strong className="invalid-feedback"> Wrong code, please check your code</strong>}

                            </div>
                        </div>
                    </div>
                    <div className="buttons-box col-12 justify-content-center mx-auto mb-3">
                        <button
                            type="button"
                            className="btn button-03 col-xl-2 col-lg-3 col-6 align-self-center text-12"
                            style={{ fontSize: "12px !important" }}
                            onClick={() => resendCode()}
                        >
                            Resend code
                        </button>
                    </div>
                </React.Fragment>
            )}
        </form>
    );
};

const numberPhone = (number) => validatorFromFunction(() => {
    return number.length === 10;
});

export default reduxForm({
    form: 'vendorRegisterStep5', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            mobile_number: combine(
                validators.exists()('This field is required'),
                numberPhone(data.mobile_number)()('Invalid mobile number'),
            ),
        });
    },
})(SignupFormPhone);
