import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {combine, validate, validatorFromFunction, validators} from 'validate-redux-form';
import {renderNumber} from '../../../Utils/renderField';

const FormStep2 = (props) => {
    const {handleSubmit} = props;

    return (
        <form name="registerStep3" className="row pt-lg-3" onSubmit={handleSubmit} style={{minHeight: '20rem'}}>
            <div className="form-group has-feedback col-sm-12 pt-lg-5 pb-lg-4">
                <label htmlFor="mobile_number">
                    <strong className="question1"> What's your </strong>
                    <strong className="question2"> Mobile number? </strong>
                </label>
                <Field
                    name="mobile_number"
                    type="number"
                    component={renderNumber}
                    numberFormat={"###-###-####"}
                    className="form-control"
                />
            </div>

            <div className="buttons-box w-75 mx-auto pt-lg-5">
                <button type="submit" className="btn button-01 py-1 col-md-4 btn-sm align-self-center mt-lg-5">
                    Next
                </button>
            </div>
        </form>
    );
};

const numberPhone = (number) => validatorFromFunction(() => {
    return number.length === 10;
});

export default reduxForm({
    form: 'FormStep2Invited', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            mobile_number: combine(
                validators.exists()('This field is required'),
                numberPhone(data.mobile_number)()('Invalid mobile number'),
            ),
        });
    },
})(FormStep2);

