import React, {useEffect} from 'react';
import './wholeSalerRegister.css';
import { connect } from "react-redux";
import {Field, reduxForm, formValueSelector} from 'redux-form';
import {renderField, SelectField} from '../Utils/renderField';

const validate = (value) => {
    let error = {}
    if(!value.team) {
        error.team = "This field is required"
    }
    return error
}

const Form = (props) => {
    //  State
    const { teams } = props;
    //  Bind
    const {handleSubmit, skipAction} = props;

    useEffect(() => {
        if (document.getElementById('WHOLESALER-REGISTER')) {
            document.getElementById('WHOLESALER-REGISTER').scrollIntoView({ behavior: 'smooth' });
        }
    }, [])

    return (
        <div className="row flex-1 justify-content-center pt-3">
            <form
                name="wholeSalerRegisterForm"
                className="w-100 col-xl-10 col-lg-11 col-md-11 col-12 d-flex flex-column"
                onSubmit={handleSubmit}
            >
                <div className="flex-1 d-flex flex-column justify-content-between">
                    <div className="row pt-4">
                        <div className="form-group has-feedback col-12 mb-3">
                            <div className="form-group has-feedback">
                                <span className='wholesaler-register-label-title'>Confirming your Team Name</span>
                                <br />
                                <span className='wholesaler-register-label-subtitle'>If you are part of a team, you have the option to choose your team from the list below. However, if your team is not listed, please select 'Not Listed' or simply skip this step.</span>
                                <br />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center">
                        <div className="form-group has-feedback col-md-6 col-sm-12 mb-md-5 mb-5">
                            <Field
                                name="team"
                                isMulti={false}
                                options={teams}
                                component={SelectField}
                                customClass="form-control-gray"
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="row mb-4 mt-3">
                        <div className="buttons-box row w-75 mx-auto pt-3 mb-0 pb-0">
                            <p className={"skip mb-0"} onClick={skipAction}>
                                Skip
                            </p>
                        </div>
                        <div className="buttons-box col-12 justify-content-center mx-auto py-md-3 py-4">
                            <button
                                type="submit"
                                className="btn wholesaler-register-button col-xl-4 col-lg-5 align-self-center"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    )

}

const WholeSalerTeamForm = reduxForm({
    form: 'wholeSalerRegisterForm', // a unique identifier for this form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
})(Form);

const selector = formValueSelector('wholeSalerRegisterForm');
const mstp = state => {
    return {
    };
};

export default connect(mstp, null)(WholeSalerTeamForm);
