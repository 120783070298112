import React, {useEffect} from 'react';
import './vendorRegister.css';
import { connect } from "react-redux";
import {Field, reduxForm, formValueSelector} from 'redux-form';
import {renderField, SelectField, renderAddressField, renderNumber} from '../../Utils/renderField';
import {Link} from "react-router-dom";

const validate = (value) => {
    let error = {}
    if(!value.first_name) {
        error.first_name = "This field is required"
    }
    if(!value.last_name) {
        error.last_name = "This field is required"
    }
    if (!value.address) {
        error.address = "This field is required";
    }

    if (!value.office_phone) {
        error.office_phone = "This field is required";
    } else {
        if (value.office_phone.length != 10) {
            error.office_phone = "Invalid phone number";
        }
    }
    if(!value.company_name) {
        error.company_name = "This field is required"
    }
    if(!value.website) {
        error.website = "This field is required"
    }
    return error
}

const SingUpFormStep2 = (props) => {
    //  STATE
    const {handleSubmit, assignAddressData} = props;

    useEffect(() => {
        if (document.getElementById('VENDOR-REGISTER')) {
            document.getElementById('VENDOR-REGISTER').scrollIntoView({ behavior: 'smooth' });
        }
    }, [])

    return (
        <div className="row flex-1 justify-content-center pt-3">
            <form
                name="register"
                className="w-100 col-xl-10 col-lg-11 col-md-11 col-12 d-flex flex-column"
                onSubmit={handleSubmit}
            >
                <div className="flex-1 d-flex flex-column justify-content-between">
                    <div className="row">
                        <div className="form-group has-feedback col-md-6 col-sm-12 mb-md-5 mb-5">
                            <label htmlFor="first_name" className="mb-0">
                                <strong className="question2">First name</strong>
                            </label>
                            <Field
                                name="first_name"
                                label="First Name"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 col-sm-12 mb-md-5 mb-5">
                            <label htmlFor="first_name" className="mb-0">
                                <strong className="question2">Last name</strong>
                            </label>
                            <Field
                                name="last_name"
                                label="Last Name"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 col-sm-12 mb-md-5 mb-5">
                            <label htmlFor="office_phone">
                                <strong className="question2">
                                    Office Phone Number
                                </strong>
                            </label>
                            <Field
                                name="office_phone"
                                type="tel"
                                component={renderNumber}
                                numberFormat={"###-###-####"}
                                // placeholder={"Telephone"}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 col-sm-12 mb-md-5 mb-5">
                            <label htmlFor="company_name" className="mb-0">
                                <strong className="question2">Company name</strong>
                            </label>
                            <Field
                                name="company_name"
                                label="Company Name"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-12 mt-0 mb-md-4 mb-5">
                            <label htmlFor="address">
                                <strong className="question2">
                                    Company Address
                                </strong>
                            </label>
                            <Field
                                name="address"
                                label="Address"
                                placeholder='Address'
                                component={renderAddressField}
                                type="text"
                                className="form-control"
                                longStateName={true}
                                setCoords={(data) => {
                                    if (data) {
                                        assignAddressData(data, "vendorRegisterStep2", 'coords')
                                    }
                                }}
                                setCityState={(data) => {
                                    if (data) {
                                        assignAddressData(data, "vendorRegisterStep2")
                                    }
                                }}
                            />
                        </div>
                        <div className="form-group has-feedback col-12 mb-5">
                            <label htmlFor="website" className="mb-0">
                                <strong className="question2">Website</strong>
                            </label>
                            <Field
                                name="website"
                                label="Website"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="buttons-box justify-content-center col-12">
                            <span className='text-center'>
                                By proceeding, I affirm that I have read and agreed to the{' '}
                                <a href='https://showitsolutions.com/terms-and-conditions/' className='a-link' target='_blank'>Terms & Conditons</a> and the <a href='https://showitsolutions.com/privacy-policy/' className='a-link' target='_blank'>Privacy Policy</a>
                            </span>
                        </div>
                        <div className="buttons-box col-12 justify-content-center mx-auto py-md-3 py-4">
                            <button
                                type="submit"
                                className="btn vendor-register-button col-xl-4 col-lg-5 align-self-center"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

const registerForm = reduxForm({
    form: 'vendorRegisterStep2', // a unique identifier for this form
    validate,
})(SingUpFormStep2);

const selector = formValueSelector('vendorRegisterStep2');
const mstp = state => {
    return {
    };
};

export default connect(mstp, null)(registerForm);

