import React from "react";
import DoughnutAgent from '../Dashboard/DoughnutAgent/DoughnutAgent';

const Revenue = (props) => {
    const { total_to_revenue, posted, applied, pending } = props;
    return (
        <div className="w-100 card border-card-page border-card-20 h-100">
            <div className="row px-1 px-lg-2 w-100 m-auto py-2 py-lg-2">
                <div className="col-lg-4 col-sm-12 text-center m-auto">
                    <h6 className="row text-dark justify-content-center font-weight-bold text-12 mb-0 pb-1">
                        Total Pending Jobs
                    </h6>
                    <h5 className=" text-dark row justify-content-center font-weight-bold mb-0 ">
                        {total_to_revenue
                            ? total_to_revenue.total
                                ? total_to_revenue.total
                                : 0
                            : 0}
                    </h5>
                    <h5 className=" text-dark row justify-content-center font-weight-bold pt-1 ">
                        US $
                        {total_to_revenue
                            ? total_to_revenue.fee
                                ? total_to_revenue.fee.toFixed(2)
                                : "0.00"
                            : "0.00"}
                    </h5>
                </div>
                <div className="text-center col-lg-8 col-sm-12 m-auto pt-sm-2 pt-lg-0">
                    <div className="row border-bottom">
                        <div className="col-4 border-right label-middle py-0 px-1">
                            Posted Jobs
                        </div>
                        <div className="col-4 border-right label-middle py-0 px-1">
                            Applied Jobs
                        </div>
                        <div className="col-4 label-middle py-0 px-1">
                            Hired/Claimed Jobs
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4 border-right py-0 px-1">
                            <div className="label-middle">
                                {posted ? (posted.total ? posted.total : 0) : 0}
                            </div>
                            <div className="label-middle">
                                US $
                                {posted
                                    ? posted.fee
                                        ? posted.fee.toFixed(2)
                                        : "0.00"
                                    : "0.00"}
                            </div>
                        </div>
                        <div className="col-4 border-right label-middle py-0 px-1">
                            <div className="label-middle">
                                {applied
                                    ? applied.total
                                        ? applied.total
                                        : 0
                                    : 0}
                            </div>
                            <div className="label-middle">
                                US $
                                {applied
                                    ? applied.fee
                                        ? applied.fee.toFixed(2)
                                        : "0.00"
                                    : "0.00"}
                            </div>
                        </div>
                        <div className="col-4 label-middle py-0 px-1">
                            <div className="label-middle">
                                {pending
                                    ? pending.total
                                        ? pending.total
                                        : 0
                                    : 0}
                            </div>
                            <div className="label-middle">
                                US $
                                {pending
                                    ? pending.fee
                                        ? pending.fee.toFixed(2)
                                        : "0.00"
                                    : "0.00"}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const Agents = (props) => {
    const { total_agent, total_user, total_user_active } = props;
    return (
        <div className="w-100 card border-card-page border-card-20 h-100">
            <div className="row w-100 m-auto pb-2">
                <div className="col-lg-2 col-sm-12 px-1 text-center m-auto border-right">
                    <div className="text-dark justify-content-center font-weight-bold text-12 mb-0 pb-1">
                        Total Agents
                    </div>
                    <h5 className=" text-dark justify-content-center font-weight-bold mb-0 ">
                        {total_agent ? total_agent : 0}
                    </h5>
                </div>
                <div className="col-lg-2 col-sm-6 px-1 text-center m-auto">
                    <div className="justify-content-center label-middle font-weight-bold">
                        Registered
                    </div>
                    <div className="justify-content-center label-middle font-weight-bold mt-3">
                        {total_user ? total_user : 0}
                    </div>
                </div>
                <div className="col-lg-2 col-sm-6 px-1 text-center m-auto">
                    <div className="justify-content-center label-middle font-weight-bold">
                        Active
                    </div>
                    <div className="justify-content-center label-middle font-weight-bold mt-3">
                        {total_user_active ? total_user_active : 0}
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 px-1 text-center m-auto d-flex flex-column align-items-center justify-content-center pt-lg-0 pt-3">
                    <div className="justify-content-center label-middle font-weight-bold d-lg-block d-none">
                        Registered
                    </div>
                    <DoughnutAgent
                        total={total_agent ? total_agent : 0}
                        count={total_user ? total_user : 0}
                    />
                </div>
                <div className="col-lg-3 col-sm-6 px-1 text-center m-auto d-flex flex-column align-items-center justify-content-center pt-lg-0 pt-3">
                    <div className="justify-content-center label-middle font-weight-bold d-lg-block d-none">
                        Active
                    </div>
                    <DoughnutAgent
                        total={total_user ? total_user : 0}
                        count={total_user_active ? total_user_active : 0}
                    />
                </div>
            </div>
        </div>
    );
};

function AgentsJobsStats(props) {
    const { dataRevenueAgents } = props;
    return (
        <div className="flex-1">
            {dataRevenueAgents && (
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 my-lg-0 my-3">
                            <Revenue
                                total_to_revenue={
                                    dataRevenueAgents.total_to_revenue
                                }
                                posted={dataRevenueAgents.posted}
                                applied={dataRevenueAgents.applied}
                                pending={dataRevenueAgents.pending}
                            />
                        </div>
                        <div className="col-lg-6 col-md-12 my-lg-0 my-3">
                            <Agents total_agent={dataRevenueAgents.total_agent} total_user={dataRevenueAgents.total_user} total_user_active={dataRevenueAgents.total_user_active} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AgentsJobsStats;
