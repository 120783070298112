import React from 'react';
import { connect } from "react-redux";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { validate, validators } from 'validate-redux-form';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { renderCurrency, renderField, renderFieldCheck} from '../Utils/renderField';

let PayoutForm = (props) => {
    const { handleSubmit, payoutModal, onOpenModal, onCloseModal, showProgress, errorMsg, login, balance, bankAccount } = props;

    // const myRef = React.useRef(null);

    const validateSubmit = () => {
        if (!props.checkedTrue) {
            return true;
        } else if (!props.checkedTrue) {
            return true;
        } else if (balance === 0) {
            return true;
        } else if (props.amount > balance) {
            return true;
        }
        return false;
    };
    return (
        <div>
            <button onClick={onOpenModal} className="btn btn-primary mt-5 w-100" disabled={!login.me.stripe_active}>
                <div className="row m-auto">
                    <div className="col-lg-12 col-sm-10 my-auto px-0">
                        Payout
                    </div>
                </div>
            </button>
            <Modal
                center
                closeOnEsc
                open={payoutModal}
                // container={myRef.current}
                closeOnOverlayClick={false}
                onClose={() => onCloseModal()}
                // initialFocusRef={document.getElementById('app-container')}
            >
                <form className="card pb-2 px-0" onSubmit={handleSubmit}>
                    <div className="card-title border-bottom py-1 text-center">
                        <label style={{ fontSize: '18px' }} className="my-0 py-0 ">
                            Payout
                        </label>
                    </div>
                    <div className="card-body pt-1 px-0 pb-2 mb-2">
                        <div className="form-group has-feedback mt-3">
                            <label className="py-1 mb-0">
                                From connect account balance
                            </label>
                            <p className="mb-0">
                                Available:
                                <strong className="font-weight-bold">
                                    &nbsp;&nbsp;USD &nbsp;&nbsp;{balance}
                                </strong>
                            </p>
                        </div>
                        <div className="form-group has-feedback">
                            <label htmlFor='amount' className="mb-0 py-1">
                                Amount
                            </label>
                            <Field
                                name="amount"
                                prefix="USD   "
                                disabled={(balance === 0)}
                                placeholder="USD  0.00"
                                component={renderCurrency}
                                customClass="col-lg-6 col-sm-10"
                            />
                        </div>
                        <div className="form-group has-feedback">
                            <label className="mb-0 py-1">
                                Send to
                            </label>
                            <p className="mb-0 pb-1">
                                {bankAccount[0].bank_name} &nbsp; ***** {bankAccount[0].last4}
                            </p>
                        </div>
                        <div className="form-group has-feedback">
                            <label htmlFor="description" className="mb-0 py-1">
                                Description of the charge on the bank statement
                            </label>
                            <Field
                                customClass="col-lg-8 col-sm-12"
                                placeholder="Payout Stripe"
                                component={renderField}
                                disabled={(balance === 0)}
                                name="description"
                                type="text"
                            />
                        </div>
                        <div className="border-bottom border-top mt-4 py-3 flex">
                            <label>CONFIRM PAYOUT</label>
                            <br/>
                            <Field
                                component={renderFieldCheck}
                                customClass=""
                                disabled={!props.amount}
                                name="confirm"
                                type="checkbox"
                            />
                            <p className="text-12 mb-0 pb-0 d-inline w-100">
                                &nbsp;Payout of <label
                                className="text-12"> USD {props.amount ? props.amount : '0.00'} &nbsp; </label>
                                From connect account
                            </p>
                            <p className="my-0 py-0 text-12 d-block">
                                balance to <span className="font-weight-bolder">
                                {bankAccount[0].bank_name} &nbsp; **** {bankAccount[0].last4}.
                            </span>
                            </p>
                            {props.description &&
                            <React.Fragment>
                                <strong className="text-12">
                                    The transfer will appear on your bank statement as
                                </strong>
                                <p className="text-12 font-weight-bold m-0 p-0">
                                    {props.description}
                                </p>
                            </React.Fragment>
                            }
                        </div>
                        {
                            !!showProgress &&
                            <div className="progress">
                                <div
                                    className="progress-bar progress-bar-striped progress-bar-animated bg-primary"
                                    role="progressbar"
                                    aria-valuenow="75"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{ width: "100%" }}
                                />
                            </div>
                        }
                        {
                            (errorMsg !== null) &&
                            <div className="w-100">
                                <div className="text-danger text-12">
                                    {errorMsg}
                                </div>
                            </div>
                        }
                        {
                            (balance === 0) &&
                            <div className="w-100 text-center">
                                <div className="text-danger text-12">
                                    Balance is 0
                                </div>
                            </div>
                        }
                        {
                            (props.amount > balance) &&
                            <div className="w-100 text-center">
                                <div className="text-danger text-12">
                                    The amount cannot be greater than the balance
                                </div>
                            </div>
                        }
                    </div>
                    <div className="row justify-content-end px-3">
                        <button type="submit" className="btn btn-primary px-5" disabled={validateSubmit()}>
                            Confirm
                        </button>
                    </div>
                </form>
            </Modal>
        </div>
    );
};


const selector = formValueSelector('PayoutForm');

PayoutForm = reduxForm({
    form: 'PayoutForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            amount: validators.exists()('This field is required'),
            description: validators.exists()('This field is required'),
        });
    },
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
})(PayoutForm);

const mstp = state => {
    const amount = selector(state, 'amount');
    const checkedTrue = selector(state, 'confirm');
    const description = selector(state, 'description');
    return {
        amount,
        checkedTrue,
        description,
    };
};

export default connect(mstp, null)(PayoutForm);
