import React, {useEffect} from "react";
import './register.css';
import "react-step-progress-bar/styles.css";

export const Info = (props) => {
    const {onSubmit} = props

    useEffect(() => {
        if (document.getElementById('BROKER-REGISTER')) {
            document.getElementById('BROKER-REGISTER').scrollIntoView({ behavior: 'smooth' });
        }
    }, [])

    return (
        <div className="row flex-1 justify-content-center pt-3">
            <div className="w-100 col-xl-10 col-lg-11 col-md-11 col-12 d-flex flex-column">
                <div className="flex-1 d-flex flex-column justify-content-between">
                    <div className="row">
                        <div className="col-12 d-flex flex-column">
                            <span className="broker-register-form-title text-center mb-3">Your Showing Assistant App</span>
                            <span className="mb-0 broker-register-info-text">
                                We are delighted to extend a warm welcome to you as we invite you to join the ShowIT Solutions app! Your presence adds tremendous value to our platform, and we're genuinely excited about the prospect of working together.
                            </span>
                            <br />
                            <span className="mb-0 broker-register-info-text">
                                By enrolling on the ShowIt Solutions™ platform, you are not only becoming a part of our community but also unlocking a myriad of opportunities for your agents. This platform is designed to empower your top-performing agents, enabling them to enhance productivity by optimizing their time. Simultaneously, it provides newer agents with invaluable experience right from day one, setting a solid foundation for their success.
                                <br />
                                We believe that your brokerage's presence on our platform will not only elevate your services but also enrich the experience for agents and clients alike.
                            </span>
                            <br />
                            <span className="mb-0 broker-register-info-text">
                            Moreover, the ShowIt Solutions™ platform goes beyond just facilitating connections. It opens the door for your brokerage to generate a continuous stream of passive income, contributing to the overall growth and success of your business.
                            </span>
                            <br />
                            <span className="broker-register-info-text">
                                Thank you for choosing to be a part of the ShowIT Solutions app.
                            </span>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="buttons-box col-12 justify-content-center mx-auto py-md-3 py-4">
                            <button
                                onClick={onSubmit}
                                type="button"
                                className="btn broker-register-button col-xl-4 col-lg-5 col-sm-8 align-self-center"
                            >
                                Get Started
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};