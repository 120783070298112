import React, { useEffect, useState } from "react";
import "./agentRegister.css";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { renderField, renderAddressField, renderNumber } from "../Utils/renderField";
import ProfileMap from "../Utils/Map/profileMap";

const validate = (value) => {
    let error = {};
    if (!value.street) {
        error.street = "This field is required";
    }
    if (!value.miles) {
        error.miles = "This field is required";
    }

    return error;
};

const Form = (props) => {
    //  State
    const { geolocationValue, milesValue } = props;

    const [hasMap, setHasMap] = useState(false);
    const [center, setCenter] = useState({
        lat: 0,
        lng: 0,
    })
    const [miles, setMiles] = useState(0)
    const [intervalId, setIntervalId] = useState(null);
    //  Bind
    const { handleSubmit, setWorkingArea } = props;

    useEffect(() => {
        const _intervalId = setInterval(() => {
            // console.log("INTERVAL");
            if (window.google && window.google.maps && !hasMap) {
                setHasMap(true);
            }
        }, 300);

        setIntervalId(_intervalId)

        if (document.getElementById("AGENT-REGISTER")) {
            document
                .getElementById("AGENT-REGISTER")
                .scrollIntoView({ behavior: "smooth" });
        }

        return () => {
            clearInterval(_intervalId);
        }
    }, []);

    useEffect(() => {
        if (hasMap == true && intervalId != null) {
            clearInterval(intervalId)
        }
    }, [hasMap])

    useEffect(() => {
        if (geolocationValue) {
            setCenter({
                lat: geolocationValue.latitude_center,
                lng: geolocationValue.longitude_center
            })
        } else {
            setCenter({
                lat: 0,
                lng: 0,
            })
        }
    }, [geolocationValue])

    useEffect(() => {
        if (milesValue != "" && milesValue != null && milesValue != undefined) {
            setMiles(parseFloat(milesValue))
        } else {
            setMiles(0)
        }
    }, [milesValue])


    return (
        <div className="row flex-1 justify-content-center pt-3">
            <form
                name="agentRegisterForm"
                className="w-100 col-xl-10 col-lg-11 col-md-11 col-12 d-flex flex-column"
                onSubmit={handleSubmit}
            >
                <div className="flex-1 d-flex flex-column justify-content-between">
                    <div className="row pt-4">
                        <div className="form-group has-feedback col-12 mb-md-5 mb-5">
                            <div className="form-group has-feedback">
                                <span className="agent-register-label-title">
                                    Choose your working area
                                </span>
                                <br />
                                <span className="agent-register-label-subtitle">
                                    You'll receive notifications of new jobs
                                    based on your preferred working areas.
                                    Please provide us with your address and the
                                    radius within which you'd like to receive
                                    job notifications.
                                </span>
                                <br />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-6 mb-md-5 mb-5">
                            <div className="row d-flex flex-column align-items-center justify-content-center">
                                <div className="form-group has-feedback col-12 mb-md-3 mb-3">
                                    <label htmlFor="street">
                                        <strong className="question2">
                                            Address
                                        </strong>
                                    </label>
                                    {hasMap && (
                                        <Field
                                            name="street"
                                            placeholder=""
                                            component={renderAddressField}
                                            type="text"
                                            className="form-control"
                                            longStateName={true}
                                            setCoords={(data) => {
                                                if (data) {
                                                    setWorkingArea(data);
                                                } else {
                                                    setWorkingArea({})
                                                }
                                            }}
                                        />
                                    )}
                                </div>
                                <div className="form-group has-feedback col-12 mb-md-3 mb-3">
                                    <label htmlFor="street">
                                        <strong className="question2">
                                            Radius (miles)
                                        </strong>
                                    </label>
                                    <Field
                                        name="miles"
                                        label="miles"
                                        placeholder='xx miles'
                                        component={renderNumber}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group has-feedback col-12 col-md-12 col-lg-6 mb-md-5 mb-5">
                            <div className="row">
                                <div className="col-sm-12" style={{ minHeight: '200px' }}>
                                    <ProfileMap center={center} radius={miles} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="buttons-box col-12 justify-content-center mx-auto py-md-3 py-4">
                            <button
                                type="submit"
                                className="btn agent-register-button col-xl-4 col-lg-5 align-self-center"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

const AgentWorkingAreaForm = reduxForm({
    form: "agentRegisterForm", // a unique identifier for this form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
})(Form);

const selector = formValueSelector("agentRegisterForm");
const mstp = (state) => {
    const geolocationValue = selector(state, "geolocation")
    const milesValue = selector(state, "miles")

    return {
        geolocationValue,
        milesValue,
    };
};

export default connect(mstp, null)(AgentWorkingAreaForm);
