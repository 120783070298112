import React, {useEffect} from 'react';
import './vendorRegister.css';
import { connect } from "react-redux";
import {Field, reduxForm, formValueSelector} from 'redux-form';
import {renderField, MultiSelectField, renderNumber, customFieldFile} from '../../Utils/renderField';

const validate = (value) => {
    let error = {}
    
    if(!value.professions) {
        error.professions = "This field is required"
    }
    if(!value.years_business) {
        error.years_business = "This field is required"
    }
    if(!value.number_employees) {
        error.number_employees = "This field is required"
    }
    return error
}

const SingUpFormStep3 = (props) => {
    //  STATE
    const {
        optionProfessions,
        insuranceText,
        insuranceActive,
        certificationText,
        certificationActive,
        businessRegistrationText,
        businessRegistrationActive,
    } = props
    const {
        handleSubmit,
        setInsurance,
        setInsuranceActive,
        setCertification,
        setCertificationActive,
        setBusinessRegistration,
        setBusinessRegistrationActive,
    } = props;

    useEffect(() => {
        if (document.getElementById('VENDOR-REGISTER')) {
            document.getElementById('VENDOR-REGISTER').scrollIntoView({ behavior: 'smooth' });
        }
    }, [])

    return (
        <div className="row flex-1 justify-content-center pt-3">
            <form
                name="registerStep3"
                className="w-100 col-xl-10 col-lg-11 col-md-11 col-12 d-flex flex-column"
                onSubmit={handleSubmit}
            >
                <div className="flex-1 d-flex flex-column justify-content-between">
                    <div className="row">
                        <div className="form-group has-feedback col-12 mt-0 mb-md-4 mb-5">
                            <label htmlFor="professions" className="mb-0">
                                <strong className="question2">Select Expertise</strong>
                            </label>
                            <Field
                                name="professions"
                                options={optionProfessions}
                                component={MultiSelectField}
                                className="form-control"
                                showSelectAllOption={false}
                                isSearchable={false}
                                displayAllSelected={true}
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 col-sm-12 mb-md-5 mb-5">
                            <label htmlFor="number_employees" className="mb-0">
                                <strong className="question2">Number of Employes</strong>
                            </label>
                            <Field
                                name="number_employees"
                                type="tel"
                                component={renderNumber}
                                numberFormat={"########"}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 col-sm-12 mb-md-5 mb-5">
                            <label htmlFor="years_business" className="mb-0">
                                <strong className="question2">Years in business</strong>
                            </label>
                            <Field
                                name="years_business"
                                type="tel"
                                component={renderNumber}
                                numberFormat={"########"}
                                className="form-control"
                            />
                        </div>

                        <div className="form-group has-feedback col-12 d-flex flex-md-row flex-column mt-4">
                            <label className="mb-md-0 mb-4 flex-1 vendor-register-upload-text" style={{textDecoration: 'underline'}}>
                                Optional
                            </label>
                        </div>
                        <div className="form-group has-feedback col-12 d-flex flex-md-row flex-column align-items-center mt-1">
                            <label htmlFor="insurance" className="mb-md-0 mb-4 flex-1 vendor-register-upload-text">
                                Upload copy of your insurance certification (P&O Insurance)
                            </label>
                            <Field
                                id={"vendor-insurance"}
                                name="insurance"
                                setFile={setInsurance}
                                component={customFieldFile}
                                title={"Upload"}
                                text={insuranceText}
                                customClass="btn-secondary mb-0 py-1 mx-auto"
                                type={"file"}
                                active={insuranceActive}
                                acceptFiles="*"
                                customContainerClass='mb-0'
                                remove={() => setInsuranceActive(true)}
                            />
                        </div>
                        <div className="form-group has-feedback col-12 d-flex flex-md-row flex-column align-items-center mt-1">
                            <label htmlFor="certification" className="mb-md-0 mb-4 flex-1 vendor-register-upload-text">
                                Upload copy of your license certificate
                            </label>
                            <Field
                                id={"vendor_certification"}
                                name="certification"
                                setFile={setCertification}
                                component={customFieldFile}
                                title={"Upload"}
                                text={certificationText}
                                customClass="btn-secondary mb-0 py-1 mx-auto"
                                type={"file"}
                                active={certificationActive}
                                acceptFiles="*"
                                customContainerClass='mb-0'
                                remove={() => setCertificationActive(true)}
                            />
                        </div>
                        <div className="form-group has-feedback col-12 d-flex flex-md-row flex-column align-items-center mt-1">
                            <label htmlFor="busines_registration" className="mb-md-0 mb-4 flex-1 vendor-register-upload-text">
                                Upload copy of your last two years business registration
                            </label>
                            <Field
                                id={"vendor_busines_registration"}
                                name="busines_registration"
                                setFile={setBusinessRegistration}
                                component={customFieldFile}
                                title={"Upload"}
                                text={businessRegistrationText}
                                customClass="btn-secondary mb-0 py-1 mx-auto"
                                type={"file"}
                                active={businessRegistrationActive}
                                acceptFiles="*"
                                customContainerClass='mb-0'
                                remove={() => setBusinessRegistrationActive(true)}
                            />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="buttons-box col-12 justify-content-center mx-auto py-md-3 py-4">
                            <button
                                type="submit"
                                className="btn vendor-register-button col-xl-4 col-lg-5 align-self-center"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        
        </div>
    );
};

const registerForm = reduxForm({
    form: 'vendorRegisterStep3', // a unique identifier for this form
    validate,
})(SingUpFormStep3);

const selector = formValueSelector('vendorRegisterStep3');
const mstp = state => {
    return {
    };
};

export default connect(mstp, null)(registerForm);

