import React, { Component } from 'react';
import PropTypes from "prop-types";
import RegisteredGrid from "./RegisteredGrid";
import Swal from "sweetalert2";


class Agent extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    state = {
        nameFile: '',
    };

    static defaultProps = {
        loading: false,
    };

    componentDidMount() {
        // const { toList2, getOffices, initializeFiltersForm } = this.props;
        // initializeFiltersForm();
        // getOffices();
        // toList2();
    }

    blockUserModal = (item) => {
        Swal.fire({
            title: '',
            text: '',
            html: `<p style="font-size: 20px; font-weight: bold"> Are you sure to lock agent ${item.first_name}?</p>`,
            type: 'warning',
            reverseButtons: true,
            customClass:'text-12',
            showCancelButton: true,
            cancelButtonColor: '#AA1111',
            confirmButtonColor: '#007bff',
            confirmButtonText: 'Yes, lock user!',
        })
            .then((result) => {
                if (result.value) {
                    this.props.blockUserTypeAgent(item.id);
                }
            });
    };

    unblockUserModal = (item) => {
        Swal.fire({
            title: '',
            text: '',
            html: `<p style="font-size: 20px; font-weight: bold"> Are you sure to unlock agent ${item.first_name}?</p>`,
            type: 'warning',
            reverseButtons: true,
            customClass:'text-12',
            showCancelButton: true,
            cancelButtonColor: '#AA1111',
            confirmButtonColor: '#007bff',
            confirmButtonText: 'Yes, unlock user!',
        })
            .then((result) => {
                if (result.value) {
                    this.props.unblockUserTypeAgent(item.id);
                }
            });
    };

    activeUser = (item) => {
        Swal.fire({
            title: '',
            text: '',
            html: `<p style="font-size: 20px; font-weight: bold"> Are you sure to validate agent ${item.first_name}?</p>`,
            type: 'warning',
            reverseButtons: true,
            customClass:'text-12',
            showCancelButton: true,
            cancelButtonColor: '#AA1111',
            confirmButtonColor: '#007bff',
            confirmButtonText: 'Yes, validate user!',
        })
            .then((result) => {
                if (result.value) {
                    this.props.activeUserTypeAgent(item.id)
                }
            });
    }

    render() {
        const {
            loader,
            page,
            data,
            searchChange2,
            toList2,
            remove,
            offices,
            setCategoryFilter,
            setOfficeFilter,
            activeUserTypeAgent,
            provideAgentsRoster,
            tabContainerHeight,
            goPage,
        } = this.props;
        return (
            <RegisteredGrid
                loader={loader}
                data={data}
                page={page}
                toList={toList2}
                searchAction={searchChange2}
                remove={remove}
                options={offices}
                filter1={setCategoryFilter}
                filter2={setOfficeFilter}
                blockUserModal={this.blockUserModal}
                unblockUser={this.unblockUserModal}
                activeUser={this.activeUser}
                provideAgentsRoster={provideAgentsRoster}
                tabContainerHeight={tabContainerHeight}
                goPage={goPage}
            />
        );
    }
}

export default Agent;
