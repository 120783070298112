import {handleActions} from 'redux-actions';
import {push} from "react-router-redux";
import {initialize as initializeForm} from 'redux-form';
import {NotificationManager} from "react-notifications";
import {api} from "api";
import Swal from "sweetalert2";
import Logo from '../../../../assets/img/logo-01.png';

const SUBMIT = 'LOGIN_SUBMIT';
const LOADER = 'LOGIN_LOADER';
const ITEM_RESET_PASS = 'ITEM_RESET_PASS';
const ME = 'LOGIN_ME';

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setItem = item => ({
    type: ITEM_RESET_PASS,
    item,
});

export const setMe = me => ({
    type: ME,
    me,
});

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit = (data = {}) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('user/login_broker', data).then((response) => {
        localStorage.setItem('token', response.token);
        localStorage.setItem('user_type', response.type);
        response.user.roles = response.user.roles.value
        dispatch(initializeForm('profile', response.user));
        dispatch(setMe(response.user));
        if (response && response.type && parseInt(response.type) == 20) {
            dispatch(push("/influencer/profile"))
        }else{
            dispatch(push("/"));
        }
        if (response.user.broker_verified === false) {
            dispatch(verifyUser()) // verify if user is valid
        } else if (response.user.min_offices === false) {
            dispatch(ModalRedirectToCreateOffice()); // show modal to create new offices
        }
    }).catch((error) => {
        console.log(error)
        NotificationManager.error('Incorrect credentials, please try again', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const getMe = () => (dispatch) => {
    api.get('/user/broker_me').then(me => {
        me.roles = me.roles.value
        dispatch(initializeForm('profile', me));
        dispatch(setMe(me));
        if (me.user.broker_verified === false) {
            dispatch(verifyUser()) // verify if user is valid
        }
    })
        .catch(() => {
        }).finally(() => {
    });
};

const sendEmailToResetPass = (email, setNextStep) => (dispatch) => {
    dispatch(setLoader(true))
    api.post('/user/reset_password_link', {email}).then(response => {
        NotificationManager.success('Instruction sent to email', 'SUCCESS', 3000);
        setNextStep && setNextStep()
    })
        .catch((error) => {
            console.log('error: ', error)
            let msg = "Failed to reset password, please try again later"
            if (error && error.details) {
                msg = error.details
            }
            NotificationManager.error(msg, 'ERROR', 3000);
        }).finally(() => {
        dispatch(setLoader(false))
    });
}

const resetPass = (data) => (dispatch, getStore) => {
    const resource = getStore().login.item
    dispatch(setLoader(true))
    api.post('/user/reset_password', {...resource, password: data.password})
        .then(response => {
            console.log('response', response)
            if (response.user_type === 10) {  // if user is broker
                dispatch(push('/login'))
            }
            NotificationManager.success('Password changed', 'SUCCESS', 3000);
        })
        .catch(() => {
            NotificationManager.error('Error to change password, please try later', 'ERROR', 3000);
        }).finally(() => {
        dispatch(setLoader(false))
    });
}

const verifyCodeToResetPass = (code, showExpired) => (dispatch) => {
    dispatch(setLoader(true))
    api.post('/user/verify_code_to_reset_pass', {code})
        .then(response => {
            dispatch(setItem(response))
            showExpired && showExpired()
        })
        .catch(() => {
            NotificationManager.error('Error to change password, please try later', 'ERROR', 3000);
        }).finally(() => {
        dispatch(setLoader(false))
    });
}

const ModalRedirectToCreateOffice = () => (dispatch) => {
    Swal.fire({
        title: "",
        imageUrl: Logo,
        imageHeight: 50,
        imageAlt: 'logo-ShowIT',
        width: '46rem',
        html:
            '<p class="text-center mx-auto" style="font: normal normal 600 14px/17px Poppins; letter-spacing: 0; ">' +
            'Welcome to your ShowIt Solutions™ Admin Panel.' +
            '</p>' + '<p class="text-center mx-auto pb-0" style="font: normal normal 600 14px/17px Poppins; letter-spacing: 0">' +
            "We will need just a few more details from you about your brokerage to finalize your office's registration. This should take only a few minutes.<br/>Please click below to setup your office's information, this will enable your agents to register on the app and join the ShowIt Solutions™ community.</p>"
        ,
        showConfirmButton: true,
        showCancelButton: false,
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn btn-primary mb-4 px-5',
        },
        confirmButtonText: "Finalize Registration",
        animation: true,
        allowOutsideClick: false,  // disable outside click of the alert
    }).then((result) => {
        if (result.value) {       // if pressed button Yes
            dispatch(push("brokerage/"));    // redirect to create office
        }
    });
}

export const verifyUser = () => (dispatch) => {
    Swal.fire({
        title: "",
        imageUrl: Logo,
        imageHeight: 50,
        imageAlt: 'logo-ShowIT',
        width: '46rem',
        html:
            '<h5 class="text-center text-dark mb-3" style="font: normal normal 600 18px/19px Poppins; letter-spacing: 0;' +
            'opacity: 1;">' + 'Welcome' + '</h5>' +
            '<p class="text-center col-lg-9 col-sm-12 mx-auto" style="font: normal normal 600 14px/17px Poppins; letter-spacing: 0; ">' +
            'Please allow up to 24 hours to activate your account. Once the account is activated you will receive an email with login information to your ShowIT Solutions Broker Admin panel!' +
            '</p>',
        showConfirmButton: true,
        showCancelButton: false,
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn button-01 mb-4',
        },
        confirmButtonText: "Exit",
        animation: true,
        allowOutsideClick: false,  // disable outside click of the alert
    }).then((result) => {
        if (result.value) {       // if pressed button Yes
            dispatch(logOut());   // remove token
            dispatch(push("/login"));    // redirect to login
        }
    });
}


export const logOut = () => (dispatch) => {
    api.post('/user/logout').then(() => {
    }).catch(() => {
    }).finally(() => {
    });
    localStorage.removeItem('token');
};


export const actions = {
    onSubmit,
    logOut,
    verifyUser,
    resetPass,
    setItem,
    sendEmailToResetPass,
    verifyCodeToResetPass,
};

export const reducers = {
    [LOADER]: (state, {loader}) => {
        return {
            ...state,
            loader,
        };
    },
    [ME]: (state, {me}) => {
        return {
            ...state,
            me,
        };
    },
    [ITEM_RESET_PASS]: (state, {item}) => {
        return {
            ...state,
            item,
        };
    },
};

export const initialState = {
    loader: false,
    me: {},
    item: {},
};

export default handleActions(reducers, initialState);
