import React, {useEffect, useState} from 'react';
import './wholeSalerRegister.css';
import { connect } from "react-redux";
import {renderField} from '../Utils/renderField';
import { minLength } from "../../../utility/validation";
import {Field, reduxForm, formValueSelector} from 'redux-form';

const validate = (value) => {
    let error = {}
    if (!value.password) {
        error.password = "This field is required"
    } else {
        if (value.password != value.confirmPassword) {
            error.confirmPassword = 'The account password don\'t match';
        }
    }
    return error
}

const Form = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const {handleSubmit, validateCode, code, stepBack, resendCode, emailConfirmed } = props;

    useEffect(() => {
        if (document.getElementById('WHOLESALER-REGISTER')) {
            document.getElementById('WHOLESALER-REGISTER').scrollIntoView({ behavior: 'smooth' });
        }
    }, [])

    return (
        <div className="row flex-1 justify-content-center pt-3">
        <form
            name="wholeSalerRegisterForm"
            className="w-100 col-xl-10 col-lg-11 col-md-11 col-12 d-flex flex-column"
            onSubmit={handleSubmit}
        >
            <div className="flex-1 d-flex flex-column justify-content-between">
                <div className="row pt-4">
                    <div className="form-group has-feedback col-12">
                        <span className='wholesaler-register-label-title'>Create your password</span>
                        <br />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group has-feedback col-xl-5 col-lg-6 col-md-9 col-sm-12 mx-auto">
                        <label htmlFor="password" className='mt-4'>
                            <strong className="question2">Create your password</strong>
                        </label>
                        <div className="w-100 mx-auto row">
                            <Field
                                name="password"
                                placeholder="Minimum 6 characters"
                                component={renderField}
                                container={"w-100"}
                                type={`${showPassword ? 'text' : 'password'}`}
                                className="form-control"
                                validate={[minLength(6)]}
                            />
                            <label className="button-eyes"
                                onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ?
                                    <i className="material-icons pointer">visibility_off</i> :
                                    <i className="material-icons pointer">visibility</i>
                                }
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row pb-5">
                    <div className="form-group has-feedback col-xl-5 col-lg-6 col-md-9 col-sm-12 mx-auto">
                        <label htmlFor="confirmPassword" className='mt-4'>
                            <strong className="question2">Confirmed your password</strong>
                        </label>
                        <div className="w-100 mx-auto row">
                            <Field
                                container={"w-100"}
                                name="confirmPassword"
                                component={renderField}
                                type={`${showPassword ? 'text' : 'password'}`}
                                className="form-control w-100"
                            />
                            <label className="button-eyes"
                                onClick={showPassword ? () => showPassAction(false) : () => showPassAction(true)}>
                                {showPassword ?
                                    <i className="material-icons pointer">visibility_off</i> :
                                    <i className="material-icons pointer">visibility</i>
                                }
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="buttons-box col-12 justify-content-center mx-auto py-md-3 py-4">
                        <button
                            type="submit"
                            className="btn wholesaler-register-button col-xl-4 col-lg-5 align-self-center"
                        >
                            Register
                        </button>
                    </div>
                </div>
            </div>

        </form>
    </div>
    )

}

const WholeSalerPasswordForm = reduxForm({
    form: 'wholeSalerRegisterForm', // a unique identifier for this form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
})(Form);

const selector = formValueSelector('wholeSalerRegisterForm');
const mstp = state => {
    return {
    };
};

export default connect(mstp, null)(WholeSalerPasswordForm);
