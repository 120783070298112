import React, {Component} from 'react';
import PropTypes from "prop-types";
import BrokerageGrid from "./BrokerageGrid";
import {NotificationManager} from "react-notifications";
import {getMe} from "../../../redux/modules/cuenta/login";

class Brokerage extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    state = {
        valueFile: '',
        modal: false,
        photo: '',
        nameFile: '',
        file: null,
        active: true,
        new_file: false,
    };

    static defaultProps = {
        loading: false,
    };

    componentDidMount() {
        this.restartState();
        if (this.props.min_offices === false) {
            this.setState({modal: true})
        }else{
            this.props.toList();
        }
        this.props.getUrlExelTemplate();
        this.props.setItemToNull()
    }

    restartState = () => {
        this.setState({
            modal: false,
            nameFile: '',
            file: null,
            active: true,
            new_file: false,
        });
    };

    setModal = (value) => {
        if (value === false) {
            this.restartState();
        }
        this.setState({modal: value});
    };

    actionFileImg = (data) => {
        this.props.setFiles(data);
    };

    onChanged = (file) => {
        const {setExcel} = this.props;
        this.setState({
            nameFile: file.name,
            file: file,
            active: false,
        });
        setExcel(file);
    };

    setActive = (value) => {
        this.setState({
            active: value,
            file: null,
            nameFile: '',
        });
        const {setExcel} = this.props;
        setExcel([]);
    };

    submitAction = (data) => {
        const {provide_agents_roster, min_offices} = this.props;
        if (provide_agents_roster != false) {
            if (this.state.file) {
                this.props.createAttachment(data, this.restartState, min_offices);
            } else {
                // NotificationManager.error('Agent list is required', 'ERROR', 3000);
                this.props.createAttachment(data, this.restartState, min_offices);
            }
        }else {
            this.props.createAttachment(data, this.restartState, min_offices, provide_agents_roster);
        }
    };

    setNewFileButton = () => {
        this.setState({
            active: true,
            file: null,
            nameFile: '',
            new_file: true,
        });
        const {setExcel} = this.props;
        setExcel([]);
    };

    closeModal = () => {
        this.setState({modal: false});
        this.props.setFiles([])
    }

    render() {
        const {loader, files, page, data, searchChange, setFiles, toList, remove, loader2, urlTemplate, provide_agents_roster, assignAddressData} = this.props;

        return (
            <BrokerageGrid
                textExcel={this.state.nameFile}
                loader={loader}
                data={data}
                page={page}
                setFiles={setFiles}
                searchAction={searchChange}
                toList={toList}
                item={{}}
                setLogo={this.setLogo}
                onSubmit={this.submitAction}
                active={this.state.active}
                setActive={this.setActive}
                new_file={this.state.new_file}
                remove={remove}
                loader2={loader2}
                urlTemplate={urlTemplate}
                closeModal={this.closeModal}
                setModal={this.setModal}
                stateModal={this.state.modal}
                setFilesImg={this.actionFileImg}
                handleOnChangeExcel={this.onChanged}
                setNewFileButton={this.setNewFileButton}
                provideAgentsRoster={provide_agents_roster}
                goToAgents={() => {}}
                assignAddressData={assignAddressData}
            />
        );
    }
}

export default Brokerage;
