import moment from "moment";
import {connect} from "react-redux";
import React, {useState} from "react";
import 'react-responsive-modal/styles.css';
import { reduxForm, Field, formValueSelector } from "redux-form";
import { Modal } from "react-responsive-modal";
import {SelectField, MultiSelectField, renderDayPicker} from "../../../Utils/renderField";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import { SIZE_PER_PAGE, JOB_DETAILS_RAW_DATA_TIME, SELECT_ALL_OPTION } from "../../../../../utility/constants";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import '../../../../../../../node_modules/bootstrap/dist/css/bootstrap.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const currencyFormatted = (cell) => {
    return (
        <p className={"my-0 mx-0 px-0"}>
            $ {parseFloat(cell ? cell : 0).toFixed(2)}
        </p>
    );
};

const PhotoModal = (props) => {
    //  State
    const {open, data} = props;
    //  Bind
    const {onClose} = props;

    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={onClose}
            classNames={{
                modal: "rounded col-lg-5 col-11",
                closeIcon: "bg-gray rounded",
            }}
        >
            <div className="w-100 row py-2 px-0 mx-auto d-flex flex-column align-items-center">
                {/* <img src={url} alt="photo" className="img-fluid rounded mx-auto d-block" style={{width: '600px'}}/> */}
                {data && data.length ? (
                    <Carousel className="w-100">
                        {data.map((item, index) => {
                            return (
                                <div key={index}>
                                    <img src={item.url} className="img-fluid rounded mx-auto d-block"/>
                                    <p className="legend font-weight-bold">{item.name}</p>
                                </div>
                            );
                        })}
                    </Carousel>
                ) : (
                    <h4>No images</h4>
                )}
            </div>
        </Modal>
    );
}

const ApplicantsModal = (props) => {
    //  State
    const {open, data} = props;
    //  Bind
    const {onClose} = props;
    const R_CANCEL = 35;

    const actions = [
        {name: 'Applied', id: 5, bg:'bg-gray', txt: 'text-black'},
        {name: 'Rejected', id: 10, bg:'bg-warning', txt: 'text-white'},
        {name: 'Canceled Application', id: 15, bg:'bg-danger', txt: 'text-white'},
        {name: 'Hired', id: 20, bg:'bg-info', txt: 'text-white'},
        {name: 'Claimed', id: 25, bg:'bg-info', txt: 'text-white'},
        {name: 'Completed', id: 30, bg:'bg-success', txt: 'text-white'},
        {name: 'Canceled', id: 35, bg:'bg-danger', txt: 'text-white'}
    ]

    const getAction = (action) => {
        let result = null
        if (action) {
            const _action = _.find(actions, {id: action})
            if (_action) {
                result = _action
            }
        }

        return result
    }

    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={onClose}
            classNames={{ modal: "rounded col-lg-9 col-11", closeIcon: "bg-gray rounded" }}
        >
            <div className="d-flex flex-column mt-4">
                <div className="page-header pl-1 pt-2 no-gutters row justify-content-between">
                    <div className="">
                        <div className="header-page col">
                            Agents that applied for the job
                        </div>
                    </div>
                </div>

                <BootstrapTable striped remote hover data={data && data.results ? data.results : []}>
                    <TableHeaderColumn
                        dataField="id"
                        isKey={true}
                        width="0px"
                        dataFormat={(cell, row) => {
                            return <div />;
                        }}
                    />
                    <TableHeaderColumn
                        width="150px"
                        dataField="agent__first_name"
                        dataFormat={(cell, row) => {
                            return <span>{row.agent.first_name}</span>;
                        }}
                    >
                        Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="150px"
                        dataField="agent__last_name"
                        dataFormat={(cell, row) => {
                            return <span>{row.agent.last_name}</span>;
                        }}
                    >
                        Last Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="150px"
                        dataField="agent__name"
                        dataFormat={(cell, row) => {
                            return (
                                <span>
                                    {row.agent.brokerage_information
                                        ? row.agent.brokerage_information
                                              .franchise
                                        : ""}
                                </span>
                            );
                        }}
                    >
                        Franchise
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="150px"
                        dataField="agent__name"
                        dataFormat={(cell, row) => {
                            return (
                                <span>
                                    {row.agent.brokerage_information
                                        ? row.agent.brokerage_information
                                              .firm_name
                                        : ""}
                                </span>
                            );
                        }}
                    >
                        Office Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="430px"
                        dataField="id"
                        dataFormat={(cell, row) => {
                            return (
                                <div>
                                    {row.job_history && row.job_history.length ? (
                                        <div className="d-flex align-content-between flex-wrap">
                                            {row.job_history.map((item, index) => {
                                                const _action = getAction(item.action)
                                                if (_action) {
                                                    return (
                                                        <div
                                                            className={`rounded d-flex flex-column justify-content-center align-items-center m-1 p-1 ${_action.bg} ${_action.txt}`}
                                                            style={{
                                                                fontSize: "10px",
                                                                width: '120px'
                                                            }}
                                                        >
                                                            <span className="font-weight-bold">
                                                                {_action.name}
                                                            </span>
                                                            <span className="font-weight-lighter">
                                                                {moment(
                                                                    item.created
                                                                ).format("lll")}
                                                            </span>
                                                        </div>
                                                    );
                                                }
                                                return null
                                            })}
                                            {data && data.state_job == R_CANCEL && (
                                                <div
                                                    className={`rounded d-flex flex-column justify-content-center align-items-center m-1 p-1 bg-danger text-white`}
                                                    style={{
                                                        fontSize: "10px",
                                                        width: '120px'
                                                    }}
                                                >
                                                    <span className="font-weight-bold">
                                                        R-Agent Canceled
                                                    </span>
                                                    <span className="font-weight-lighter">
                                                        {data.cancellation_date ? moment(
                                                            data.cancellation_date
                                                        ).format("lll") : ''}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    ): (
                                        <div>
                                            {row.accepted && !row.reviewed  ? (
                                                <span className="bg-success rounded p-1 font-weight-bold text-white">
                                                    Accepted
                                                </span>
                                            ): null}
                                            {row.canceled ? (
                                                <span className="bg-danger rounded p-1 font-weight-bold text-white">
                                                    Canceled
                                                </span>
                                            ): null}
                                            {row.rejected ? (
                                                <span className="bg-warning rounded p-1 font-weight-bold text-white">
                                                    Rejected
                                                </span>
                                            ): null}
                                            {row.accepted  && row.reviewed ? (
                                                <span className="bg-info rounded p-1 font-weight-bold text-white">
                                                    Reviewed
                                                </span>
                                            ): null}
                                            {!row.accepted && !row.canceled && !row.rejected && !row.reviewed ? (
                                                <span className="bg-gray rounded p-1 font-weight-bold text-black">
                                                    Applied
                                                </span>
                                            ): null}
                                            {data && data.state_job == R_CANCEL && (
                                                <span className="bg-danger rounded p-1 font-weight-bold text-white">
                                                    R-Agent Canceled 
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </div>
                            );
                        }}
                    >
                        Actions
                    </TableHeaderColumn>
                    {/* <TableHeaderColumn
                        width="150px"
                        dataField="id"
                        dataFormat={(cell, row) => {
                            return (
                                <span>{moment(row.modified).format("lll")}</span>
                            );
                        }}
                    >
                        Date & Time
                    </TableHeaderColumn> */}
                </BootstrapTable>
            </div>
        </Modal>
    );
}

const AgentsModal = (props) => {
    //  State
    const {open, data} = props;
    //  Bind
    const {onClose} = props;

    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={onClose}
            classNames={{ modal: "rounded col-lg-6 col-11", closeIcon: "bg-gray rounded" }}
        >
            <div className="d-flex flex-column mt-4">
                <div className="page-header pl-1 pt-2 no-gutters row justify-content-between">
                    <div className="">
                        <div className="header-page col">
                            Agents
                        </div>
                    </div>
                </div>

                <BootstrapTable striped remote hover data={data}>
                    <TableHeaderColumn
                        dataField="agent_id"
                        isKey={true}
                        width="0px"
                        dataFormat={(cell, row) => {
                            return <div />;
                        }}
                    />
                    <TableHeaderColumn
                        width="150px"
                        dataField="first_name"
                    >
                        Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="150px"
                        dataField="last_name"
                    >
                        Last Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="150px"
                        dataField="email"
                    >
                        Email
                    </TableHeaderColumn>
                    
                </BootstrapTable>
            </div>
        </Modal>
    );
}

const CancellationPoliciesModal = (props) => {
    //  State
    const { open } = props;
    //  Bind
    const { onClose } = props;

    const data = [
        {
            id: 1,
            type: 1,
            description:
                "For cancellations at least two (2) hours in advance of the accepted job start time there shall be no cancellation fee",
            agent: "D and R Agent",
        },
        {
            id: 2,
            type: 2,
            description:
                "For job cancellations up to 30 minutes “grace Period” after claiming a job of being hired to a job, the agent can cancel the job without any penalty",
            agent: "D and R Agent",
        },
        {
            id: 3,
            type: 3,
            description:
                "For job cancellation of 2 hours or less in advance of the accepted job start time, and after the “Grace Period” time, Immediate $30 penalty to the cancelled agent ($25 to the cancelling agent and $5 to ShowIT)",
            agent: "D and R Agent",
        },
        {
            id: 4,
            type: 4,
            description:
                "In instances where a job was posted 24 hours or more before the job’s start time and the hiring agent’s decision to hire was four (4) hours or less before the job’s start time, the Showing agent may cancel the job without penalty. ($25 to the cancelling agent and $5 to ShowIT)",
            agent: "D Agent",
        },
        // {
        //     id: 5,
        //     type: 5,
        //     description:
        //         "Jobs posted 24hours or more before job start time and hiring agent took decision to hire 4 hours or less before job start time. If showing agent cancel a job it will be one of the two allowed cancellation during the month",
        //     agent: "D Agent",
        // },
        // {
        //     id: 6,
        //     type: 6,
        //     description:
        //         "Less than (2) hours in advance of the accepted Job start time $30 penalty",
        //     agent: "R Agent",
        // },
        {
            id: 5,
            type: 5,
            description:
                "Job has been cancelled due to Showing agent No Show",
            agent: "R Agent",
        },
        {
            id: 6,
            type: 6,
            description:
                "Job has been cancelled due to showing agent not meeting all job requirements",
            agent: "R Agent",
        },
    ];

    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={onClose}
            classNames={{
                modal: "rounded col-lg-6 col-sm-10",
                closeIcon: "bg-gray rounded",
            }}
        >
            <div className="d-flex flex-column mt-4">
                <div className="page-header pl-1 pt-2 no-gutters row justify-content-between">
                    <div className="">
                        <div className="header-page col">
                            Cancellation Policies
                        </div>
                    </div>
                </div>

                <BootstrapTable striped remote hover data={data}>
                    <TableHeaderColumn
                        dataField="id"
                        isKey={true}
                        width="0px"
                        dataFormat={(cell, row) => {
                            return <div />;
                        }}
                    />
                    <TableHeaderColumn
                        width="50px"
                        dataField="type"
                        dataFormat={(cell, row) => {
                            return (
                                <h5 className="font-weight-bold mb-0">
                                    {cell}
                                </h5>
                            );
                        }}
                    >
                        Type
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="description"
                        dataFormat={(cell, row) => {
                            return <span>{cell}</span>;
                        }}
                    >
                        Description
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="120px"
                        dataField="agent"
                        dataFormat={(cell, row) => {
                            return (
                                <span className="font-weight-bold mb-0">
                                    {cell}
                                </span>
                            );
                        }}
                    >
                        Agent
                    </TableHeaderColumn>
                </BootstrapTable>
            </div>
        </Modal>
    );
};

const ClientReviewModal = (props) => {
    //  State
    const {open, data} = props;
    //  Bind
    const {onClose} = props;
    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={onClose}
            classNames={{ modal: "rounded col-lg-4 col-md-8 col-10", closeIcon: "bg-gray rounded" }}
        >
            <div className="d-flex flex-column mt-4">
                <div className="page-header pl-1 pt-2 no-gutters row justify-content-between mb-3">
                    <div className="">
                        <div className="header-page ml-lg-3 ml-0">
                            Client Review
                        </div>
                    </div>
                </div>
                {data && data.feedback && data.feedback.length ? (
                    data.feedback.map((item, index) => {
                        return (
                            <div className="form-group" key={index}>
                                <label htmlFor="">{item.question}</label>
                                {item.type == 5 ? (
                                    <textarea
                                        style={{ resize: 'none' }}
                                        className="form-control text-area-h-auto"
                                        disabled="true"
                                        value={item.answer}
                                        rows={6}
                                        />
                                ): null}

                                { item.type == 10 ? (
                                    <div className="w-100 d-flex flex-row align-items-center justify-content-center">
                                        <div className="checkbox c-checkbox d-flex flex-column px-3 align-items-center justify-content-center">
                                            <label>YES</label>
                                            <label className="needsclick">
                                                <input
                                                    type="checkbox"
                                                    disabled={true}
                                                    checked={item.answer != 'N/A' ? (item.answer == 'Yes' ? true : false) : null }
                                                />
                                                <span className="fa fa-check"/>
                                                {/* &nbsp;{label} */}
                                            </label>
                                        </div>
                                        <div className="checkbox c-checkbox d-flex flex-column px-3 align-items-center justify-content-center ">
                                            <label>No</label>
                                            <label className="needsclick">
                                                <input
                                                    type="checkbox"
                                                    disabled={true}
                                                    checked={item.answer != 'N/A' ? (item.answer == 'No' ? true : false) : null }
                                                />
                                                <span className="fa fa-check"/>
                                                {/* &nbsp;{label} */}
                                            </label>
                                        </div>
                                    </div>
                                ): null}
                            </div>
                        )
                    })
                ): null}
            </div>
        </Modal>
    )
}

const RowDetail = (props) => {
    const {viewPhoto} = props;
    return (
        <BootstrapTable containerClass="row-detail" data={props.data}>
            <TableHeaderColumn
                dataField="id"
                isKey={true}
                width="0px"
                dataFormat={(cell, row) => {
                    return <div />;
                }}
            />
            <TableHeaderColumn width="150px" dataField="state">
                State
            </TableHeaderColumn>
            <TableHeaderColumn width="150px" dataField="city">
                City
            </TableHeaderColumn>
            <TableHeaderColumn width="450px" dataField="address">
                Address
            </TableHeaderColumn>
            <TableHeaderColumn width="150px" dataField="zip_code">
                Zip Code
            </TableHeaderColumn>
            <TableHeaderColumn
                width="250px"
                dataField="confirm_arrival"
                dataFormat={(cell, row) => {
                    let _listImages = [];
                    if (row.confirm_arrival && row.confirm_arrival.photo) {
                        _listImages.push({
                            name: "Confirm arrival",
                            url: row.confirm_arrival.photo,
                        });
                    }
                    return (
                        <div className="w-100 d-flex">
                            <div className="d-flex flex-row align-items-center py-1">
                                {_listImages.length ? (
                                    <button
                                        className="btn btn-link d-flex align-items-center justify-content-center mr-2"
                                        style={{
                                            minWidth: "1.6rem",
                                            maxWidth: "1.6rem",
                                            minHeight: "1.6rem",
                                            maxHeight: "1.6rem",
                                        }}
                                        onClick={() => viewPhoto(_listImages)}
                                    >
                                        <i className="fa fa-image"></i>
                                    </button>
                                ) : null}
                                {row.confirm_arrival ? (
                                    <b className="font-weight-bold">Yes</b>
                                ) : (
                                    row.was_not_shown ? null : (
                                        <b className="font-weight-bold">No</b>
                                    )
                                )}
                                {row.confirm_arrival &&
                                row.confirm_arrival.created ? (
                                    <div className="d-flex flex-row">
                                        <p className="mx-2 mb-0">-</p>
                                        <p className="mb-0">
                                            {moment(
                                                row.confirm_arrival.created
                                            ).format("lll")}
                                        </p>
                                    </div>
                                ) : null}
                                {row.was_not_shown && (
                                    <span className="text-dark text-small-chart p-1 font-weight-bold rounded bg-warning">Was Not Shown</span>
                                )}
                            </div>
                        </div>
                    );
                }}
            >
                Confirm Arrival
            </TableHeaderColumn>

            <TableHeaderColumn
                width="250px"
                dataField="confirm_departure"
                dataFormat={(cell, row) => {
                    let _listImages = [];
                    if (row.confirm_departure && row.confirm_departure.photo) {
                        _listImages.push({
                            name: "Confirm departure",
                            url: row.confirm_departure.photo,
                        });
                    }
                    return (
                        <div className="w-100 d-flex">
                            <div className="d-flex flex-row align-items-center py-1">
                                {_listImages.length ? (
                                    <button
                                        className="btn btn-link d-flex align-items-center justify-content-center mr-2"
                                        style={{
                                            minWidth: "1.6rem",
                                            maxWidth: "1.6rem",
                                            minHeight: "1.6rem",
                                            maxHeight: "1.6rem",
                                        }}
                                        onClick={() => viewPhoto(_listImages)}
                                    >
                                        <i className="fa fa-image"></i>
                                    </button>
                                ) : null}
                                {row.confirm_departure ? (
                                    <b className="font-weight-bold">Yes</b>
                                ) : (
                                    <b className="font-weight-bold">No</b>
                                )}
                                {row.confirm_departure && row.confirm_departure.created ? (
                                    <div className="d-flex flex-row">
                                        <p className="mx-2 mb-0">-</p>
                                        <p className="mb-0">
                                            {moment(
                                                row.confirm_departure.created
                                            ).format("lll")}
                                        </p>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    );
                }}
            >
                Confirm Departure
            </TableHeaderColumn>
            <TableHeaderColumn
                width="250px"
                dataField="feedback"
                dataFormat={(cell, row) => {
                    return (
                        <div className="w-100 d-flex">
                            <div className="d-flex flex-row align-items-center py-1">
                                {row.feedback ? (
                                    <b className="font-weight-bold">Yes</b>
                                ): (
                                    <b className="font-weight-bold">No</b>
                                )}
                                {(row.feedback && row.feedback.created) ?
                                    (
                                        <div className="d-flex flex-row">
                                            <p className="mx-2 mb-0">-</p>
                                            <p className="mb-0">{moment(row.feedback.created).format("lll")}</p>
                                        </div>
                                    ): null
                                }
                            </div>
                        </div>
                    );
                }}
            >
                Submit Feedback
            </TableHeaderColumn>
            <TableHeaderColumn
                width="250px"
                dataField="confirm_installation"
                dataFormat={(cell, row) => {
                    let _listImages = [];
                    if (row.confirm_installation && row.confirm_installation.length) {
                        row.confirm_installation.forEach(item => {
                            _listImages.push({
                                name: item.name != "" ? item.name : "Confirm Install",
                                url: item.photo,
                            });
                        })
                    }
                    return (
                        <div className="w-100 d-flex">
                            <div className="d-flex flex-row align-items-center py-1">
                                {_listImages.length ? (
                                    <button
                                        className="btn btn-link d-flex align-items-center justify-content-center mr-2"
                                        style={{
                                            minWidth: "1.6rem",
                                            maxWidth: "1.6rem",
                                            minHeight: "1.6rem",
                                            maxHeight: "1.6rem",
                                        }}
                                        onClick={() => viewPhoto(_listImages)}
                                    >
                                        <i className="fa fa-image"></i>
                                    </button>
                                ) : null}
                                {_listImages.length ? (
                                    <b className="font-weight-bold">Yes</b>
                                ) : (
                                    <b className="font-weight-bold">No</b>
                                )}
                                {_listImages.length && row.confirm_installation[0].created ? (
                                    <div className="d-flex flex-row">
                                        <p className="mx-2 mb-0">-</p>
                                        <p className="mb-0">
                                            {moment(
                                                row.confirm_installation[0].created
                                            ).format("lll")}
                                        </p>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    );
                }}
            >
                Confirm Install
            </TableHeaderColumn>
            <TableHeaderColumn
                width="250px"
                dataField="confirm_drop_off"
                dataFormat={(cell, row) => {
                    let _listImages = [];
                    if (row.confirm_drop_off && row.confirm_drop_off.photo) {
                        _listImages.push({
                            name: "Confirm Drop Off",
                            url: row.confirm_drop_off.photo,
                        });
                    }
                    return (
                        <div className="w-100 d-flex">
                            <div className="d-flex flex-row align-items-center py-1">
                                {_listImages.length ? (
                                    <button
                                        className="btn btn-link d-flex align-items-center justify-content-center mr-2"
                                        style={{
                                            minWidth: "1.6rem",
                                            maxWidth: "1.6rem",
                                            minHeight: "1.6rem",
                                            maxHeight: "1.6rem",
                                        }}
                                        onClick={() => viewPhoto(_listImages)}
                                    >
                                        <i className="fa fa-image"></i>
                                    </button>
                                ) : null}
                                {row.confirm_drop_off ? (
                                    <b className="font-weight-bold">Yes</b>
                                ) : (
                                    <b className="font-weight-bold">No</b>
                                )}
                                {row.confirm_drop_off && row.confirm_drop_off.created ? (
                                    <div className="d-flex flex-row">
                                        <p className="mx-2 mb-0">-</p>
                                        <p className="mb-0">
                                            {moment(
                                                row.confirm_drop_off.created
                                            ).format("lll")}
                                        </p>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    );
                }}
            >
                Confirm Drop Off
            </TableHeaderColumn>

            {/* <TableHeaderColumn width="150px" dataField="zip_code">Zip Code</TableHeaderColumn> */}
            <TableHeaderColumn dataField="zip_code2"></TableHeaderColumn>
        </BootstrapTable>
    );
};

export const JobDetailRawDataGrid = (props) => {
    const [photoModal, setPhotoModal] = useState(false);
    const [listImages, setListImages] = useState([]);
    //  Applicants
    const [applicantsModal, setApplicantsModal] = useState(false);
    const [applicantsData, setApplicantsData] = useState(null);
    //  Cancellation Policies
    const [cancellationPoliciesModal, setCancellationPoliciesModal] = useState(false);
    //  Others
    const [agentsNewJobNotificationModal, setAgentsNewJobNotificationModal] = useState(false);
    const [agentsNewJobNotification, setAgentsNewJobNotification] = useState([]);
    //  Client review
    const [showClientReviewModal, setShowClientReviewModal] = useState(false);
    const [clientReviewData, setClientReviewData] = useState(null);


    const { loader = false, data, nextPage, page, paymentThroughBroker, changeJobTransferType, } = props;
    const tableOptions = {
        sizePerPage: SIZE_PER_PAGE,
        hideSizePerPage: true,
        alwaysShowAllBtns: true,
        noDataText: "No data",
        page,
        onPageChange: nextPage,
    };
    
    const viewPhoto = (list) => {
        setListImages(list)
        setTimeout(() => {
            setPhotoModal(true)
        }, 300);
    }
    
    const closeViewPhoto = () => {
        setListImages([])
        setPhotoModal(false);
    }

    const openApplicantsModal = (data, results) => {
        setApplicantsData({...data, results})
        setTimeout(() => {
            setApplicantsModal(true)
        }, 300);
    }
    
    const closeApplicantsModal = (data) => {
        setApplicantsData(null)
        setTimeout(() => {
            setApplicantsModal(false)
        }, 300);
    }

    const isExpandableRow = (row) => {
        if (row.job_description && row.job_description.length > 0) return true;
        else return false;
    };

    const expandComponent = (row) => {
        return (
            <RowDetail
                data={row.job_description}
                viewPhoto={viewPhoto}
            />
        );
    };

    const openAgentsModal = (data) => {
        setAgentsNewJobNotification(data)
        setTimeout(() => {
            setAgentsNewJobNotificationModal(true)
        }, 300);
    }

    const closeAgentsModal = (data) => {
        setAgentsNewJobNotification([])
        setTimeout(() => {
            setAgentsNewJobNotificationModal(false)
        }, 300);
    }

    const openClientReviewModal = (data) => {
        setClientReviewData(data)
        setTimeout(() => {
            setShowClientReviewModal(true)
        }, 300);
    }
    
    const closeClientReviewModal = () => {
        setShowClientReviewModal(false)
        setClientReviewData(null)
    }


    return (
        <div className="pt-2 px-4">
            <LoadMask loading={loader} dark blur>
                <BootstrapTable
                    data={loader ? [] : data.results ? data.results : []}
                    striped
                    remote
                    pagination
                    hover
                    fetchInfo={{ dataTotalSize: data.count }}
                    options={tableOptions}
                    expandableRow={isExpandableRow}
                    expandComponent={expandComponent}
                    expandColumnOptions={{ expandColumnVisible: true }}
                    tableContainerClass="table-vertical-border"
                >
                    <TableHeaderColumn
                        rowSpan="2"
                        dataField="job_no"
                        width="80px"
                        dataAlign="right"
                    >
                        Work number
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        rowSpan="2"
                        dataField="id"
                        isKey
                        width="80px"
                        dataAlign="right"
                    >
                        Job #
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        rowSpan="2"
                        dataField="status"
                        width="150px"
                        dataFormat={(cell, row) => {
                            return (
                                <div className="d-flex flex-row">
                                    <button
                                        title="Agents"
                                        className="btn btn-light d-flex align-items-center justify-content-center mr-2"
                                        style={{
                                            minWidth: "1.6rem",
                                            maxWidth: "1.6rem",
                                            minHeight: "1.6rem",
                                            maxHeight: "1.6rem",
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            openApplicantsModal({state_job: row.state_job, cancellation_date: row.cancellation_date}, row.applicants);
                                        }}
                                    >
                                        <i className="fa fa-users text-success"></i>
                                    </button>

                                    <span>{cell}</span>
                                </div>
                            );
                        }}
                    >
                        Job Status
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        rowSpan="2"
                        dataField="job_type__name"
                        width="190px"
                        dataFormat={(cell, row) => {
                            return (
                                <div className="w-100 d-flex">
                                    {row.job_type ? row.job_type.name : null}
                                </div>
                            );
                        }}
                    >
                        Job Type
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        rowSpan="2"
                        dataField="job_sub_type"
                        width="190px"
                    >
                        Job Sub Type
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        rowSpan="2"
                        dataField="created"
                        width="150px"
                        dataFormat={(cell, row) => {
                            return (
                                <div>{cell && moment(cell).format("lll")}</div>
                            );
                        }}
                    >
                        Job Posted Date & Time
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        rowSpan="2"
                        dataField="first_applied__created"
                        width="150px"
                        dataFormat={(cell, row) => {
                            return (
                                <div>
                                    {row.first_applied &&
                                        moment(
                                            row.first_applied.created
                                        ).format("lll")}
                                </div>
                            );
                        }}
                    >
                        First Applied Date & Time
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        rowSpan="2"
                        dataField="claimed_hired__created"
                        width="150px"
                        dataFormat={(cell, row) => {
                            return (
                                <div>
                                    {row.claimed_hired &&
                                        moment(
                                            row.claimed_hired.created
                                        ).format("lll")}
                                </div>
                            );
                        }}
                    >
                        Job Claimed/Hired Date & Time
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        row="0"
                        rowSpan="2"
                        dataField="start_date"
                        width="150px"
                        dataFormat={(cell, row) => {
                            return (
                                <div>{cell && moment(cell).format("lll")}</div>
                            );
                        }}
                    >
                        Job Start Date & Time
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        row="0"
                        rowSpan="2"
                        dataField="date_completed"
                        width="150px"
                        dataFormat={(cell, row) => {
                            return (
                                <div>{cell && moment(cell).format("lll")}</div>
                            );
                        }}
                    >
                        Job Completed Date & Time
                    </TableHeaderColumn>

                    {/* HIRING AGENT INFO */}
                    <TableHeaderColumn row="0" colSpan="4" headerAlign="center">
                        Hiring Agent Info
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        row="1"
                        dataField="hiring_agent_info__first_name"
                        dataFormat={(cell, row) => {
                            return (
                                <div>
                                    {row.hiring_agent_info
                                        ? row.hiring_agent_info.first_name
                                        : ""}
                                </div>
                            );
                        }}
                        width="150px"
                    >
                        Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        row="1"
                        dataField="hiring_agent_info__last_name"
                        dataFormat={(cell, row) => {
                            return (
                                <div>
                                    {row.hiring_agent_info
                                        ? row.hiring_agent_info.last_name
                                        : ""}
                                </div>
                            );
                        }}
                        width="150px"
                    >
                        Last Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        row="1"
                        dataField="hiring_agent_info__franchise"
                        dataFormat={(cell, row) => {
                            return (
                                <div>
                                    {row.hiring_agent_info
                                        ? row.hiring_agent_info.franchise
                                        : ""}
                                </div>
                            );
                        }}
                        width="190px"
                    >
                        Fanchise
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        row="1"
                        dataField="hiring_agent_info__office_name"
                        dataFormat={(cell, row) => {
                            return (
                                <div>
                                    {row.hiring_agent_info
                                        ? row.hiring_agent_info.office_name
                                        : ""}
                                </div>
                            );
                        }}
                        width="190px"
                    >
                        Office Name
                    </TableHeaderColumn>

                    {/* SHOWING AGENT INFO */}
                    <TableHeaderColumn
                        row="0"
                        colSpan="4"
                        headerAlign="center"
                        width="150px"
                    >
                        Showing Agent info
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        row="1"
                        dataField="showing_agent_info__first_name"
                        dataFormat={(cell, row) => {
                            return (
                                <div>
                                    {row.showing_agent_info
                                        ? row.showing_agent_info.first_name
                                        : ""}
                                </div>
                            );
                        }}
                        width="150px"
                    >
                        Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        row="1"
                        dataField="showing_agent_info__last_name"
                        dataFormat={(cell, row) => {
                            return (
                                <div>
                                    {row.showing_agent_info
                                        ? row.showing_agent_info.last_name
                                        : ""}
                                </div>
                            );
                        }}
                        width="150px"
                    >
                        Last Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        row="1"
                        dataField="showing_agent_info__franchise"
                        dataFormat={(cell, row) => {
                            return (
                                <div>
                                    {row.showing_agent_info
                                        ? row.showing_agent_info.franchise
                                        : ""}
                                </div>
                            );
                        }}
                        width="190px"
                    >
                        Fanchise
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        row="1"
                        dataField="showing_agent_info__office_name"
                        dataFormat={(cell, row) => {
                            return (
                                <div>
                                    {row.showing_agent_info
                                        ? row.showing_agent_info.office_name
                                        : ""}
                                </div>
                            );
                        }}
                        width="190px"
                    >
                        Office Name
                    </TableHeaderColumn>

                    {/* CANCELLATION FEE */}
                    <TableHeaderColumn
                        row="0"
                        colSpan="4"
                        headerAlign="center"
                        width="150px"
                    >
                        Cancellation
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        row="1"
                        dataField="cancellation_by"
                        width="150px"
                    >
                        Job Cancellation by
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        row="1"
                        dataField="canceled_at"
                        width="150px"
                        dataFormat={(cell, row) => {
                            return (
                                <div>{cell && moment(cell).format("lll")}</div>
                            );
                        }}
                    >
                        Job Canceled at
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        row="1"
                        dataField="cancellation_reason"
                        width="200px"
                    >
                        Reason of Cancellation
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        row="1"
                        dataField="cancellation_type"
                        width="150px"
                        header
                    >
                        <div
                            style={{ cursor: "pointer" }}
                            className="d-flex flex-row align-items-center"
                            onClick={(e) => {
                                e.stopPropagation();
                                setCancellationPoliciesModal(true);
                            }}
                        >
                            <i
                                className="fa fa-info-circle text-info mr-2"
                                style={{ fontSize: "20px" }}
                            ></i>
                            Cancellation Type (1,2,3)
                        </div>
                    </TableHeaderColumn>

                    {/* NO SHOW FEE */}
                    <TableHeaderColumn
                        rowSpan="2"
                        dataField="no_show_by"
                        width="150px"
                    >
                        No Show By
                    </TableHeaderColumn>
                    

                    {/* SHOWIT AND BROKERS PROFIT */}
                    <TableHeaderColumn
                        row="0"
                        colSpan="2"
                        headerAlign="center"
                        width="150px"
                    >
                        ShowIT and Brokers Profit
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        row="1"
                        dataAlign="right"
                        dataField="broker_hiring_agent"
                        width="150px"
                        dataFormat={currencyFormatted}
                    >
                        Broker Profit Hiring Agent
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        row="1"
                        dataAlign="right"
                        dataField="broker_showing_agent"
                        width="150px"
                        dataFormat={currencyFormatted}
                    >
                        Broker Profit Showing Agent
                    </TableHeaderColumn>

                    {/* Feedback */}
                    <TableHeaderColumn
                        row="0"
                        colSpan="2"
                        headerAlign="center"
                        width="150px"
                    >
                        Feedback
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        row="1"
                        dataAlign="center"
                        dataField="agent_review"
                        width="150px"
                        dataFormat={(cell, row) => {
                            return <span>{cell ? "Yes" : "No"}</span>;
                        }}
                    >
                        Agent review
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        row="1"
                        dataAlign="right"
                        dataField="client_review"
                        width="150px"
                        dataFormat={(cell, row) => {
                            return (
                                <div className="d-flex flex-row align-items-center justify-content-center py-1 w-100">
                                    <span>{cell ? "Yes" : "No"}</span>
                                    {cell ? (
                                        <button
                                            className="btn btn-link d-flex align-items-center justify-content-center ml-3"
                                            style={{
                                                minWidth: "1.6rem",
                                                maxWidth: "1.6rem",
                                                minHeight: "1.6rem",
                                                maxHeight: "1.6rem",
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                openClientReviewModal({ feedback: row.customer_feedback })
                                            }}
                                        >
                                            <i className="fa fa-eye"></i>
                                        </button>
                                    ): null}
                                </div>
                            )
                        }}
                    >
                        Client Review
                    </TableHeaderColumn>

                    {/* CLIENT ARRIVAL */}
                    <TableHeaderColumn
                        row="0"
                        colSpan="1"
                        headerAlign="center"
                        width="150px"
                    >
                        Client Arrival
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        row="1"
                        dataAlign="center"
                        dataField="has_client_arrived"
                        width="150px"
                        dataFormat={(cell, row) => {
                            return <span>{cell ? "Yes" : "No"}</span>;
                        }}
                    >
                        Did the Client / Vendor arrived
                    </TableHeaderColumn>
                    {/* Total Agents with New Job Notification */}
                    <TableHeaderColumn
                        row="0"
                        colSpan="2"
                        headerAlign="center"
                        width="150px"
                    >
                        Total Agents with New Job Notification
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        row="1"
                        dataAlign="right"
                        dataField="agents_new_job_notification_working_area"
                        width="150px"
                        dataFormat={(cell, row) => {
                            const _agents =
                                row.agents_new_job_notification &&
                                row.agents_new_job_notification.working_area
                                    ? row.agents_new_job_notification
                                          .working_area
                                    : [];
                            return (
                                <div className="d-flex flex-row">
                                    <button
                                        title="Agents"
                                        className="btn btn-light d-flex align-items-center justify-content-center mr-2"
                                        style={{
                                            minWidth: "1.6rem",
                                            maxWidth: "1.6rem",
                                            minHeight: "1.6rem",
                                            maxHeight: "1.6rem",
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            openAgentsModal(_agents);
                                        }}
                                    >
                                        <i className="fa fa-users text-info"></i>
                                    </button>

                                    <span>
                                        {_agents.length}
                                    </span>
                                </div>
                            );
                        }}
                    >
                        Working area
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        row="1"
                        dataAlign="right"
                        dataField="agents_new_job_notification_follower"
                        width="150px"
                        dataFormat={(cell, row) => {
                            const _agents =
                                row.agents_new_job_notification &&
                                row.agents_new_job_notification.follower
                                    ? row.agents_new_job_notification.follower
                                    : [];
                            return (
                                <div className="d-flex flex-row">
                                    <button
                                        title="Agents"
                                        className="btn btn-light d-flex align-items-center justify-content-center mr-2"
                                        style={{
                                            minWidth: "1.6rem",
                                            maxWidth: "1.6rem",
                                            minHeight: "1.6rem",
                                            maxHeight: "1.6rem",
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            openAgentsModal(_agents);
                                        }}
                                    >
                                        <i className="fa fa-users text-info"></i>
                                    </button>

                                    <span>
                                        {_agents.length}
                                    </span>
                                </div>
                            );
                        }}
                    >
                        Followers
                    </TableHeaderColumn>
                    {paymentThroughBroker ? (
                        <TableHeaderColumn
                            rowSpan="2"
                            dataAlign="center"
                            dataField="transfer_type"
                            width="150px"
                            dataFormat={(cell, row) => {
                                console.log("CELL HOLD: ", cell);
                                let disabled = false
                                const RELEASE = 100
                                const HOLD = 200
                                let transferType = HOLD
                                if (cell == RELEASE) {
                                    transferType = HOLD
                                } else {
                                    transferType = RELEASE
                                }
                                if (row.state_job == 35) {
                                    if (row.cancellation_transferred_from_broker) {
                                        disabled = true
                                    }
                                } else {
                                    if (row.transferred_from_broker) {
                                        disabled = true
                                    }
                                }

                                return (
                                    <div className="d-flex flex-row">
                                        <button
                                            disabled={disabled}
                                            title="Hold / Release"
                                            className="btn btn-danger d-flex align-items-center justify-content-center mr-2"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                changeJobTransferType({id: row.id, transfer_type: transferType});
                                            }}
                                        >
                                            {cell == 100 ? "HOLD": "RELEASE"}
                                        </button>
                                    </div>
                                );
                            }}
                        >
                            Hold / Release
                        </TableHeaderColumn>
                    ): null}
                </BootstrapTable>
            </LoadMask>
            <PhotoModal
                open={photoModal && listImages}
                data={listImages}
                onClose={closeViewPhoto}
            />

            <ApplicantsModal
                open={applicantsModal && applicantsData}
                data={applicantsData}
                onClose={closeApplicantsModal}
            />

            <AgentsModal
                open={agentsNewJobNotificationModal}
                data={agentsNewJobNotification}
                onClose={closeAgentsModal}
            />

            <CancellationPoliciesModal
                open={cancellationPoliciesModal}
                onClose={() => setCancellationPoliciesModal(false)}
            />

            <ClientReviewModal
                open={showClientReviewModal && clientReviewData}
                data={clientReviewData}
                onClose={closeClientReviewModal}
            />
        </div>
    );
};

const FilterContainer = (props) => {
    //  State
    const {downloadLoader, timeValue, end_dateValue, start_dateValue} = props;
    //  Bind
    const {setStatusFilter, downloadJobDetailsRawDataReport, setTimeFilter} = props;

    // STATUS FILTER
    const JOB_STATUS = [
        // {label: 'All', value: ''},
        {label: 'Posted', value: 5},
        {label: 'Complete', value: 'complete'},
        {label: 'Expired', value: 55},
        {label: 'Cancelled', value: 35},
        {label: 'No Show', value: 'no_show'},
        {label: 'Hired/Claimed', value: 25},
    ]

    return (
        <div className="d-flex align-items-center justify-content-end mt-2 w-100 mb-3">
            <div className="form-group has-feedback col-lg-2 col-sm-12 col-md-6">
                <label htmlFor="filter_category">Job Status</label>
                <Field
                    name="jobStatus"
                    // isMulti={false}
                    options={JOB_STATUS}
                    set_value={setStatusFilter}
                    component={MultiSelectField}
                    className="form-control"
                />
            </div>
            <div className="form-group has-feedback col-lg-2 col-sm-12 col-md-6">
                <label htmlFor="time">Time</label>
                <Field
                    name="time"
                    isMulti={false}
                    options={JOB_DETAILS_RAW_DATA_TIME}
                    set_value={(option) => {
                        setTimeFilter(option ? option.value : "")
                    }}
                    component={SelectField}
                    className="form-control"
                    disabled={false}
                />
            </div>
            {timeValue == 'custom' && (
                <React.Fragment>
                    <div className="col-lg-2 col-12">
                        <div className="form-group has-feedback w-100">
                            <label htmlFor="start_date">Start Date</label>
                            <Field
                                name="start_date"
                                initialDate={moment().format('YYYY-MM-DD').toString()}
                                component={renderDayPicker}
                                maxDate={end_dateValue ? moment(end_dateValue).toDate() : new Date()}
                                onChange={(e) => {
                                    setTimeFilter("")
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-lg-2 col-12">
                        <div className="form-group has-feedback w-100">
                            <label htmlFor="end_date">End Date</label>
                            <Field
                                name="end_date"
                                initialDate={moment().format('YYYY-MM-DD').toString()}
                                component={renderDayPicker}
                                minDate={start_dateValue ? moment(start_dateValue).toDate() : new Date()}
                                onChange={(e) => {
                                    setTimeFilter("")
                                }}
                            />
                        </div>
                    </div>
                </React.Fragment>
            )}
            <button className="btn btn-primary text-white col-lg-2 col-sm-10 h-75"
                disabled={downloadLoader === true}
                onClick={downloadJobDetailsRawDataReport}>
                <i className={`fa ${!downloadLoader ? "fa-download" : "fa-spinner fa-pulse fa-fw"} mr-2`} />
                Download Report
            </button>
        </div>
    )
}

// export default reduxForm({
//     form: 'jobDetailsRawDataForm', // a unique identifier for this form
// })(FilterContainer);

const selector = formValueSelector('jobDetailsRawDataForm');

let JobDetailsRawDataForm = reduxForm({
    form: "jobDetailsRawDataForm", // a unique identifier for this form
    initialValues: {'jobStatus': SELECT_ALL_OPTION.value}
})(FilterContainer);

const mstp = state => {
    const timeValue = selector(state, 'time');
    const start_dateValue = selector(state, 'start_date');
    const end_dateValue = selector(state, 'end_date');

    return {
        timeValue,
        start_dateValue,
        end_dateValue,
    };
};

export default connect(mstp, null)(JobDetailsRawDataForm);