import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/cuenta/whole_saler_register';
import WholeSalerRegister from './WholeSalerRegister';


const ms2p = (state) => {
  return {
    ...state.wholeSalerRegister
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(WholeSalerRegister);