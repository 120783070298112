import {Collapse} from 'reactstrap';
import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {
    PENDING,
} from "../../../../utility/constants";
import {Redirect} from "react-router";

class InfluencerSideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            isStats: false,
        };
    }

    toggle = (isStats = false) => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
            isStats: isStats,
        });
    };

    customClick = () => {
        this.toggle(true);
        // this.props.navToggle();
    };

    customClickNav = () => {
        if (this.state.dropdownOpen) {
            this.toggle(false);
        }
        this.setState({isStats: false});
        this.props.navToggle();
    };

    validateLocation = () => {
        if (this.props.url) {
            const path = this.props.url
            if (path === '/influencer/profile') {
                console.log('')
            } else {
                return <Redirect to="/page-not-found"/>
            }
        }
    }

    render() {
        const {toggleOpen, navToggle, user, textColor, url} = this.props;
        return (
            <aside className={`main-sidebar px-0 col-md-3 col-lg-x-2 ${toggleOpen ? '' : 'open'}`}>
                {/* {this.validateLocation()} */}
                <div className="main-navbar my-0 py-0">
                    <nav className="align-items-stretch flex-md-nowrap navbar mb-5 pt-0">
                        <a href="#" className="w-100 h-100">
                            <div className="d-table m-auto">
                                <img id="main-logo"
                                     className="d-inline-block align-top mx-2 p-0 "
                                     src={require('assets/img/logo-01.png')}
                                     alt="Logo"/>
                            </div>
                        </a>
                        <a
                            className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
                            onClick={this.customClickNav}>
                            <i className="material-icons"></i>
                        </a>
                    </nav>
                </div>
                <div className="nav-wrapper mt-4">
                    <ul className="nav--no-borders flex-column nav">
                        <li className="nav-item" onClick={this.customClickNav}>
                            <NavLink
                                to="/influencer/profile"
                                exact
                                className="nav-link"
                                activeClassName={'active text-dark'}
                            >
                                <i className="material-icons mr-2">account_box</i>
                                Profile
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={this.customClickNav}>
                            <NavLink
                                to="/influencer/leading-list"
                                exact
                                className="nav-link"
                                activeClassName={'active text-dark'}
                            >
                                <i className="material-icons mr-2">person_add</i>
                                Sponsor List
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={this.customClickNav}>
                            <NavLink
                                to="/influencer/agents"
                                exact
                                className="nav-link"
                                activeClassName={'active text-dark'}
                            >
                                <i className="material-icons mr-2">account_box</i>
                                Agents
                            </NavLink>
                        </li>
                        <li
                            className="nav-item text-danger"
                            onClick={this.customClickNav}
                        >
                            <NavLink
                                to="/wallet"
                                className={`nav-link ${!user.stripe_active && 'text-danger font-weight-bold'}`}
                                activeClassName={'active text-white'}
                            >
                                <i className={`material-icons mr-2 ${!user.stripe_active && 'text-danger'}`}>account_balance_wallet</i>
                                Wallet
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={this.customClickNav}>
                            <NavLink
                                to="/influencer/help-and-support"
                                exact
                                className="nav-link"
                                activeClassName={'active text-dark'}
                            >
                                <i className="material-icons mr-2">contact_support</i>
                                Help & Support
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </aside>
        );
    }
}

export default InfluencerSideBar;
