import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Grid from './AgentsGrid'
import UnregisteredGrid from './UnregisteredAgentsGrid'
import Tabs, {TabPane, TabContent} from 'rc-tabs';
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import InfluencerAgentFiltersForm from './AgentsFilters'

class AgentsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabContainerHeight: '200px',
        }
    }

    componentDidMount() {
        setTimeout(() => {
            const tabContainerHeight = document.getElementById('AGENT-TAB-CONTAINER').clientHeight;
            this.setState({tabContainerHeight})
            this.props.toList()
            this.props.toListUnregister()
        }, 300)
    }

    render() {
        //  State
        const {data, page, loader, tab, dataUnregister, pageUnregister} = this.props;
        //  Bind
        const {toList, changeFilters, setTab, toListUnregister} = this.props
        return (
            <div id="PAGE-CONTAINER">
                <div
                    id="INFLUENCER-AGENTS-TITLE"
                    className="page-header pl-1 pt-3 no-gutters row w-100"
                >
                    <div>
                        <div className="header-page">Agents</div>
                    </div>
                </div>
                <Tabs
                    activeKey={tab}
                    defaultActiveKey="Registered"
                    tabBarPosition="top"
                    onChange={(key) => setTab(key)}
                    renderTabBar={() => <ScrollableInkTabBar/>}
                    renderTabContent={() => <TabContent/>}
                >
                    <TabPane tab="Users" key="Registered" className="py-0 d-flex flex-column">
                        <div className="px-3">
                            <InfluencerAgentFiltersForm
                                changeFilters={changeFilters}
                            />
                        </div>
                        <div id="AGENT-TAB-CONTAINER" className="flex-1 px-3 pt-2 mb-1 d-flex flex-column" style={{position: 'relative'}}>
                            {/* <AgentRegistered tabContainerHeight={this.state.tabContainerHeight}/> */}
                            <Grid data={data} page={page} loader={loader} toList={toList} tabContainerHeight={this.state.tabContainerHeight}/>
                        </div>
                    </TabPane>
                    <TabPane tab="Unregistered" key="Unregistered" className="py-0 d-flex flex-column">
                        <div className="px-3">
                            <InfluencerAgentFiltersForm
                                changeFilters={changeFilters}
                            />
                        </div>
                        <div id="AGENT-TAB-CONTAINER" className="flex-1 px-3 pt-2 mb-1 d-flex flex-column" style={{position: 'relative'}}>
                            <UnregisteredGrid data={dataUnregister} page={pageUnregister} loader={loader} toList={toListUnregister} tabContainerHeight={this.state.tabContainerHeight} />
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}

AgentsList.propTypes = {

}

export default AgentsList