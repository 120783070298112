import '../register.css';
import React, {useEffect} from 'react';
import {Link} from "react-router-dom";

const StepFinish = () => {

    useEffect(() => {
        if (document.getElementById('BROKER-REGISTER')) {
            document.getElementById('BROKER-REGISTER').scrollIntoView({ behavior: 'smooth' });
        }
    }, [])

    return (
        <div>
            <div className=" w-75 mx-auto text-center mt-3 mt-md-5">
                <h5 className="broker-register-label-title mt-n2 mb-5">
                    Thank you for registering!
                </h5>
                {/* <p className="text-login">
                    By registering on ShowIt Solutions™ platform, you open a
                    world of opportunities for your agents allowing your top
                    agents be more productive by leveraging their time and
                    resources, while your newer agents can gain valuable
                    experience from day 1. At the same time, you can generate
                    stream of passive income for your brokerage.
                </p>
                <p className="py-0 my-0 font-weight-bold h5 mb-2">Next step</p> */}
                <p className="broker-register-label-subtitle mb-4">
                    Please allow up to 24 hours to activate your account
                </p>
                <p className="broker-register-label-subtitle ">
                    Following the activation of your account, anticipate receiving an email that includes the login credentials for your ShowIT Solutions Broker's Admin Panel.
                </p>
            </div>

            <div className="buttons-box w-75  px-5 mt-5 mx-auto ">
                <Link
                    to="/login"
                    className="btn broker-register-button col-xl-4 col-lg-5 align-self-center"
                >
                    Login
                </Link>
            </div>
        </div>
    );
};

export default StepFinish;
