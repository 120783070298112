import React, {useEffect} from 'react';
import SignupFormPhone from "./SignupFormPhone";
import ValidateCode from "./ValidateCode";

const SignupFormStep3 = (props) => {

    const {onSubmitStep, validateCode, code, stepBack, resendCode } = props;

    useEffect(() => {
        if (document.getElementById('BROKER-REGISTER')) {
            document.getElementById('BROKER-REGISTER').scrollIntoView({ behavior: 'smooth' });
        }
    }, [])

    return (
        <div className="row flex-1 justify-content-center pt-3">
            <div className="w-100 col-xl-10 col-lg-11 col-md-11 col-12 d-flex flex-column">
                <div className="flex-1 d-flex flex-column justify-content-between">
                    <div className="row">
                        <div className='w-100 col-sm-12'>
                            <SignupFormPhone
                                stepBack={stepBack}
                                validateCode={validateCode}
                                code={code}
                                resendCode={resendCode}
                                onSubmit={()=> {
                                    onSubmitStep();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignupFormStep3;
