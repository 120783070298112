import { handleActions } from "redux-actions";

const SHOW_MODAL = "SHOW_MODAL";

const showModal = (show) => (dispatch) => {
    dispatch({ type: SHOW_MODAL, is_open: show });
};

export const actionsModal = {
    showModal,
};

export const reducers = {
    [SHOW_MODAL]: (state, { is_open }) => {
        return {
            ...state,
            is_open,
        };
    },
};

export const initialState = {
    is_open: false,
};

export default handleActions(reducers, initialState);
