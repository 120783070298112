import React from 'react';
import HeaderCard from "../HeaderCard";
import Grid from "../Utils/Grid";
import {standardActions} from "../Utils/Grid/StandardActions";
import {USER_ROLES_LIST, MANAGING, OFFICE_MANAGER} from "../../../utility/constants";


const ListGrid = (props) => {
    const {data, loader, page, searchAction, toList, remove, me} = props;
    return (
        <div>
            <div className="page-header pl-1 pt-3 no-gutters row">
                <div>
                    <div className="header-page">Users</div>
                </div>
            </div>
            <div
                className="card border-dark border-card-page px-4 py-2"
                style={{
                    minWidth: '99%',
                    minHeight: '15%',
                }}
            >
                <HeaderCard
                    active_link={true}
                    link={'users/invite'}
                    text_link={"Invite"}
                    id_header={'cardInvites'}
                    searchAction={(value) => searchAction(value)}
                    showFile={false}
                    showLink={me && [MANAGING, OFFICE_MANAGER].includes(me.roles)}
                />
                {console.log('data: ', data)}
                <div className="card-body px-0 py-2">
                    <Grid
                        hover
                        page={page}
                        keyField={'id'}
                        data={data}
                        loading={loader}
                        striped
                        onPageChange={(value) => toList(value)}
                        columns={[
                            {
                                dataField: "id",
                                text: "",
                                formatter:
                                    standardActions({
                                        remove: remove,
                                        // view: "users",
                                        edit: 'users',
                                    }),
                            },
                            {
                                dataField: "account_status",
                                formatter: formatStatus(),
                                text: "Status",
                            },
                            {
                                dataField: "first_name",
                                formatter: fillName(),
                                text: "Full Name",
                            },
                            {
                                dataField: "phone",
                                formatter: formatPhoneNumber(),
                                text: "Mobile number",
                            },
                            {
                                dataField: "email",
                                text: "E-mail",
                            },
                            {
                                dataField: "roles",
                                formatter: formatRoles(),
                                text: "Permission ",
                            },
                        ]}
                    />
                </div>
            </div>

        </div>
    );
};

export function formatPhoneNumber() {
    return (cell, row) => {
        let newCell = cell
        if (newCell && newCell.includes('+')) {
            newCell = newCell.replace('+', '')
        }
        if (newCell && newCell.includes('-')) {
            newCell = newCell.replace('-', '')
        }
        if (newCell && newCell.length > 10) {
            newCell = newCell.substr(newCell.length - 10, 12)
        }
        if (newCell) {
            newCell = `${newCell.substr(0, 3)}-${newCell.substr(3, 3)}-${newCell.substr(6, 4)}`
        }
        return (
            <p className={'my-0 mx-0 px-0'} style={{minWidth: '100px'}}>
                {newCell}
            </p>
        )
    };
}

function fillName() {
    return (cell, row) => {
        return (
            <p className={'my-0'}>
                {cell + ' ' + row.last_name}
            </p>
        )
    };
}

export function formatStatus() {
    return (cell, row) => {
        return (
            <React.Fragment>
                <p className={`my-0 text-capitalize fw-bold text-${cell === 1 ? 'danger' : 'success'}`}>
                    {cell === 1 ? "Sent" : "Registered"}
                </p>
            </React.Fragment>
        )
    };
}

export function formatRoles() {
    return (cell) => {
        const getStatus = () => {
            let text = ''
            USER_ROLES_LIST.map(item => {
                if (cell === item.value) {
                    text = item.label
                }
            })
            return text
        }
        return (
            <div className='m-0 p-0'>
                {getStatus()}
            </div>
        )
    };
}

export default ListGrid;
