import React, {useEffect, useState, useRef} from 'react';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import Switch from 'react-switch';
import DayPicker from '../DayPicker';
import FileUploader from '../FileUploader/FileUploader';
import DatePicker from "react-date-picker";
import _ from "lodash";
import {SketchPicker} from 'react-color';
import Select, { Creatable, Async, components } from 'react-select';
import {OPEN_MARKETING, WITHIN_YOUR_BROKERAGE, WITHIN_YOUR_FRANCHISE, SELECT_ALL_OPTION, GOOGLE_MAPS_KEY} from "../../../../utility/constants";
import GooglePlacesAutocomplete, {geocodeByAddress} from 'react-google-places-autocomplete';


export const colorPicker = ({input, placeholder, type, meta: {touched, error}}) => {
    return (
        <SketchPicker
            color={input.value}
            onChangeComplete={(color) => input.onChange(color.hex)}
        />
    );
};

export const renderField = ({
                                container = "", disabled, input, customClass, placeholder, onFocus, type, meta: {touched, error},
                            }) => {
    const invalid = touched && error;
    return (
        <div className={container}>
            <input
                {...input}
                placeholder={placeholder}
                disabled={disabled}
                type={type}
                onFocus={onFocus}
                className={classNames(`form-control ${customClass}`, {'is-invalid': invalid})}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};


export const renderTextArea = ({
                                   disabled, input, customClass='', placeholder, rows, meta: {touched, error},
                               }) => {
    const invalid = touched && error;
    return (
        <div>
            <textarea
                {...input}
                placeholder={placeholder}
                style={{resize: 'none'}}
                rows={rows || 3}
                disabled={disabled}
                className={classNames(`form-control ${customClass}`, { 'is-invalid': invalid })}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderNumber = ({
                                 disabled, input, decimalScale, placeholder, meta: {touched, error}, prefix = "", suffix = "", numberFormat,
                                 type="tel"
                             }) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames('form-control', {'is-invalid': invalid})}
                decimalScale={decimalScale || 0}
                format={numberFormat}
                disabled={disabled}
                fixedDecimalScale
                value={input.value}
                thousandSeparator
                prefix={prefix}
                suffix={suffix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
                type={type}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderCurrency = ({
                                   disabled, input, customClass, placeholder, onFocus, type, meta: {touched, error}, prefix = "Q ",
                               }) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                className={classNames('form-control', {'is-invalid': invalid}, customClass)}
                decimalScale={2}
                fixedDecimalScale
                placeholder={placeholder}
                value={input.value}
                prefix={prefix}
                disabled={disabled}
                allowNegative={false}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderSwitch = ({
                                 input, meta: {touched, error}, label, disabled, left,
                             }) => {
    const invalid = touched && error;
    return (
        <div className="d-flex align-items-center">
            {left}
            <Switch
                onColor="#AA1111"
                offColor="#D7D7D7"
                offHandleColor="#323232"
                handleDiameter={14}
                onHandleColor="#FFFFFF"
                uncheckedIcon={false}
                checkedIcon={false}
                height={20}
                width={44}
                disabled={disabled}
                onChange={(value) => {
                    input.onChange(value);
                }}
                checked={input.value ? input.value : false}
                // id="normal-switch"
            />
            &nbsp;{label}
            {invalid && (
                <div className="invalid-feedback d-inline">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderFieldCheck = ({input, label, value, disabled, type, meta: {touched, error}}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="checkbox c-checkbox m-0 p-0 d-inline">
                <label className="needsclick mb-0 m-0 pb-0">
                    <input
                        type="checkbox"
                        disabled={disabled}
                        {...input}
                        className={classNames('', {'is-invalid': invalid})}
                    />
                    <span className="fa fa-check"/>
                    &nbsp;{label}
                </label>
            </div>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};

export const radioSelect2 = ({input, isSub, idOption, removeFilter, addFilter}) => {
    const [check, setCheck] = useState(true);
    const setValues = (val) => {
        if (val) {
            addFilter(idOption, isSub);
        } else {
            removeFilter(idOption, isSub);
        }
        setCheck(val);
    };
    return (
        <React.Fragment>
            <div className={`d-flex ${isSub ? 'ml-3' : ''}`}>
                <div className="px-1">
                    <label
                        id={`${input.name}`}
                        onClick={() => setValues(!check)}>
                        {check ?
                            <i className="material-icons checked-icon">radio_button_checked</i> :
                            <i className="material-icons checked-icon">radio_button_unchecked</i>
                        }
                    </label>
                </div>
            </div>
        </React.Fragment>
    );
};

export const renderFieldRadio = ({input, label, value, disabled, meta: {touched, error}}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="d-flex">
                <label className="negro font-weight-normal">
                    <input
                        type="radio"
                        disabled={disabled}
                        {...input}
                        className={classNames('', {'is-invalid': invalid})}
                    />
                    <span/>
                    &nbsp;{label}
                </label>
            </div>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};

export const fieldRadio = ({input, set_value, valueOp, checked, value, disabled, meta: {touched, error}}) => {
    // console.log('checked', checked)
    const actionClick = () => {
        set_value(valueOp)
    }

    return (
        <div className="">
            <input
                type="radio"
                name={input.name}
                checked={checked}
                contentEditable={true}
                onChange={(value) => input.onChange(!checked)}
                onClick={actionClick}
                disabled={disabled}
            />
        </div>
    );
};

export const fieldRadioGroup = ({input, initialValue = undefined, disabled, meta: {touched, error}}) => {
    const [option, setOption] = useState(1);

    useEffect(() => {
        console.log('did mont')
        if (!input.value) {
            input.onChange(1)
        }
    }, [])

    useEffect(() => {
        console.log('will updated')
        if (initialValue !== undefined) {
            setOption(initialValue)
        }
    }, [initialValue])


    return (
        <div className="">
            <div className="w-100 justify-content-start mt-4 mb-0">
                <h6 className="broker-register mt-0 text-center mb-0">
                    Operation Access
                </h6>
                <p className="pt-2 text-12 font-weight-bold text-black mb-2">
                    Please choose one:
                </p>
                {/* ----------------------------------------------------------------*/}
                {/* -----------------------    OPTION 1    -------------------------*/}
                {/* ----------------------------------------------------------------*/}
                <div className='row w-100 mx-auto py-1'>
                    <input
                        type="radio"
                        name={'WITHIN_YOUR_BROKERAGE'}
                        checked={option === WITHIN_YOUR_BROKERAGE}
                        contentEditable={true}
                        onChange={(value) => {
                            input.onChange(WITHIN_YOUR_BROKERAGE);
                            setOption(WITHIN_YOUR_BROKERAGE);
                        }}
                        disabled={disabled}
                    />
                     <label htmlFor="WITHIN_YOUR_BROKERAGE" className="text-12-2 col mb-0">
                        Within your Brokerage: Your affiliated licensees
                        can only obtain or provide support personnel services within your brokerage. Multiple offices
                        within the same brokerage are all considered to be a part of the same brokerage.
                    </label>
                </div>
                {/* ----------------------------------------------------------------*/}
                {/* -----------------------    OPTION 2    -------------------------*/}
                {/* ----------------------------------------------------------------*/}
                <div className='row w-100 mx-auto py-1'>
                    <input
                        type="radio"
                        name={'WITHIN_YOUR_FRANCHISE'}
                        checked={option === WITHIN_YOUR_FRANCHISE}
                        contentEditable={true}
                        onChange={(value) => {
                            input.onChange(WITHIN_YOUR_FRANCHISE);
                            setOption(WITHIN_YOUR_FRANCHISE);
                        }}
                        disabled={disabled}
                    />
                    <label htmlFor="option2" className="text-12-2 col mb-0">
                        Within your Franchise:  Your affiliated licensees can
                        only obtain or provide support personnel services from other Member Brokers who share the same
                        franchisor as you.
                    </label>
                </div>
                {/* ----------------------------------------------------------------*/}
                {/* -----------------------    OPTION 3    -------------------------*/}
                {/* ----------------------------------------------------------------*/}
                <div className='row w-100 mx-auto py-1'>
                    <input
                        type="radio"
                        name={'OPEN_MARKETING'}
                        checked={option === OPEN_MARKETING}
                        contentEditable={true}
                        onChange={(value) => {
                            input.onChange(OPEN_MARKETING);
                            setOption(OPEN_MARKETING);
                        }}
                        disabled={disabled}
                    />
                     <label htmlFor="option3" className="text-12-2 col mb-0">
                        Open market:  Your affiliated licensees can obtain or provide support personnel services
                        from all other Member Brokers in the ShowIT Solutions™ network.
                    </label>
                </div>
            </div>
        </div>
    );
};

export const SelectField = (
    {
        input,
        disabled,
        isClearable,
        isMulti,
        isSearchable,
        options,
        placeholder,
        labelKey = "label",
        valueKey = "value",
        set_value,
        item,
        setItem,
        meta: {touched, error},
    }) => {

    const invalid = touched && error;
    const _options = [];
    options.forEach(option => {
        _options.push({
            ...option,
            label: option[labelKey],
            value: option[valueKey],
        });
    });
    let value = input.value;
    if (value !== null && value !== undefined) {
        value = _.find(_options, {value});
    }

    return (
        <React.Fragment>
            <Select
                isClearable={isClearable}
                className={classNames('react-select-container', {'is-invalid': invalid})}
                backspaceRemovesValue={false}
                isMulti={isMulti}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => {
                    input.onChange(e ? e[valueKey] : null);
                    set_value && set_value(e);
                    setItem && setItem({});
                }}
                value={item && Object.entries(item).length !== 0 ? item : value}
                isDisabled={disabled}
                theme={theme => ({
                    ...theme,
                    borderRadius: 5,
                    borderLeft: "1px solid #C7C7C7",
                    borderRight: "1px solid #C7C7C7",
                    borderTop: "1px solid #C7C7C7",
                    borderBottom: "1px solid #C7C7C7",
                    fontSize: "12px !important",
                    color: '7b7b7b !important',
                    colors: {
                        ...theme.colors,
                        primary25: '#F1F1F1', // style to select one option
                        primary: '#7E8A97',  // style of option selected
                    },
                })}
                styles={customStyles}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};


export const AsyncSelectField = (
    {
        input,
        disabled,
        isClearable,
        isSearchable,
        loadOptions,
        placeholder,
        meta: {touched, error},
    }) => {

    const invalid = touched && error;

    return (
        <React.Fragment>
            <Async
                isClearable={isClearable}
                cacheOptions
                className={classNames('react-select-container', {'is-invalid': invalid})}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                defaultOptions
                loadOptions={loadOptions}
                placeholder={placeholder}
                onChange={(e) => {
                    input.onChange(e ? e : null);
                }}
                value={input.value}
                isDisabled={disabled}
                theme={theme => ({
                    ...theme,
                    borderRadius: 5,
                    colors: {
                        ...theme.colors,
                        primary25: '#B9BDC1',
                        primary: '#7E8A97',
                    },
                })}
                styles={customStyles}

            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};

export const CreatableSelectField = (
    {
        input,
        disabled,
        isClearable,
        isSearchable,
        options,
        placeholder,
        labelKey = "label",
        valueKey = "value",
        meta: {touched, error},
    }) => {

    const invalid = touched && error;
    const _options = [];
    options.forEach(option => {
        _options.push({
            ...option,
            label: option[labelKey],
            value: option[valueKey],
        });
    });

    return (
        <React.Fragment>
            <Creatable
                isClearable={isClearable}
                className={classNames('react-select-container', {'is-invalid': invalid})}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => {
                    input.onChange(e ? e : null);
                }}
                value={input.value}
                isDisabled={disabled}
                theme={theme => ({
                    ...theme,
                    borderRadius: 5,
                    colors: {
                        ...theme.colors,
                        primary25: '#B9BDC1',
                        primary: '#7E8A97',
                    },
                })}
                styles={customStyles}

            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};

const customStyles = {
    option: (styles, {isDisabled, isSelected}) => ({
        ...styles,

        ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled && (isSelected ? "#E1E1E1 " : '#E6E6E5 0% 0% no-repeat padding-box'),
            color: !isDisabled && (isSelected ? 'black' : 'black'),
        },
    }),
    menu: style => ({
        ...style,
        background: '#FFFFFF',
        // border: '1px solid $primary',
        borderLeft: "1px solid #C7C7C7",
        borderRight: "1px solid #C7C7C7",
        borderTop: "1px solid #C7C7C7",
        borderBottom: "1px solid #C7C7C7",
        fontSize: "14px !important",
        borderRadius: "5px",
        opacity: 1,
        marginTop: 0,
        color: '7b7b7b !important',
    }),
    control: styles => ({
        ...styles,
        backgroundColor: "#FFFFFF",
        borderLeft: "1px solid #C7C7C7",
        borderRight: "1px solid #C7C7C7",
        borderTop: "1px solid #C7C7C7",
        borderBottom: "1px solid #C7C7C7",
        fontSize: "12px !important",
        borderRadius: "5px",
        opacity: 1,
        paddingBottom: 0,
        paddingTop: 0,
        marginTop: 0,
        font: "normal normal medium 13px/17px Poppins-Regular !important",
        minHeight: 27,
        marginBottom: 0,
        color: '7b7b7b !important',
    }),
};


/**
 * @param photo: este parametro se usa para tener la imagen previa de una imagen en dado caso el formulario es
 * usado para una actualizacion, se espera que sea la ruta donde se encuentra la imagen
 * @param setFile
 * @param className
 * @param disabled
 * @param input
 * @param touched
 * @param error
 * */
export const customFieldFile = ({title, customClass, text, id, setFile, input, type, active, remove, meta: {touched, error}, customContainerClass="mb-3",
acceptFiles="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
}) => {
    const action = (e) => {
        setFile(e.target.files[0]);
    };

    const actionRemove = () => {
        remove();
        input.onChange('');
        let formUpload = document.getElementById('upload-agent-1');
        formUpload.reset()
    };

    const invalid = touched && error;
    return (
        <form className={customContainerClass} name='upload-agent' id="upload-agent-1">
            {active &&
            <label
                className={"btn py-0 justify-content-center d-flex " + customClass}
                htmlFor={`id${id}`}
            >{title ? title : "Import Excel"}
            </label>
            }
            <input
                type={type}
                {...input}
                className="display-none"
                accept={acceptFiles}
                id={`id${id}`}
                onChange={action}
            />
            {!active &&
            <div className={"mx-auto"}>
                <p className="btn button-03 my-auto px-4"> {text} </p>
                <img
                    src={require('../../../../../assets/img/delete.svg')}
                    alt='delete'
                    className={"mt-1 ml-2"}
                    style={{
                        width: "22px",
                        height: "22px",
                        cursor: "pointer",
                    }}
                    onClick={actionRemove}
                />
            </div>
            }
            {(invalid) &&
            <div className="invalid-feedback d-block">
                {error}
            </div>
            }
        </form>
    );
};


export const renderFilePicker = ({photo,field, setFile, label, placeholder, styles, className, disabled, input, meta: {touched, error}}) => {
    const invalid = touched && error;

    return (
        <div className={classNames(`${className}`, {'is-invalid': invalid})}>
            <FileUploader
                placeholder={placeholder}
                disabled={disabled}
                label={label}
                img={!!photo ? photo : null}
                onFileChange={(e, file) => {
                    file = file || e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        input.onChange(reader.result);
                        if (!!setFile) {
                            setFile(file);
                        }
                    };
                    reader.readAsDataURL(file);
                }}/>
            {invalid && <div className="invalid-feedback d-block" style={styles && styles}>
                {error}
            </div>}
        </div>
    );
};

export const renderDayPicker = ({className, disabled, maxDate, minDate, initialDate, outputFormatt=null, input, meta: {touched, error}}) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, {'is-invalid': invalid})}>
            <DayPicker
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                onChange={e => input.onChange(e)}
                value={(input.value === '' || input.value === undefined || input.value === null) ? initialDate : input.value}
                outputFormatt={outputFormatt}
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    );
};

export const renderDatePicker = ({className, disabled, maxDate, minDate, input, meta: {touched, error}}) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, {'is-invalid': invalid})}>
            <DatePicker
                onChange={e => input.onChange(e)}
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                value={input.value}
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    );
};

export const renderSearchField = ({
    disabled, input, customClass, placeholder, onFocus, type, meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div className='img-fluid w-100 pl-2'>
            <img
                    className="custom-search-icon"
                    src={require('../../../../../assets/img/search.svg')}
                    alt="searchIcon"
                />
            <input
                {...input}
                placeholder={placeholder}
                disabled={disabled}
                type={type}
                onFocus={onFocus}
                className={classNames(`search-input ${customClass}`, { 'is-invalid': invalid })}
            />
            {
                input.value && input.value.length && (
                    <div onClick={(e) => input.onChange("")} className="pointer" style={{display: "inline", marginLeft: "-27px"}}>
                        <i className="material-icons" style={{top: "6px", fontSize: "20px", color: "#7E8A97"}}>close</i>
                    </div>
                )
            }
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

const Option = (props) => {

    // console.log("PROPS OPT: ", props);
    const {selectProps, isSelected} = props;
    let isChecked = selectProps.isOptionSelected;
    if (isSelected) {
        isChecked = true;
    }
    return (
      <React.Fragment>
        <components.Option {...props}>
            <div className="pointer d-flex flex-row align-items-center">
                <div className="read-checkbox">
                    <i className={`fa fa-check ${isChecked ? 'checked' : ''}`}></i>
                </div>
                <label className="pointer m-0 pl-1 flex-1" style={{ wordBreak: 'break-all', lineHeight: "1"}}>{" "}{props.label}</label>
            </div>
        </components.Option>
      </React.Fragment>
    );
  };

const ValueContainer = ({
    children,
    ...props
}) => {
    const {options, selectProps, displayAllSelected} = props;
    console.log("PROPS VALUE: ", props);
    const [selected, setSelected] = useState(0);
    const [nameItem, setNameItem] = useState(null);
    const [inputChild, setInputChild] = useState(null);

    useEffect(() => {
        const placeholder = _.find(children, { key: "placeholder" })

        if (placeholder) {
            setSelected(0)
        } else {
            const items = _.find(children, function (o) { return o && o.length > 0 });
            if (items && items.length) {
                if (items.length == 1) {
                    const {data} = items[0].props;
                    setNameItem(data ? data.label : null)
                }else {
                    const itemsNames = items.map((item) => {
                        const {data} = item.props;
                        return data ? data.label : null
                    })
                    const hasName = itemsNames.filter((item) => item != null)
                    setNameItem(hasName.join(", "))
                }
                setSelected(items.length)
            } else {
                setSelected(0)
            }
        }
    }, [children])

    return (
        <components.ValueContainer {...props}>
            <div className="flex-1 d-flex flex-row align-items-center">
                {children && children.map((child, index) => {
                    if (child) {
                        if (index == 0) {
                            if (child.length) {
                                if (selected != null && selected > 1 && displayAllSelected){
                                    return <span className="value_selected">{nameItem}</span>
                                } else {
                                    if(selected != null && selected == 1) {
                                        return <span className="value_selected">{nameItem}</span>
                                    }else if (selected != null && selected > 1) {
                                        return <span className="value_selected">Selected ({selected})</span>
                                    } else {
                                        return <span className="value_selected"></span>
                                    }
                                }
                            }else {
                                if (selectProps.menuIsOpen) {
                                    return null
                                }else {
                                    if (child.key == 'placeholder') {
                                        return <span className="value_selected">{child.props && child.props.children ? child.props.children : "Select..."}</span>
                                    }else {
                                        return <span className="value_selected">Select...</span>
                                    }
                                }
                            }
                        } else {
                            if (child.length) {
                                return null
                            }else {
                                if (child.key == null) {
                                    return child
                                }
                                return null
                            }
                        }
                    }
                    return null
                })}
            </div>
        </components.ValueContainer>
    )
};

const MenuWithFooter = (props) => {
    //  State
    const {selectProps} = props;
    const {selectAllOption} = selectProps;
    //  Bind
    const {getValue} = props;
    const {onApply, onMenuClose, isTouchDevice} = selectProps;
    const menuRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                onMenuClose();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef])

    const onClickApply = (e) => {
        const value = getValue();
        const _value = [];
        if (value && value.length) {
            value.forEach((item) => {
                if (item["value"] !== selectAllOption["value"]){
                    _value.push(item["value"])
                }
            })
        }
        onApply(_value)
        onMenuClose();
    }

    return (
        <div ref={menuRef}>
            <components.Menu {...props}>
                <div>
                    <div>{props.children}</div>
                    {!isTouchDevice && selectAllOption != null && (
                        <div className="d-flex flex-row align-items-center justify-content-center my-2">
                            <button className="btn btn-secondary" type="button" onClick={onClickApply} style={{minHeight: '1.4rem'}}>Apply</button>
                        </div>
                    )}
                </div>
            </components.Menu>
        </div>
    )
}

export const MultiSelectField = (
    {
        input,
        disabled,
        isClearable,
        isMulti=true,
        isSearchable,
        displayAllSelected=false,
        options,
        placeholder,
        labelKey = "label",
        valueKey = "value",
        set_value,
        item,
        setItem,
        meta: {touched, error},
        showSelectAllOption=true
    }) => {

    const [selectIsOpen, setSelectIsOpen] = useState(false)
    const valueRef = useRef(input.value);
    valueRef.current = input.value;

    const isTouchDevice = () => {
        return window.matchMedia("(pointer: coarse)").matches
    }

    const selectAllOption = SELECT_ALL_OPTION;
    const invalid = touched && error;
    const isSelectAllSelected = () => {
        let result = false;

        if (valueRef.current && typeof(valueRef.current) == "string" && valueRef.current == selectAllOption.value){
            result = true
        }
        return result
    }

    const getOptions = () => {
        let values = options
        if (showSelectAllOption) {
            values = [selectAllOption, ...options]
        }
        return values
    };

    const getValues = () => {
        let _value = [];
        if (input.value) {
            if (input.value !== selectAllOption.value) {
                input.value.forEach(value_item => {
                    let _item = _.find(options, { value: value_item });
                    if (_item) {
                        _value.push(_item)
                    }
                })
            }else {
                _value = selectAllOption
            }
        }
        return _value
    }

    const getValue = () =>
        isSelectAllSelected() && showSelectAllOption ? selectAllOption : getValues();

    const onMenuOpen = () => {
        console.log("ABRIENDO");
        setTimeout(()=>{
            const selectedEl = document.getElementsByClassName("MultiSelectShowIT__menu-list")[0];
            if(selectedEl) {
                selectedEl.scrollTo(0, 0)
            }
        },15);
    };

    return (
        <React.Fragment>
            <Select
                menuIsOpen={selectIsOpen}
                isClearable={false}
                className={classNames('react-select-container', {'is-invalid': invalid})}
                classNamePrefix={"MultiSelectShowIT"}
                backspaceRemovesValue={false}
                isMulti={isMulti}
                isSearchable={isSearchable}
                options={getOptions()}
                placeholder={placeholder}
                closeMenuOnSelect={false}
                onChange={(e, actionMeta) => {
                    if (isMulti) {
                        const { action, option, removedValue } = actionMeta;
                        let valueOption = [];
                        let _value = [];
                        if (action === "select-option" && option.value === selectAllOption.value) {
                            valueOption.push(selectAllOption);
                            // console.log("CASO 1");
                        } else if (
                            (action === "deselect-option" &&
                                option.value === selectAllOption.value) ||
                            (action === "remove-value" &&
                                removedValue.value === selectAllOption.value)
                        ) {
                            valueOption = [];
                            // console.log("CASO 2");
                        } else if (
                            actionMeta.action === "deselect-option" &&
                            isSelectAllSelected()
                        ) {
                            valueOption = options.filter(({ value }) => value !== option.value);
                            // console.log("CASO 3");
                        } else {
                            const _includeSelectAll = _.find(e, function(o) {
                                return o[valueKey] == selectAllOption[valueKey]
                            })
                            if (_includeSelectAll) {
                                let valuesToExclude = [];
                                e.forEach((item) => {
                                    if(item[valueKey] !== selectAllOption[valueKey]) {
                                        valuesToExclude.push(item[valueKey])
                                    }
                                })
                                valueOption = _.filter(options, function(o) {
                                    const isExclude = _.find(valuesToExclude, function (_o) {
                                        return _o == o[valueKey]
                                    })
                                    if (isExclude) {
                                        return false
                                    }else {
                                        return true
                                    }
                                })
                            }else {
                                const _valueOption = e || [];
                                if (_valueOption.length == options.length && showSelectAllOption) {
                                    valueOption = [selectAllOption]
                                }else {
                                    valueOption = _valueOption
                                }
                            }
                            // console.log("CASO 4");
                        }
                        const includeSelectAll = _.find(valueOption, function(o) {
                            return o[valueKey] === selectAllOption[valueKey]
                        })
                        if (includeSelectAll && showSelectAllOption) {
                            _value = selectAllOption[valueKey];
                        }else {
                            valueOption.forEach(item => {
                                _value.push(item[valueKey])
                            })
                        }

                        input.onChange(_value, actionMeta);
                        if (isTouchDevice()) {
                            console.log("ES TOUCH: ", _value);
                            set_value(_value)
                        }
                    }else{
                        input.onChange(e ? e[valueKey] : null);
                    }
                }}
                value={getValue()}
                isDisabled={disabled}
                theme={theme => ({
                    ...theme,
                    borderRadius: 5,
                    borderLeft: "1px solid #C7C7C7",
                    borderRight: "1px solid #C7C7C7",
                    borderTop: "1px solid #C7C7C7",
                    borderBottom: "1px solid #C7C7C7",
                    fontSize: "12px !important",
                    color: '7b7b7b !important',
                    colors: {
                        ...theme.colors,
                        primary25: '#F1F1F1', // style to select one option
                        primary: '#7E8A97',  // style of option selected
                    },
                })}
                styles={customMultiStyles}
                allowSelectAll={true}
                hideSelectedOptions={false}
                isOptionSelected={isSelectAllSelected()}
                onApply={set_value}
                isTouchDevice={isTouchDevice()}
                selectAllOption={showSelectAllOption ? selectAllOption : null}
                onMenuOpen={() => {
                    onMenuOpen()
                    setSelectIsOpen(true)
                }}
                onMenuClose={() => setSelectIsOpen(false)}
                components={{
                    Option,
                    ValueContainer: (props) => <ValueContainer {...props} displayAllSelected={displayAllSelected}/>,
                    Menu: MenuWithFooter
                    // Menu: (props) => <MenuWithFooter {...props} onApply={set_value} selectAllOption={selectAllOption} />,
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};

const customMultiStyles = {
    option: (styles, {isDisabled, isSelected}) => ({
        ...styles,
        backgroundColor: 'transparent',
        ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled && (isSelected ? "#E1E1E1 " : '#E6E6E5 0% 0% no-repeat padding-box'),
            color: !isDisabled && (isSelected ? 'black' : 'black'),
        },
    }),
    menu: style => ({
        ...style,
        background: '#FFFFFF',
        // border: '1px solid $primary',
        borderLeft: "1px solid #C7C7C7",
        borderRight: "1px solid #C7C7C7",
        borderTop: "1px solid #C7C7C7",
        borderBottom: "1px solid #C7C7C7",
        fontSize: "14px !important",
        borderRadius: "5px",
        opacity: 1,
        marginTop: 0,
        color: '7b7b7b !important',
    }),
    menuList: style => ({
        ...style,
        maxHeight: "60vh",
    }),
    control: styles => ({
        ...styles,
        backgroundColor: "#FFFFFF",
        borderLeft: "1px solid #C7C7C7",
        borderRight: "1px solid #C7C7C7",
        borderTop: "1px solid #C7C7C7",
        borderBottom: "1px solid #C7C7C7",
        fontSize: "12px !important",
        borderRadius: "5px",
        opacity: 1,
        paddingBottom: 0,
        paddingTop: 0,
        marginTop: 0,
        font: "normal normal medium 13px/17px Poppins-Regular !important",
        minHeight: 27,
        marginBottom: 0,
        color: '7b7b7b !important',
    }),
};

export const renderAddressField = ({
    disabled, input, customClass, placeholder, meta: { touched, error },
    setCoords=null, setCityState=null, longStateName=false
}) => {
    const invalid = touched && error;
    const [value, setValue] = useState(null);
    const [intervalId, setIntervalId] = useState(null);
    const [hasGoogle, setHasGoogle] = useState(false);

    useEffect(() => {
        const _intervalId = setInterval(() => {
            // console.log("INTERVAL");
            if (window.google && window.google.maps && !hasGoogle) {
                setHasGoogle(true);
            }
        }, 300);

        setIntervalId(_intervalId)

        return () => {
            clearInterval(_intervalId);
        }
    }, [])

    useEffect(() => {
        formattValue(input)
    }, [input.value])

    useEffect(() => {
        if (hasGoogle == true && intervalId != null) {
            clearInterval(intervalId)
            formattValue(input)
        }
    }, [hasGoogle])


    const formattValue = (_input) => {
        if (_.isEmpty(_input.value)) {
            setValue(null);
            if(setCoords) {
                setCoords(null)
            }
            _input.onBlur();
        } else if (_.isString(_input.value.formatted_address)) {
            if (window.google) {
                geocodeByAddress(_input.value.formatted_address)
                    .then(results => {
                        console.log("GEO RES: ", results);
                        if (results.length) {
                            const _value = {
                                label: results[0].formatted_address,
                                value: { ...results[0], description: results[0].formatted_address }
                            }
                            setValue(_value);
                            const item = results[0];
                            if(setCoords) {
                                const {geometry} = item;
                                const {location} = geometry;
                                let coordinates = null
                                if (location) {
                                    coordinates = {
                                        latitude: location.lat(),
                                        longitude: location.lng()
                                    }
                                }
    
                                setCoords(coordinates)
                            }
                            if (setCityState) {
                                let data = {
                                    city: null,
                                    state: null,
                                    zip_code: null
                                }
                                const {address_components} = item;
                                if (address_components && address_components.length > 0) {
                                    const city = _.find(address_components, function(o) {
                                        if (o.types && o.types.includes('locality')) {
                                            return true;
                                        }
                                        return false;
                                    })
                                    const state = _.find(address_components, function(o) {
                                        if (o.types && o.types.includes('administrative_area_level_1')) {
                                            return true;
                                        }
                                        return false;
                                    })
                                    const zip_code = _.find(address_components, function(o) {
                                        if (o.types && o.types.includes('postal_code')) {
                                            return true;
                                        }
                                        return false;
                                    })
                                    data.city = city ? city.long_name: null;
                                    data.state = state ? (longStateName ? state.long_name: state.short_name): null;
                                    data.zip_code = zip_code ? zip_code.long_name : null
                                }
                                setCityState(data)
                            }
                        } else {
                            setValue(null)
                            if(setCoords) {
                                setCoords(null)
                            }
                            if (setCityState) {
                                setCityState({city: null, state: null, zip_code: null})
                            }
                        }
                        _input.onBlur();
                    })
                    .catch(error => console.error(error));
            }
        } else if (_.isString(_input.value)) {
            if (window.google) {
                geocodeByAddress(_input.value)
                    .then(results => {
                        console.log("GEO RES 2: ", results);
                        if (results.length) {
                            const _value = {
                                label: results[0].formatted_address,
                                value: { ...results[0], description: results[0].formatted_address }
                            }
                            setValue(_value);
                            const item = results[0];
                            if(setCoords) {
                                const {geometry} = item;
                                const {location} = geometry;
                                let coordinates = null
                                if (location) {
                                    coordinates = {
                                        latitude: location.lat(),
                                        longitude: location.lng()
                                    }
                                }
    
                                setCoords(coordinates)
                            }
                            if (setCityState) {
                                let data = {
                                    city: null,
                                    state: null,
                                    zip_code: null
                                }
                                const {address_components} = item;
                                if (address_components && address_components.length > 0) {
                                    const city = _.find(address_components, function(o) {
                                        if (o.types && o.types.includes('locality')) {
                                            return true;
                                        }
                                        return false;
                                    })
                                    const state = _.find(address_components, function(o) {
                                        if (o.types && o.types.includes('administrative_area_level_1')) {
                                            return true;
                                        }
                                        return false;
                                    })

                                    const zip_code = _.find(address_components, function(o) {
                                        if (o.types && o.types.includes('postal_code')) {
                                            return true;
                                        }
                                        return false;
                                    })
                                    data.city = city ? city.long_name: null;
                                    data.state = state ? (longStateName ? state.long_name: state.short_name): null;
                                    data.zip_code = zip_code ? zip_code.long_name : null
                                }
                                setCityState(data)
                            }
                        } else {
                            setValue(null)
                            if(setCoords) {
                                setCoords(null)
                            }
                            if (setCityState) {
                                setCityState({city: null, state: null, zip_code: null})
                            }
                        }
                        _input.onBlur();
                    })
                    .catch(error => console.error(error));
            }
        }
    }


    return (
        <React.Fragment>
            <GooglePlacesAutocomplete
                apiKey={GOOGLE_MAPS_KEY}
                apiOptions={{ language: 'en', region: 'us' }}
                autocompletionRequest={{
                    componentRestrictions: {
                        country: 'us',
                    }
                }}
                selectProps={{
                    className: classNames('react-select-container', { 'is-invalid': invalid }),
                    isClearable: true,
                    placeholder: placeholder,
                    isDisabled: disabled,
                    theme: (theme) => ({
                        ...theme,
                        borderRadius: 5,
                        fontSize: "12px !important",
                        color: '#7b7b7b !important',
                        colors: {
                            ...theme.colors,
                            primary25: '#F1F1F1', // style to select one option
                            primary: '#7E8A97',  // style of option selected
                        },
                    }),
                    styles: {
                        input: (provided) => ({
                            ...provided,
                            fontSize: "13px !important",
                            color: '#495057 !important',
                        }),
                        control:(provided) => ({
                            ...provided,
                            borderColor: invalid ? "#c4183c" : "#C7C7C7",
                            borderWidth: "2px",
                        }),
                        option: (provided) => ({
                            ...provided,
                            fontSize: "13px !important",
                            color: '#495057 !important',
                            borderBottom: "1px solid #C7C7C7",
                        }),
                        singleValue: (provided) => ({
                            ...provided,
                            fontSize: "13px !important",
                            color: '#495057 !important',
                        }),
                    },
                    onChange: (e) => {
                        console.log("CHANGE VALUE: ", e);
                        setValue(e);
                        if (e) {
                            const _value = e.value ? e.value : null
                            input.onChange(_value ? _value.description : '');
                        } else {
                            input.onChange('');
                        }
                    },
                    value: value,
                }}

                onLoadFailed={(error) => (
                    console.error("Could not inject Google script", error)
                )}
            />
            {invalid && (
                <div className="invalid-feedback d-block">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};

export const RenderField = {
    renderField,
    renderTextArea,
    renderNumber,
    renderCurrency,
    renderSwitch,
    renderFieldCheck,
    renderFieldRadio,
    SelectField,
    AsyncSelectField,
    CreatableSelectField,
    customFieldFile,
    radioSelect2,
    fieldRadio,
    fieldRadioGroup,
    renderSearchField,
    renderAddressField,
};
