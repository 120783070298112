import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {renderField} from '../../../Utils/renderField';
import {validate, validators} from 'validate-redux-form';
import {STATE_USA_LIST} from "../../../../../utility/constants";


const FormStep1 = (props) => {
    const {handleSubmit} = props;

    return (
        <form name="register" className="w-100" onSubmit={handleSubmit}>
            <div className="col">
                <div className="form-group has-feedback col-md-5  mx-auto ">
                    <label htmlFor="first_name" className="mb-0  mt-lg-2">
                        <strong className="question1"> What's your </strong>
                        <strong className="question2">First name?</strong>
                    </label>
                    <Field
                        name="first_name"
                        label="First Name"
                        component={renderField}
                        type="text"
                        className="form-control"/>
                </div>
                <div className="form-group has-feedback col-md-5 mx-auto">
                    <label htmlFor="first_name" className="mb-0">
                        <strong className="question1"> What's your </strong>
                        <strong className="question2"> Last name? </strong>
                    </label>
                    <Field
                        name="last_name"
                        label="Last Name"
                        component={renderField}
                        type="text"
                        className="form-control"/>
                </div>
                <div className="form-group has-feedback col-md-5 mx-auto">
                    <label htmlFor="email" className="mb-0">
                        <strong className="question1"> What's your </strong>
                        <strong className="question2"> Email? </strong>
                    </label>
                    <Field
                        name="email"
                        label="email"
                        type="email"
                        options={STATE_USA_LIST}
                        component={renderField}
                        className="form-control"
                    />
                </div>
            </div>
            <div className="w-100 mt-4">
                <div className="form-group has-feedback col-md-5 row mx-auto justify-content-center">
                    <div className="col-lg-12 px-0 ml-3">
                        <p className="my-0 paragraph" style={{fontSize: '12px !important'}}>
                            By continuing, I confirm that I have read and agree to the
                            <span className="d-inline-block">
                                <a
                                    href="https://showitsolutions.com/terms-and-conditions/"
                                    target="_blank"
                                    className="link-blue mx-1"
                                >
                                   Terms {'&'} Conditions,
                                </a>
                            </span>
                            <span className="d-inline-block">
                                <a
                                    href="https://showitsolutions.com/privacy-policy/"
                                    target="_blank"
                                    className="link-blue mx-1"
                                >
                                    Privacy Policy
                                </a>
                            </span>
                            and
                            <span className="d-inline-block">
                                <a
                                    href="https://showitsolutions.com/brokerage-and-licensee-consent/"
                                    target="_blank"
                                    className="link-blue"
                                >
                                    Brokerage / Licensee Consent
                                </a>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="buttons-box w-75 justify-content-center mx-auto py-lg-3">
                <button type="submit" className="btn button-01 col-lg-4 col-sm-8 align-self-center">
                    Next
                </button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'FormStep1Invited', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            first_name: validators.exists()('This field is required'),
            last_name: validators.exists()('This field is required'),
            email: validators.exists()('This field is required'),
        });
    },
})(FormStep1);
