import React from 'react';
import {reduxForm} from 'redux-form';
import {validate, validators} from 'validate-redux-form';
import ReactCodeInput from "react-verification-code-input";

const ValidateCode = (props) => {
    const {action, title, message, code, resendCode} = props;
    return (
        <form name="validateCodeForm" className="row pt-lg-4" style={{minHeight:'20rem'}}>
            <div className="form-group has-feedback col-lg-8  mx-auto justify-content-center text-center">
                <label htmlFor="mobile_number" className="text-left">
                    {/*{title}*/}
                    <strong className="question2"> Enter </strong>
                    <p className="message-code">
                        the 4-digit code that was sent to you to confirm your {title}
                    </p>
                </label>
                <div style={code === 'error' ?
                    { } : { width: '300px !important'}}
                     className={`p-0 w-100 ${code === 'error' && "is-invalid mx-auto"} `}
                >
                    <ReactCodeInput
                        onComplete={action}
                        fieldWidth={50}
                        fieldHeight={33}
                        title={''}
                        className={` w-100 ${code === 'error' && "is-invalid"} `}
                        fields={4}
                        required={true}
                        type={"number"}
                    />
                </div>
                {code === 'error' &&
                <strong className="invalid-feedback"> Wrong code, please check your code</strong>}
            </div>
            <div className="buttons-box w-75 mx-auto">
                <button
                    type="button"
                    className="btn button-03 col-lg-3 my-2 col-sm-8 align-self-center text-12"
                    style={{fontSize: "12px !important"}}
                    onClick={()=>resendCode()}
                >
                    Resend code
                </button>
            </div>
            <p className={"text-center mt-4"} style={{fontSize: "11px", fontFamily: "Poppins-SemiBold"}}>
                {/*{message}*/}
            </p>
            <div className="buttons-box w-75 mx-auto pt-lg-5">
                <button type="submit" className="btn button-01 col-md-4 align-self-center mt-lg-2">
                    Next
                </button>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'validateCodeForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            mobile_number: validators.exists()('This field is required'),
            email: validators.exists()('This field is required'),
        });
    },
})(ValidateCode);

