import React, { Component } from "react";
import Map from "./Map";
import LoadMask from "../Utils/LoadMask/LoadMask";
import AgentsJobsStats from "./AgentsJobsStats"
import AgentsJobsFiltersForm from "./AgentsJobsFiltersForm";
import "./agents_jobs.css";

export class AgentsJobs extends Component {
    state = {
        mapHeight: 200,
    };

    componentDidMount() {
        const { toList, getAgentsData, getBrokeragesOption, getJobTypesOption, getDataRevenueAgents } = this.props;
        const _heigth = document.getElementById("AGENTS-JOB-MAP").clientHeight;
        this.setState({ mapHeight: _heigth });
        toList();
        // getAgentsData();
        getDataRevenueAgents()
        getJobTypesOption(true);
        getBrokeragesOption(null, true)
    }

    render() {
        //  State
        const { data, loader, agents, brokers, franchises, brokerages, jobTypes, dataRevenueAgents } = this.props;
        const { mapHeight } = this.state;
        //  Bind
        const {changeFilters} = this.props;

        return (
            <div className="flex-1 d-flex flex-column pb-2">
                <div className="page-header pl-1 pt-3 no-gutters row pb-3">
                    <div className="flex-1 d-flex flex-lg-row flex-column">
                        <div className="header-page w-auto h-auto mr-4">Agents & Jobs</div>
                        {dataRevenueAgents && (
                            <AgentsJobsStats dataRevenueAgents={dataRevenueAgents}/>
                        )}
                    </div>
                </div>
                <AgentsJobsFiltersForm
                    optionBrokerages={brokerages}
                    optionJobTypes={jobTypes}
                    changeFilters={changeFilters}/>
                <LoadMask loading={loader} light>
                    <div className="map-card card border-dark flex-1">
                        <div
                            id="AGENTS-JOB-MAP"
                            className="card-body px-0 py-0 d-flex flex-column"
                        >
                            <div
                                className="d-block w-100"
                                style={{ height: `${mapHeight}px` }}
                            >
                                <Map
                                    jobs={
                                        data && data.results ? data.results : []
                                    }
                                    agents={
                                        agents && agents.results
                                            ? agents.results
                                            : []
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </LoadMask>
            </div>
        );
    }
}

export default AgentsJobs;
