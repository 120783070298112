import moment from "moment";
import Avatar from "react-avatar";
import React, {Component} from 'react';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import GridTableWithScroll from "../../../Utils/GridTableWithScroll";
import {SIZE_PER_PAGE} from "../../../../../utility/constants";

class PostedDetailsGrid extends Component {
    componentDidMount() {
        const {match} = this.props;
        if (match.params.id) {
            this.props.getPostsDetails(match.params.id, 1)
        }
    }

    nextPage = (page = 1) => {
        const {match} = this.props;
        let id = match.params.id
        this.props.getPostsDetails(id, page)
    }

    componentWillUnmount() {
        this.props.setDefaultValue();
    }

    formatPhoneNumber = (cell = '') => {
        let phone = ''
        if (cell.length > 10) {
            cell = cell.substr(cell.length - 10, 12)
        }
        phone = `${cell.substr(0, 3)}-${cell.substr(3, 3)}-${cell.substr(6, 4)}`
        return phone
    }

    render() {
        const {loader, itemPostedDetails} = this.props;

        return (
            <div className="d-flex flex-column">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">
                            Stats / Agent Posts Details
                        </div>
                    </div>
                </div>
                <LoadMask loading={loader} light>
                    <div className="card w-100 border-card-page px-0 py-3">
                        {
                            itemPostedDetails.agent &&
                            <div className="row mx-auto w-100 px-4">
                                <div className="pr-4 d-flex align-items-center justify-content-center">
                                    <Avatar
                                        src={itemPostedDetails.agent.photo}
                                        name={itemPostedDetails.agent.first_name}
                                        size={37}
                                        round
                                    />
                                </div>
                                <div className="col-sm-12 col-lg-3 align-items-center d-flex px-0">
                                    <h5 className="font-weight-bold text-dark mb-0">
                                        {itemPostedDetails.agent.first_name + ' ' + itemPostedDetails.agent.last_name}
                                    </h5>
                                </div>
                                <div className="col-lg-2 col-sm-6 text-dark font-weight-bold">
                                    <span className="text-12 py-0 my-0">
                                    Total Jobs Posted
                                    </span>
                                    <h6 className="mb-0 font-weight-bold line-y-0">
                                        {itemPostedDetails.count}
                                    </h6>
                                </div>
                                <div className="col-lg-2 col-sm-6 text-dark font-weight-bold">
                                    <span className="text-12 py-0 my-0">
                                    Real state license
                                    </span>
                                    <h6 className="mb-0 font-weight-bold line-y-0">
                                        {itemPostedDetails.agent.real_estate_licence}
                                    </h6>
                                </div>
                                <div className="col-lg-2 col-sm-6 text-dark font-weight-bold">
                                    <span className="text-12 py-0 my-0">
                                    Mobile number
                                    </span>
                                    <h6 className="mb-0 font-weight-bold line-y-0">
                                        {this.formatPhoneNumber(itemPostedDetails.agent.mobile_number)}
                                    </h6>
                                </div>
                                <div className="col-lg-2 col-sm-6 text-dark font-weight-bold">
                                    <span className="text-12 py-0 my-0">
                                    E-mail
                                    </span>
                                    <h6 className="mb-0 font-weight-bold line-y-0">
                                        {itemPostedDetails.agent.email}
                                    </h6>
                                </div>
                            </div>
                        }
                    </div>
                </LoadMask>
                <div className="card border-card-page px-0 pt-1 pb-0 mt-3">
                    <div className="pt-0 pb-0 px-3">
                        <GridTableWithScroll
                            data={itemPostedDetails}
                            keyField={'id'}
                            wrapperClasses={'table-scroll-details'}
                            loading={loader}
                            onPageChange={(value) => this.nextPage(value)}
                            sizePerPage={SIZE_PER_PAGE}
                            remote={true}
                            rowEvents={null}
                            columns={[
                                {
                                    dataField: "icon",
                                    formatter: loadIcon(),
                                    text: "",
                                },
                                {
                                    dataField: "job_name",
                                    short: true,
                                    text: "Job Type",
                                },
                                {
                                    dataField: "data_appliance.hired_agent_name",
                                    short: true,
                                    text: "Hired Agent",
                                },
                                {
                                    dataField: "data_appliance.office_name",
                                    short: true,
                                    text: "Hired Agent Office",
                                },
                                {
                                    dataField: "date_completed",
                                    formatter: dateCompleted(),
                                    text: "Date Performed",
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function hiringAgentName() {
    return (cell, row) => {
        return (
            <div>
                {cell + ' ' + row.job__agent__last_name}
            </div>
        )
    }
}

function jobName() {
    return (cell, row) => {
        return (
            <div>
                {row.job_type_option ? row.job_type_option__name : cell}
            </div>
        )
    }
}

function dateCompleted() {
    return (cell) => {
        return (
            <div>
                {moment(cell).format('ll')}
            </div>
        )
    }
}

function loadIcon() {
    return (cell, row) => {
        return (
            <div className="p-0 m-0 justify-content-center container-cell-icon mx-auto">
                <img
                    src={cell}
                    alt=' '
                    className="align-self-center"
                    style={{maxWidth: '95%', maxHeight: '90%'}}
                />
            </div>
        )
    }
}


export default PostedDetailsGrid;
