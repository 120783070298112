import React, {Component} from "react";
import './FileUploader.css';

const defaultProps = {
    baseColor: 'gray',
    activeColor: 'green',
    overlayColor: 'rgba(255,255,255,0.2)',
    opacity: 0,
};

class FileUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            imageSrc: '',
            loaded: false,
        };

        this.onDragEnter = this.onDragEnter.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
    }

    componentDidUpdate() {
        if (this.state.imageSrc === '' && this.props.source !== undefined && this.props.source !== null) {
            this.setState({
                imageSrc: this.props.source,
                loaded: true,
            });
        }
    }

    componentDidMount() {
        if(this.props.img){
            this.setState({imageSrc: this.props.img, loaded: true, active:true})
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.img !== null && nextProps.img !== undefined) {
            // setea la imágen si se le envia una
            this.setState({
                imageSrc: nextProps.img,
                loaded: true,
                isImage: true,
            });
        }
    }

    onDragEnter(e) {
        this.setState({active: true});
    }

    onDragLeave(e) {
        this.setState({active: false});
    }

    onDragOver(e) {
        e.preventDefault();
    }

    onDrop(e) {
        e.preventDefault();
        this.setState({active: false});
        this.onFileChange(e, e.dataTransfer.files[0]);
    }

    onFileChange(e, file) {
        this.props.onFileChange(e, file);
        file = file || e.target.files[0];
        // const {update} = this.props
        // update(file)
        const pattern = /-*/;
        const imagePattern = /image-*/;

        const reader = new FileReader();
        if (file) {
            const isImage = !!file.type.match(imagePattern);
            if (!file.type.match(pattern)) {
                alert('Format Invalid');
                return;
            }

            this.setState({loaded: false});

            reader.onload = (e) => {
                this.setState({
                    imageSrc: reader.result,
                    isImage,
                    loaded: true,
                });
            };
            reader.readAsDataURL(file);
        }
    }

    getFileObject() {
        return this.refs.input.files[0];
    }

    getFileString() {
        return this.state.imageSrc;
    }

    render() {
        // let state = this.state,
        //     props = defaultProps,
        //     labelClass = `uploader uploader-start ${state.loaded && 'loaded'} ${state.imageSrc === '' && 'uploader-start'}`,
        //     borderColor = state.active ? props.activeColor : props.baseColor,
        //     iconColor = state.active
        //         ? props.activeColor
        //         : (state.loaded)
        //             ? props.overlayColor
        //             : props.baseColor,
        //     hideIcon = state.loaded ? 0 : 1;
        let state = this.state,
            props = defaultProps,
            labelClass  = `uploader ${this.props.dashed ? 'uploader-dashed' : ''} ${state.loaded && 'loaded'}`,
            borderColor = state.active ? props.activeColor : props.baseColor,
            iconColor   = state.active
                ? props.activeColor
                : (state.loaded)
                    ? props.overlayColor
                    : props.baseColor,
            hideIcon = state.loaded ? 0 : 1;

        return (
            <label
                className={`${labelClass}`}
                onDragEnter={this.onDragEnter}
                onDragLeave={this.onDragLeave}
                onDragOver={this.onDragOver}
                onDrop={this.onDrop}
                style={{outlineColor: borderColor}}>
                {/* {
                    state.imageSrc === '' ?
                        <div className="w-75 border-img row d-flex text-center border-img-input"
                             style={{height: '6.5rem'}}
                        >
                            <div className="my-auto col text-12">
                                <p className="text-12 my-auto">
                                    <i className="material-icons">file_upload</i>
                                </p>
                                {this.props.placeholder ? this.props.placeholder : 'Upload logo'}
                            </div>
                        </div>
                        :
                        <div>
                            <img
                                src={state.imageSrc ? state.imageSrc : require('assets/img/uploadLogo.PNG')}
                                className={state.loaded ? 'loaded' : undefined}
                                alt='img'
                            />
                        </div>
                } */}
                <img src={state.isImage ? state.imageSrc : require('assets/img/uploadLogo.PNG')} className={state.loaded ? 'loaded' : undefined}/>
                <img style={{ color: iconColor, opacity: hideIcon }} className="icon icon-upload"
                     src={require(`assets/img/uploadLogo.PNG`)} alt=""/>
                <p className="texto gris text-center mt-n1" style={{opacity:hideIcon}}>{this.props.placeholder ? this.props.placeholder : 'Upload logo'}</p>
                {/* <input
                    disabled={this.props.disabled}
                    type="file"
                    accept="image/*"
                    onChange={this.onFileChange}
                    ref="input"
                /> */}
                <input disabled={this.props.disabled} type="file" accept="image/*" onChange={this.onFileChange} ref="input" />
            </label>
        );
    }
}

export default FileUploader;
