import React from "react";
import { Field, reduxForm } from "redux-form";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import {
    customFieldFile,
    renderField,
    renderFieldRadio,
} from "../../../Utils/renderField";
import {
    validate,
    validatorFromFunction,
    validators,
} from "validate-redux-form";

const influencerTeamForm = (props) => {
    const {
        handleSubmit,
        text,
        handleOnChange,
        active,
        setActive,
        loader2,
        closeModal,
        urlTemplate,
        edit,
        fileName,
    } = props;

    const smartTrim = (string, maxLength) => {
        if (!string) return string;
        if (maxLength < 1) return string;
        if (string.length <= maxLength) return string;
        if (maxLength == 1) return string.substring(0,1) + '...';
    
        var midpoint = Math.ceil(string.length / 2);
        var toremove = string.length - maxLength;
        var lstrip = Math.ceil(toremove/2);
        var rstrip = toremove - lstrip;
        return string.substring(0, midpoint-lstrip) + '...' 
        + string.substring(midpoint+rstrip);
    } 

    return (
        <form
            name="brokerageForm"
            className="pt-2 flex col-sm-12 col-lg-10 mx-auto"
            onSubmit={handleSubmit}
            style={{ minHeight: "26rem" }}
        >
            <LoadMask loading={loader2} light>
                <div className="row">
                    <div className="form-group has-feedback col-lg-12 col-sm-12">
                        <label htmlFor="name">Team Name</label>
                        <Field
                            name="name"
                            label="Name"
                            placeholder={""}
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback col-lg-12 col-sm-12">
                        <label htmlFor="total_agents"># of agents in your team</label>
                        <Field
                            type="text"
                            name="total_agents"
                            label="# of agents in your team"
                            component={renderField}
                            placeholder={""}
                            className="form-control"
                            disabled={fileName != null}
                        />
                    </div>
                </div>

                <React.Fragment>
                    <p className="text-12 mt-1 mb-3 text-justify">
                        {edit ? "Update Agent's Roster List" : (
                            <React.Fragment>
                                In order to give your agents access to all the
                                perks on ShowIT Solutions™ platform, you will
                                need to upload your agents roster. To simplify
                                the process, please download agents roster
                                template.
                            </React.Fragment>
                        )}
                    </p>
                    {/*-------------------------------------------------------------------------------*/}
                    {/*-----------------        DOWNLOAD AGENT TEMPLATE            -------------------*/}
                    {/*-------------------------------------------------------------------------------*/}
                    <div className="row flex-row text-center justify-content-center">
                        <div className="col-lg-6 col-md-4 col-sm-8">
                            <label className="text-12">
                                Agent Roster Template
                            </label>
                            <div className="d-flex flex-row justify-content-center">
                                <a
                                    href={urlTemplate}
                                    download="template.xlsx"
                                    className="btn btn-success text-white col-lg-8"
                                >
                                    Download
                                </a>
                            </div>
                        </div>
                    </div>
                    {/*<div className="row w-75 px-5 mx-auto flex-1">*/}
                    {/*-------------------------------------------------------------------------------*/}
                    {/*-----------------            UPLOAD AGENT ROSTER            -------------------*/}
                    {/*-------------------------------------------------------------------------------*/}
                    <div className="row flex-row text-center justify-content-center mt-2">
                        <div className="col-md-3">
                        </div>
                        <div className="col-md-6">
                            <label className="text-12">
                                Import your agent list
                            </label>
                            {/*----------------------------------------*/}
                            {/*------------   if is create  -----------*/}
                            {/*----------------------------------------*/}
                            <div className="d-flex flex-column justify-content-center">
                                <Field
                                    id={"office_excel"}
                                    name="office_excel"
                                    setFile={handleOnChange}
                                    component={customFieldFile}
                                    title={"Import"}
                                    text={text}
                                    customContainerClass="mb-1"
                                    customClass="btn-primary col-lg-8 col-sm-8 py-1 mx-auto"
                                    type={"file"}
                                    active={active}
                                    accept={"*"}
                                    remove={() => setActive(true)}
                                />
                                {fileName && (
                                    <span className="mx-2">{smartTrim(fileName, 20)}</span>
                                )}
                            </div>
                        </div>
                        {edit && (
                            <div className="col-md-3">
                                <label className="text-12">
                                    Update/Replace Roster List?
                                </label>
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <Field
                                        type="radio"
                                        name="roster_replace"
                                        label="Update"
                                        value='update'
                                        component={renderFieldRadio}
                                    />
                                    <Field
                                        type="radio"
                                        name="roster_replace"
                                        label="Replace"
                                        value="replace"
                                        component={renderFieldRadio}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </React.Fragment>

                {/*-------------------------------------------------------------------------------*/}
                {/*-----------------        ACTIONS CREATE OR UPDATE           -------------------*/}
                {/*-------------------------------------------------------------------------------*/}
                <div className="w-100 my-4 mx-auto text-center">
                    <label
                        className={`btn button-03 mx-3 col-lg-4 col-sm-10 my-0`}
                        onClick={closeModal}
                    >
                        Cancel
                    </label>
                    <button
                        type="submit"
                        className="btn btn-primary mx-3 col-lg-4 col-sm-10 mt-sm-1 mt-lg-0 py-0"
                        disabled={loader2}
                    >
                        {edit ? "Update" : "Create"}
                    </button>
                </div>
            </LoadMask>
        </form>
    );
};

const numberPhone = (number) =>
    validatorFromFunction(() => {
        return number.length === 10;
    });

export default reduxForm({
    form: "influencerTeamForm", // a unique identifier for this form
    initialValues: {roster_replace: 'update'},
    validate: (data) => {
        return validate(data, {
            name: validators.exists()("This field is required"),
        });
    },
})(influencerTeamForm);
