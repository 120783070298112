import '../register.css';
import React, {useEffect} from 'react';
import { connect } from "react-redux";
import {Field, reduxForm, formValueSelector} from 'redux-form';
import {validatorFromFunction, validators} from 'validate-redux-form';
import {renderField, SelectField, renderAddressField, renderNumber} from '../../../Utils/renderField';
import {Link} from "react-router-dom";
import {STATE_USA_LIST, BROKER_ROLES_LIST, OTHER} from "../../../../../utility/constants";

const validate = (value) => {
    let error = {}
    if(!value.first_name) {
        error.first_name = "This field is required"
    }
    if(!value.last_name) {
        error.last_name = "This field is required"
    }
    // if(!value.state) {
    //     error.state = "This field is required"
    // }
    if(!value.broker_license_number) {
        error.broker_license_number = "This field is required"
    }
    if(!value.role) {
        error.role = "This field is required"
    }else {
        if (value.role == OTHER && !value.role_name) {
            error.role_name = "This field is required"
        }
    }
    if (!value.address) {
        error.address = "This field is required";
    }
    if (!value.office_phone) {
        error.office_phone = "This field is required";
    } else {
        if (value.office_phone.length != 10) {
            error.office_phone = "Invalid phone number";
        }
    }

    if (
        value.has_multiple_offices == null ||
        value.has_multiple_offices == undefined
    ) {
        error.has_multiple_offices = "This field is required";
    } else {
        if (value.has_multiple_offices == true && !value.number_offices) {
            error.number_offices = "This field is required";
        }
    }

    return error
}

const SingUpFormStep1 = (props) => {
    //  STATE
    const {roleValue, has_multiple_officesValue} = props
    const {handleSubmit, assignAddressData} = props;

    useEffect(() => {
        if (document.getElementById('BROKER-REGISTER')) {
            document.getElementById('BROKER-REGISTER').scrollIntoView({ behavior: 'smooth' });
        }
    }, [])

    return (
        <div className="row flex-1 justify-content-center pt-3">
            <form
                name="register"
                className="w-100 col-xl-10 col-lg-11 col-md-11 col-12 d-flex flex-column"
                onSubmit={handleSubmit}
            >
                <div className="flex-1 d-flex flex-column justify-content-between">
                    <div className="row">
                        <div className="form-group has-feedback col-md-6 col-sm-12 mb-md-4 mb-5">
                            <label htmlFor="first_name" className="mb-0">
                                <strong className="question2">First name</strong>
                            </label>
                            <Field
                                name="first_name"
                                label="First Name"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 col-sm-12 mb-md-4 mb-5">
                            <label htmlFor="first_name" className="mb-0">
                                <strong className="question2">Last name</strong>
                            </label>
                            <Field
                                name="last_name"
                                label="Last Name"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 col-sm-12 mb-md-4 mb-5">
                            <label htmlFor="broker_license_number" className="mb-0">
                                <strong className="question2">
                                    RE License Number{" "}
                                </strong>
                            </label>
                            <Field
                                name="broker_license_number"
                                label="broker_license_number"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 col-sm-12 mb-md-4 mb-5">
                            <label htmlFor="role">
                                <strong className="question2">
                                    Select Your Office Role
                                </strong>
                            </label>
                            <Field
                                name="role"
                                // label="roles"
                                isMulti={false}
                                isSub={false}
                                options={BROKER_ROLES_LIST}
                                component={SelectField}
                                className="form-control"
                            />
                        </div>
                        {roleValue == OTHER && (
                            <div className="form-group has-feedback col-md-6 col-sm-12 mb-md-4 mb-5">
                                <label htmlFor="role_name">
                                    <strong className="question2">
                                        Role name
                                    </strong>
                                </label>
                                <Field
                                    name="role_name"
                                    label="role_name"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        )}
                        <div className="form-group has-feedback col-md-6 col-sm-12 mb-md-4 mb-5">
                            <label htmlFor="address">
                                <strong className="question2">
                                    Office Address
                                </strong>
                            </label>
                            <Field
                                name="address"
                                label="Address"
                                placeholder='Address'
                                component={renderAddressField}
                                type="text"
                                className="form-control"
                                longStateName={true}
                                setCityState={(data) => {
                                    console.log('ADDRESS DATA: ', data);
                                    if (data) {
                                        assignAddressData(data, "register")
                                    }
                                }}
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 col-sm-12 mb-md-4 mb-5">
                            <label htmlFor="office_phone">
                                <strong className="question2">
                                    Office Phone Number
                                </strong>
                            </label>
                            <Field
                                name="office_phone"
                                type="tel"
                                component={renderNumber}
                                numberFormat={"###-###-####"}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 col-sm-12 mb-md-4 mb-5">
                            <label htmlFor="has_multiple_offices">
                                <strong className="question2">
                                    Do you have multiple offices
                                </strong>
                            </label>
                            <Field
                                name="has_multiple_offices"
                                isMulti={false}
                                isSub={false}
                                options={[{label: "Yes", value: true}, {label: "No", value: false}]}
                                component={SelectField}
                                className="form-control"
                            />
                        </div>
                        {has_multiple_officesValue == true ? (
                            <div className="form-group has-feedback col-lg-3 col-md-6 col-sm-12 mb-md-4 mb-5">
                                <label htmlFor="number_offices">
                                    <strong className="question2">
                                        # of Offices
                                    </strong>
                                </label>
                                <Field
                                    name="number_offices"
                                    type="number"
                                    min={0}
                                    step={1}
                                    component={renderField}
                                    className="form-control"
                                />
                            </div>
                        ): null}
                        {has_multiple_officesValue == true ? (
                            <div className="form-group has-feedback col-lg-3 col-md-6 col-sm-12 mb-md-4 mb-5">
                                <label htmlFor="group_name">
                                    <strong className="question2">
                                        Group Name
                                    </strong>
                                </label>
                                <Field
                                    name="group_name"
                                    type="text"
                                    component={renderField}
                                    className="form-control"
                                />
                            </div>
                        ): null}
                    </div>
                    <div className="row mb-4">
                        <div className="buttons-box justify-content-center col-12">
                            <span className='text-center broker-register-info-text'>
                                By proceeding, I affirm that I have read and agreed to the {' '}
                                <a
                                    href="https://showitsolutions.com/terms-and-conditions/"
                                    target="_blank"
                                    className="a-link"
                                >
                                    Terms {"&"} Conditions
                                </a>, {' '}
                                <a
                                    href="https://showitsolutions.com/privacy-policy/"
                                    target="_blank"
                                    className="a-link"
                                >
                                    Privacy Policy
                                </a>{' '}and{' '}
                                <a
                                    href="https://showitsolutions.com/brokerage-and-licensee-consent/"
                                    target="_blank"
                                    className="a-link"
                                >
                                    Brokerage / Licensee Consent
                                </a>
                            </span>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="buttons-box col-12 justify-content-center mx-auto py-md-3 py-4">
                            <button
                                type="submit"
                                className="btn broker-register-button col-xl-4 col-lg-5 align-self-center"
                            >
                                Next
                            </button>
                        </div>

                    </div>
                </div>
            </form>
        </div>
    );
};

const registerForm = reduxForm({
    form: 'register', // a unique identifier for this form
    validate,
})(SingUpFormStep1);

const selector = formValueSelector('register');
const mstp = state => {
    const roleValue = selector(state, 'role');
    const has_multiple_officesValue = selector(state, 'has_multiple_offices');
    return {
        roleValue,
        has_multiple_officesValue,
    };
};

export default connect(mstp, null)(registerForm);

