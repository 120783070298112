import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/broker/broker';
import List from "./List";

const ms2p = (state) => {
    return{
        ...state.broker,
        me: state.login.me
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(List);
