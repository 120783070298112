import React, {useEffect} from "react";
import './vendorRegister.css';
import "react-step-progress-bar/styles.css";

export const InfoVendor = (props) => {
    const {onSubmit} = props

    useEffect(() => {
        if (document.getElementById('VENDOR-REGISTER')) {
            document.getElementById('VENDOR-REGISTER').scrollIntoView({ behavior: 'smooth' });
        }
    }, [])

    return (
        <div className="row flex-1 justify-content-center pt-3">
            <div className="w-100 col-xl-10 col-lg-11 col-md-11 col-12 d-flex flex-column">
                <div className="flex-1 d-flex flex-column justify-content-between">
                    <div className="row">
                        <div className="col-12 d-flex flex-column">
                            <span className="mb-0 vendor-register-info-text">
                                We are excited to welcome you to ShowIT Solutions App platform!
                                <br />
                                We are thrilled to have you on board as a vendor and look forward to working with you. We believe that our platform will provide a great opportunity for you to reach new customers and grow your business.
                                <br />
                                Our app allows Real Estate agents and their client to browse and order services from a variety of vendors, and we are confident that your services will be a great fit for our platform.
                            </span>
                            <br />
                            <span className="mb-0 vendor-register-info-text">
                                To get started, please provide us with your vendor details and product information. We will then work with you to set up your account and get your services live on the platform.
                            </span>
                            <br />
                            <span className="mb-0 vendor-register-info-text">
                                Should you have any questions or concerns, please feel free to reach out to us. Your satisfaction is our priority, and we are committed to providing the support you need for a positive experience on our platform.
                            </span>
                            <br />
                            <span className="vendor-register-info-text">
                                Thank you for choosing to be a part of the ShowIT Solutions app. We eagerly anticipate a successful partnership and look forward to working with you.
                            </span>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="buttons-box col-12 justify-content-center mx-auto py-md-3 py-4">
                            <button
                                onClick={onSubmit}
                                type="button"
                                className="btn vendor-register-button col-xl-4 col-lg-5 col-sm-8 align-self-center"
                            >
                                Get Started
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const StepProgressBar = (props) => {
    const {progress, showAllStep=true} = props;

    return (
        <div className="justify-content-center d-flex mb-4 pt-0 mt-0">
            <div className="circle ml-3 active-circle"> </div>
            <div className={`circle ${progress && progress >= 40? "active-circle": "" }`}> </div>
            <div className={`circle ${progress && progress >= 60? "active-circle": "" }`}> </div>
            {showAllStep && <div className={`circle ${progress && progress >= 80? "active-circle": "" }`}> </div>}
            {showAllStep && <div className={`circle ${progress && progress >= 100? "active-circle": "" }`}> </div>}
            {showAllStep && <div className={`circle ${progress && progress >= 120? "active-circle": "" }`}> </div>}
        </div>
    )
}

export const SuccessRegistered = () => {

    useEffect(() => {
        if (document.getElementById('VENDOR-REGISTER')) {
            document.getElementById('VENDOR-REGISTER').scrollIntoView({ behavior: 'smooth' });
        }
    }, [])

    return (
        <div className="row flex-1 justify-content-center pt-3">
            <div className="w-100 col-xl-10 col-lg-11 col-md-11 col-12 d-flex flex-column">
                <div className="flex-1 d-flex flex-column justify-content-between">
                    <div className="row">
                        <div className="col-12 d-flex flex-column">
                            <h3 className="mb-3 text-center vendor-register-label-title">Thank you for registering</h3>
                            <span className="mb-3 text-center vendor-register-label-subtitle">
                            Please allow up to 24 hours to activate your account
                            </span>
                            <span className="mb-3 text-center vendor-register-label-subtitle">
                                Please download the ShowIT Solutions app on your phone.  Once your account is activated, you will recieve on email with login cradentials to your account on ShowIT Solutions™ app
                            </span>
                            <span className="mb-0 mt-4 font-weight-bold text-center d-flex align-items-center justify-content-center">
                                <p
                                    className="h4 mb-0 font-weight-bold"
                                    style={{ color: "#AA1711", marginRight: "10px" }}
                                >
                                    Download
                                </p>
                                <p className="h4 mb-0 font-weight-bold">Today</p>
                            </span>
                            <div className="w-100 d-flex flex-column flex-md-row align-items-center justify-content-center">
                                <img
                                    className="img-fluid"
                                    style={{ maxWidth: "230px" }}
                                    src={`https://chart.googleapis.com/chart?chs=400x400&cht=qr&chl=${encodeURIComponent(
                                        "http://onelink.to/yy2qau"
                                    )}&choe=UTF-8`}
                                    alt=""
                                />
                                <a className="a-link vendor-register-link" href="http://onelink.to/yy2qau" target="_blank" rel="link"> http://onelink.to/yy2qau</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StepProgressBar;
