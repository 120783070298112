import React from "react";
import moment from "moment";
import {connect} from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { renderSearchField, MultiSelectField, renderDayPicker, SelectField } from "../../../Utils/renderField";
import {
    SELECT_ALL_OPTION,
    AGENT_PERFORMANCE_TIME
} from '../../../../../utility/constants'

let AgentPerformanceFilterForm = (props) => {
    //  State
    const {
        optionBrokerages=[],
        timeValue,
        start_dateValue,
        end_dateValue,
    } = props;

    //  Bind
    const {changeFilters} = props;

    return (
        <div className="row">
            <div className={`col-xl-2 col-lg-4 col-sm-12 col-md-12 mt-2 d-flex flex-row align-items-end`}>
                <div className="form-group has-feedback w-100">
                    <Field
                        name="search"
                        label="Search"
                        placeholder={'Search Agent'}
                        component={renderSearchField}
                        onChange={(e) => {
                            changeFilters(
                                "search",
                                e.target && e.target.value ? e.target.value : ""
                            )
                        }}
                        type="text"
                    />
                </div>

            </div>
            
            <div className={`col-xl-10 col-lg-8 col-sm-12 col-md-12`}>
                <div className="row">
                    <div className={`col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2`}>
                        <div className="form-group has-feedback w-100">
                            <label htmlFor="brokerage">Office</label>
                            <Field
                                name="brokerage"
                                options={optionBrokerages}
                                set_value={(value) => {
                                    changeFilters(
                                        "brokerage",
                                        value
                                    )

                                }}
                                component={MultiSelectField}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2">
                        <div className="form-group has-feedback w-100">
                            <label htmlFor="time">Time</label>
                            <Field
                                name="time"
                                isMulti={false}
                                options={AGENT_PERFORMANCE_TIME}
                                set_value={(option) => {
                                    changeFilters(
                                        "time",
                                        option ? option.value : ""
                                    )
                                }}
                                component={SelectField}
                                className="form-control"
                            />
                        </div>
                    </div>
                    {timeValue == 'custom' && (
                        <React.Fragment>
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2">
                                <div className="form-group has-feedback w-100">
                                    <label htmlFor="start_date">Start Date</label>
                                    <Field
                                        name="start_date"
                                        initialDate={moment().format('YYYY-MM-DD').toString()}
                                        component={renderDayPicker}
                                        maxDate={end_dateValue ? moment(end_dateValue).toDate() : new Date()}
                                        onChange={(e) => {
                                            changeFilters(
                                                "start_date",
                                                ""
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2">
                                <div className="form-group has-feedback w-100">
                                    <label htmlFor="end_date">End Date</label>
                                    <Field
                                        name="end_date"
                                        initialDate={moment().format('YYYY-MM-DD').toString()}
                                        component={renderDayPicker}
                                        minDate={start_dateValue ? moment(start_dateValue).toDate() : new Date()}
                                        onChange={(e) => {
                                            changeFilters(
                                                "end_date",
                                                ""
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div> 
    );
};

AgentPerformanceFilterForm = reduxForm({
    form: 'AgentPerformanceFilterForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(AgentPerformanceFilterForm);

const selector = formValueSelector('AgentPerformanceFilterForm');

const mstp = state => {
    const timeValue = selector(state, 'time');
    const start_dateValue = selector(state, 'start_date');
    const end_dateValue = selector(state, 'end_date');

    return {
        timeValue,
        start_dateValue,
        end_dateValue,
    }
}

export default connect(mstp, null)(AgentPerformanceFilterForm);
