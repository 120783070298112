import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/dashboard';
import Dashboard from "./Dashboard";

const ms2p = (state) => {
    return {
        ...state.dashboard,
        login: state.login,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Dashboard);
