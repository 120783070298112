import React from 'react';
import moment from "moment";
import {
    fieldRadioGroup,
    renderField,
    renderNumber,
    SelectField,
    renderAddressField
} from "../../Utils/renderField";
import {
    STATE_USA_LIST,
    STATUS_BROKER,
    DENIED_BROKER,
    PENDING_BROKER,
    VERIFIED_BROKER,
    LOCKED_BROKER,
    BLOCKED_BROKER,
} from "../../../../utility/constants";
import { renderFilePicker } from "../../Utils/renderField/renderField";
import {Link} from "react-router-dom";
import {Field, reduxForm} from "redux-form";

const validate = (values) => {
    let errors = {};

    if (!values.name) {
        errors.name = "This field is required";
    }
    if (!values.owner_email) {
        errors.owner_email = "This field is required";
    }
    if (!values.license) {
        errors.license = "This field is required";
    }
    if (!values.office_phone_number) {
        errors.office_phone_number = "This field is required";
    } else {
        if (values.office_phone_number.length < 10) {
            errors.office_phone_number = "Invalid phone number";
        }
    }
    if (
        values.provide_agents_roster !== true &&
        values.provide_agents_roster !== false
    ) {
        errors.provide_agents_roster = "This field is required";
    }

    return errors;
};

const BrokerProfileForm = (props) => {
    const {handleSubmit, is_view = false, item, goToPage, assignAddressData} = props;

    const getStatus = (status) => {
        let text = "";
        STATUS_BROKER.map((item) => {
            if (status === item.value) {
                text = item.label;
            }
        });
        return text;
    };

    const getColor = (status) => {
        let colorText = "black";
        if (status === DENIED_BROKER) {
            colorText = "danger";
        } else if (status === PENDING_BROKER) {
            colorText = "warning";
        } else if (status === VERIFIED_BROKER) {
            colorText = "success";
        } else if (status === LOCKED_BROKER) {
            colorText = "danger";
        } else if (status === BLOCKED_BROKER) {
            colorText = "danger";
        }
        return colorText;
    };

    return (
        <form name="brokerProfileForm" className="my-1 col-sm-12 p-sm-0" onSubmit={handleSubmit}>
            <div className="row mx-auto pt-2">
                <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12">
                    {item.logo_office && (
                        <div className="form-group has-feedback">
                            <Field
                                photo={item.logo_office ? item.logo_office : null}
                                setFile={() => {}}
                                name="photo"
                                component={renderFilePicker}
                                disabled={true}
                                // className="avatar-round"
                            />
                        </div>
                    )}
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Sign-up Date
                        </span>
                        <span className="font-weight-bolder text-primary">
                            {moment(item.created).format("ll")}
                        </span>
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mb-5">
                        <span className="font-weight-bolder h6 mb-1">
                            Status
                        </span>
                        {item && (
                            <span
                                className={`text-${getColor(
                                    item.status
                                )} font-weight-bold`}
                            >
                                {getStatus(item.status)}
                            </span>
                        )}
                    </div>
                    {item && item.status === VERIFIED_BROKER && (
                        <React.Fragment>
                            <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                                <span className="font-weight-bolder h6 mb-1">
                                    Total Agents
                                </span>
                                {item && item.agents_stats && (
                                    <span
                                        className="font-weight-bolder text-primary pointer"
                                        onClick={(e) => goToPage('/agents')}
                                    >
                                        {item.agents_stats.total_agents
                                            ? item.agents_stats.total_agents
                                            : 0}
                                    </span>
                                )}
                            </div>
                            <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                                <span className="font-weight-bolder h6 mb-1">
                                    Registered Agents
                                </span>
                                {item && item.agents_stats && (
                                    <span
                                        className="font-weight-bolder text-primary pointet"
                                        onClick={(e) => goToPage('/agents')}
                                    >
                                        {item.agents_stats.total_user
                                            ? item.agents_stats.total_user
                                            : 0}
                                    </span>
                                )}
                            </div>
                            <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                                <span className="font-weight-bolder h6 mb-1">
                                    Unregistered Agents
                                </span>
                                {item && item.agents_stats && (
                                    <span
                                        className="font-weight-bolder text-primary pointer"
                                        onClick={(e) => goToPage('/agents')}
                                    >
                                        {item.agents_stats.total_unregistered
                                            ? item.agents_stats
                                                  .total_unregistered
                                            : 0}
                                    </span>
                                )}
                            </div>
                            <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                                <span className="font-weight-bolder h6 mb-1">
                                    Active Agents
                                </span>
                                {item && item.agents_stats && (
                                    <span
                                        className="font-weight-bolder text-primary pointer"
                                        onClick={(e) => goToPage('/agents')}
                                    >
                                        {item.agents_stats.total_user_active
                                            ? item.agents_stats
                                                  .total_user_active
                                            : 0}
                                    </span>
                                )}
                            </div>
                            <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                                <span className="font-weight-bolder h6 mb-1">
                                    Total ShowIT Share
                                </span>
                                {item && (
                                    <span className="font-weight-bolder text-primary">
                                        ${" "}
                                        {item.total_show_it_share
                                            ? item.total_show_it_share.toFixed(
                                                  2
                                              )
                                            : "0.00"}
                                    </span>
                                )}
                            </div>
                            {/* <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                                <span className="font-weight-bolder h6 mb-1">
                                    Total ShowIT Revenue
                                </span>
                                {item && (
                                    <span className="font-weight-bolder text-primary">
                                        ${" "}
                                        {item.total_show_it_revenue
                                            ? item.total_show_it_revenue.toFixed(
                                                  2
                                              )
                                            : "0.00"}
                                    </span>
                                )}
                            </div> */}
                        </React.Fragment>
                    )}
                </div>
                <div className="col-xl-9 col-lg-9 col-md-7 col-sm-12">
                    <div className="row">
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="name">Broker Full Name</label>
                            <Field
                                name="name"
                                label="Broker Full Name"
                                component={renderField}
                                type="text"
                                className="form-control"
                                disabled={is_view}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="license">RE License #</label>
                            <Field
                                name="license"
                                label="License number"
                                component={renderField}
                                type="text"
                                className="form-control"
                                disabled={is_view}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="owner_email">Email</label>
                            <Field
                                name="owner_email"
                                label="email"
                                component={renderField}
                                type="email"
                                className="form-control"
                                disabled={is_view}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label
                                htmlFor="office_phone_number"
                                className="mx-0 px-0"
                            >
                                Phone #
                            </label>
                            <Field
                                numberFormat={"###-###-####"}
                                name="office_phone_number"
                                component={renderNumber}
                                className="form-control"
                                disabled={is_view}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label
                                htmlFor="mobile_number"
                                className="mx-0 px-0"
                            >
                                Mobile #
                            </label>
                            <Field
                                numberFormat={"###-###-####"}
                                name="mobile_number"
                                component={renderNumber}
                                className="form-control"
                                disabled={is_view}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="group_name">Group Name</label>
                            <Field
                                name="group_name"
                                label="Group Name"
                                component={renderField}
                                type="text"
                                className="form-control"
                                disabled={is_view}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="address">Address</label>
                            <Field
                                name="address"
                                label="Address"
                                placeholder='Address'
                                component={renderAddressField}
                                type="text"
                                className="form-control"
                                longStateName={true}
                                setCityState={(data) => {
                                    console.log('ADDRESS DATA: ', data);
                                    if (data) {
                                        assignAddressData(data, "registerStep2")
                                    }
                                }}
                            />
                            {/* <Field
                                name="address"
                                label="Address"
                                component={renderField}
                                type="text"
                                className="form-control"
                                disabled={is_view}
                            /> */}
                        </div>
                        {/* <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="account_status">State</label>
                            <Field
                                name="state"
                                label="state"
                                disabled={is_view}
                                options={STATE_USA_LIST}
                                component={SelectField}
                                className="form-control"
                            />
                        </div> */}
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="languages"># of Offices</label>
                            <div className="d-flex flex-row">
                                <div
                                    className="flex-1 form-control text-primary"
                                >
                                    {item &&
                                        (item.has_multiple_offices
                                            ? item.number_offices
                                                ? item.number_offices
                                                : ""
                                            : item.offices
                                            ? item.offices.length
                                            : "")}
                                </div>
                            </div>
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="languages">Brokerage Licence</label>
                            <div
                                className="form-control text-area-h-auto"
                                style={{ height: "auto !important" }}
                            >
                                {item &&
                                    item.offices &&
                                    item.offices.map((office, index) => {
                                        if (index == 0) {
                                            return (
                                                <span key={index}>{office.license}</span>
                                            );
                                        }
                                        return (
                                            <span key={index}>
                                                <span className="pr-3"></span>
                                                <span className="pr-3">/</span>
                                                <span>{office.license}</span>
                                            </span>
                                        );
                                    })}
                            </div>
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="provide_agents_roster">
                                Will you provide agent's roster list?
                            </label>
                            <Field
                                name="provide_agents_roster"
                                label="provide_agents_roster"
                                options={[
                                    { label: "Yes", value: true },
                                    { label: "No", value: false },
                                ]}
                                component={SelectField}
                                className="form-control mb-2 pb-5"
                            />
                        </div>
                        <div className="row mx-auto">
                            <Field
                                name="operation_access"
                                disabled={is_view}
                                initialValue={item.operation_access}
                                component={fieldRadioGroup}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="row w-100 mt-5 justify-content-center ">
                        {/* <Link to={'/'} className={`w-100 btn btn-secondary text-white col-lg-2 col-md-4 col-sm-12 mx-4 mt-2`}>
                            Cancel
                        </Link> */}
                        <button type="submit" className="btn btn-primary col-lg-2 col-md-4 col-sm-12 mt-2">
                            Update
                        </button>
                    </div>
                </div>
            </div>

        </form>
    )
}

const numberPhone = (number) => validatorFromFunction(() => {
    return number.length === 10;
});

    export default reduxForm({
    form: 'brokerProfileForm', // a unique identifier for this form
    validate,
})(BrokerProfileForm);
