import { connect } from 'react-redux';
import './agent.css'
import { setTab, getStats, getBrokeragesOption, changeFilters, resetData } from '../../../redux/modules/agent/agent';
import React, {Component} from 'react';
import Tabs, {TabPane} from "rc-tabs";
import AgentRegistered from "./Register/index";
import TabContent from "rc-tabs/lib/TabContent";
import AgentFiltersForm from './AgentFiltersForm'
import AgentUnregistered from "./Unregister/AgentContainer";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";

class AgentTab extends Component {

    state = {
        tabContainerHeight: '200px',
    }

    componentDidMount() {
        // this.props.getAgentStats()
        setTimeout(() => {
            const tabContainerHeight = document.getElementById('AGENT-TAB-CONTAINER').clientHeight;
            this.setState({tabContainerHeight})
            setTimeout(() => {
                this.props.getAgentBrokerages(true, true)
            }, 400)
        }, 300)
    }

    componentWillUnmount() {
        this.props.resetAgentData()
    }

    render() {
        //  State
        const {tab, stats, brokerages} = this.props;
        const {tabContainerHeight} = this.state;

        console.log("BROKERAGES: ", brokerages);
        //  Bind
        const {changeTab, changeAgentFilters} = this.props

        const getPercent = (total, count) => {
            try {
                if (total > 0) {
                    let percent = 0;
                    if (total >= count) {
                        percent = (count * 100) / total;
                    }
        
                    return percent;
                }else{
                    return 0
                }
            } catch (error) {
                return 0
            }
        }

        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row pt-0 justify-content-between">
                    <div className="">
                        <div className="header-page">Agents</div>
                    </div>
                    <div className='stats-section'>
                        <div className='stats-item'>
                            <span className='text-center'>Total Agents</span>
                            {stats.total_agent}
                        </div>
                        <div className='stats-item'>
                            <span className='text-center'>Unregistered</span>
                            {stats.total_unregistered} ({getPercent(stats.total_agent, stats.total_unregistered).toFixed(2)}%)
                        </div>
                        <div className='stats-item'>
                            <span className='text-center'>Registered</span>
                            {stats.total_registered} ({getPercent(stats.total_agent, stats.total_registered).toFixed(2)}%)
                        </div>
                        <div className='stats-item'>
                            <span className='text-center'>Active</span>
                            {stats.total_user_active} ({getPercent(stats.total_registered, stats.total_user_active).toFixed(2)}%)
                        </div>
                    </div>
                </div>
                <Tabs
                    activeKey={tab}
                    defaultActiveKey="Registered"
                    tabBarPosition="top"
                    onChange={(key) => changeTab(key)}
                    renderTabBar={() => <ScrollableInkTabBar/>}
                    renderTabContent={() => <TabContent/>}
                >
                    <TabPane tab="Registered" key="Registered" className="py-0 d-flex flex-column">
                        <div className="px-3">
                            <AgentFiltersForm
                                optionBrokerages={brokerages}
                                changeFilters={changeAgentFilters}
                                typeState='registered'/>
                        </div>
                        <div id="AGENT-TAB-CONTAINER" className="flex-1 px-3 pt-2 mb-1 d-flex flex-column" style={{position: 'relative'}}>
                            <AgentRegistered tabContainerHeight={tabContainerHeight}/>
                        </div>
                    </TabPane>
                    <TabPane tab="Unregistered" key="Unregistered" className="py-0 d-flex flex-column">
                        <div className="px-3">
                            <AgentFiltersForm
                                optionBrokerages={brokerages}
                                changeFilters={changeAgentFilters}
                                typeState='unregistered'/>
                        </div>
                        <div id="AGENT-TAB-CONTAINER" className="flex-1 px-3 pt-2 mb-1 d-flex flex-column" style={{position: 'relative'}}>
                            <AgentUnregistered tabContainerHeight={tabContainerHeight}/>
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}

// export default AgentTab;
const ms2p = (state) => {
    return{
        tab: state.agent.tab,
        stats: state.agent.stats,
        brokerages: state.agent.brokerages,
    };
};

const md2p = {
    changeTab: setTab,
    getAgentStats: getStats,
    getAgentBrokerages: getBrokeragesOption,
    changeAgentFilters: changeFilters,
    resetAgentData: resetData,
};

export default connect(ms2p, md2p)(AgentTab);