import React, {Component} from 'react';
import ChangedPasswordForm from "./ChangedPasswordForm";

class ChangedPassword extends Component {

    static defaultProps = {
        loading: false,
    };

    componentDidMount() {
        const {match, getAgentUser} = this.props;
        getAgentUser(match.params.id);
    }

    goBack = () => {
        const {history} = this.props;
        history.goBack();
    }

    sendData = (data)=>{
        const {item, changedPasse, history} = this.props;
        changedPasse(item.id, {...data, "email":item.email}, this.goBack);
    }

    render() {
        const { loader, item } = this.props;
        return (


            <div className="d-flex flex-column">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="w-100 pr-4">
                        <div className="header-page d-flex justify-content-between">
                            <div> Changed Password
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="card border-dark border-card-page px-4 py-2"
                    style={{
                        minWidth: '99%',
                        minHeight: '15%',
                    }}
                >
                    <ChangedPasswordForm
                        loader={loader}
                        goBack={this.goBack}
                        onSubmit={this.sendData}
                        item={item}
                    />
                </div>
            </div>
        );
    }
}

export default ChangedPassword;
