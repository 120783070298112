import React, { Component } from 'react';
import UpdateForm from "./UpdateForm";
import Swal from 'sweetalert2';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class UpdateAgent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            is_view: false,
            is_update: false,
            photo: null,
        };
    }

    componentDidMount() {
        const {match, read} = this.props;
        if (match.params.id) {
            read(match.params.id);
            console.log("PATH: ", match.path);
            if (match.path === '/agents/agent/:id/edit') {
                this.setState({is_update: false, is_view: true});
            }
            if (match.path === '/agents/agent-details/:id') {
                this.setState({is_update: false, is_view: true});
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.item.working_area !== prevProps.item.working_area) {
            this.props.setWorkingArea({
                latitude_center: this.props.item.working_area ? this.props.item.working_area.latitude_center : prevProps.item.working_area.latitude_center,
                longitude_center: this.props.item.working_area ? this.props.item.working_area.longitude_center : prevProps.item.working_area.longitude_center,
            })
        }
    }

    updateForm = (data) => {
        const {editAgent} = this.props;
        if (data.signed) {
            if (this.state.photo) {
                const {simpleUpdate} = this.props;
                simpleUpdate({...data, photo: null}, [{"name": "photo", "file": this.state.photo}]);
            } else {
                editAgent(data.id, data);
            }
        }
        if (!data.signed) {
            editAgent(data.id, data);
        }
    };

    setAvatar = (photo) => {
        this.setState({photo});
    };

    activateUser = (data) => {
        // const {activateAgent} = this.props;
        // activateAgent(data.id, data);
    }

    remove = (item) => {
        if (item.agent_account_status != 40) {
            Swal.fire('', 'Agent status must change to locked prior to deleting the agent', 'info')
        }else {
            Swal.fire({
                title: 'Are you sure you want to delete this Agent?',
                // text: "By doing that, the agent's detailed info will be completely deleted\nThis action can't be reverted!",
                html: `<p style="font-size: 15px; font-weight: 400; margin-bottom: 0">By doing that, the agent's detailed info will be completely deleted</p><br/><p style="font-size: 20px; font-weight: bold;margin-bottom: 0"> This action can't be reverted!?</p>`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#7E8A97',
                cancelButtonColor: '#B9BDC1',
                confirmButtonText: 'Yes, delete it!',
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    this.props.removeAgent(item.id);
                }
            });
        }
    };

    render() {
        const { loader, item, workingArea, goPage } = this.props;

        const pendingAgent = item && item.agent_account_status == 10 ? true : false;
        const submit = pendingAgent ? this.activateUser : this.updateForm;

        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row pt-0">
                    <div className="">
                        <div className="header-page">
                            <button className='btn back-btn mr-3 my-2' onClick={() => this.props.history.goBack()}>
                                <i className="material-icons pointer">arrow_back</i> 
                            </button>
                            {pendingAgent && !this.state.is_view && "Pending Agent"}
                            {(this.state.is_view && "Agent Profile") || (this.state.is_update && !pendingAgent && "Agent Profile")}
                        </div>
                    </div>
                </div>
                <div
                    className="card border-card-page px-lg-4 py-2"
                    style={{
                        minWidth: '99%',
                        minHeight: '15%',
                    }}
                >
                    <LoadMask loading={loader} light>
                        <UpdateForm
                            loader={loader}
                            onSubmit={submit}
                            is_view={this.state.is_view}
                            is_update={this.state.is_update}
                            item={item}
                            setAvatar={this.setAvatar}
                            center={{
                                lat: workingArea.latitude_center,
                                lng: workingArea.longitude_center
                            }}
                            remove={this.remove}
                            goPage={goPage}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default UpdateAgent;
