import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import FormStep1 from "./Steps/Step1";
import FormStep2 from "./Steps/Step2";
import FormStep3 from "./Steps/Step3";
import StepProgressBar from "../Register/Forms/StepProgessBar";

class RegisterForInvites extends Component {
    constructor(props) {
        super(props);
        this.state = {
            back: false,
            showPassword: false,
        }
    }

    actionOnSubmit = () => {
        this.props.onSubmitAction();
    };

    action = () => {
        const {setSuccess} = this.props;
        setSuccess(true);
    };

    componentDidMount() {
        const {setSuccess, setStep} = this.props;
        setSuccess(false);
        this.props.resetInitialData()
        setStep(20);
    }

    goLogin = () => {
        this.props.goLogin();
    }

    showPass = (value) => {
        this.setState({showPassword: value})
    }

    render() {
        const {
            step,
            data,
            loader,
            code,
            stepBack,
            resendCodePhone,
            validateCode2,
            goToStep2,
            clickGoBack2,
            validateNumber,
            submitRegisterInvite,
        } = this.props;

        if (localStorage.getItem('token')) {
            return (<Redirect to="/"/>);
        }

        return (
            <div className="body-img">
                <div className="blue-gradient-bg">
                    <div className="login-wrapper  h-100" style={{minWidth: '60%'}}>
                        <div className="card card-login col-lg-7 col-sm-12 px-lg-2" style={{minHeight: '40%'}}>
                            {(step < 80) && <img
                                className="ml-4 mt-2 back-icon"
                                src={require('../../../../../assets/img/chevron-left-solid.svg')}
                                alt='back'
                                onClick={step === 20 ? () => this.goLogin() : () => clickGoBack2()}
                            />}
                            <div className="container-logo-register justify-content-center  align-self-center mt-0">
                                <img
                                    src={require("../../../../../assets/img/logo-01.png")}
                                    className="logo-form"
                                    alt={"showIt logo"}/>
                            </div>
                            <h5 className="text-center mt-2 broker-register">
                                {(step === 80) ?
                                    data.first_name + ' ' + data.last_name :
                                    'BROKER'
                                }
                            </h5>
                            <LoadMask loading={loader} light>
                                <div className="mt-0 justify-content-center mx-auto">
                                    {step < 80 && <StepProgressBar progress={step} showAllStep={false}/>}
                                    {step === 20 &&
                                    <FormStep1 onSubmit={goToStep2}/>}
                                    {(step === 40) &&
                                    <FormStep2
                                        code={code}
                                        stepBack={stepBack}
                                        onSubmit={validateNumber}
                                        validateCode={validateCode2}
                                        resendCode={resendCodePhone}
                                    />
                                    }
                                    {step === 60 &&
                                    <FormStep3
                                        onSubmit={submitRegisterInvite}
                                        showPassAction={this.showPass}
                                        showPassword={this.state.showPassword}
                                    />
                                    }
                                </div>
                            </LoadMask>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RegisterForInvites;
