import React, { Component } from "react";
import { JobDetailRawDataGrid } from "./JobDetailRawDataComponent";
import { reduxForm, Field } from "redux-form";
import { SelectField } from "../../../Utils/renderField";

import FilterContainer from "./JobDetailRawDataComponent";
class JobDetailRawDataList extends Component {
    componentDidMount() {
        this.props.getJobDetailsRawData();
    }
    changePage = (page) => {
        this.props.getJobDetailsRawData(page);
    };

    render() {
        //  Status
        const { dataStats, loader, page, downloadLoader, me } = this.props;
        //  Bind
        const { downloadJobDetailsRawDataReport, setJobStatusFilter, setTimeFilter, changeJobTransferType } = this.props;

        return (
            <div className="d-flex flex-column">
                <div className="page-header pl-1 pt-2 no-gutters row justify-content-between">
                    <div className="">
                        <div className="header-page col">
                            Stats / Job details raw data
                        </div>
                    </div>
                </div>
                <FilterContainer
                    downloadLoader={downloadLoader}
                    // setStatusFilter={setJobStatusFilter}
                    setStatusFilter={setJobStatusFilter}
                    setTimeFilter={setTimeFilter}
                    downloadJobDetailsRawDataReport={downloadJobDetailsRawDataReport}
                />
                <div className="card border-card-page px-0 py-2 mt-2">
                    <JobDetailRawDataGrid
                        page={page}
                        data={dataStats}
                        loader={loader}
                        nextPage={this.changePage}
                        paymentThroughBroker={me ? me.payment_through_broker : false}
                        changeJobTransferType={changeJobTransferType}
                    />
                </div>
            </div>
        );
    }
}

export default JobDetailRawDataList;
