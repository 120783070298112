import {api} from "api";
import moment from "moment";
import {handleActions} from "redux-actions";
import {change, initialize} from "redux-form";
import {get1Month, get3Month, getMonths, LabelDaysOfWeek, SELECT_ALL_OPTION, getDashboardDateRange} from "../../../utility/constants";

const SET_DATA = "SET_DATA_DASHBOARD";
const SET_LOADER = "SET_LOADER_DASHBOARD";
const SET_LOADER_2 = "SET_LOADER_PERFORMANCE_DASHBOARD";
const SET_JOB_NAMES = "SET_JOB_NAMES_DASHBOARD";
const SET_DATA_OFFICES = "SET_DATA_OFFICES_DASHBOARD";
const SET_FILTER_MONTH = "SET_FILTER_MONTH_DASHBOARD";
const SET_ITEM_REVENUE = "SET_ITEM_REVENUE_DASHBOARD";
const SET_DATA_AGENT_OFFICES = "SET_DATA_AGENT_OFFICES_DASHBOARD";
const SET_FILTER_JOB_TYPE_ID = "SET_FILTER_JOB_TYPE_ID_DASHBOARD";
const SET_FILTER_BROKERAGE_ID = "SET_FILTER_BROKERAGE_ID_DASHBOARD";
const SET_FILTER_JOB_OPTION_ID = "SET_FILTER_JOB_OPTION_ID_DASHBOARD";
const SET_ITEM_JOB_WEEK = "SET_ITEM_JOB_WEEK_DASHBOARD";
const SET_VALUE_FILTER_AGENT_ID = "SET_VALUE_FILTER_AGENT_ID_DASHBOARD";
const SET_VALUE_FILTER_WEEK = "SET_VALUE_FILTER_WEEK_DASHBOARD";
const SET_VALUE_FILTER_RANKING = "SET_VALUE_FILTER_RANKING_DASHBOARD";
const SET_VALUE_FILTER_BROKERAGE_ID = "SET_VALUE_FILTER_BROKERAGE_ID_WEEK_DASHBOARD";
const SET_FRANCHISES = "SET_FRANCHISES_DASHBOARD";
const SET_CATEGORIES = "SET_CATEGORIES_DASHBOARD";
const SET_DATA_SHOW_IT = "SET_DATA_SHOW_IT_DASHBOARD";
const SET_DATA_SHOW_IT_CATEGORY = "SET_DATA_SHOW_IT_CATEGORY_DASHBOARD";
const SET_SHOWIT_RANGER = "SET_SHOWIT_RANGER_DASHBOARD";
const SET_JOB_RANGER = "SET_JOB_RANGER_DASHBOARD";
const SET_DATA_JOB = "SET_DATA_JOB_DASHBOARD";
const SET_DATA_REVENUE_AGENTS = "SET_DATA_REVENUE_AGENTS_DASHBOARD";
const SET_DATA_CATEGORY = "SET_DATA_CATEGORY_DASHBOARD";
const SET_DATA_AGENTS = "SET_DATA_AGENTS_DASHBOARD";

const setLoader = (loader) => ({
    type: SET_LOADER,
    loader,
});

const setLoader2 = (loader2) => ({
    type: SET_LOADER_2,
    loader2,
});

const setData = (data, typeAction) => ({
    type: typeAction,
    data,
});

const setOffices = (offices) => ({
    type: SET_DATA_OFFICES,
    offices,
});

const setNameJob = (nameJob) => ({
    type: SET_JOB_NAMES,
    nameJob,
});

const setAgent = (agentRegistered) => ({
    type: SET_DATA_AGENT_OFFICES,
    agentRegistered,
});

const setFilterMonth = (month) => ({
    type: SET_FILTER_MONTH,
    month,
});

const setFilterBrokerage = (brokerageId) => ({
    type: SET_FILTER_BROKERAGE_ID,
    brokerageId,
});

const setFilterJobTypeId = (jobTypeId) => ({
    type: SET_FILTER_JOB_TYPE_ID,
    jobTypeId,
});

const setFilterJobOptionId = (jobOptionId) => ({
    type: SET_FILTER_JOB_OPTION_ID,
    jobOptionId,
});

const setItemRevenue = (revenueItem) => ({
    type: SET_ITEM_REVENUE,
    revenueItem,
});

const setItemJobWeek = (jobWeekItem) => ({
    type: SET_ITEM_JOB_WEEK,
    jobWeekItem,
});

const setFilterAgentId = (agentId) => ({
    type: SET_VALUE_FILTER_AGENT_ID,
    agentId,
});

const setFilterRanking = (ranking) => ({
    type: SET_VALUE_FILTER_RANKING,
    ranking,
});

const setFilterBrokerageWeek = (brokerageIdWeek) => ({
    type: SET_VALUE_FILTER_BROKERAGE_ID,
    brokerageIdWeek,
});

const setFilterWeek = (week) => ({
    type: SET_VALUE_FILTER_WEEK,
    week,
});
const setOptionsFranchises = franchises => ({
    type: SET_FRANCHISES,
    franchises,
});
const setCategories = categories => ({
    type: SET_CATEGORIES,
    categories,
});

const setDataShowIT = dataShowIT => ({
    type: SET_DATA_SHOW_IT,
    dataShowIT,
});
const setDataShowITCategory = dataShowITCategory => ({
    type: SET_DATA_SHOW_IT_CATEGORY,
    dataShowITCategory,
});
const setShowITRanger = showITRanger => ({
    type: SET_SHOWIT_RANGER,
    showITRanger,
});
const setJobRanger = jobRanger => ({
    type: SET_JOB_RANGER,
    jobRanger,
});
const setDataJob = dataJob => ({
    type: SET_DATA_JOB,
    dataJob,
});
const setDataRevenueAgents = dataRevenueAgents => ({
    type: SET_DATA_REVENUE_AGENTS,
    dataRevenueAgents,
});
const setDataCategory = dataCategory => ({
    type: SET_DATA_CATEGORY,
    dataCategory,
});
const setDataAgents = dataAgents => ({
    type: SET_DATA_AGENTS,
    dataAgents,
});


const initialReport = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get("broker-user/report", {})
        .then((response) => {
            dispatch(setData(response.results, SET_DATA));
        })
        .catch((error) => {
            console.log('error- ', error);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const getAllJobType = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get("job_type/all_job_type", {})
        .then((response) => {
            let JOB = [];
            response.map((nameJob) => {
                if (nameJob.job_type_option.length > 0) {
                    if(nameJob.name.toUpperCase().includes('VENDOR')) {
                        JOB.push({
                            ...nameJob,
                            primary_color: nameJob.job_type_option[0].primary_color
                        });
                    }else {
                        // JOB.push({title: true, name: nameJob.name})
                        nameJob.job_type_option.map((option) => {
                            JOB.push({
                                ...option,
                                isSub: false,
                                title: false,
                            });
                        });
                    }
                } else {
                    JOB.push(nameJob);
                }
            });
            dispatch(setNameJob(JOB));
        })
        .catch((error) => {
            console.log('error- ', error);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const getOffices = (getData = null, selectAll=false) => (dispatch) => {
    api.get('brokerage/option_select')
        .then(response => {
            let option = [];
            if (response.results.length > 1) {
                option = response.results;
                if (selectAll) {
                    dispatch(
                        change(
                            "DashboardForm",
                            "brokerage",
                            SELECT_ALL_OPTION["value"]
                        )
                    );
                }
            } else {
                option = [...response.results];
                const brokerage_id = option.length ? option[0].value : "";
                dispatch(
                    change(
                        "DashboardForm",
                        "brokerage",
                        brokerage_id && brokerage_id != "" ? [brokerage_id] : SELECT_ALL_OPTION["value"]
                    )
                );
            }
            dispatch(setOffices(option));
        })
        .catch(() => {
            dispatch(setBrokerages([]));
            dispatch(change("DashboardForm", "brokerage", SELECT_ALL_OPTION["value"]));
        })
        .finally(() => {
            if (getData) {
                setTimeout(() => {
                    dispatch(getDataShowIT())
                    dispatch(getDataShowITCategories())
                    dispatch(getDataJob())
                    dispatch(getDataJobCategories())
                    dispatch(getDataRevenueAgents())
                }, 500)
            }
        });
};

const getFranchisesOption = (getData = null, selectAll=false) => (dispatch) => {
    api.get("general/option_franchise", {})
        .then((response) => {
            let options = response.results;
            if (response.results.length > 1) {
                if(selectAll) {
                    dispatch(
                        change(
                            "DashboardForm",
                            "franchise",
                            SELECT_ALL_OPTION["value"]
                        )
                    );
                }
            } else {
                const franchise_id = options.length ? options[0].value : "";
                dispatch(
                    change(
                        "DashboardForm",
                        "franchise",
                        franchise_id && franchise_id != ""  ? [franchise_id] : SELECT_ALL_OPTION["value"]
                    )
                );
            }
            dispatch(setOptionsFranchises(options));
        })
        .catch(() => {
            dispatch(setOptionsFranchises([]));
            dispatch(change("DashboardForm", "franchise", SELECT_ALL_OPTION["value"]));
        })
        .finally(() => {
            if (getData) {
                setTimeout(() => {
                    dispatch(getDataShowIT())
                    dispatch(getDataShowITCategories())
                    dispatch(getDataJob())
                    dispatch(getDataJobCategories())
                    dispatch(getDataRevenueAgents())
                }, 500)
            }
        });
};

const getCategoriesOption = (getData = null, selectAll=false) => (dispatch) => {
    api.get("general/option_job_category", {})
        .then((response) => {
            let options = [...response.results];
            if (selectAll) {
                dispatch(
                    change(
                        "DashboardForm",
                        "category",
                        SELECT_ALL_OPTION["value"]
                    )
                );
            }
            dispatch(setCategories(options));
        })
        .catch(() => {
            dispatch(setCategories([]));
            dispatch(change("DashboardForm", "category", ""));
        })
        .finally(() => {
            if (getData) {
                setTimeout(() => {
                    dispatch(getDataShowIT())
                    dispatch(getDataJob())
                    dispatch(getDataJobCategories())
                    dispatch(getDataShowITCategories())
                }, 500)
            }
        });
};

const getAgent = (brokerage = '') => (dispatch) => {
    dispatch(setFilterAgentId(''))
    api.get("broker-user/agent_by_office", {brokerage})
        .then((response) => {
            const labelUser = response.data;
            let userArray = [{
                label: 'All',
                value: '',
            }];
            if (labelUser.length > 0) {
                labelUser.map((user) => {
                    userArray.push({
                        label: `${user.first_name + ' ' + user.last_name}`,
                        value: user.id,
                        brokerage: user.brokerage_information_id,
                    });
                });
            } else {
                userArray = [{
                    label: 'No options',
                    value: '',
                }]
            }
            dispatch(change('DashboardForm', 'agentFilterPerformance', ''))
            dispatch(setAgent(userArray));
        })
        .catch((error) => {
        })
        .finally(() => {
        });
}

const dataChartRevenue = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const source = getStore()['dashboard'];
    let month = source.month;
    if (month === '') {
        month = get1Month();
    }
    const job_type = source.jobTypeId;
    const job_type_option = source.jobOptionId;
    const brokerage = source.brokerageId;
    const params = {
        month,
        job_type,
        job_type_option,
        brokerage,
    };
    const labels = LabelDaysOfWeek(month)
    api.get('broker-user/revenue', params)
        .then(response => {
            let dataReceived = []
            // console.log('response revenue : ', response)
            const dataResp = response
            dataResp.job_type.map((item) => {
                let data = []
                for (let names in item) {
                    if (names !== 'id' && names !== 'name' && names !== 'primary_color' && names !== 'count_job' && names !== 'usd') {
                        if (item[names] === null) {
                            data.push(0)
                        } else {
                            data.push(item[names])
                        }
                    }
                }

                dataReceived.push({
                    itemId: item.id,
                    data: data,
                    tension: 0.4,
                    usd: item.usd,
                    label: item.name,
                    borderWidth: 1.6,
                    count: item.count_job,
                    borderColor: item.primary_color,
                    backgroundColor: item.primary_color,
                })
            })
            dataResp.job_option.map((item) => {
                let data2 = []
                for (let names in item) {
                    if (names !== 'id' && names !== 'name' && names !== 'primary_color' && names !== 'count_job' && names !== 'usd' && names !== 'job_type__name') {
                        if (item[names] === null) {
                            data2.push(0)
                        } else {
                            data2.push(item[names])
                        }
                    }
                }
                dataReceived.push({
                    itemId: item.id,
                    data: data2,
                    tension: 0.4,
                    usd: item.usd,
                    label: item.name,
                    borderWidth: 1.6,
                    count: item.count_job,
                    borderColor: item.primary_color,
                    job_type_name: item.job_type__name,
                    backgroundColor: item.primary_color,
                })
            })
            let revenue1 = response.revenue1;
            let revenue2 = response.revenue2;
            if (revenue1 === undefined || revenue1 === null) {
                revenue1 = 0
            }
            if (revenue2 === undefined || revenue2 === null) {
                revenue2 = 0
            }
            let total = parseFloat(revenue1) + parseFloat(revenue2)
            const dataFormatter = {
                labels: labels,
                datasets: dataReceived,
                revenue: total,
            }
            dispatch(setItemRevenue(dataFormatter));
        })
        .catch((error) => {
            console.log('--- error dataChartRevenue ---', error);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const dataChartPerformance = () => (dispatch, getStore) => {
    dispatch(setLoader2(true));
    const source = getStore()['dashboard'];
    let rangeDate = source.week;
    const agentId = source.agentId;
    const rating = source.ranking;
    const job_type = source.jobTypeId;
    const job_type_option = source.jobOptionId;
    const brokerage = source.brokerageIdWeek;
    let isMonth = false
    if (rangeDate === '') {
        isMonth = true
        rangeDate = get1Month();
    }
    const params = {
        rangeDate,
        brokerage,
        isMonth,
        agentId,
        rating,
        job_type,
        job_type_option,
    };
    const labels = LabelDaysOfWeek(rangeDate)
    api.get('broker-user/data_chart_performance', params)
        .then(response => {
            let dataReceived = []
            response.job_type.map((item) => {
                let data = []
                for (let names in item) {
                    if (names !== 'id' && names !== 'name' && names !== 'primary_color' && names !== 'total_appliance' && names !== 'score') {
                        if (item[names] === null) {
                            data.push(0)
                        } else {
                            data.push(item[names])
                        }
                    }
                }
                dataReceived.push({
                    itemId: item.id,
                    data: data,
                    tension: 0.4,
                    score: item.score,
                    label: item.name,
                    borderWidth: 1.6,
                    count: item.total_appliance,
                    borderColor: item.primary_color,
                    backgroundColor: item.primary_color,
                })
            })
            response.job_option.map((item) => {
                let data2 = []
                for (let names in item) {
                    if (names !== 'id' && names !== 'name' && names !== 'primary_color' && names !== 'total_appliance' && names !== 'score' && names !== 'job_type__name') {
                        if (item[names] === null) {
                            data2.push(0)
                        } else {
                            data2.push(item[names])
                        }
                    }
                }
                dataReceived.push({
                    itemId: item.id,
                    data: data2,
                    tension: 0.4,
                    score: item.score,
                    label: item.name,
                    borderWidth: 1.6,
                    count: item.total_appliance,
                    borderColor: item.primary_color,
                    job_type_name: item.job_type__name,
                    backgroundColor: item.primary_color,
                })
            })
            let count1 = response.count1;
            let count2 = response.count2;
            if (count1 === undefined || count1 === null) {
                count1 = 0
            }
            if (count2 === undefined || count2 === null) {
                count2 = 0
            }
            let total = parseFloat(count1) + parseFloat(count2)
            const dataFormatter = {
                labels: labels,
                datasets: dataReceived,
                count: total,
            }
            dispatch(setItemJobWeek(dataFormatter));
        })
        .catch((error) => {
            console.log('--- error job_by_week ---', error);
        })
        .finally(() => {
            dispatch(setLoader2(false));
        });
};

// -------------------------------------------------
// ------  SET VALUES TO FILTER BY MONTH   ---------
// -------------------------------------------------
const filterBrokerage = (option) => (dispatch) => {
    dispatch(setFilterBrokerage(option.value));
    dispatch(dataChartRevenue());
};

const filterMonth = (option) => (dispatch) => {
    dispatch(setFilterMonth(option.value));
    dispatch(dataChartRevenue());
};

const addFilterJobId = (option, isSub) => (dispatch, getStore) => {
    const source = getStore()['dashboard'];
    if (isSub) {
        const ids = source.jobOptionId;
        ids.push(option);
        dispatch(setFilterJobOptionId(ids));
    } else {
        const ids = source.jobTypeId;
        ids.push(option);
        dispatch(setFilterJobTypeId(ids));
    }
    dispatch(dataChartRevenue());
    // dispatch(dataChartPerformance());
};

const removeFilterJobId = (option, isSub) => (dispatch, getStore) => {
    const source = getStore()['dashboard'];
    let newIds = [];
    if (isSub) {
        const ids = source.jobOptionId;
        let indexD = null;
        ids.map((oneId, index) => {
            if (oneId !== option) {
                newIds.push(oneId);
            } else {
                indexD = index;
            }
        });
        dispatch(setFilterJobOptionId(newIds));
    } else {
        const ids = source.jobTypeId;
        let indexD = null;
        ids.map((oneId, index) => {
            if (oneId !== option) {
                newIds.push(oneId);
            } else {
                indexD = index;
            }
        });
        dispatch(setFilterJobTypeId(newIds));
    }
    dispatch(dataChartRevenue());
};

// -------------------------------------------------
// -------  SET VALUES TO FILTER BY WEEK   ---------
// -------------------------------------------------
const filterWeek = (option) => (dispatch) => {
    dispatch(setFilterWeek(option.value));
    dispatch(dataChartPerformance());
};

const filterAgent = (option) => (dispatch) => {
    dispatch(setFilterAgentId(option.value));
    dispatch(dataChartPerformance());
};

const filterRanking = (option) => (dispatch) => {
    dispatch(setFilterRanking(option.value));
    dispatch(dataChartPerformance());
};

const setFilterOfficeWeek = (option) => (dispatch) => {
    dispatch(setFilterBrokerageWeek(option.value));
    dispatch(getAgent(option.value));
    dispatch(dataChartPerformance());
};

const removeValues = () => (dispatch) => {
    dispatch(setItemRevenue({
        week: [],
        dataset: [],
        revenue: 0,
    }));
    dispatch(setFilterMonth(''))
    dispatch(setFilterWeek(''))
    dispatch(setFilterAgentId(''))
    dispatch(setFilterBrokerage(''))
    dispatch(setItemJobWeek({
        dataset: [],
        count: 0,
    }));
    dispatch(setFilterJobTypeId([]));
    dispatch(setFilterJobOptionId([]));
}

const initFilters = () => (dispatch) =>  {
    dispatch(initialize("DashboardForm", {state: "<SELECT_ALL>", category: "<SELECT_ALL>"}))
}

const getDataShowIT = (loader=true) => (dispatch, getStore) => {
    if (loader) {
        dispatch(setLoader(true));
    }
    const store = getStore();
    const {showITRanger} = store['dashboard'];
    const { DashboardForm } = store.form;
    let params = {
        state: "",
        franchise: "",
        brokerage: "",
        time: "",
        category: "",
    };
    if (DashboardForm && DashboardForm.values) {
        const { state, franchise, brokerage, category, time, start_date, end_date } =
            DashboardForm.values;
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            if (state.length == 0) {
                params.state = "null"
            }else {
                params.state = state;
            }
            // params.state = state;
        }
        if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
            if (franchise.length == 0) {
                params.franchise = 0
            }else {
                params.franchise = franchise;
            }
            // params.franchise = franchise;
        }
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            // params.brokerage = brokerage;
            if (brokerage.length == 0) {
                params.brokerage = 0
            }else {
                params.brokerage = brokerage;
            }
        }
        if (category !== "" && category !== null && category !== undefined && category !== SELECT_ALL_OPTION["value"]) {
            // params.category = category;
            if (category.length == 0) {
                params.category = [0]
            }else {
                params.category = category;
            }
        }
        if (time !== "" && time !== null && time !== undefined) {
            if (time === 'custom') {
                let startDate = moment().format("DD-MM-YYYY")
                let endDate = moment().format("DD-MM-YYYY")

                if (start_date && start_date !== "" & start_date !== null && start_date !== undefined){
                    startDate = moment(start_date).format("DD-MM-YYYY")
                }
                if (end_date && end_date !== "" & end_date !== null && end_date !== undefined){
                    endDate = moment(end_date).format("DD-MM-YYYY")
                }
                params.time = [startDate, endDate];
            }else {
                params.time = time;
            }
        }else {
            params.time = getDashboardDateRange(6, 'months');
        }
    }else {
        params.time = getDashboardDateRange(6, 'months');
    }

    if(showITRanger) {
        params.frequency = showITRanger.value
    }

    api.get("dashboard/show_it", params)
        .then((response) => {
            let dataReceived = []
            let labels = [];
            response.job_type.map((item) => {
                let data = []
                for (let names in item) {
                    if (names !== 'id' && names !== 'name' && names !== 'primary_color' && names !== 'count_job' && names !== 'usd' && names !== 'job_type_option__primary_color' && names !== 'cancellation_history') {
                        if (item[names] === null) {
                            data.push(0)
                        } else {
                            data.push(item[names])
                        }
                        const _name = _.find(labels, function(o) {
                            return o == names
                        })
                        if (!_name) {
                            labels.push(names);
                        }
                    }
                }
                dataReceived.push({
                    itemId: item.id,
                    data: data,
                    tension: 0.4,
                    usd: item.usd,
                    label: item.name,
                    borderWidth: 1.6,
                    count: item.count_job,
                    borderColor: item.job_type_option__primary_color ? item.job_type_option__primary_color : item.primary_color,
                    backgroundColor: item.job_type_option__primary_color ? item.job_type_option__primary_color : item.primary_color,
                })
            })
            response.job_option.map((item) => {
                let data2 = []
                for (let names in item) {
                    if (names !== 'id' && names !== 'name' && names !== 'primary_color' && names !== 'count_job' && names !== 'usd' && names !== 'job_type__name' && names !== 'job_type_option__primary_color' && names !== 'cancellation_history') {
                        if (item[names] === null) {
                            data2.push(0)
                        } else {
                            data2.push(item[names])
                        }
                    }
                }
                dataReceived.push({
                    itemId: item.id,
                    data: data2,
                    tension: 0.4,
                    usd: item.usd,
                    label: item.name,
                    borderWidth: 1.6,
                    count: item.count_job,
                    borderColor: item.primary_color,
                    job_type_name: item.job_type__name,
                    backgroundColor: item.primary_color,
                })
            })
            let revenue1 = response.revenue1;
            let revenue2 = response.revenue2;
            if (revenue1 === undefined || revenue1 === null) {
                revenue1 = 0
            }
            if (revenue2 === undefined || revenue2 === null) {
                revenue2 = 0
            }
            let total = parseFloat(revenue1) + parseFloat(revenue2)
            const dataFormatter = {
                labels: labels,
                datasets: dataReceived,
                revenue: total,
            }
            dispatch(setDataShowIT(dataFormatter));
        })
        .catch((error) => {
            console.log("--- error job_by_week ---", error);
        })
        .finally(() => {
            if (loader) {
                dispatch(setLoader(false));
            }
        });
};

const getDataShowITCategories = (loader=true) => (dispatch, getStore) => {
    if (loader) {
        dispatch(setLoader2(true));
    }
    const store = getStore();
    const {showITRanger} = store['dashboard'];
    const { DashboardForm } = store.form;
    let params = {
        state: "",
        franchise: "",
        brokerage: "",
        time: "",
        category: "",
    };
    if (DashboardForm && DashboardForm.values) {
        const { state, franchise, brokerage, category, time, start_date, end_date } =
            DashboardForm.values;
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            if (state.length == 0) {
                params.state = "null"
            }else {
                params.state = state;
            }
        }
        if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
            if (franchise.length == 0) {
                params.franchise = 0
            }else {
                params.franchise = franchise;
            }
        }
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            if (brokerage.length == 0) {
                params.brokerage = 0
            }else {
                params.brokerage = brokerage;
            }
        }
        if (category !== "" && category !== null && category !== undefined && category !== SELECT_ALL_OPTION["value"]) {
            if (category.length == 0) {
                params.category = [0]
            }else {
                params.category = category;
            }
        }
        if (time !== "" && time !== null && time !== undefined) {
            if (time === 'custom') {
                let startDate = moment().format("DD-MM-YYYY")
                let endDate = moment().format("DD-MM-YYYY")

                if (start_date && start_date !== "" & start_date !== null && start_date !== undefined){
                    startDate = moment(start_date).format("DD-MM-YYYY")
                }
                if (end_date && end_date !== "" & end_date !== null && end_date !== undefined){
                    endDate = moment(end_date).format("DD-MM-YYYY")
                }
                params.time = [startDate, endDate];
            }else {
                params.time = time;
            }
        }else {
            params.time = getDashboardDateRange(6, 'months');
        }
    }else {
        params.time = getDashboardDateRange(6, 'months');
    }

    if(showITRanger) {
        params.frequency = showITRanger.value
    }

    api.get("dashboard/show_it_category", params)
        .then((response) => {
            // console.log("RESPONSE: ", response);
            let dataReceived = []
            let labels = [];

            response.data.forEach(item => {
                let data = []
                for (let names in item) {
                    if (names !== 'id' && names !== 'name' && names !== 'color' && names !== 'state_job' && names !== 'state' && names !== 'usd' && names !== 'background' && names !== 'count_job') {
                        if (item[names] === null) {
                            data.push(0)
                        } else {
                            data.push(item[names])
                        }
                        const _name = _.find(labels, function (o) {
                            return o == names
                        })
                        if (!_name) {
                            labels.push(names);
                        }
                                        
                    }
                }
                dataReceived.push({
                    data: data,
                    tension: 0.4,
                    label: item.name,
                    borderWidth: 1.6,
                    usd: item.usd,
                    count_job: item.count_job,
                    color: item.color ? item.color : "#cecece",
                    backgroundColor: item.background ? item.background : "#cecece",
                })
            });

            const dataFormatter = {
                labels: labels,
                datasets: dataReceived,
                total: response.total ? response.total : 0,
            }
            dispatch(setDataShowITCategory(dataFormatter));
        })
        .catch((error) => {
            console.log("--- error job_by_week ---", error);
            dispatch(setDataShowITCategory({
                total: 0,
                labels: [],
                datasets: [],
            }))
        })
        .finally(() => {
            if (loader) {
                dispatch(setLoader2(false));
            }
        });
};

const getDataJob = (loader=true) => (dispatch, getStore) => {
    if (loader) {
        dispatch(setLoader2(true));
    }
    const store = getStore();
    const {showITRanger} = store['dashboard'];
    const { DashboardForm } = store.form;
    let params = {
        state: "",
        franchise: "",
        brokerage: "",
        time: "",
        category: "",
    };
    if (DashboardForm && DashboardForm.values) {
        const { state, franchise, brokerage, category, time, start_date, end_date } =
            DashboardForm.values;
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            if (state.length == 0) {
                params.state = "null"
            }else {
                params.state = state;
            }
        }
        if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
            if (franchise.length == 0) {
                params.franchise = 0
            }else {
                params.franchise = franchise;
            }
        }
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            if (brokerage.length == 0) {
                params.brokerage = 0
            }else {
                params.brokerage = brokerage;
            }
        }
        if (category !== "" && category !== null && category !== undefined && category !== SELECT_ALL_OPTION["value"]) {
            if (category.length == 0) {
                params.category = [0]
            }else {
                params.category = category;
            }
        }
        if (time !== "" && time !== null && time !== undefined) {
            if (time === 'custom') {
                let startDate = moment().format("DD-MM-YYYY")
                let endDate = moment().format("DD-MM-YYYY")

                if (start_date && start_date !== "" & start_date !== null && start_date !== undefined){
                    startDate = moment(start_date).format("DD-MM-YYYY")
                }
                if (end_date && end_date !== "" & end_date !== null && end_date !== undefined){
                    endDate = moment(end_date).format("DD-MM-YYYY")
                }
                params.time = [startDate, endDate];
            }else {
                params.time = time;
            }
        }else {
            params.time = getDashboardDateRange(6, 'months');
        }
    }else {
        params.time = getDashboardDateRange(6, 'months');
    }

    if(showITRanger) {
        params.frequency = showITRanger.value
    }

    api.get("dashboard/jobs", params)
        .then((response) => {
            let dataReceived = []
            let labels = [];
            response.job_type.map((item) => {
                let data = []
                for (let names in item) {
                    if (names !== 'id' && names !== 'name' && names !== 'primary_color' && names !== 'count_job' && names !== 'score' && names !== 'job_type_option__primary_color') {
                        if (item[names] === null) {
                            data.push(0)
                        } else {
                            data.push(item[names])
                        }
                        const _name = _.find(labels, function(o) {
                            return o == names
                        })
                        if (!_name) {
                            labels.push(names);
                        }
                    }
                }
                dataReceived.push({
                    itemId: item.id,
                    data: data,
                    tension: 0.4,
                    score: item.score,
                    label: item.name,
                    borderWidth: 1.6,
                    count: item.count_job,
                    borderColor: item.job_type_option__primary_color ? item.job_type_option__primary_color : item.primary_color,
                    backgroundColor: item.job_type_option__primary_color ? item.job_type_option__primary_color : item.primary_color,
                })
            })
            response.job_option.map((item) => {
                let data2 = []
                for (let names in item) {
                    if (names !== 'id' && names !== 'name' && names !== 'primary_color' && names !== 'count_job' && names !== 'score' && names !== 'job_type__name' && names !== 'job_type_option__primary_color') {
                        if (item[names] === null) {
                            data2.push(0)
                        } else {
                            data2.push(item[names])
                        }
                    }
                }
                dataReceived.push({
                    itemId: item.id,
                    data: data2,
                    tension: 0.4,
                    score: item.score,
                    label: item.name,
                    borderWidth: 1.6,
                    count: item.count_job,
                    borderColor: item.primary_color,
                    job_type_name: item.job_type__name,
                    backgroundColor: item.primary_color,
                })
            })
            let count1 = response.count1;
            let count2 = response.count2;
            if (count1 === undefined || count1 === null) {
                count1 = 0
            }
            if (count2 === undefined || count2 === null) {
                count2 = 0
            }
            let total = parseFloat(count1) + parseFloat(count2)
            const dataFormatter = {
                labels: labels,
                datasets: dataReceived,
                total: total,
            }
            dispatch(setDataJob(dataFormatter));
        })
        .catch((error) => {
            console.log("--- error job_by_week ---", error);
            dispatch(setDataJob({
                labels: [],
                datasets: [],
                total: 0,
            }))
        })
        .finally(() => {
            if (loader) {
                dispatch(setLoader2(false));
            }
        });
};

const getDataJobCategories = (loader=true) => (dispatch, getStore) => {
    if (loader) {
        dispatch(setLoader2(true));
    }
    const store = getStore();
    const {showITRanger} = store['dashboard'];
    const { DashboardForm } = store.form;
    let params = {
        state: "",
        franchise: "",
        brokerage: "",
        time: "",
        category: "",
    };
    if (DashboardForm && DashboardForm.values) {
        const { state, franchise, brokerage, category, time, start_date, end_date } =
            DashboardForm.values;
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            if (state.length == 0) {
                params.state = "null"
            }else {
                params.state = state;
            }
        }
        if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
            if (franchise.length == 0) {
                params.franchise = 0
            }else {
                params.franchise = franchise;
            }
        }
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            if (brokerage.length == 0) {
                params.brokerage = 0
            }else {
                params.brokerage = brokerage;
            }
        }
        if (category !== "" && category !== null && category !== undefined && category !== SELECT_ALL_OPTION["value"]) {
            if (category.length == 0) {
                params.category = [0]
            }else {
                params.category = category;
            }
        }
        if (time !== "" && time !== null && time !== undefined) {
            if (time === 'custom') {
                let startDate = moment().format("DD-MM-YYYY")
                let endDate = moment().format("DD-MM-YYYY")

                if (start_date && start_date !== "" & start_date !== null && start_date !== undefined){
                    console.log("ENTRA FECHA INICIO: ", start_date);
                    startDate = moment(start_date).format("DD-MM-YYYY")
                }
                if (end_date && end_date !== "" & end_date !== null && end_date !== undefined){
                    console.log("ENTRA FECHA INICIO: ", end_date);
                    endDate = moment(end_date).format("DD-MM-YYYY")
                }
                console.log("DATES I: ", startDate);
                console.log("DATES E: ", endDate);
                params.time = [startDate, endDate];
            }else {
                params.time = time;
            }
        }else {
            params.time = getDashboardDateRange(6, 'months');
        }
    }else {
        params.time = getDashboardDateRange(6, 'months');
    }

    if(showITRanger) {
        params.frequency = showITRanger.value
    }

    api.get("dashboard/jobs_category", params)
        .then((response) => {
            let dataReceived = []
            let labels = [];

            response.data.forEach(item => {
                let data = []
                for (let names in item) {
                    if (names !== 'id' && names !== 'name' && names !== 'color' && names !== 'state_job' && names !== 'state' && names !== 'total' && names !== 'background') {
                        if (item[names] === null) {
                            data.push(0)
                        } else {
                            data.push(item[names])
                        }
                        const _name = _.find(labels, function (o) {
                            return o == names
                        })
                        if (!_name) {
                            labels.push(names);
                        }
                                        
                    }
                }
                dataReceived.push({
                    data: data,
                    tension: 0.4,
                    label: item.name,
                    borderWidth: 1.6,
                    total: item.total,
                    color: item.color ? item.color : "#cecece",
                    backgroundColor: item.background ? item.background : "#cecece",
                })
            });

            const dataFormatter = {
                labels: labels,
                datasets: dataReceived,
                total: response.total ? response.total : 0,
            }
            dispatch(setDataCategory(dataFormatter));
        })
        .catch((error) => {
            console.log("--- error job_by_week ---", error);
            dispatch(setDataCategory({
                total: 0,
                labels: [],
                datasets: [],
            }))
        })
        .finally(() => {
            if (loader) {
                dispatch(setLoader2(false));
            }
        });
};


const getDataRevenueAgents = () => (dispatch, getStore) => {
    // dispatch(setLoader2(true));
    const store = getStore();
    const { DashboardForm } = store.form;
    let params = {
        state: "",
        franchise: "",
        brokerage: "",
    };
    if (DashboardForm && DashboardForm.values) {
        const { state, franchise, brokerage, category, time } =
            DashboardForm.values;
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            params.state = state;
        }
        if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
            params.franchise = franchise;
        }
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            params.brokerage = brokerage;
        }
    }

    api.get("dashboard/revenue", params)
        .then((response) => {
            if (response) {
                dispatch(setDataRevenueAgents(response.results));
            } else {
                dispatch(setDataRevenueAgents({
                    total_agent: 0,
                    total_user: 0,
                    posted: {},
                    applied: {},
                    pending: {},
                    total_to_revenue: {}
                }));
            }
        })
        .catch((error) => {
            console.log("--- error job_by_week ---", error);
            dispatch(setDataRevenueAgents({
                total_agent: 0,
                total_user: 0,
                posted: {},
                applied: {},
                pending: {},
                total_to_revenue: {}
            }));
        })
        .finally(() => {
            // dispatch(setLoader2(false));
        });
};

const changeFilters = (type, value=null) => (dispatch) => {
    console.log("TYPE:, ",  type);
    switch (type) {
        case 'state':
            setTimeout(() => {
                dispatch(getDataShowIT())
                dispatch(getDataShowITCategories())
                dispatch(getDataJob())
                dispatch(getDataJobCategories())
                dispatch(getDataRevenueAgents())
            }, 500)
            break;
        case 'franchise':
            setTimeout(() => {
                dispatch(getDataShowIT())
                dispatch(getDataShowITCategories())
                dispatch(getDataJob())
                dispatch(getDataJobCategories())
                dispatch(getDataRevenueAgents())
            }, 500)
            break;
        case 'brokerage':
            setTimeout(() => {
                dispatch(getDataShowIT())
                dispatch(getDataShowITCategories())
                dispatch(getDataJob())
                dispatch(getDataJobCategories())
                dispatch(getDataRevenueAgents())
                dispatch(getDataAgents())
            }, 500)
            break;
        case 'category':
            setTimeout(() => {
                dispatch(getDataShowIT())
                dispatch(getDataShowITCategories())
                dispatch(getDataJob())
                dispatch(getDataJobCategories())
            }, 500)
            break;
        case 'time':
            setTimeout(() => {
                dispatch(getDataShowIT())
                dispatch(getDataShowITCategories())
                dispatch(getDataJob())
                dispatch(getDataJobCategories())
                dispatch(getDataAgents())
            }, 500)
            break;
        case 'start_date':
            setTimeout(() => {
                dispatch(getDataShowIT())
                dispatch(getDataShowITCategories())
                dispatch(getDataJob())
                dispatch(getDataJobCategories())
                dispatch(getDataAgents())
            }, 500)
            break;
        case 'end_date':
            setTimeout(() => {
                dispatch(getDataShowIT())
                dispatch(getDataShowITCategories())
                dispatch(getDataJob())
                dispatch(getDataJobCategories())
                dispatch(getDataAgents())
            }, 500)
            break;
        default:
            break;
    }
}

const setRanger = (option, typeRange) => (dispatch) => {
    if (typeRange == "show_it") {
        // console.log("OPTION: ", option);
        dispatch(setShowITRanger(option))
        setTimeout(() => {
            dispatch(getDataShowIT())
            dispatch(getDataJob())
            dispatch(getDataJobCategories())
            dispatch(getDataShowITCategories())
        }, 800)
    }else {
        dispatch(setJobRanger(option))
        setTimeout(() => {
            dispatch(getDataJob())
        }, 800)
    }
}

const getDataAgents = () => (dispatch, getStore) => {
    // dispatch(setLoader2(true));
    const store = getStore();
    const { DashboardForm } = store.form;
    let params = {
        state: "",
        franchise: "",
        brokerage: "",
    };
    if (DashboardForm && DashboardForm.values) {
        const { brokerage, time, start_date, end_date } = DashboardForm.values;
        if (
            brokerage !== "" &&
            brokerage !== null &&
            brokerage !== undefined &&
            brokerage !== SELECT_ALL_OPTION["value"]
        ) {
            params.brokerage = brokerage;
        }
        if (time !== "" && time !== null && time !== undefined) {
            if (time === "custom") {
                let startDate = moment().format("DD-MM-YYYY");
                let endDate = moment().format("DD-MM-YYYY");

                if (
                    start_date &&
                    (start_date !== "") & (start_date !== null) &&
                    start_date !== undefined
                ) {
                    startDate = moment(start_date).format("DD-MM-YYYY");
                }
                if (
                    end_date &&
                    (end_date !== "") & (end_date !== null) &&
                    end_date !== undefined
                ) {
                    endDate = moment(end_date).format("DD-MM-YYYY");
                }
                console.log("DATES I: ", startDate);
                console.log("DATES E: ", endDate);
                params.start_date = startDate;
                params.end_date = endDate;
            } else {
                params.start_date = time[0];
                params.end_date = time[1];
            }
        } else {
            const _time = getDashboardDateRange(6, 'months');
            params.start_date = _time[0];
            params.end_date = _time[1];
        }
    } else {
        const _time = getDashboardDateRange(6, 'months');
        params.start_date = _time[0];
        params.end_date = _time[1];
    }

    api.get("dashboard/agents_stats", params)
        .then((response) => {
            if (response) {
                dispatch(setDataAgents(response.results));
            } else {
                dispatch(setDataAgents({
                    hiring_agents: [],
                    showing_agents: []
                }));
            }
        })
        .catch((error) => {
            console.log("--- error job_by_week ---", error);
            dispatch(setDataAgents({
                hiring_agents: [],
                showing_agents: []
            }));
        })
        .finally(() => {
        });
};

// -----------------------------------------------------
// ------------------   ACTION      --------------------
// -----------------------------------------------------
export const actions = {
    getAgent,
    getOffices,
    filterWeek,
    filterMonth,
    filterAgent,
    removeValues,
    getAllJobType,
    initialReport,
    filterRanking,
    addFilterJobId,
    filterBrokerage,
    dataChartRevenue,
    removeFilterJobId,
    setFilterOfficeWeek,
    dataChartPerformance,
    getFranchisesOption,
    getCategoriesOption,
    initFilters,
    getDataShowIT,
    getDataShowITCategories,
    getDataJob,
    getDataJobCategories,
    getDataRevenueAgents,
    changeFilters,
    setRanger,
    getDataAgents,
};


export const reducers = {
    [SET_LOADER]: (state, {loader}) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_LOADER_2]: (state, {loader2}) => {
        return {
            ...state,
            loader2,
        };
    },
    [SET_DATA]: (state, {data}) => {
        return {
            ...state,
            data,
        };
    },
    [SET_JOB_NAMES]: (state, {nameJob}) => {
        return {
            ...state,
            nameJob,
        };
    },
    [SET_DATA_OFFICES]: (state, {offices}) => {
        return {
            ...state,
            offices,
        };
    },
    [SET_DATA_AGENT_OFFICES]: (state, {agentRegistered}) => {
        return {
            ...state,
            agentRegistered,
        };
    },
    [SET_FILTER_MONTH]: (state, {month}) => {
        return {
            ...state,
            month,
        };
    },
    [SET_FILTER_BROKERAGE_ID]: (state, {brokerageId}) => {
        return {
            ...state,
            brokerageId,
        };
    },
    [SET_FILTER_JOB_TYPE_ID]: (state, {jobTypeId}) => {
        return {
            ...state,
            jobTypeId,
        };
    },
    [SET_FILTER_JOB_OPTION_ID]: (state, {jobOptionId}) => {
        return {
            ...state,
            jobOptionId,
        };
    },
    [SET_ITEM_REVENUE]: (state, {revenueItem}) => {
        return {
            ...state,
            revenueItem,
        };
    },
// const SET_VALUE_FILTER_BROKERAGE_ID = "SET_VALUE_FILTER_BROKERAGE_ID_WEEK_DASHBOARD";

    [SET_ITEM_JOB_WEEK]: (state, {jobWeekItem}) => {
        return {
            ...state,
            jobWeekItem,
        };
    },
    [SET_VALUE_FILTER_RANKING]: (state, {ranking}) => {
        return {
            ...state,
            ranking,
        };
    },
    [SET_VALUE_FILTER_WEEK]: (state, {week}) => {
        return {
            ...state,
            week,
        };
    },
    [SET_VALUE_FILTER_AGENT_ID]: (state, {agentId}) => {
        return {
            ...state,
            agentId,
        };
    },
    [SET_VALUE_FILTER_BROKERAGE_ID]: (state, {brokerageIdWeek}) => {
        return {
            ...state,
            brokerageIdWeek,
        };
    },
    [SET_FRANCHISES]: (state, {franchises}) => {
        return {
            ...state,
            franchises,
        };
    },
    [SET_CATEGORIES]: (state, {categories}) => {
        return {
            ...state,
            categories,
        };
    },
    [SET_DATA_SHOW_IT]: (state, {dataShowIT}) => {
        return {
            ...state,
            dataShowIT,
        };
    },
    [SET_SHOWIT_RANGER]: (state, {showITRanger}) => {
        return {
            ...state,
            showITRanger,
        };
    },
    [SET_JOB_RANGER]: (state, {jobRanger}) => {
        return {
            ...state,
            jobRanger,
        };
    },
    [SET_DATA_JOB]: (state, {dataJob}) => {
        return {
            ...state,
            dataJob,
        };
    },
    [SET_DATA_REVENUE_AGENTS]: (state, {dataRevenueAgents}) => {
        return {
            ...state,
            dataRevenueAgents,
        };
    },
    [SET_DATA_CATEGORY]: (state, {dataCategory}) => {
        return {
            ...state,
            dataCategory,
        };
    },
    [SET_DATA_SHOW_IT_CATEGORY]: (state, {dataShowITCategory}) => {
        return {
            ...state,
            dataShowITCategory,
        };
    },
    [SET_DATA_AGENTS]: (state, {dataAgents}) => {
        return {
            ...state,
            dataAgents,
        };
    },
};

export const initialState = {
    data: null,
    nameJob: [],
    offices: [],
    franchises: [],
    categories: [],
    agentRegistered: [],
    loader: false,
    loader2: false,

    //values to filter by month
    month: '',
    jobTypeId: [],
    jobOptionId: [],
    brokerageId: '',
    revenueItem: {
        datasets: [],
        revenue: 0,
    },

    // values to filter by week
    week: '',
    agentId: '',
    ranking: '',
    brokerageIdWeek: '',
    jobWeekItem: {
        datasets: [],
        count: 0,
    },

    showITRanger: {label: 'Monthly', value: 'monthly'},
    jobRanger: {label: 'Monthly', value: 'monthly'},
    dataShowIT: {
        labels: [],
        datasets: [],
        revenue: 0,
    },
    dataJob: {
        labels: [],
        datasets: [],
        total: 0,
    },
    dataRevenueAgents: {
        total_agent: 0,
        total_user: 0,
        posted: {},
        applied: {},
        pending: {},
        total_to_revenue: {}
    },
    dataCategory: {
        total: 0,
        labels: [],
        datasets: [],
    },
    dataShowITCategory: {
        total: 0,
        labels: [],
        datasets: [],
    },
    dataAgents: {
        hiring_agents: [],
        showing_agents: []
    }
};

export default handleActions(reducers, initialState);
