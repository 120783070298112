import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/cuenta/vendor_register';
import VendorRegister from './VendorRegister';


const ms2p = (state) => {
  return {
    ...state.vendorRegister
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(VendorRegister);