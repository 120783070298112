import moment from "moment";
import Tabs, {TabPane} from "rc-tabs";
import PayoutForm from "./PayoutForm";
import ModalWallet from "./ModalWallet";
import React, { Component } from 'react';
import LinkWrapper from "./EditBankIframe";
import LoadMask from "../Utils/LoadMask/LoadMask";
import GridTable from "../Utils/GridTable";
import TabContent from "rc-tabs/lib/TabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";


class Wallet extends Component {

    constructor(props) {
        super(props);
        this.state = {
            url: null,
            complete: true,
            withAccount: false,
            modalOnBoarding: false,
            modalOnConfiguring: false,
            open: false,
            message: null,
            showProgress: false,
            payoutModal: false,
            tab: "Payouts",
        };
    }

    componentDidMount() {
        const { login: { me } } = this.props;
        if (me.stripe_active === true) {
            this.props.getAccountStripe(this.openModalOnBoarding, this.openModalOnConfiguring);
            this.props.getPaymentsHistory();
        } else {
            this.props.crateStripeAccounts(this.openModalOnBoarding);
        }
    }

    openLink = () => {
        const { linkOnBoard } = this.props;
        linkOnBoard(this.setUri);

    };

    openModalOnBoarding = () => {
        this.openLink();
        const { modalOnBoarding } = this.state;
        this.setState({
            modalOnBoarding: !modalOnBoarding,
            modalOnConfiguring: false,
            complete: false,
        });
    };

    openModalOnConfiguring = () => {
        this.openLink();
        const { modalOnConfiguring } = this.state;
        this.setState({
            modalOnConfiguring: !modalOnConfiguring,
            modalOnboarding: false,
        });
    };

    setUri = (url) => {
        this.setState({ url });
    };

    fixMount = (value) => {
        if (value > 0) {
            return (value / 100);
        }
        return 0;
    };

    onOpenModal = () => this.setState({
        open: true,
        message: null,
        showProgress: false,
    });

    onOpenModalPayout = () => this.setState({
        message: null,
        payoutModal: true,
        showProgress: false,
    });

    onCloseModal = () => {
        this.props.setInitialFormPayout();
        this.setState({
            open: false,
            message: null,
            payoutModal: false,
            showProgress: false,
        });
    };

    setMessage = (err) => this.setState({
        message: err,
        showProgress: false,
    });

    submitActionPayout = (data) => {
        this.setState({
            showProgress: true,
            message: null,
        });
        this.props.createPayout(data, this.onCloseModal, this.setMessage);
    };

    changeTab = (key) =>  {
        this.setState({tab: key})
    }

    render() {
        const { loader, item, balance, payout, bankAccount, getUrlEdit, paymentData, paymentLoader, paymentPage } = this.props;
        const { modalOnBoarding, modalOnConfiguring, url, getPaymentsHistory } = this.state;
        return (
            <div className="d-flex flex-column">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">
                            Wallet
                        </div>
                    </div>
                </div>
                <LoadMask loading={loader} light>
                    <div className="card  border-card-page px-4 py-2 mt-3" style={{ minHeight: '18rem' }}>
                        {item && item.id &&
                        <React.Fragment>
                            <ModalWallet
                                open={(modalOnBoarding || modalOnConfiguring) && url}
                                modalOnConfiguring={modalOnConfiguring}
                                url={url}
                            />
                            <div className="row py-4">
                                {/*-------------------------------------------------------*/}
                                {/*------------    BALANCE AND ACCOUNT BANK    -----------*/}
                                {/*-------------------------------------------------------*/}
                                <div className="col-lg-4 col-sm-12  m-auto">
                                    <div className="border-bottom row justify-content-between pr-4">
                                        <h5 className="font-weight-bold">Total Balance</h5>
                                        <h5 className="ml-5 font-weight-bold"> $ {this.fixMount(balance.available[0].amount)} </h5>
                                    </div>
                                    <div className="mt-3 col px-0">
                                        {/*{bankAccount.data.length > 0 ?*/}
                                        {/*    bankAccount.data.map((bank, index) => {*/}
                                        {bankAccount.length > 0 ?
                                            bankAccount.map((bank, index) => {
                                                return (
                                                    <div
                                                        className="row justify-content-between border-bottom"
                                                        key={`bank-${index}`}
                                                    >
                                                        <p className="mb-1 text-12">{bank.bank_name || bank.brand}</p>
                                                        <p className="mb-1 text-12 justify-content-between">Account &nbsp;&nbsp; **** {bank.last4}</p>
                                                    </div>
                                                );
                                            })
                                            :
                                            <p> No bank accounts </p>
                                        }
                                    </div>
                                    {/*------------------------------------------------------------------*/}
                                    {/*-- button to open modal redirect to updated Stripe bank account --*/}
                                    {/*------------------------------------------------------------------*/}
                                    <LinkWrapper
                                        getLink={getUrlEdit}
                                        me={this.props.login.me}
                                        getAccount={this.props.getAccountStripe}
                                    />
                                    {bankAccount.length > 0 &&
                                    <PayoutForm
                                        balance={this.fixMount(balance.available[0].amount)}
                                        showProgress={this.state.showProgress}
                                        payoutModal={this.state.payoutModal}
                                        onOpenModal={this.onOpenModalPayout}
                                        onSubmit={this.submitActionPayout}
                                        onCloseModal={this.onCloseModal}
                                        errorMsg={this.state.message}
                                        bankAccount={bankAccount}
                                        login={this.props.login}
                                    />
                                    }
                                </div>
                                {/*-------------------------------------------------------*/}
                                {/*----------------     PAYOUT HISTORY      --------------*/}
                                {/*-------------------------------------------------------*/}
                                <div className="col-lg-6 col-sm-12 border-left px-lg-3 px-sm-0" style={{ minHeight: '28rem' }}>
                                <Tabs
                                    activeKey={this.state.tab}
                                    defaultActiveKey="Registered"
                                    tabBarPosition="top"
                                    onChange={(key) => this.changeTab(key)}
                                    renderTabBar={() => <ScrollableInkTabBar/>}
                                    renderTabContent={() => <TabContent/>}
                                >
                                    <TabPane tab="Payouts history" key="Payouts" className="py-0">
                                        <div className="mt-5 px-lg-1 px-sm-0">
                                            {/* <h6 className="font-weight-bold">
                                                Payouts history
                                            </h6> */}
                                            {/*<div className="w-100 px-2 filter-job py-2">*/}
                                            <div className="px-0">
                                                <GridTable
                                                    hover
                                                    striped
                                                    keyField={'id'}
                                                    data={{ results: payout.data }}
                                                    // loading={loader}
                                                    trClassName={'text-success'}
                                                    // onPageChange={(value) => console.log(value)}
                                                    columns={[
                                                        {
                                                            dataField: "created",
                                                            formatter: formatMont(),
                                                            text: "Date",
                                                        },
                                                        {
                                                            dataField: "arrival_date",
                                                            formatter: formatHour(),
                                                            text: "Hour",
                                                        },
                                                        {
                                                            dataField: "amount",
                                                            formatter: formatAmount(),
                                                            text: "Amount",
                                                        },
                                                        // {
                                                        //     dataField: "destination",
                                                        //     formatter: getNameBank(bankAccount),
                                                        //     text: "Destination Account",
                                                        // },
                                                    ]}
                                                />

                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Payments history" key="Payments">
                                        <div className="px-0">
                                            <GridTable
                                                hover
                                                striped
                                                keyField={'id'}
                                                data={paymentData}
                                                loading={paymentLoader}
                                                trClassName={'text-success'}
                                                onPageChange={(value) => getPaymentsHistory(value)}
                                                columns={[
                                                    {
                                                        dataField: "created",
                                                        formatter: (cell, row) => {
                                                            return <span>{cell ? moment(cell).format("lll"): ""}</span>
                                                        },
                                                        text: "Date",
                                                    },
                                                    {
                                                        dataField: "amount",
                                                        formatter: formatPaymentAmount(),
                                                        text: "Amount",
                                                    },
                                                    {
                                                        dataField: "fee_amount",
                                                        formatter: formatPaymentAmount(),
                                                        text: "Stripe Montly Fee",
                                                    },
                                                ]}
                                            />

                                        </div>
                                    </TabPane>
                                </Tabs>
                                </div>
                            </div>
                        </React.Fragment>}
                    </div>
                </LoadMask>
            </div>
        );
    }
}

export function formatAmount() {
    return (cell) => {
        return (
            <div>
                <p className="p-0 m-0 row justify-content-end" style={{ width: '4.5rem' }}>
                    $&nbsp;&nbsp;{parseFloat(cell / 100)
                    .toFixed(2)}
                </p>
            </div>
        );
    };
}

export function formatPaymentAmount() {
    return (cell) => {
        return (
            <div>
                <p className="p-0 m-0 row justify-content-end" style={{ width: '4.5rem' }}>
                    $&nbsp;&nbsp;{parseFloat(cell)
                    .toFixed(2)}
                </p>
            </div>
        );
    };
}

function getNameBank(banks) {

    return (cell) => {
        return (
            <div className="w-100">
                <p className="p-0 m-0">
                    {banks[0].bank_name}
                </p>
            </div>
        );
    };
}

export function formatMont() {
    return (cell) => {
        const mont = moment.unix(cell)
            .format("MMM DD, YYYY");
        return (
            <div>
                <p className="p-0 m-0" style={{ width: '4.5rem' }}>
                    {mont.toString()}
                </p>
            </div>
        );
    };
}

export function formatHour() {
    return (cell, row) => {
        const hour = moment.unix(row.created)
            .format("hh:mm:ss");
        return (
            <div>
                <p className="p-0 m-0" style={{ width: '4.5rem' }}>
                    {hour.toString()}
                </p>
            </div>
        );
    };
}

export default Wallet;
