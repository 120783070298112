import React, {useState, useEffect} from "react";
// import Grid from "../Utils/Grid";
import CreateForm from "./CreateForm";
import HeaderCard from "../HeaderCard";
import {Modal} from "react-responsive-modal";
import {formatPhoneNumber} from "../Broker/ListGrid";
import {SIZE_PER_PAGE, BROKERAGE_STATUSES, BROKERAGE_ACTIVE, BROKERAGE_BLOCKED, BROKERAGE_LOCKED, STATE_USA_LIST} from "../../../utility/constants";
import {standardActions} from "../Utils/Grid/StandardActions";
import Grid from "../Utils/GridTable";

const BrokerageGrid = (props) => {
    const {
        loader,
        data,
        searchAction,
        toList,
        remove,
        setModal,
        textExcel,
        stateModal,
        setFilesImg,
        handleOnChangeExcel,
        onSubmit,
        setLogo,
        new_file,
        setNewFileButton,
        setActive,
        active,
        urlTemplate,
        loader2,
        closeModal,
        provideAgentsRoster,
        goToAgents,
        assignAddressData,
    } = props;

    const [gridHeight, setGridHeight] = useState(200)

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("BROKERAGE-LIST");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])

    return (
        <div id="PAGE-CONTAINER">
            <div className="page-header pl-1 pt-3 no-gutters row">
                <div className="">
                    <div className="header-page">
                        Offices
                    </div>
                </div>
            </div>
            <HeaderCard
                searchAction={(value) => searchAction(value)}
                id_header={'cardBrokerage'}
                showFile={false}
                btnModal={true}
                activeModal={() => setModal(true)}
                text_link={"Create New Office"}
                placeholder="Search office"
                justify={"justify-content-start px-4"}
            />
            <Modal
                center
                closeOnEsc
                open={stateModal}
                closeOnOverlayClick={false}
                onClose={closeModal}
                classNames={{modal: 'col-lg-7 col-sm-11 px-0 border-card-20 pt-5 pb-2'}}
            >
                <div className="col-lg-12 col-sm-12 border-card-20">
                    <CreateForm
                        text={textExcel}
                        loader={false}
                        setLogo={setLogo}
                        handleOnChange={handleOnChangeExcel}
                        setFiles={setFilesImg}
                        item={{}}
                        loader2={loader2}
                        onSubmit={onSubmit}
                        is_update={false}
                        active={active}
                        setActive={setActive}
                        new_file={new_file}
                        setNewFileButton={setNewFileButton}
                        closeModal={closeModal}
                        urlTemplate={urlTemplate}
                        provideAgentsRoster={provideAgentsRoster}
                        assignAddressData={assignAddressData}
                    />
                </div>
            </Modal>
            <div id='BROKERAGE-LIST' className="card border-dark  border-card-page px-4 py-2 flex-1">
                <div className="card-body px-0 pt-2 mb-0 pb-0 d-flex flex-column flex-1">
                    <Grid
                        height={gridHeight}
                        hover
                        striped
                        wrapperClasses='table-scroll-auto'
                        keyField={'id'}
                        data={data}
                        loading={loader}
                        sizePerPage={SIZE_PER_PAGE}
                        onPageChange={(value) => toList(value)}
                        columns={[
                            {
                                dataField: "id",
                                text: "",
                                formatter:
                                    standardActions({
                                        // view: "/brokerage",
                                        edit: "brokerage",
                                        // remove: remove,
                                    }),
                            },
                            {
                                dataField: "logo",
                                formatter: loadImg(),
                                text: "",
                            },
                            {
                                dataField: "status",
                                formatter: formatStatus(),
                                text: "status",
                            },
                            {
                                dataField: "firm_name",
                                sort: true,
                                text: "Office Name",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                    };
                                },
                            },
                            {
                                dataField: "broker__name",
                                sort: true,
                                formatter: (cell, row) => {
                                    return <span>{row.broker_info ? row.broker_info.name : "" }</span>
                                },
                                text: "Broker Name",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                    };
                                },
                            },
                            {
                                dataField: "broker__franchise__name",
                                sort: true,
                                formatter: (cell, row) => {
                                    return <span>{row.broker_info && row.broker_info.franchise ? row.broker_info.franchise.name : "" }</span>
                                },
                                text: "Franchise",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                    };
                                },
                            },
                            {
                                dataField: "broker_info.group_name",
                                sort: false,
                                text: "Group Name",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                    };
                                },
                            },
                            {
                                dataField: "license",
                                sort: true,
                                text: "Office License Number",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "190px",
                                    };
                                },
                            },
                            {
                                dataField: "state",
                                sort: false,
                                text: "State",
                            },
                            {
                                dataField: "city",
                                sort: true,
                                text: "City",
                            },
                            {
                                dataField: "telephone",
                                formatter: formatPhoneNumber(),
                                text: "Telephone",
                            },
                            {
                                dataField: "address",
                                sort: true,
                                text: "Address",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "190px",
                                    };
                                },
                            },
                            {
                                dataField: "address_line2",
                                sort: true,
                                text: "Address Line 2",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "190px",
                                    };
                                },
                            },
                            {
                                dataField: "broker__name",
                                sort: true,
                                formatter: (cell, row) => {
                                    return <span>{row.broker_info ? row.broker_info.name : "" }</span>
                                },
                                text: "Broker Name",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                    };
                                },
                            },
                            {
                                dataField: "agents_stats.total_agents",
                                formatter: formatAgentTotal(goToAgents),
                                text: "# of Agents",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "110px",
                                        textAlign: "center",
                                    };
                                },
                                style: (colum, colIndex) => {
                                    return {
                                        textAlign: "center",
                                    };
                                },
                            },
                            {
                                dataField: "agents_stats.total_user",
                                formatter: formatAgentTotal(goToAgents),
                                text: "# of Registered Agents",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "150px",
                                        textAlign: "center",
                                    };
                                },
                                style: (colum, colIndex) => {
                                    return {
                                        textAlign: "center",
                                    };
                                },
                            },
                            {
                                dataField: "agents_stats.total_unregistered",
                                formatter: formatAgentTotal(goToAgents),
                                text: "# of Unregistered Agents",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "150px",
                                        textAlign: "center",
                                    };
                                },
                                style: (colum, colIndex) => {
                                    return {
                                        textAlign: "center",
                                    };
                                },
                            },
                            {
                                dataField: "agents_stats.total_user_active",
                                formatter: formatAgentTotal(goToAgents),
                                text: "# of Active Agents",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                        textAlign: "center",
                                    };
                                },
                                style: (colum, colIndex) => {
                                    return {
                                        textAlign: "center",
                                    };
                                },
                            },
                            {
                                dataField: "total_show_it_share",
                                formatter: formatCurrency(),
                                text: "Total ShowIT Share",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                        textAlign: "right",
                                    };
                                },
                                style: (colum, colIndex) => {
                                    return {
                                        textAlign: "right",
                                    };
                                },
                            }
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};

export function loadImg() {
    return (cell, row) => {
        return (
            <div style={{
                width: cell ? "25px" : "30px",
                height: cell ? "20px" : "22px",
            }} className="p-0 m-0 justify-content-center">
                <img
                    src={cell ? cell : require('../../../../assets/img/uploadLogo.PNG')}
                    alt='logo'
                    className="align-self-center"
                    style={{maxWidth: '95%', maxHeight: '90%'}}
                />
            </div>
        );
    };
}

export function loadOffice() {
    return (cell, row) => {
        return (
            <React.Fragment>
                <div>
                    <a
                        href={cell}
                        className={'d-block text-dark'}
                    >
                        {cell && cell.substring(34, cell.length - 5)}
                    </a>
                </div>
            </React.Fragment>
        );
    };
}

const formatAgentTotal = (goTo) => (cell, row) => {
    return(
        <p className="my-0 text-primary pointer text-center" onClick={(e) => goTo(row.id)}>{cell}</p>
    )
}

export function formatStatus() {
    return (cell, row) => {
        const getStatus = () => {
            let text = ''
            BROKERAGE_STATUSES.map(item => {
                if (cell === item.value) {
                    text = item.label
                }
            })
            return text
        }

        const getColor = () => {
            let colorText = 'black'
            if (cell === BROKERAGE_ACTIVE) {
                colorText = 'success'
            } else if (cell === BROKERAGE_LOCKED) {
                colorText = 'danger'
            }
            else if (cell === BROKERAGE_BLOCKED) {
                colorText = 'danger'
            }
            return colorText
        }
        return (
            <React.Fragment>
                <p
                    className={`my-0 text-capitalize font-weight-bold text-${getColor()}`}
                    style={{
                        font: 'normal normal medium 13px/22px Poppins',
                        letterSpacing: '0px',
                        opacity: '1'
                    }}
                >
                    {getStatus()}
                </p>
            </React.Fragment>
        )
    };
}

const formatCurrency = () => (cell, row) => {
    return (<div>
        $ {cell ? cell.toFixed(2) : "0.00"}
    </div>)
}

export function formattedState() {
    return (cell, row) => {
        const getState = () => {
            let text = ''
            STATE_USA_LIST.map(option => {
                if (option.value === cell) {
                    text = option.label
                }
            })
            return text;
        }
        return (
            <p className={`my-0 text-capitalize`}>
                {getState()}
            </p>
        )
    };
}

export default BrokerageGrid;
