import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/invitesQualifiyng/qualifying";
import VerificationInvite from "./invite";

const ms2p = (state) => {
    return {
        ...state.verifyTokenInvite,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(VerificationInvite);
