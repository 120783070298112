import React, {useEffect} from 'react';
import './wholeSalerRegister.css';
import { connect } from "react-redux";
import {renderNumber} from '../Utils/renderField';
import ReactCodeInput from "react-verification-code-input";
import {Field, reduxForm, formValueSelector} from 'redux-form';

const validate = (value) => {
    let error = {}
    if(!value.mobile_number) {
        error.mobile_number = "This field is required"
    }
    return error
}

const Form = (props) => {
    const {handleSubmit, validateCode, code, stepBack, resendCode, phoneConfirmed } = props;

    useEffect(() => {
        if (document.getElementById('WHOLESALER-REGISTER')) {
            document.getElementById('WHOLESALER-REGISTER').scrollIntoView({ behavior: 'smooth' });
        }
    }, [])

    return (
        <div className="row flex-1 justify-content-center pt-3">
            <div className="w-100 col-xl-10 col-lg-11 col-md-11 col-12 d-flex flex-column">
                <div className="flex-1 d-flex flex-column justify-content-between">
                    <div className="row">
                        <div className='w-100 col-sm-12'>
                            <form name="wholeSalerRegisterForm" className="row" onSubmit={handleSubmit}>
                                <div className="form-group has-feedback col-sm-12 mb-lg-5 mb-3">
                                    <span className='wholesaler-register-label-title'>Confirming your cell phone</span>
                                    <br />
                                    <span className='wholesaler-register-label-subtitle'>We will send you a confirmation code to your cell phone</span>
                                    <br />
                                    <label htmlFor="mobile_number" className='mt-4'>
                                        <strong className="question2">Cell Phone Number</strong>
                                    </label>
                                    <Field
                                        name="mobile_number"
                                        type="tel"
                                        component={renderNumber}
                                        numberFormat={"###-###-####"}
                                        className="form-control"
                                    />
                                </div>
                                {phoneConfirmed ? (
                                    <div className="buttons-box col-12 justify-content-center mx-auto pt-lg-5 mb-4">
                                        <button type="submit" className="btn wholesaler-register-button col-xl-4 col-lg-5 align-self-center">
                                            Next
                                        </button>
                                    </div>
                                ) :
                                    !stepBack ? (
                                        <div className="buttons-box col-12 justify-content-center mx-auto pt-lg-5 mb-4">
                                            <button type="submit" className="btn wholesaler-register-button col-xl-4 col-lg-5 align-self-center">
                                                Send the code
                                            </button>
                                        </div>
                                    ) : (
                                        <React.Fragment>
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="form-group has-feedback col-lg-8  mx-auto justify-content-center text-center">
                                                        <label htmlFor="mobile_number" className="text-left">
                                                            <p className="wholesaler-register-label-subtitle mb-1">
                                                                Enter the 4-digit code bellow
                                                            </p>
                                                        </label>
                                                        <div style={code === 'error' ?
                                                            {} : { width: '300px !important' }}
                                                            className={`p-0 w-100 ${code === 'error' && "is-invalid mx-auto"} `}
                                                        >
                                                            <ReactCodeInput
                                                                onComplete={validateCode}
                                                                fieldWidth={50}
                                                                fieldHeight={33}
                                                                title={''}
                                                                className={` w-100 ${code === 'error' && "is-invalid"} `}
                                                                fields={4}
                                                                required={true}
                                                                type={"number"}
                                                            />
                                                        </div>
                                                        {code === 'error' &&
                                                            <strong className="invalid-feedback"> Wrong code, please check your code</strong>}

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="buttons-box col-12 justify-content-center mx-auto mb-3">
                                                <button
                                                    type="button"
                                                    className="btn button-03 col-xl-2 col-lg-3 col-6 align-self-center text-12"
                                                    style={{ fontSize: "12px !important" }}
                                                    onClick={() => resendCode()}
                                                >
                                                    Resend code
                                                </button>
                                            </div>
                                        </React.Fragment>
                                    )}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

const WholeSalerPhoneForm = reduxForm({
    form: 'wholeSalerRegisterForm', // a unique identifier for this form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
})(Form);

const selector = formValueSelector('wholeSalerRegisterForm');
const mstp = state => {
    return {
    };
};

export default connect(mstp, null)(WholeSalerPhoneForm);
