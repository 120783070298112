import React from 'react';
import FormPhone from "./FormPhone";
import ValidateCode from "../../Register/Forms/ValidateCode";

const Step2 = (props) => {

    const {onSubmit, validateCode, code, stepBack, resendCode} = props;

    return (
        <div>
            {!stepBack &&
            <FormPhone
                onSubmit={onSubmit}
            />
            }
            {stepBack &&
            <ValidateCode
                code={code}
                action={validateCode}
                resendCode={resendCode}
                title={'phone number*'}
                message={"Enter the 4 digit code send to you to confirm your phone number"}
            />
            }
        </div>
    );
};

export default Step2;
