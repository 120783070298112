import React, {Component} from 'react';
import PropTypes from "prop-types";
import LoadMask from "../Utils/LoadMask/LoadMask";
import InvitesForm from "./InvitesForm";
import {MANAGING, OFFICE_MANAGER} from "../../../utility/constants";

class Invites extends Component {

    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        loading: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            is_view: false,
            is_update: false,
            id_: null,
        };
    }

    componentDidMount() {
        const {match, setRealId, real_id} = this.props;
        if (match.params.id || real_id === undefined) {
            this.props.customRead(match.params.id);
            setRealId(match.params.id);
        }
        if (match.path === '/users/:id/edit') {
            this.setState({
                is_update: true,
                is_view: false,
                real_id: match.params.id,
            });
        }
        if (match.path === '/users/:id/details') {
            this.setState({
                is_view: true,
                is_update: false,
                real_id: match.params.id,
            });
        }
        console.log('mount!')
        this.props.getBrokerages();
    }

    componentWillMount() {
        if (this.state.is_view === false && this.state.is_update === false) {
            this.setState({real_id: null})
        }
    }

    componentWillUnmount() {
        const {setItem, setRealId, setItem2} = this.props
        setItem({});
        setItem2({});
        setRealId(0);
    }

    updateForm = (data) => {
        this.props.updatePermission(this.goBack)
    };

    createInvite = (data) => {
        this.props.invites(this.goBack)
    };

    goBack = () => {
        const {history} = this.props;
        history.goBack()
    }


    render() {
        const {loader, option, setPermissions, item, setItem, cancelInvite, item2, match} = this.props;
        const sendFunction = match.params.id
            ? this.updateForm
            : this.createInvite;
        return (
            <div className="d-flex flex-column">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">
                            {
                                this.state.is_view && "Invite details" || this.state.is_update && "update invite"
                            }
                            {!this.state.is_update && !this.state.is_view && "Invite"}
                        </div>
                    </div>
                </div>
                <div className="card border-dark border-card-page px-4 py-2">
                    <LoadMask loading={loader} light>
                        <InvitesForm
                            option={option}
                            loader={loader}
                            onSubmit={sendFunction}
                            is_view={this.state.is_view}
                            is_update={this.state.is_update}
                            setPermissions={setPermissions}
                            item={item}
                            item2={item2}
                            real_id={this.state.real_id}
                            setItem={setItem}
                            cancelInvite={cancelInvite}
                            goBack={this.goBack}
                            {...this.props}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default Invites;
