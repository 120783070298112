import React from "react";
import "react-step-progress-bar/styles.css";

class StepProgressBar extends React.Component {
    render() {
        const {progress, showAllStep=true} = this.props;

        return (
            <div className="justify-content-center d-flex mb-4 pt-0 mt-0">
                <div className="circle ml-3 active-circle"> </div>
                <div className={`circle ${progress && progress >= 20? "active-circle": "" }`}> </div>
                <div className={`circle ${progress && progress >= 40? "active-circle": "" }`}> </div>
                <div className={`circle ${progress && progress >= 60? "active-circle": "" }`}> </div>
                {showAllStep && <div className={`circle ${progress && progress >= 80? "active-circle": "" }`}> </div>}
                {showAllStep && <div className={`circle ${progress && progress >= 100? "active-circle": "" }`}> </div>}
            </div>
        )
    }
}

export default StepProgressBar;
