import "./register.css"
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import SignUpFormStep1 from "./Forms/SignupFormStep1";
import SignUpFormStep3 from "./Forms/SignupFormStep3";
import SignUpFormStep4 from "./Forms/SignupFormStep4";
import SignUpFormStep5 from "./Forms/SignupFormStep5";
import SignupFormOtherUser from "./Forms/SignupFormOtherUser";
import StepFinish from "./Forms/StepFinish";
import StepProgressBar from "./Forms/StepProgessBar";
import OperationAccess from "./Forms/operationAccess";
import { Info } from "./RegisterComponents";

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            back: false,
            showPassword: false,
            influencerId: null,
        }
    }

    actionOnSubmit = () => {
        const { influencerId } = this.state;
        this.props.onSubmitAction(influencerId);
    };

    actionOnSubmitOther = () => {
        this.props.onSubmitOther();
    };


    action = () => {
        const { setSuccess } = this.props;
        setSuccess(true);
    };

    componentDidMount() {
        const { location } = this.props
        this.props.resetInitialData()
        if (location && location.search) {
            const searchParams = new URLSearchParams(location.search)
            const influencerId = searchParams.get('influencer_id')
            if (influencerId) {
                this.setState({ influencerId })
            }
        }
        document.title = "Sign Up"
    }

    goLogin = () => {
        this.props.goLogin();
    }

    showPass = (value) => {
        this.setState({ showPassword: value })
    }

    render() {
        const {
            step,
            code,
            data,
            loader,
            success,
            stepBack,
            clickBack,
            onSubmitStep,
            validateCode,
            resendCodePhone,
            resendCodeEmail,
            operationAccess,
            assignAddressData,
            setOperationAccess,
        } = this.props;

        if (localStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }
        return (
            <div className="body-img">
                <div className="blue-gradient-bg d-flex align-items-center">
                    <div
                        className="broker-register-wrapper"
                    >
                        <div
                            id='BROKER-REGISTER'
                            className={`card broker-register-card col-lg-10 col-sm-11`}
                        >
                            <div className="broker-register-card-body">
                                <div className="row flex-1">
                                    <div className="col-lg-4 col-md-3 col-12">
                                        <div className="broker-register-title-page-container">
                                            <div className="broker-register-titles-container mt-1 mt-lg-3">
                                                <span className='broker-register-title'>BROKER</span>
                                                <span className='broker-register-subtitle'>Sign Up</span>
                                            </div>
                                            <div className="broker-register-logo-container mt-4 mt-md-0">
                                                <img
                                                    src={require("../../../../../assets/img/logo-01.png")}
                                                    alt={"showIt logo"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-9 col-12">
                                        <div className="broker-register-form-container">
                                            <LoadMask loading={loader} light>
                                                <div className="mb-2">
                                                    <div className="d-flex flex-row align-items-center mb-2">
                                                        <div className='broker-register-back-button-container'>
                                                            {!success && step <= 100 && (
                                                                <div className='broker-register-back-button' onClick={() => clickBack()}>
                                                                    <img
                                                                        src={require("../../../../../assets/img/chevron-left-solid.svg")}
                                                                        alt="back"
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                        <span className='broker-register-form-title'>Create an Account</span>
                                                    </div>
                                                    {!success && step < 120 && (
                                                        <StepProgressBar progress={step} />
                                                    )}
                                                </div>

                                                {step === 0 && (
                                                    <Info
                                                        onSubmit={() => onSubmitStep()}
                                                    />
                                                )}
                                                {step === 20 && <SignUpFormStep1 assignAddressData={assignAddressData} onSubmit={() => onSubmitStep()} />}
                                                {step === 40 &&
                                                    <OperationAccess
                                                        option={operationAccess}
                                                        setOption={setOperationAccess}
                                                        ActionClick={() => onSubmitStep()}
                                                    />
                                                }

                                                {step === 60 &&
                                                    <SignUpFormStep3
                                                        onSubmitStep={onSubmitStep}
                                                        validateCode={validateCode}
                                                        code={code}
                                                        stepBack={stepBack}
                                                        resendCode={resendCodePhone}
                                                    />}
                                                {step === 80 &&
                                                    <SignUpFormStep4
                                                        onSubmitStep={onSubmitStep}
                                                        validateCode={validateCode}
                                                        code={code}
                                                        stepBack={stepBack}
                                                        resendCode={resendCodeEmail}
                                                    />}
                                                {!success && step === 100 &&
                                                    <SignUpFormStep5
                                                        onSubmit={this.actionOnSubmit}
                                                        showPassAction={this.showPass}
                                                        showPassword={this.state.showPassword}
                                                    />}
                                                {step === 120 && <StepFinish />}
                                            </LoadMask>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Register;
