import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/influencer/agent'
import AgentsList from "./AgentsList";

const ms2p = (state) => {
    return{
        ...state.influencerAgent
    };
};

const md2p = {
    ...actions
};

export default connect(ms2p, md2p)(AgentsList);