import React, {useState, useEffect} from 'react';
import moment from "moment";
import {SIZE_PER_PAGE} from "../../../../utility/constants";
import GridTableWithScroll from "../../Utils/GridTableWithScroll";


const StatsRevenueGrid = (props) => {
    const {loader, data, nextPage} = props;

    const [gridHeight, setGridHeight] = useState(200)

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("STATS-REVENUE");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])

    return (
        <div id="STATS-REVENUE" className="card container-list border-dark border-card-page px-4 py-2 flex-overflow-hidden-1">
            <div className="card-body px-0 pt-2 mb-0 pb-0 d-flex flex-column">
                <GridTableWithScroll
                    height={gridHeight}
                    data={data}
                    wrapperClasses="table-scroll-auto"
                    keyField={'id'}
                    loading={loader}
                    onPageChange={(value) => nextPage(value)}
                    remote={true}
                    sizePerPage={SIZE_PER_PAGE}
                    columns={[
                        {
                            dataField: "icon",
                            formatter: loadImg(),
                            text: "",
                        },
                        {
                            dataField: "job_name",
                            short: true,
                            text: "Job Type",
                        },
                        {
                            dataField: "created",
                            short: false,
                            formatter: dateTimeFormat(),
                            text: "Date Posted",
                        },
                        {
                            dataField: "date_completed",
                            formatter: dateFormat(),
                            text: "Date Completed",
                        },
                        {
                            dataField: "agent_name",
                            short: false,
                            text: "Hiring Agent",
                        },
                        {
                            dataField: "office_name",
                            short: true,
                            text: "Office",
                        },
                        {
                            dataField: "performing_agent_name",
                            short: false,
                            text: "Performing Agent",
                        },
                        {
                            dataField: "performing_office_name",
                            short: false,
                            text: "Office",
                        },
                        {
                            dataField: "hiring_agent_amount",
                            short: false,
                            formatter: formatterAmount(),
                            text: "Hiring Agent Profit",
                        },
                        {
                            dataField: "showing_agent_amount",
                            short: false,
                            formatter: formatterAmount(),
                            text: "Showing Profit",
                        },
                        {
                            dataField: "total_broker_fee",
                            short: false,
                            formatter: formatterAmount(),
                            text: "Total Profit",
                        },
                    ]}
                />
            </div>
        </div>
    );
};

function dateFormat() {
    return (cell) => {
        return (
            <div>
                <p className="p-0 m-0" style={{width: '5rem'}}>
                    {cell && moment(cell).format('ll')}
                </p>
            </div>
        );
    };
}


function formatterAmount() {
    return (cell) => {
        return (
            <div>
                <p className="p-0 m-0 row justify-content-end" style={{width: '4.5rem'}}>
                    $ {cell ? cell : 0.00}
                </p>
            </div>
        );
    };
}

function loadImg() {
    return (cell, row) => {
        return (
            <div className="p-0 m-0 justify-content-center container-cell-icon mx-auto">
                <img
                    src={cell ? cell : require('../../../../../assets/img/uploadLogo.PNG')}
                    alt=' '
                    className="align-self-center"
                    style={{maxWidth: '95%', maxHeight: '90%'}}
                />
            </div>
        );
    };
}

function dateTimeFormat() {
    return (cell) => {
        return (
            <div>
                <p className="p-0 m-0" style={{width: '5rem'}}>
                    {cell && moment(cell).format('lll')}
                </p>
            </div>
        );
    };
}

export default StatsRevenueGrid;
