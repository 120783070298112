import React from "react";
import { Field, reduxForm } from "redux-form";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import {
    renderTextArea,
} from "../../Utils/renderField";
import { renderFilePicker } from "../../Utils/renderField/renderField";

const validate = (value) => {
    let errors = {};

    if(!value.content) {
        errors.content = "This field is required"
    }

    return errors
}

const HelpSupportForm = (props) => {
    const {
        handleSubmit,
        agent,
        option,
        setFile,
        loader,
        goBack,
    } = props;

    return (
        <form
            name="brokerageForm"
            className="pt-2 flex col-sm-12 col-lg-8 mx-auto"
            onSubmit={handleSubmit}
            style={{ minHeight: "36rem" }}
        >
            <div className="row">
                <div className="col-sm-12">
                    <h4 className="font-weight-bold">Hello {agent}</h4>
                </div>
            </div>
            {option && (
                <React.Fragment>
                    {option.subtitle && (
                        <div className="row mt-4">
                            <div className="col-sm-12">
                                <h5 className="font-weight-bold">{option.subtitle}</h5>
                            </div>
                        </div>
                    )}
                    <div className={`row ${option.subtitle ? "mt-1" : "mt-4"}`}>
                        <div className="col-sm-12">
                            <p style={{whiteSpace: 'pre-line'}} className="font-weight-bold">{option.description}</p>
                        </div>
                    </div>
                </React.Fragment>
            )}

            <div className="row">
                <div className="col-sm-12 mx-auto">
                    <label htmlFor="content">
                        Please share your feedback
                    </label>
                    <Field
                        name="content"
                        label="content"
                        component={renderTextArea}
                        rows={6}
                        customClass="text-area-h-auto border"
                    />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-sm-12 mx-auto">
                    <label className="w-100 text-center mb-0" htmlFor="attachments">
                        Optionally add a photo
                    </label>
                    <Field
                        photo={null}
                        setFile={setFile}
                        name="attachments"
                        component={renderFilePicker}
                    />
                </div>
            </div>

            {/*-------------------------------------------------------------------------------*/}
            {/*-----------------        ACTIONS CREATE OR UPDATE           -------------------*/}
            {/*-------------------------------------------------------------------------------*/}
            <div className="w-100 my-4 mx-auto text-center">
                <button
                    type="submit"
                    className="btn btn-primary mx-3 col-lg-4 col-sm-10 mt-sm-1 mt-lg-0 py-0"
                    disabled={loader}
                >
                    Send
                </button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: "HelpSupportForm", // a unique identifier for this form
    validate,
})(HelpSupportForm);
