import React from 'react';
import {validate, validators} from "validate-redux-form";
import {reduxForm, Field} from "redux-form";
import {renderField} from "../Utils/renderField";


const InvitesQualifyingForm = (props) => {
    const {handleSubmit, logOut, send} = props;
    return (
        <div className="mx-auto justify-content-center py-4" style={{width: "45rem"}}>
            <div className="container-logo-register justify-content-center mb-3 align-self-center mt-0">
                <img
                    src={require("../../../../assets/img/logo-01.png")}
                    className="logo-form"
                    alt={"showIt logo"}/>
            </div>
            {!send && <h6 className="text-center col-lg-9 text-dark col-sm-12 mx-auto pt-2"
                         style={{font: "normal normal 600 14px/17px Poppins"}}>
                Your account has been denied, to continue with the registration you can send a
                registration invitation to your broker qualified
            </h6>
            }

            {send ?
                <div className="col text-center">
                    <h4 className="text-center col-lg-9 text-dark col-sm-12 mx-auto pt-2">
                        Thanks you
                    </h4>
                    <h6 className="text-center col-lg-9 text-dark col-sm-12 mx-auto">
                        Your invitation was sent to the Broker
                    </h6>
                    <button
                        type="button"
                        className="btn button-01 col-lg-3 col-sm-6 mt-3 mx-auto justify-content-center text-center"
                        onClick={() => logOut()}
                    >
                        Exit
                    </button>
                </div>
                :
                <form name="InviteQualifyingForm" onSubmit={handleSubmit}
                      className="mx-auto col-lg-6 col-sm-12 mt-lg-4">
                    <div className="form-group has-feedback">
                        <label htmlFor="first_name"> First Name </label>
                        <Field
                            name="first_name"
                            label="First Name"
                            component={renderField}
                            type="text"
                        />
                    </div>
                    <div className="form-group has-feedback">
                        <label htmlFor="qualifying_last_name"> Last Name</label>
                        <Field
                            name="last_name"
                            label="Last Name"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback">
                        <label htmlFor="email"> Email </label>
                        <Field
                            name="email"
                            label="Email"
                            component={renderField}
                            type="email"
                            className="form-control"
                        />
                    </div>
                    <div className="mx-auto text-center pt-3 justify-content-between">
                        <button
                            type="button"
                            className="btn button-03 col-lg-5 col-sm-6 mt-sm-2 mr-lg-3 mr-sm-0"
                            onClick={() => logOut()}
                        >
                            Exit
                        </button>
                        <button
                            type="submit"
                            className="btn button-01 col-lg-5 col-sm-6 mt-sm-2 ml-lg-3 ml-sm-0"
                        >
                            Send invite
                        </button>
                    </div>
                </form>
            }
        </div>
    )
}


export default reduxForm({
    form: "inviteQualifying",
    validate: (data) => {
        return validate(data, {
            first_name: validators.exists()('This field is required'),
            last_name: validators.exists()('This field is required'),
            email: validators.exists()('This field is required')
        })
    }
})(InvitesQualifyingForm)
