import React, {useState, useEffect} from "react";
import Avatar from "react-avatar";
import {
    SIZE_PER_PAGE,
    REGISTERED_VALUE,
    PENDING_VALUE,
} from "../../../../../utility/constants";
import Grid from "../../../Utils/GridTable";

const InfluencerAgentsGrid = (props) => {
    const {
        data,
        page,
        toList,
        loader,
        tabContainerHeight,
    } = props;

    return (
        <div className="container-list card border-dark border-card-page px-4 py-2 flex-1">
            <div className="card-body px-0 pt-2 mb-0 pb-0 d-flex flex-column flex-1">
                <Grid
                    hover
                    height={tabContainerHeight}
                    wrapperClasses="table-scroll-auto"
                    keyField={'id'}
                    loading={loader}
                    page={page}
                    data={data}
                    sizePerPage={SIZE_PER_PAGE}
                    onPageChange={(value) => toList(value)}
                    columns={[
                        {
                            dataField: "first_name",
                            text: "First Name",
                        },
                        {
                            dataField: "last_name",
                            text: "Last Name",
                        },
                        {
                            dataField: "account_status",
                            formatter: formatStatus(),
                            text: "",
                        },
                    ]}
                    />
                </div>
            </div>
    )
}

export function formatStatus() {
    return (cell, row) => {
        return (
            <div className="row w-100 d-flex flex-column justify-content-center align-items-center" style={{width: '13rem'}}>
                {cell === REGISTERED_VALUE && (
                    <span className="p-1 text-white bg-success rounded w-50 text-center">
                        Registered
                    </span>
                )}
                {cell == PENDING_VALUE&& (
                    <span className="p-1 text-dark bg-gray rounded w-50 text-center">
                        Unregistered
                    </span>
                )}
            </div>
        );
    };
}

export default InfluencerAgentsGrid;

