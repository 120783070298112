import {connect} from 'react-redux';
import {actions} from '../../../redux/modules/brokerage/brokerage'
import Brokerage from "./Brokerage";

const ms2p = (state) => {
    return {
        ...state.brokerage,
        min_offices: state.login.me.min_offices,
        provide_agents_roster: state.login.me.provide_agents_roster,
    };
};

const md2p = {...actions};

export default connect(ms2p, md2p)(Brokerage);

