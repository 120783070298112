import { handleActions } from "redux-actions";
import { createReducer } from "../baseReducer/baseReducer";
import { NotificationManager } from "react-notifications";
import { api } from "api";
import { push } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";

// ------------------------------------
// CONSTANTS
// ------------------------------------
const endpoint = "influencer";
const SET_DATA = 'SET_DATA_INFLUENCER_PROFILE';
const SET_PHOTO = 'SET_PHOTO_INFLUENCER_PROFILE';
const SET_LOGO = 'SET_LOGO_INFLUENCER_PROFILE';

// ------------------------------------
// BASE ACTION
// ------------------------------------
const baseReducer = createReducer(
    "influencerProfile",
    endpoint,
    "influencerForm",
    ""
);

// ------------------------------------
// Pure Actions
// ------------------------------------
const setData = data => ({
    type: SET_DATA,
    data,
});
const setAvatar = photo => ({
    type: SET_PHOTO,
    photo,
})
const setLogo = logo => ({
    type: SET_LOGO,
    logo,
})

// ------------------------------------
// Actions
// ------------------------------------
const getProfile = () => (dispatch, getStore) => {
    dispatch(baseReducer.actions.setLoader(true));
    const store = getStore();
    const {me} = store.login;
    if (me && me.influencer_id) {
        api.get(`${endpoint}/${me.influencer_id}`).then(response => {
            if (response) {
                dispatch(setData(response))
                dispatch(initializeForm('influencerForm', response))
            }else {
                dispatch(setData(null))
            }
        }).catch(error => {
            dispatch(setData(null))
        }).finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        })
    }else {
        dispatch(baseReducer.actions.setLoader(false));
    }
}

const edit = (id, data = {}, goBack) => (dispatch, getStore) => {
    dispatch(baseReducer.actions.setLoader(true));
    delete data.agreement;
    delete data.logo;
    delete data.photo;

    const {photo, logo} = getStore().influencerProfile;
    if (photo !== null || logo !== null) {
        let files = [];
        if (photo !== null) {
            files.push({name: 'photo', file: photo})
        }
        if (logo !== null) {
            files.push({name: 'logo', file: logo})
        }
        api.putAttachments(`${endpoint}/${id}`, data, files)
            .then((response) => {
                NotificationManager.success('Record updated', 'SUCCESS', 3000);
                dispatch(setAvatar(null));
                dispatch(setLogo(null));
                dispatch(setData(null))
                dispatch(getProfile())
                goBack()
            })
            .catch((error) => {
                let msg = "Edition failed";
                if (error && error.detail) {
                    msg = error.detail
                }
                console.log("ERROR", error);
                NotificationManager.error(msg, 'ERROR');
            })
            .finally(() => {
                dispatch(baseReducer.actions.setLoader(false));
            });
    }else {
        api.put(`${endpoint}/${id}`, data).then(() => {
            NotificationManager.success('Record updated', 'Success', 3000);
            dispatch(setAvatar(null));
            dispatch(setLogo(null));
            dispatch(setData(null))
            dispatch(getProfile())
            goBack()
        }).catch(() => {
            // NotificationManager.error('Edition failed', 'ERROR', 0);
            let msg = "Edition failed";
            if (error && error.detail) {
                msg = error.detail
            }
            console.log("ERROR", error);
            NotificationManager.error(msg, 'ERROR');
        }).finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        });
    }
};

export const actions = {
    ...baseReducer.actions,
    getProfile,
    setAvatar,
    setLogo,
    edit,
};

export const reducers = {
    ...baseReducer.reducers,
    [SET_DATA]: (state, {data}) => {
        return {
            ...state,
            data,
        };
    },
    [SET_PHOTO]: (state, {photo}) => {
        return {
            ...state,
            photo,
        };
    },
    [SET_LOGO]: (state, {logo}) => {
        return {
            ...state,
            logo,
        };
    },
};

export const initialState = {
    ...baseReducer.initialState,
    photo: null,
    logo: null,
    data: null,
};

export default handleActions(reducers, initialState);
