import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";
import {NotificationManager} from "react-notifications";
import {api} from "api";
import {push} from "react-router-redux";
import { change as changeForm, initialize } from "redux-form";
import {SELECT_ALL_OPTION} from '../../../utility/constants'

// ------------------------------------
// Constants
// ------------------------------------
const LOADER = 'LOADER_AGENT_REGISTER';
const SET_FILES_AGENT = 'SET_FILES_AGENT_REGISTER';
const SET_OPTION_FILTER = 'SET_OPTION_FILTER_AGENT_REGISTER';
const SET_CATEGORY_FILTER = 'SET_CATEGORY_FILTER_REGISTER';
const SET_ACCOUNT_STATUS = 'SET_ACCOUNT_STATUS_REGISTER';
const SET_NAME_OFFICE__FILTER = 'SET_NAME_OFFICE__FILTER_REGISTER';
const SET_NOTES_LOADER = 'SET_NOTES_LOADER_GENT';
const SET_NOTES_ITEM_LOADER = 'SET_NOTES_ITEM_LOADER_GENT';
const SET_NOTES_PAGE = 'SET_NOTES_PAGE_GENT';
const SET_NOTES_DATA = 'SET_NOTES_DATA_GENT';
const SET_NOTES_ITEM = 'SET_NOTES_ITEM_GENT';
const SET_WORKING_AREA = 'SET_WORKING_AREA_AGENT';

//-------------------------------------
// Base reducer
//-------------------------------------
const basicReducer = createReducer(
    "agentRegistered",
    "agent-registered",
    "updateAgentForm",
    "/agents",
);

// ------------------------------------
// Pure Actions
// ------------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setOption = offices => ({
    type: SET_OPTION_FILTER,
    offices,
});

const setFilterCategory = category => ({
    type: SET_CATEGORY_FILTER,
    category,
});
const setAccountStatus = account_status => ({
    type: SET_ACCOUNT_STATUS,
    account_status,
});

const setFilterOffice = idOffice => ({
    type: SET_NAME_OFFICE__FILTER,
    idOffice,
});

const setNotesItemLoader = (notesItemLoader) => ({
    type: SET_NOTES_ITEM_LOADER,
    notesItemLoader,
});
const setNotesLoader = (notesLoader) => ({
    type: SET_NOTES_LOADER,
    notesLoader,
});
const setNotesPage = (notesPage) => ({
    type: SET_NOTES_PAGE,
    notesPage,
});
const setNotesData = (notesData) => ({
    type: SET_NOTES_DATA,
    notesData,
});
export const setNotesItem = (notesItem) => ({
    type: SET_NOTES_ITEM,
    notesItem,
});

const setWorkingArea = (workingArea) => ({
    type: SET_WORKING_AREA,
    workingArea,
});

// ------------------------------------
// Actions
// ------------------------------------

export const toList2 = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore()
    const resource = store['agentRegistered'];
    let params = {page};
    params.ordering = resource.ordering;
    // params.search = resource.search;
    // params.locked = resource.category;
    // if (resource.account_status) {
    //     params.agent_account_status = resource.account_status;
    // }
    // params.brokerage_id = resource.idOffice;

    const { AgentFiltersForm } = store.form;
    if (AgentFiltersForm && AgentFiltersForm.values) {
        const { brokerage, registered_status, search, operation_access } =
            AgentFiltersForm.values;
        
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            if (brokerage.length == 0) {
                params.brokerage_information__id__in = 0
            }else {
                params.brokerage_information__id__in = brokerage;
            }
        }
        if (registered_status !== "" && registered_status !== null && registered_status !== undefined && registered_status !== SELECT_ALL_OPTION["value"]) {
            if (registered_status.length == 0) {
                params.agent_account_status__in = 0
            }else {
                params.agent_account_status__in = registered_status;
            }
        }
        if (search !== "" && search !== null && search !== undefined) {
            params.search = search;
        }
        if (operation_access !== "" && operation_access !== null && operation_access !== undefined && operation_access !== SELECT_ALL_OPTION["value"]) {
            if (operation_access.length == 0) {
                params.brokerage_information__broker__operation_access__in = 0
            }else {
                params.brokerage_information__broker__operation_access__in = operation_access;
            }
        }
    }
    api.get('agent-registered', params)
        .then((response) => {
            dispatch(basicReducer.actions.setData(response));
            dispatch(basicReducer.actions.setPage(page));
        })
        .catch(() => {
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const getAgentUser = (id) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.get(`agent-registered/${id}`)
        .then((response) => {
            dispatch(basicReducer.actions.setItem(response));
        })
        .catch((error) => {
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const changedPasse = (id, data, goBack=null) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.post(`registered_agents/changed_passe`, {...data, 'id': id})
        .then((response) => {
            NotificationManager.success('Password updated', 'SUCCESS', 1000);
            if (goBack) {
                goBack()
            } else {
                dispatch(push(`/agents/agent/${id}/edit/`))
            }
        }).catch(() => {
        NotificationManager.error('Change Password failed', 'Error', 1000);
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
}

export const update = (data = {}, attachments = []) => (dispatch) => {
    delete data.photo;
    dispatch(setLoader(true));
    api.putAttachments(`agent-registered/${data.id}`, data, attachments)
        .then((response) => {
            dispatch(push('/agents'));
            NotificationManager.success('Agent updated successfully', 'SUCCESS', 1000);
        })
        .catch(() => {
            NotificationManager.error('Error, please try again', 'ERROR', 1000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const getOffices = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('brokerage/option_select')
        .then(response => {
            let option = [];
            if (response.results.length > 1) {
                option = [{
                    label: 'All',
                    value: '',
                }, ...response.results];
            }else {
                option = [...response.results];
                dispatch(setFilterOffice(response.results[0].value));
                dispatch(changeForm('agentRegisterForm', 'filterOffice', response.results[0].value))
            }
            dispatch(setOption({results: option}));
        })
        .catch(() => {
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const setCategoryFilter = (category) => (dispatch, getStore) => {
    const page = getStore()['agentRegistered'].page;
    if (category.value == undefined || category.value == true || category.value == '') {
        dispatch(setAccountStatus(null))
        dispatch(setFilterCategory(category.value));
    }else {
        dispatch(setFilterCategory(''));
        dispatch(setAccountStatus(category.value))
    }
    dispatch(toList2(page));
};

const setOfficeFilter = (office) => (dispatch, getStore) => {
    const page = getStore()['agentRegistered'].page;
    dispatch(setFilterOffice(office.value));
    dispatch(toList2(page));
};

const blockUserTypeAgent = (id) => (dispatch, getStore) => {
    const page = getStore()['agentRegistered'].page;
    dispatch(setLoader(true));
    api.put(`agent-registered/${id}/to_block`)
        .then(response => {
            NotificationManager.success('Block agent successfully', 'SUCCESS', 2000);
            dispatch(toList2(page));
        })
        .catch((error) => {
            NotificationManager.error('Block agent failed, please try again later', 'ERROR', 2000);
            console.log('error:', error);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const unblockUserTypeAgent = (id) => (dispatch, getStore) => {
    const page = getStore()['agentRegistered'].page;
    dispatch(setLoader(true));
    api.put(`agent-registered/${id}/unblock`)
        .then(response => {
            NotificationManager.success('Unblock user successfully', 'SUCCESS', 2000);
            dispatch(toList2(page));
        })
        .catch((error) => {
            NotificationManager.error('Failed Unblock agent, please try again later', 'ERROR', 2000);
            console.log('error:', error);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};
const activeUserTypeAgent = (id) => (dispatch, getStore) => {
    const page = getStore()['agentRegistered'].page;
    dispatch(setLoader(true));
    api.put(`agent-registered/${id}/validate`)
        .then(response => {
            NotificationManager.success('The user was successfully validated', 'SUCCESS', 2000);
            dispatch(toList2(page));
        })
        .catch((error) => {
            NotificationManager.error('Failed validate agent, please try again later', 'ERROR', 2000);
            console.log('error:', error);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const searchChange2 = search => (dispatch) => {
    dispatch(basicReducer.actions.setSearch(search));
    dispatch(toList2());
};

const simpleUpdate = (data = {}, attachments = []) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.putAttachments(`registered_agents/${data.id}`, data, attachments).then((response) => {
        dispatch(push('/agents'));
        NotificationManager.success('Agent updated', 'SUCCESS', 1000);
    }).catch(() => {
        NotificationManager.error('Error, please try again', 'ERROR', 1000);
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
};

const editAgent = (id, data) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.put(`registered_agents/${id}`, data).then(() => {
        NotificationManager.success('Record Updated', 'Success', 3000);
        dispatch(push('/agents'));
    }).catch(() => {
        NotificationManager.error('Update failed', 'ERROR', 3000);
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
};

const initializeFiltersForm = () => (dispatch, getStore) => {
    const resource = getStore()['agentRegistered'];
    dispatch(initialize('agentRegisterForm', {
        filterCategory: resource.category,
        filterOffice: resource.idOffice ? resource.idOffice : null
    }));
}


const goPage = (url) => (dispatch) => {
    dispatch(push(url));
};

export const resetData = () => (dispatch) => {
    dispatch(basicReducer.actions.setData({
        results: [],
        count: 0
    }))
}

export const listLockNotes = (user_id, page = 1) => (dispatch, getStore) => {
    const resource = getStore()["agent"];
    const params = { page, user_id, type: 100 };
    // params.ordering = resource.ordering;
    // params.search = resource.search;
    dispatch(setNotesLoader(true));
    api.get('lock_note', params).then((response) => {
        dispatch(setNotesData(response));
        dispatch(setNotesPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setNotesLoader(false));
    });
};

export const createLockNotes = (user_id, data, onCloseModal=null) => (dispatch, getStore) => {
    dispatch(setNotesItemLoader(true));
    const body = { ...data, user: user_id, type: 100 };
    api.post('lock_note', body).then((response) => {
        dispatch(listLockNotes(user_id))
        if(onCloseModal) onCloseModal()
        NotificationManager.success('Note created successfully', 'SUCCESS', 2000);
    }).catch(() => {
        NotificationManager.error('The note was not registered, try again later', 'Error', 3000);
    }).finally(() => {
        dispatch(setNotesItemLoader(false));
    });
};

export const readLockNotes = (id, user_id=null, onOpenModal=null) => (dispatch) => {
    let params = {};
    if (user_id) {
        params.user_id = user_id
    }
    dispatch(setNotesLoader(true));
    api.get(`lock_note/${id}`, params).then((response) => {
        dispatch(setNotesItem(response));
        if (onOpenModal) {
            dispatch(initialize('lockNoteForm', {note: response.note ? response.note : ''}))
            onOpenModal()
        }
    }).catch(() => {
        dispatch(setNotesItem(null));
        dispatch(initialize('lockNoteForm', {}))
    }).finally(() => {
        dispatch(setNotesLoader(false));
    });
};

export const editLockNotes = (id, data,  onCloseModal=null) => (dispatch) => {
    dispatch(setNotesItemLoader(true));
    api.put(`lock_note/${id}`, {...data, type: 100}).then((response) => {
        dispatch(setNotesItem(null));
        if (onCloseModal) {
            dispatch(initialize('lockNoteForm', {}))
            onCloseModal()
        }
        dispatch(listLockNotes(data.user))
        NotificationManager.success('Note updated successfully', 'SUCCESS', 2000);
    }).catch(() => {
        NotificationManager.error('The note was not updated, try again later', 'Error', 3000);
    }).finally(() => {
        dispatch(setNotesItemLoader(false));
    });
};

export const deleteLockNotes = (id, user_id, onCloseModal=null) => (dispatch) => {
    dispatch(setNotesItemLoader(true));
    api.eliminar(`lock_note/${id}`).then((response) => {
        dispatch(setNotesItem(null));
        if (onCloseModal) {
            dispatch(initialize('lockNoteForm', {}))
            onCloseModal()
        }
        dispatch(listLockNotes(user_id))
        NotificationManager.success("Note deleted successfully", 'SUCCESS', 2000)
    }).catch(() => {
        NotificationManager.error('It was not possible to delete the note', 'Error', 3000);
    }).finally(() => {
        dispatch(setNotesItemLoader(false));
    });
};

const removeAgent = id => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.eliminar(`registered_agents/${id}`).then(() => {
        dispatch(push('/agents'));
        NotificationManager.success('Agent removed', 'Success', 3000);
    }).catch(() => {
        NotificationManager.error('Transaction error', 'Error', 3000);
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
};

// ------------------------------------
// Actions
// ------------------------------------

export const actions = {
    getAgentUser,
    changedPasse,
    update,
    getOffices,
    setCategoryFilter,
    setOfficeFilter,
    blockUserTypeAgent,
    unblockUserTypeAgent,
    toList2,
    searchChange2,
    editAgent,
    simpleUpdate,
    initializeFiltersForm,
    activeUserTypeAgent,
    ...basicReducer.actions,
    goPage,
    setWorkingArea,
    removeAgent,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    ...basicReducer.reducers,
    [LOADER]: (state, {loader}) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_FILES_AGENT]: (state, {files}) => {
        return {
            ...state,
            files,
        };
    },
    [SET_OPTION_FILTER]: (state, {offices}) => {
        return {
            ...state,
            offices,
        };
    },
    [SET_CATEGORY_FILTER]: (state, {category}) => {
        return {
            ...state,
            category,
        };
    },
    [SET_NAME_OFFICE__FILTER]: (state, {idOffice}) => {
        return {
            ...state,
            idOffice,
        };
    },
    [SET_ACCOUNT_STATUS]: (state, {account_status}) => {
        return {
            ...state,
            account_status,
        };
    },
    [SET_NOTES_LOADER]: (state, {notesLoader}) => {
        return {
            ...state,
            notesLoader,
        };
    },
    [SET_NOTES_ITEM_LOADER]: (state, {notesItemLoader}) => {
        return {
            ...state,
            notesItemLoader,
        };
    },
    [SET_NOTES_PAGE]: (state, {notesPage}) => {
        return {
            ...state,
            notesPage,
        };
    },
    [SET_NOTES_DATA]: (state, {notesData}) => {
        return {
            ...state,
            notesData,
        };
    },
    [SET_NOTES_ITEM]: (state, {notesItem}) => {
        return {
            ...state,
            notesItem,
        };
    },
    [SET_WORKING_AREA]: (state, {workingArea}) => {
        return {
            ...state,
            workingArea,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    files: [],
    offices: {results: []},
    category: '',
    account_status: null,
    idOffice: '',
    ...basicReducer.initialState,
    notesLoader: false,
    notesItemLoader: false,
    notesPage: 1,
    notesData: {
        results: [],
        count: 0,
    },
    notesItem: null,
    workingArea: {
        latitude_center: 0,
        longitude_center: 0,
    },
};

export default handleActions(reducers, initialState);

