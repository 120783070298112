import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/invitesQualifiyng/qualifying';
import { actionsModal } from '../../../redux/modules/modal/modal';
import InvitesQualifying from "./InvitesQualifying";

const ms2p = (state) => {
    return{
        ...state.verifyTokenInvite,
        ...state.modal,
    };
};

const md2p = { ...actions, ...actionsModal };

export default connect(ms2p, md2p)(InvitesQualifying);

