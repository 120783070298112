import {connect} from "react-redux";
import './dashboard.css';
import React, {Component} from 'react';
import {
    PENDING,
    getDateRange,
    getMonthsLabel,
    getMonthsRange,
    LabelDaysOfWeek,
    OFFICE_MANAGER,
} from "../../../utility/constants";
import {reduxForm, formValueSelector} from "redux-form";
import DashboardFiltersForm from './DashboardFiltersForm'
import DoughnutAgent from './DoughnutAgent/DoughnutAgent';
import {ShowITChart, JobChart, Agents} from "./DashboardComponents"

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const {
            initialReport,
            getAllJobType,
            getOffices,
            getFranchisesOption,
            getCategoriesOption,
            initFilters,
            getDataShowIT,
            getDataShowITCategories,
            getDataJob,
            getDataJobCategories,
            getDataRevenueAgents,
            getDataAgents,
            login: {me}
        } = this.props;
        initFilters()
        if (me.account_status !== PENDING) {
            getOffices(null, true);
            getAllJobType();
            getFranchisesOption(null, true);
            getCategoriesOption(null, true);

            getDataShowIT(false);
            getDataShowITCategories(false);
            getDataJob(false);
            getDataJobCategories(false);
            getDataRevenueAgents();
            getDataAgents()
        }
    }

    componentWillUnmount() {
        this.props.removeValues();
    }


    filterMonthAction = (itemSelect) => {
        this.props.filterMonth(itemSelect);
    }

    filterWeekAction = (itemSelect) => {
        this.props.filterWeek(itemSelect);
    }

    setLabel = (value) => {
        if (value.length > 0) {
            this.setState({months: getDateRange(value), labelDash: LabelDaysOfWeek(value)})
        } else {
            this.setState({months: getMonthsRange(), labelDash: getMonthsLabel()})
        }
    }


    render() {
        const {
            data,
            loader,
            loader2,
            offices,
            franchises,
            categories,
            nameJob,
            revenueItem,
            jobWeekItem,
            filterAgent,
            filterRanking,
            addFilterJobId,
            agentRegistered,
            filterBrokerage,
            removeFilterJobId,
            setFilterOfficeWeek,
            //
            // jobNames,
            dataShowIT,
            dataShowITCategory,
            showITRanger,
            dataJob,
            dataCategory,
            dataRevenueAgents,
            dataAgents,
            login: {me},
        } = this.props;

        //  Bind
        const { changeFilters, setRanger } = this.props;

        return (
            <div className="w-100 px-1 pb-5">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">
                            {/*{me.roles === OFFICE_MANAGER*/}
                            {/*    ? 'Home' :*/}
                                Dashboard
                            {/*}*/}
                        </div>
                    </div>
                </div>

                {/* FILTERS */}
                <DashboardFiltersForm
                    optionFranchises={franchises}
                    optionBrokerages={offices}
                    optionCategories={categories}
                    changeFilters={changeFilters}
                />

                <div className="row px-1">
                    <div className="row w-100 mx-0 px-0 mb-sm-2 mb-lg-0">
                        {/* ShowIT */}
                        <ShowITChart
                            loader={loader}
                            data={dataShowIT}
                            jobNames={nameJob}
                            ranger={showITRanger}
                            setRanger={setRanger}
                            dataShowITCategory={dataShowITCategory}
                        />
                        {/*-------------------------------------------------*/}
                        {/*------------------- CARD RIGHT TOP  -------------*/}
                        {/*-------------------------------------------------*/}
                        {/* Job */}
                        <JobChart
                            loader={loader2}
                            data={dataJob}
                            jobNames={nameJob}
                            ranger={showITRanger}
                            setRanger={setRanger}
                            dataCategory={dataCategory}
                        />
                    </div>
                </div>
                {/*CARD SMALL*/}
                <div className="row mt-3">
                    {/* REVENUE */}
                    <div
                        className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mt-3"
                        style={{ height: "inherit" }}
                    >
                        <div className="card border-card-page border-card-20 h-100">
                            <div className="card-title row pt-3 px-3">
                                <label className="p-0 mx-2 mb-lg-0 mb-sm-1 my-auto align-self-center label-semi-bold">
                                    Revenue
                                </label>
                            </div>
                            <div className="row px-1 px-lg-2 w-100 m-auto py-2 py-lg-2">
                                <div className="col-lg-4 col-sm-12 text-center m-auto">
                                    <h6 className="row text-dark justify-content-center font-weight-bold text-12 mb-0 pb-1">
                                        Total Pending Jobs
                                    </h6>
                                    <h5 className=" text-dark row justify-content-center font-weight-bold mb-0 ">
                                        {dataRevenueAgents &&
                                        dataRevenueAgents.total_to_revenue
                                            ? dataRevenueAgents.total_to_revenue
                                                  .total
                                                ? dataRevenueAgents
                                                      .total_to_revenue.total
                                                : 0
                                            : 0}
                                    </h5>
                                    <h5 className=" text-dark row justify-content-center font-weight-bold pt-1 ">
                                        US $
                                        {dataRevenueAgents &&
                                        dataRevenueAgents.total_to_revenue
                                            ? dataRevenueAgents.total_to_revenue
                                                  .fee
                                                ? dataRevenueAgents.total_to_revenue.fee.toFixed(
                                                      2
                                                  )
                                                : "0.00"
                                            : "0.00"}
                                    </h5>
                                </div>
                                <div className="text-center col-lg-8 col-sm-12 m-auto pt-sm-2 pt-lg-0">
                                    <div className="row border-bottom">
                                        <div className="col-4 border-right label-middle py-0 px-1">
                                            Posted Jobs
                                        </div>
                                        <div className="col-4 border-right label-middle py-0 px-1">
                                            Applied Jobs
                                        </div>
                                        <div className="col-4 label-middle py-0 px-1">
                                            Hired/Claimed Jobs
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4 border-right py-0 px-1">
                                            <div className="label-middle">
                                                {dataRevenueAgents &&
                                                dataRevenueAgents.posted
                                                    ? dataRevenueAgents.posted
                                                          .total
                                                        ? dataRevenueAgents
                                                              .posted.total
                                                        : 0
                                                    : 0}
                                            </div>
                                            <div className="label-middle">
                                                US $
                                                {dataRevenueAgents &&
                                                dataRevenueAgents.posted
                                                    ? dataRevenueAgents.posted
                                                          .fee
                                                        ? dataRevenueAgents.posted.fee.toFixed(
                                                              2
                                                          )
                                                        : "0.00"
                                                    : "0.00"}
                                            </div>
                                        </div>
                                        <div className="col-4 border-right label-middle py-0 px-1">
                                            <div className="label-middle">
                                                {dataRevenueAgents &&
                                                dataRevenueAgents.applied
                                                    ? dataRevenueAgents.applied
                                                          .total
                                                        ? dataRevenueAgents
                                                              .applied.total
                                                        : 0
                                                    : 0}
                                            </div>
                                            <div className="label-middle">
                                                US $
                                                {dataRevenueAgents &&
                                                dataRevenueAgents.applied
                                                    ? dataRevenueAgents.applied
                                                          .fee
                                                        ? dataRevenueAgents.applied.fee.toFixed(
                                                              2
                                                          )
                                                        : "0.00"
                                                    : "0.00"}
                                            </div>
                                        </div>
                                        <div className="col-4 label-middle py-0 px-1">
                                            <div className="label-middle">
                                                {dataRevenueAgents &&
                                                dataRevenueAgents.pending
                                                    ? dataRevenueAgents.pending
                                                          .total
                                                        ? dataRevenueAgents
                                                              .pending.total
                                                        : 0
                                                    : 0}
                                            </div>
                                            <div className="label-middle">
                                                US $
                                                {dataRevenueAgents &&
                                                dataRevenueAgents.pending
                                                    ? dataRevenueAgents.pending
                                                          .fee
                                                        ? dataRevenueAgents.pending.fee.toFixed(
                                                              2
                                                          )
                                                        : "0.00"
                                                    : "0.00"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mt-3"
                        style={{ height: "inherit" }}
                    >
                        <div className="card border-card-page border-card-20 h-100">
                            <div className="card-title row pt-3 px-3 mb-0">
                                <label className="p-0 mx-2 mb-lg-0 mb-sm-1 my-auto align-self-center label-semi-bold">
                                    Agents
                                </label>
                            </div>
                            <div className="row w-100 m-auto pb-2">
                                <div className="col-lg-2 col-sm-12 px-1 text-center m-auto border-right">
                                    <div className="text-dark justify-content-center font-weight-bold text-12 mb-0 pb-1">
                                        Total Agents
                                    </div>
                                    <h5 className=" text-dark justify-content-center font-weight-bold mb-0 ">
                                        {dataRevenueAgents &&
                                        dataRevenueAgents.total_agent
                                            ? dataRevenueAgents.total_agent
                                            : 0}
                                    </h5>
                                </div>
                                <div className="col-lg-2 col-sm-6 px-1 text-center m-auto">
                                    <div className="justify-content-center label-middle font-weight-bold">
                                        Registered
                                    </div>
                                    <div className="justify-content-center label-middle font-weight-bold mt-3">
                                        {dataRevenueAgents &&
                                        dataRevenueAgents.total_user
                                            ? dataRevenueAgents.total_user
                                            : 0}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-sm-6 px-1 text-center m-auto">
                                    <div className="justify-content-center label-middle font-weight-bold">
                                        Active
                                    </div>
                                    <div className="justify-content-center label-middle font-weight-bold mt-3">
                                        {dataRevenueAgents &&
                                        dataRevenueAgents.total_user_active
                                            ? dataRevenueAgents.total_user_active
                                            : 0}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 px-1 text-center m-auto d-flex flex-column align-items-center justify-content-center pt-lg-0 pt-3">
                                    <div className="justify-content-center label-middle font-weight-bold d-lg-block d-none">
                                        Registered
                                    </div>
                                    <DoughnutAgent
                                        total={
                                            dataRevenueAgents &&
                                            dataRevenueAgents.total_agent
                                                ? dataRevenueAgents.total_agent
                                                : 0
                                        }
                                        count={
                                            dataRevenueAgents &&
                                            dataRevenueAgents.total_user
                                                ? dataRevenueAgents.total_user
                                                : 0
                                        }
                                    />
                                </div>
                                <div className="col-lg-3 col-sm-6 px-1 text-center m-auto d-flex flex-column align-items-center justify-content-center pt-lg-0 pt-3">
                                    <div className="justify-content-center label-middle font-weight-bold d-lg-block d-none">
                                        Active
                                    </div>
                                    <DoughnutAgent
                                        total={
                                            dataRevenueAgents &&
                                            dataRevenueAgents.total_user
                                                ? dataRevenueAgents.total_user
                                                : 0
                                        }
                                        count={
                                            dataRevenueAgents &&
                                            dataRevenueAgents.total_user_active
                                                ? dataRevenueAgents.total_user_active
                                                : 0
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AGENTS */}
                    <Agents data={dataAgents} />
                </div>
            </div>
        );
    }
}

const selector = formValueSelector('DashboardForm');

Dashboard = reduxForm({
    form: 'DashboardForm', // a unique identifier for this form
})(Dashboard);

const mstp = state => {
    // filter to chart performance
    const dateSelected = selector(state, 'timeFilterPerformance');
    const brokerageSelect = selector(state, 'officeFilterPerformance');
    const ratingSelected = selector(state, 'ratingFilterPerformance');
    // filter to chart revenue
    const timeFilterRevenue = selector(state, 'timeFilterRevenue');
    const office1 = selector(state, 'officeFilterRevenue');
    return {
        timeFilterRevenue,
        brokerageSelect,
        ratingSelected,
        dateSelected,
        office1,
    };
};

export default connect(mstp, null)(Dashboard);
