import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

class Actions extends Component {
    constructor(props) {
        super(props);
    }

    remove = (id) => {
        return () => {
            Swal.fire({
                title: 'Are you sure to delete the record?',
                text: "This action can't be reverted!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#7E8A97',
                cancelButtonColor: '#B9BDC1',
                confirmButtonText: 'Yes, delete it!',
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    this.props.remove(id);
                }
            });
        }
    };

    render() {
        const { id, view, edit, remove,  message } = this.props;

        return (
            <div className="d-flex justify-content-center px-0 mx-0">
                {
                    (message !== undefined) &&
                    (<span className="txt-danger">Pending</span>)
                }
                {(remove !== undefined) && (
                    <a className="px-2 custom-delete" style={ { cursor: "pointer" } } onClick={ this.remove( id ) }>
                        <img
                            className="custom-container-icon"
                            src={require('../../../../../assets/img/delete.svg')}
                            alt="deleteSVG"
                        />
                    </a>
                )}
                {(edit !== undefined) && (
                    <Link className="text-warning px-0 mx-2" to={ `${ edit }/${ id }/edit` } >
                    <img
                        className="custom-container-icon"
                        src={require('../../../../../assets/img/edit.svg')}
                        alt="editSVG"/>
                    </Link>
                )}
                {(view !== undefined) && (
                    <Link to={ `${ view }/${ id }/details` } className="px-0 mx-2" >
                        <img
                            className="custom-container-icon"
                            src={require('../../../../../assets/img/view-details.svg')}
                            alt="viewSVG"
                        />
                    </Link>
                )}
            </div>
        );
    }
}
Actions.propTypes = {
};

export function standardActions(actions) {
    return (cell, row) => {
        return ( <Actions id={cell} {...actions}/> )
    };
}
