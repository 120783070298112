import {handleActions} from 'redux-actions';
import {NotificationManager} from "react-notifications";
import {initialize as initializeForm, change} from 'redux-form';
import {api} from "api";

// ------------------------------------
//  CONSTATNS
// ------------------------------------
const LOADER = 'REGISTER_LOADER_VENDOR_REGISTER';
const PROGRESS_STEP = 'PROGRESS_STEP_VENDOR_REGISTER';
const SET_SUCCESS = 'SET_SUCCESS_VENDOR_REGISTER';
const ALL_DATA_FORM = 'ALL_DATA_FORM_VENDOR_REGISTER';
const SET_CODE_VALIDATE = 'SET_CODE_VALIDATE_VENDOR_REGISTER';
const SET_EMAIL_CONFIRMED = 'SET_EMAIL_CONFIRMED_VENDOR_REGISTER';
const SET_PHONE_CONFIRMED = 'SET_PHONE_CONFIRMED_VENDOR_REGISTER';
const SET_DATA_VALIDATE = 'SET_DATA_VALIDATE_VENDOR_REGISTER';
const SET_STEP_BACK = 'SET_STEP_BACK_VENDOR_REGISTER';
const PROFESSIONS = 'PROFESSIONS_VENDOR_REGISTER';
const REGISTER = 'REGISTER_VENDOR_REGISTER';

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});
export const setCode = code => ({
    type: SET_CODE_VALIDATE,
    code,
});
export const setEmailConfirmed = email_confirmed => ({
    type: SET_EMAIL_CONFIRMED,
    email_confirmed,
});
export const setPhoneConfirmed = phone_confirmed => ({
    type: SET_PHONE_CONFIRMED,
    phone_confirmed,
});

const setStep = step => ({
    type: PROGRESS_STEP,
    step: step,
});

const setAllDataForm = (data) => ({
    type: ALL_DATA_FORM,
    data,
});

const setSuccess = (success) => ({
    type: SET_SUCCESS,
    success,
});
export const setDataValidate = data_validate => ({
    type: SET_DATA_VALIDATE,
    data_validate,
});
export const setStepBack = stepBack => ({
    type: SET_STEP_BACK,
    stepBack,
});
export const setProfessions = professions => ({
    type: PROFESSIONS,
    professions,
});
export const setRegister = register => ({
    type: REGISTER,
    register,
});

// ------------------------------------
// Actions
// ------------------------------------
const validateCode = (value) => (dispatch, getStore) => {
    const step = getStore().vendorRegister.step;
    const allInfo = getStore().vendorRegister.data;
    const field = getStore().vendorRegister.data_validate;
    const url = step === 80 ? 'confirmation-code/validate_code' : 'confirmation-code/validate_code_email';
    const body = step === 80 ? {'phone_number': field, 'code': value} : {'email': field, 'code': value};
    dispatch(setLoader(true));
    api.post(`${url}`, body)
        .then(response => {
            if (step === 80) {
                dispatch(setPhoneConfirmed(field));
                if (allInfo && allInfo.invite_id) {
                    dispatch(initializeForm('vendorRegisterStep4', {'email': allInfo.email}));
                }
                dispatch(setStep(step + 20));
                dispatch(setStepBack(false)); // for view the code_number form
            }
            if (step === 100) {
                dispatch(setEmailConfirmed(field));
                dispatch(setRegister(true))
            }
            dispatch(setCode(false)); // for view the code_number form
        })
        .catch((error) => {
            dispatch(setCode('error'))
        })
        .finally(() => {
            dispatch(setLoader(false))
        })
};

const resendCodePhone = () => (dispatch, getStore) => {
    dispatch(setLoader(true))
    const phone = getStore().vendorRegister.data_validate;
    api.post('confirmation-code/resend_code_phone', {'phone_number': phone})
        .then(() => {
            NotificationManager.success('Code sent', 'Success', 2000)
        })
        .catch(() => {
            NotificationManager.error('Code send Failed', 'Error', 2000)
        })
        .finally(() => {
            dispatch(setLoader(false));
        })
};

const insertPhone = (mobile_number) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    let data = getStore().register.data;
    api.post('confirmation-code', {'phone_number': mobile_number})
        .then(response => {
            dispatch(setDataValidate(mobile_number));
            dispatch(setStepBack(true));
        })
        .catch(() => {
            delete data.mobile_number
            NotificationManager.error('Fail to send code, please verify your mobile number', 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const resendCodeEmail = () => (dispatch, getStore) => {
    dispatch(setLoader(true))
    const email = getStore().vendorRegister.data_validate;
    api.post('confirmation-code/resend_code_email', {'email': email})
        .then(() => {
            NotificationManager.success('Code sent', 'Success', 2000)
        })
        .catch(() => {
            NotificationManager.error('Code send Failed', 'Error', 2000)
        })
        .finally(() => {
            dispatch(setLoader(false));
        })
};

const insertEmail = (email) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    let data = getStore().register.data;
    api.post('confirmation-code/create_code', {'email': email})
        .then(response => {
            dispatch(setDataValidate(email));
            dispatch(setStepBack(true));
        })
        .catch((error) => {
            delete data.email
            const  _message = "Fail to send code, please verify your email and try again"
            const message = error.detail ? error.detail : _message
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
}

const resetInitialData = () => (dispatch) => {
    dispatch(setAllDataForm({}));
    dispatch(setStep(20));
    dispatch(setCode(false));
    dispatch(setSuccess(false));
    dispatch(setDataValidate(null));
    dispatch(setPhoneConfirmed(false));
    dispatch(setEmailConfirmed(false));
    dispatch(setStepBack(false));
    dispatch(setRegister(false))
}

const clickBack = () => (dispatch, getStore) => {
    const real_step = getStore().vendorRegister.step;
    const data = getStore().vendorRegister.data;
    const stepBack = getStore().vendorRegister.stepBack;
    console.log('DATA: ', data);
    console.log('STEP BACK: ', real_step);
    if (real_step === 40) {
        dispatch(setStep(real_step - 20));
    }
    if (real_step === 60) {
        dispatch(setStep(real_step - 20));
        setTimeout(() => {
            dispatch(initializeForm('vendorRegisterStep2', data));
        }, 400);
    }
    if (real_step === 80) {
        dispatch(setStep(real_step - 20));
        dispatch(setStepBack(false));
        setTimeout(() => {
            dispatch(initializeForm('vendorRegisterStep3', data));
        }, 400)
    }
    if (real_step === 100) {
        dispatch(setStep(real_step - 20));
        dispatch(setStepBack(false));
        setTimeout(() => {
            dispatch(initializeForm('vendorRegisterStep5', data));
        }, 400)
    }
}

const onSubmitStep = () => (dispatch, getStore) => {
    const step = getStore().vendorRegister.step;
    let data = getStore().vendorRegister.data;
    const email_confirmed = getStore().vendorRegister.email_confirmed;
    const phone_confirmed = getStore().vendorRegister.phone_confirmed;
    let form = '';
    if (step === 20) {
        dispatch(setStep(step + 20));
        if (data && data.first_name) {
            dispatch(initializeForm('vendorRegisterStep2', {
                first_name: data.first_name,
                last_name: data.last_name,
                address: data.address,
                city: data.city,
                latitude: data.latitude,
                longitude: data.longitude,
                zipcode: data.zipcode,
                office_phone: data.office_phone,
                company_name: data.company_name,
                website: data.website,
            }));
        }
    }
    if (step === 40) {
        form = getStore().form.vendorRegisterStep2.values;
        if (data && data.professions && data.professions.length > 0) {
            dispatch(initializeForm('vendorRegisterStep3', {
                professions: data.professions,
                years_business: data.years_business,
                number_employees: data.number_employees,
            }));
        }
        dispatch(setStep(step + 20));
        dispatch(setStepBack(false));
    }
    
    if (step === 60) {
        form = getStore().form.vendorRegisterStep3.values;
        if (data && data.mobile_number) {
            dispatch(initializeForm('vendorRegisterStep5', {
                mobile_number: data.mobile_number,
            }));
        }
        dispatch(setStep(step + 20));
        dispatch(setStepBack(false));
    }

    if (step === 80) {
        form = getStore().form.vendorRegisterStep5.values;
        if (data && data.email) {
            dispatch(initializeForm('vendorRegisterStep6', {
                email: data.email,
            }));
        }
        if (Object.keys(data).includes('mobile_number')) {
            if (data['mobile_number'] === form.mobile_number) {
                if (form.mobile_number === phone_confirmed) {
                    // if mobile_number and phone_confirmed match then not post to API
                    dispatch(setStep(step + 20));
                    dispatch(setStepBack(false));
                } else {
                    // activate the form to insert the submitted code to mobile number
                    dispatch(setStepBack(true))
                }
            } else {
                // if mobile number inserted is new
                dispatch(insertPhone(form.mobile_number));
            }
        } else {
            // first mobile number inserted
            dispatch(insertPhone(form.mobile_number));
        }
    }

    if (step === 100) {
        form = getStore().form.vendorRegisterStep6.values;
        if (Object.keys(data).includes('email')) {
            if (data['email'] === form.email) {
                if (form.email === email_confirmed) {
                    // if email and email_confirmed match then not post to API
                    // dispatch(setStep(step + 20));
                    dispatch(setRegister(true))
                    dispatch(setStepBack(false));
                } else {
                    // if email and email_confirmed not match then actives form validate code
                    dispatch(setStepBack(true))
                }
            } else {
                // post because user inserted new email address in form
                dispatch(insertEmail(form.email));
            }
        } else {
            // if is first email inserted
            dispatch(insertEmail(form.email));
        }
    }
    // insert new data to initialize data
    const new_data = Object.assign(data, form);
    console.log("NEW DATA: ", new_data);
    dispatch(setAllDataForm(new_data));
}

const assignAddressData = (data, form, type='address') => (dispatch) => {
    if (data) {
        console.log('ADDRESS DATA: ', data);
        
        setTimeout(() => {
            if (type == 'coords') {
                const {latitude, longitude} = data
                dispatch(change(form, 'latitude', latitude))
                dispatch(change(form, 'longitude', longitude))
            } else {
                const {state, city, zip_code} = data
                dispatch(change(form, 'state', state))
                dispatch(change(form, 'city', city))
                dispatch(change(form, 'zipcode', zip_code))
            }
        }, 500);
    }

}

const getProfessions = () => (dispatch) => {
    api.get('job_type_vendor/options')
        .then((response) => {
            dispatch(setProfessions(response && response.results ? response.results : []));
        })
        .catch(() => {
            dispatch(setProfessions([]));
        })
};

const onSubmitAction = (files=[]) => (dispatch, getStore) => {
    const currentData = getStore().vendorRegister.data;
    const data = currentData
    dispatch(setLoader(true));
    api.postAttachments('vendor_user', data, files)
        .then((response) => {
            NotificationManager.success('Vendor registered successfully', 'SUCCESS', 3000);
            dispatch(setStep(120));
            
        })
        .catch((error) => {
            console.log("ERROR: ", error);
            const _message = "Register failed, try again";
            const message = error.detail ? error.detail : _message 
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const actions = {
    resetInitialData,
    validateCode,
    resendCodePhone,
    resendCodeEmail,
    clickBack,
    setStep,
    setSuccess,
    onSubmitStep,
    assignAddressData,
    getProfessions,
    onSubmitAction,
}

export const reducers = {
    [LOADER]: (state, {loader}) => {
        return {
            ...state,
            loader,
        };
    },
    [PROGRESS_STEP]: (state, {step}) => {
        return {
            ...state,
            step,
        };
    },
    [ALL_DATA_FORM]: (state, {data}) => {
        return {
            ...state,
            data,
        };
    },
    [SET_SUCCESS]: (state, {success}) => {
        return {
            ...state,
            success,
        };
    },
    [SET_DATA_VALIDATE]: (state, {data_validate}) => {
        return {
            ...state,
            data_validate,
        };
    },
    [SET_CODE_VALIDATE]: (state, {code}) => {
        return {
            ...state,
            code,
        };
    },
    [SET_STEP_BACK]: (state, {stepBack}) => {
        return {
            ...state,
            stepBack,
        };
    },
    [SET_EMAIL_CONFIRMED]: (state, {email_confirmed}) => {
        return {
            ...state,
            email_confirmed,
        };
    },
    [SET_PHONE_CONFIRMED]: (state, {phone_confirmed}) => {
        return {
            ...state,
            phone_confirmed,
        };
    },
    [PROFESSIONS]: (state, {professions}) => {
        return {
            ...state,
            professions,
        };
    },
    [REGISTER]: (state, {register}) => {
        return {
            ...state,
            register,
        };
    },
}

export const initialState = {
    loader: false,
    step: 20,
    data: {},
    success: false,
    code: false,
    stepBack: false,
    data_validate: null,
    phone_confirmed: false,
    email_confirmed: false,
    professions: [],
    register: false
}


export default handleActions(reducers, initialState);
