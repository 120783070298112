import React, {Component} from 'react';
import FormCreateLink from "./FormCreateLink"
import LoadMask from "../../../Utils/LoadMask/LoadMask";


class CreateLink extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nextStep: false,
        }
    }

    handleSubmit = (data) => {
        this.props.sendEmailToResetPass(data.email, this.setNextStep);
    }

    setNextStep = ()=>{
        this.setState({nextStep: true})
    }

    render() {
        const {loader} = this.props;
        const {nextStep} = this.state;

        return (
            <div className="body-img">
                <div className="blue-gradient-bg">
                    <div className="login-wrapper  h-100" style={{minWidth: '60%'}}>
                        <div className="card card-login col-sm-12 col-lg-4 px-lg-5">
                            <div className="container-logo-register justify-content-center mt-0 align-self-center">
                                <img
                                    src={require("../../../../../../assets/img/logo-01.png")}
                                    className="logo-form-sing-in"
                                    alt={"ShowIt Logo"}
                                    style={{width: '50%'}}
                                />
                            </div>
                            <LoadMask loading={loader} light>
                                <div className="px-lg-5 py-3 px-sm-0">
                                    <h6 className="font-weight-bold">
                                        Reset Password
                                    </h6>
                                    {nextStep ?
                                        <div>
                                            <p className="text-12 pb-5 pt-3">
                                                If we found an account associated with that email, we've sent
                                                password reset instructions to the primary email address on the account.
                                            </p>

                                        </div>
                                        :
                                        <div>
                                            <p className="text-12">
                                                To reset your password, please provide your email with which you
                                                registered
                                            </p>
                                            <FormCreateLink
                                                onSubmit={this.handleSubmit}
                                            />
                                        </div>
                                    }
                                </div>
                            </LoadMask>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateLink;
