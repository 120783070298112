import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/broker/broker';
import Invites from "./Invites";

import formValueSelector from "redux-form/lib/formValueSelector";
const selector = formValueSelector('invitesForm');

const ms2p = (state) => {
    return{
        ...state.broker,
        roles: selector(state, 'roles'),
        me: state.login.me
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Invites);
