import React from "react";
import moment from "moment";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import {Field, reduxForm} from 'redux-form';
import {SelectField} from "../../Utils/renderField";
import {formatPhoneNumber} from "../../Broker/ListGrid";
import {standardActions} from "../../Utils/Grid/StandardActions";
import {
    DEFAULT_CATEGORY_REGISTERED,
    CATEGORY_REGISTERED_LIST,
    BROKER_OPERATIONAL_MODE_LIST,
    DEFAULT_CATEGORY_REGISTERED_ROSTER, REASON_LOCKED_BY,
    REGISTERED_PENDING, REGISTERED_RE_ASSIGN, REGISTERED_ACTIVE, REGISTERED_LOCKED, REGISTERED_BLOCKED,
} from "../../../../utility/constants";
import HeaderCard from "../../HeaderCard";
import Grid from "../../Utils/GridTable";

const RosterListForm = (props) => {
    const {
        data,
        page,
        toList,
        loader,
        options,
        filter1,
        filter2,
        unblockUser,
        activeUser,
        searchAction,
        blockUserModal,
        provideAgentsRoster,
        tabContainerHeight,
        goPage,
    } = props;
    return (
        <div className="d-flex flex-1 flex-column">
            <div className="card border-dark border-card-page px-2 pt-2 pb-0 mb-0 flex-1">
                <div className="card-body px-0 pt-2 mb-0 pb-0 d-flex flex-column">
                    <Grid
                        hover
                        height={tabContainerHeight}
                        page={page}
                        keyField={'id'}
                        data={data}
                        loading={loader}
                        sizePerPage={50}
                        striped
                        onPageChange={(value) => toList(value)}
                        columns={[
                            {
                                dataField: "id",
                                text: "",
                                formatter:
                                    standardActions({
                                        edit: "/agents/agent",
                                    }),
                            },
                            {
                                dataField: "photo",
                                formatter: avatar(),
                                text: "  ",
                            },
                            {
                                dataField: "brokerage_information",
                                formatter: formatStatusAccount(),
                                text: "Status",
                            },
                            {
                                dataField: "first_name",
                                sort: true,
                                text: "First Name",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                    };
                                },
                            },
                            {
                                dataField: "last_name",
                                sort: true,
                                text: "Last Name",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                    };
                                },
                            },
                            {
                                dataField: "email",
                                sort: true,
                                text: "E-mail",
                            },
                            {
                                dataField: "mobile_number",
                                formatter: formatPhoneNumber(),
                                text: "Phone",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                    };
                                },
                            },
                            {
                                dataField: "date_joined",
                                formatter: (cell, row) => {
                                    return <span>{cell ? moment(cell).format('ll') : ""}</span>
                                },
                                sort: true,
                                text: "SignUp Date",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                    };
                                },
                            },
                            {
                                dataField: "real_estate_licence",
                                sort: false,
                                text: "License",
                            },
                            // {
                            //     dataField: "brokerage_information__broker__operation_access",
                            //     text: "Operational Mode",
                            //     formatter: (cell, row) => {
                            //         const {brokerage_information} = row;
                            //         if (brokerage_information && brokerage_information.broker) {
                            //             const item = _.find(BROKER_OPERATIONAL_MODE_LIST, {value: brokerage_information.broker.operation_access})
                            //             const label = item ? item.label : '';
                            //             return <span>{label}</span>
                            //         }
                            //         return <span></span>
                            //     }
                            // },
                            {
                                dataField: "brokerage_information.franchise",
                                text: "Franchise",
                            },
                            {
                                dataField: "brokerage_information__firm_name",
                                sort: true,
                                formatter: formatBrokerage(),
                                text: "Office name",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                    };
                                },
                            },
                            {
                                dataField: "total_performed",
                                sort: false,
                                align: "center",
                                text: "Total Performed",
                            },
                            {
                                dataField: "total_posted",
                                sort: false,
                                align: "center",
                                text: "Total Posted",
                            },
                            {
                                dataField: "total_reviews",
                                sort: false,
                                align: "center",
                                formatter: formatReviews(),
                                text: "Total Reviews",
                            },
                            {
                                dataField: "active",
                                sort: false,
                                text: "Active",
                            },
                            {
                                dataField: 'reason_locked',
                                formatter: setReason(goPage),
                                text: 'Lock Reason'
                            },
                            {
                                dataField: "locked",
                                formatter: formatStatusLocked(blockUserModal, unblockUser, goPage),
                                text: "",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "200px",
                                    };
                                },
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    );

};

export function formatStatusAccount() {
    return (cell, row) => {
        const getStatus = () => {
            let text = ''
            CATEGORY_REGISTERED_LIST.forEach(item => {
                if (row.agent_account_status === item.value) {
                    text = item.label
                }
            })
            return text
        }
        const getColor = () => {
            let colorText = 'black'
            if (row.agent_account_status === REGISTERED_PENDING) {
                colorText = 'warning'
            } else if (row.agent_account_status === REGISTERED_RE_ASSIGN) {
                colorText = 'warning'
            } else if (row.agent_account_status === REGISTERED_ACTIVE) {
                colorText = 'success'
            } else if (row.agent_account_status === REGISTERED_LOCKED) {
                colorText = 'danger'
            } else if (row.agent_account_status === REGISTERED_BLOCKED) {
                colorText = 'danger'
            }
            return colorText
        }
        return (
            <React.Fragment>
                <span className={`text-capitalize font-weight-bold text-${getColor()}`}>
                    {getStatus()}
                </span>
            </React.Fragment>
        )
    };
}

export function formatReviews() {
    return (cell, row) => {
        return (
            <Link
                onClick={(e) => e.stopPropagation()}
                to={`/agents/agent-reviews/${row.id}`}
                className="w-100 text-primary"
            >
                {cell}
            </Link>
        )
    };
}

export function formatBrokerage() {
    return (cell, row) => {
        if (row.brokerage_information && row.brokerage_information.id) {
            return (
                <Link
                    onClick={(e) => e.stopPropagation()}
                    to={`brokerage/${row.brokerage_information.id}/edit`}
                    className="w-100 text-primary"
                >
                    {row.brokerage_information.firm_name}
                </Link>
            )
        }
        return <span></span>
    };
}

export function formatStatusLocked(blockUser, unblockUser, goPage) {
    return (cell, row) => {
        if (cell === true) {
            return (
                <div className="row" style={{ width: '9rem' }}>
                    <button
                        onClick={(e) => {
                            e.stopPropagation()
                            unblockUser(row)
                        }}
                        className="btn btn-danger px-0 d-inline mx-0"
                        style={{ fontSize: '10px !important' }}
                    >
                        Unlock User
                    </button>
                </div>
            )
        }else {
            return (
                <div className="row" style={{ width: "13rem" }}>
                    {row.agent_account_status == 10 && (
                        <React.Fragment>
                            {/* <div
                                title="Pending Notes"
                                className="pointer"
                                style={{ position: "relative", width: "40px", overflow: "hidden"}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    goPage(
                                        `/agents/agent/${row.id}/pending-notes`
                                    );
                                }}
                            >
                                <i
                                    className="material-icons pointer ml-2 text-dark"
                                    style={{
                                        minWidth: "1.9rem",
                                        maxWidth: "1.9rem",
                                        minHeight: "1.9rem",
                                        maxHeight: "1.9rem",
                                    }}
                                >
                                    note_text
                                </i>
                                <span
                                    className="badge bg-info"
                                    style={{
                                        position: "absolute",
                                        borderRadius: "50%",
                                        left: "3px",
                                        top: "-1px",
                                        padding: "5px",
                                        width: "15px",
                                        height: "15px",
                                        fontSize: "10px",
                                        lineHeight: "inherit",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    {row.pending_notes ? row.pending_notes : 0}
                                </span>
                            </div> */}
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    // activeUser(row)
                                    goPage(`/agents/agent/${row.id}/edit`);
                                }}
                                className={`btn btn-info px-4 d-inline mx-0 ${row.agent_account_status == 10 ? "ml-3" : ""}`}
                            >
                                Activate User
                            </button>
                        </React.Fragment>
                    )}
                    {row.agent_account_status == 20 && (
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                blockUser(row);
                            }}
                            className="btn btn-danger px-0 d-inline mx-0"
                            style={{ fontSize: "10px !important" }}
                        >
                            Lock User
                        </button>
                    )}
                </div>
            );

        }
    };
}

export function formatText() {
    return (cell) => {
        return (
            <div>
                <p className="p-0 m-0" style={{width: '4.5rem'}}>
                    {cell}
                </p>
            </div>
        );
    };
}

export function formatName() {
    return (cell) => {
        return (
            <div>
                <p className="p-0 m-0" style={{width: '6.5rem'}}>
                    {cell}
                </p>
            </div>
        );
    };
}

function avatar() {
    return (cell, row) => {
        return (
            <div style={{width: '20px'}} className="mx-auto">
                <Avatar
                    size="23"
                    src={cell}
                    round={true}
                    name={row.first_name}
                />
            </div>
        );
    };
}

function setReason(goPage) {
    return (cell, row) => {
        let reason = ''
        // console.log('reason: ', row)
        if (!row.locked) {
            return null;
        }
        if (cell) {
            REASON_LOCKED_BY.map((item) => {
                if (item.value === cell) (reason = item.label)
            })
        }

        return (
            <div className="d-flex flex-row align-items-center justify-content-between" style={{width: '150px', overflow: 'hidden'}}>
                <span className="text-danger font-weight-bold">
                    {reason}
                </span>
                <div className="pointer" style={{position: 'relative'}} onClick={(e) => {
                        e.stopPropagation()
                        goPage(`/agents/agent/${row.id}/lock-notes`)
                    }}>
                    <i className="material-icons pointer ml-2 text-dark" style={{
                        minWidth: "1.9rem",
                        maxWidth: "1.9rem",
                        minHeight: "1.9rem",
                        maxHeight: "1.9rem",
                    }}>note_text
                    </i>
                    <span className="badge bg-danger" style={{
                        position: 'absolute',
                        borderRadius: "50%",
                        left: "3px",
                        top: "-1px",
                        padding: "5px",
                        width: "15px",
                        height: "15px",
                        fontSize: "10px",
                        lineHeight: "inherit",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        }}>{row.lock_notes}</span>
                </div>
            </div>
        );
    };
}


export default reduxForm({
    form: 'agentRegisterForm', // a unique identifier for this form
})(RosterListForm);
