import _ from "lodash";
import { api } from "api";
import moment from "moment";
import { handleActions } from "redux-actions";
import { change, initialize } from "redux-form";
import { createReducer } from "../baseReducer/baseReducer";
import { NotificationManager } from "react-notifications";
import {
    SELECT_ALL_OPTION,
    getDashboardDateRange,
} from "../../../utility/constants";

// ------------------------------------
// Constants
// ------------------------------------
const SET_FRANCHISES = "SET_FRANCHISES_AGENTPERFORMANCE";
const SET_BROKERS = "SET_BROKERS_AGENTPERFORMANCE";
const SET_BROKERAGES = "SET_BROKERAGES_AGENTPERFORMANCE";
const SET_JOB_TYPE = "SET_JOB_TYPE_AGENTPERFORMANCE";
const SET_AGENTS = "SET_AGENTS_AGENTPERFORMANCE";

//-------------------------------------
// Base reducer
//-------------------------------------
const basicReducer = createReducer(
    "statsRevenue",
    "broker_user/stats_revenue",
    "statsRevenueForm",
    "/revenue"
);

// ------------------------------------
// Pure Actions
// ------------------------------------
const setOptionsFranchises = (franchises) => ({
    type: SET_FRANCHISES,
    franchises,
});
const setBrokers = (brokers) => ({
    type: SET_BROKERS,
    brokers,
});

const setBrokerages = (brokerages) => ({
    type: SET_BROKERAGES,
    brokerages,
});

const setJobTypes = (jobTypes) => ({
    type: SET_JOB_TYPE,
    jobTypes,
});

const setAgents = (agents) => ({
    type: SET_AGENTS,
    agents,
});

// ------------------------------------
// Actions
// ------------------------------------
export const changeFilters =
    (type, value = null) =>
    (dispatch, getStore) => {
        console.log("TYPE:, ", type);
        switch (type) {
            case "brokerage":
                setTimeout(() => {
                    // dispatch(toList());
                    dispatch(getAgentsByOffice(true))
                }, 500);
                break;
            case "job_type":
                setTimeout(() => {
                    dispatch(toList());
                }, 500);
                break;
            case "agent":
                setTimeout(() => {
                    dispatch(toList());
                }, 500);
                break;
            case "time":
                setTimeout(() => {
                    dispatch(toList());
                }, 500);
                break;
            case "start_date":
                setTimeout(() => {
                    dispatch(toList());
                }, 500);
                break;
            case "end_date":
                setTimeout(() => {
                    dispatch(toList());
                }, 500);
                break;
            default:
                break;
        }
    };

export const getBrokeragesOption =
    (getData = null, selectAll = false) =>
    (dispatch, getStore) => {
        const store = getStore();

        api.get(`brokerage/option_select`)
            .then((response) => {
                let option = [];
                if (response.results.length > 1) {
                    option = response.results;
                    if (selectAll) {
                        dispatch(
                            change(
                                "FilterStatsRevenueForm",
                                "brokerage",
                                SELECT_ALL_OPTION["value"]
                            )
                        );
                    }
                } else {
                    option = [...response.results];
                    const brokerage_id = option.length ? option[0].value : "";
                    console.log("AAAAAAAAA: ", brokerage_id);
                    dispatch(
                        change("FilterStatsRevenueForm", "brokerage", SELECT_ALL_OPTION["value"])
                    );
                }
                dispatch(setBrokerages(option));
            })
            .catch(() => {
                dispatch(setBrokerages([]));
                dispatch(change("FilterStatsRevenueForm", "brokerage", ""));
            })
            .finally(() => {
                if (getData) {
                    setTimeout(() => {
                        // dispatch(toList());
                        dispatch(getAgentsByOffice(true))
                    }, 500);
                }
            });
    };

export const getJobTypesOption =
    (selectAll = false) =>
    (dispatch) => {
        api.get("general/option_job_types", {})
            .then((response) => {
                let options = [];
                if (response.results.length > 1) {
                    options = [...response.results];
                    if (selectAll) {
                        dispatch(
                            change(
                                "FilterStatsRevenueForm",
                                "job_type",
                                SELECT_ALL_OPTION["value"]
                            )
                        );
                    }
                } else {
                    options = [...response.results];
                    const job_type = options.length ? options[0].value : "";
                    dispatch(
                        change("FilterStatsRevenueForm", "job_type", SELECT_ALL_OPTION["value"])
                    );
                }
                dispatch(setJobTypes(options));
            })
            .catch(() => {
                dispatch(setJobTypes([]));
                dispatch(change("FilterStatsRevenueForm", "job_type", ""));
            })
            .finally(() => {});
    };

const getAgentsByOffice =
    (getData = null, selectAll = false) =>
    (dispatch, getStore) => {
        const store = getStore();
        const { FilterStatsRevenueForm } = store.form;
        let params = {
            broker_id: "",
            brokerage: "",
            is_multi: true,
        };
        if (FilterStatsRevenueForm && FilterStatsRevenueForm.values) {
            const { broker, brokerage } = FilterStatsRevenueForm.values;
            if (
                broker !== "" &&
                broker !== null &&
                broker !== undefined &&
                broker !== SELECT_ALL_OPTION["value"]
            ) {
                if (broker.length == 0) {
                    params.broker_id = "";
                } else {
                    params.broker_id = broker;
                }
            }
            if (
                brokerage !== "" &&
                brokerage !== null &&
                brokerage !== undefined &&
                brokerage !== SELECT_ALL_OPTION["value"]
            ) {
                if (brokerage.length == 0) {
                    params.brokerage = "";
                } else {
                    params.brokerage = brokerage;
                }
            }
        }
        api.get("broker-user/agent_by_office", params)
            .then((response) => {
                const labelUser = response.data;
                let userArray = [];
                if (labelUser.length > 1) {
                    labelUser.map((user) => {
                        userArray.push({
                            label: `${user.first_name + " " + user.last_name}`,
                            value: user.id,
                            brokerage: user.brokerage_information_id,
                        });
                    });
                    if (selectAll) {
                        dispatch(
                            change(
                                "FilterStatsRevenueForm",
                                "agent",
                                SELECT_ALL_OPTION["value"]
                            )
                        );
                    }
                } else {
                    labelUser.map((user) => {
                        userArray.push({
                            label: `${user.first_name + " " + user.last_name}`,
                            value: user.id,
                            brokerage: user.brokerage_information_id,
                        });
                    });
                    const agent_id = userArray.length ? userArray[0].value : "";
                    dispatch(
                        change("FilterStatsRevenueForm", "agent", SELECT_ALL_OPTION["value"])
                    );
                }
                dispatch(setAgents(userArray));
            })
            .catch((error) => {
                dispatch(setAgents([]));
                dispatch(change("FilterStatsRevenueForm", "agent", ""));
            })
            .finally(() => {
                if (getData) {
                    setTimeout(() => {
                        dispatch(toList());
                    }, 500);
                }
            });
    };

const toList =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(basicReducer.actions.setLoader(true));
        const store = getStore();
        const resource = store["statsAgentPerformance"];
        let params = { page };
        const { FilterStatsRevenueForm } = store.form;
        if (FilterStatsRevenueForm && FilterStatsRevenueForm.values) {
            const {
                brokerage,
                job_type,
                agent,
                time,
                start_date,
                end_date,
            } = FilterStatsRevenueForm.values;
            if (
                brokerage !== "" &&
                brokerage !== null &&
                brokerage !== undefined &&
                brokerage !== SELECT_ALL_OPTION["value"]
            ) {
                if (brokerage.length == 0) {
                    params.brokerage = 0;
                } else {
                    params.brokerage = brokerage;
                }
            }
            if (
                job_type !== "" &&
                job_type !== null &&
                job_type !== undefined &&
                job_type !== SELECT_ALL_OPTION["value"]
            ) {
                if (job_type.length == 0) {
                    params.job_type = 0;
                    params.job_type_option = 0;
                } else {
                    const _jobTypes = _.filter(job_type, function (o) {
                        return !o.toString().toUpperCase().includes("OTH");
                    });
                    params.job_type = _jobTypes.length ? _jobTypes : 0;
                    const _jobTypeOptions = _.filter(job_type, function (o) {
                        if (o.toString().toUpperCase().includes("OTH")) {
                            return true;
                        }
                        return false;
                    });
                    params.job_type_option = _jobTypeOptions.length
                        ? _jobTypeOptions.map((item) => {
                              return parseInt(
                                  item.toString().replace("OTH-", "")
                              );
                          })
                        : 0;
                }
            }
            if (
                agent !== "" &&
                agent !== null &&
                agent !== undefined &&
                agent !== SELECT_ALL_OPTION["value"]
            ) {
                if (agent.length == 0) {
                    params.agent = 0;
                } else {
                    params.agent = agent;
                }
            }
            if (time !== "" && time !== null && time !== undefined) {
                if (time === "custom") {
                    let startDate = moment().format("DD-MM-YYYY");
                    let endDate = moment().format("DD-MM-YYYY");

                    if (
                        start_date &&
                        (start_date !== "") & (start_date !== null) &&
                        start_date !== undefined
                    ) {
                        startDate = moment(start_date).format("DD-MM-YYYY");
                    }
                    if (
                        end_date &&
                        (end_date !== "") & (end_date !== null) &&
                        end_date !== undefined
                    ) {
                        endDate = moment(end_date).format("DD-MM-YYYY");
                    }
                    params.time = [startDate, endDate];
                } else {
                    params.time = time;
                }
            } else {
                params.time = getDashboardDateRange(1, "months");
            }
        } else {
            params.time = getDashboardDateRange(1, "months");
        }
        api.get("broker-user/stats_revenue", params)
            .then((response) => {
                dispatch(basicReducer.actions.setData(response));
                dispatch(basicReducer.actions.setPage(page));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(basicReducer.actions.setLoader(false));
            });
    };

export const actions = {
    ...basicReducer.actions,
    changeFilters,
    getBrokeragesOption,
    getJobTypesOption,
    getAgentsByOffice,
    toList,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    ...basicReducer.reducers,
    [SET_FRANCHISES]: (state, { franchises }) => {
        return {
            ...state,
            franchises,
        };
    },
    [SET_BROKERS]: (state, { brokers }) => {
        return {
            ...state,
            brokers,
        };
    },
    [SET_BROKERAGES]: (state, { brokerages }) => {
        return {
            ...state,
            brokerages,
        };
    },
    [SET_JOB_TYPE]: (state, { jobTypes }) => {
        return {
            ...state,
            jobTypes,
        };
    },
    [SET_AGENTS]: (state, { agents }) => {
        return {
            ...state,
            agents,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    ...basicReducer.initialState,
    brokers: [],
    brokerages: [],
    franchises: [],
    jobTypes: [],
    agents: [],
};

export default handleActions(reducers, initialState);
