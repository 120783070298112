import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/agent/userAgents';
import Registered from "./Registered";

const ms2p = (state) => {
    return{
        ...state.agentRegistered,
        provideAgentsRoster: state.login.me.provide_agents_roster,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Registered);
