import {api} from "api";
import moment from "moment";
import Swal from 'sweetalert2';
import {handleActions} from "redux-actions";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import {change} from "redux-form";
import {getDashboardDateRange, SELECT_ALL_OPTION} from "../../../utility/constants"

const SET_LOADER = "SET_LOADER_STATS";
const SET_ITEM = "SET_ITEM_REVENUE_STAT";
const SET_PAGE = "SET_PAGE_REVENUE_STAT";
const SET_PAGE_2 = "SET_PAGE_PERFORMANCE_STAT";
const SET_DATA_JOB = "SET_DATA_JOB_FILTER_STATS";
const SET_DATA_AGENT = "SET_DATA_AGENT_FILTER_STATS";
const SET_JOB_TYPE_ID_STATS = "SET_JOB_TYPE_ID_STATS";
const SET_JOB_OPTION_ID_STATS = "SET_JOB_OPTION_ID_STATS";
const SET_DATA_OFFICES = "SET_DATA_OFFICES_FILTER_STATS";
const SET_ITEM_PERFORMANCE = "SET_ITEM_PERFORMANCE_STATS";
const SET_ITEM_AGENT_DETAIL = "SET_ITEM_AGENT_DETAIL_STATS";
const SET_JOB_POSTED_DATA = "SET_JOB_POSTED_DATA_STATS";
const SET_PAGE_JOB_POSTED_DATA = "SET_PAGE_JOB_POSTED_DATA_STATS";
const SET_ITEM_POSTED_DETAILS = "SET_ITEM_POSTED_DETAILS_STATS";
const SET_DATA_STATS = "SET_DATA_STATS_STATS";
const SET_JOB_STATUS = "SET_JOB_STATUS_STATS";
const SET_DOWNLOAD_LOADER = "SET_DOWNLOAD_LOADER_STATS";

const setLoader = (loader) => ({
    type: SET_LOADER,
    loader,
});

const setPagination = (page) => ({
    type: SET_LOADER,
    page,
});

const setPagination2 = (page2) => ({
    type: SET_PAGE_2,
    page2,
});

const setPaginationJobPosted = (pageJobsPosted) => ({
    type: SET_PAGE_JOB_POSTED_DATA,
    pageJobsPosted,
});

const setItem = (item) => ({
    type: SET_ITEM,
    item,
});

const setItemPerformance = (itemPerformance) => ({
    type: SET_ITEM_PERFORMANCE,
    itemPerformance,
});

const setDataOffices = (dataOfficeName) => ({
    type: SET_DATA_OFFICES,
    dataOfficeName,
});

const setNameJob = (dataJobType) => ({
    type: SET_DATA_JOB,
    dataJobType,
});

const setDateAgent = (dataAgentName) => ({
    type: SET_DATA_AGENT,
    dataAgentName,
});

const setDetailsAgent = (itemDetails) => ({
    type: SET_ITEM_AGENT_DETAIL,
    itemDetails,
});

const setJopPostedData = (jobPostedData) => ({
    type: SET_JOB_POSTED_DATA,
    jobPostedData,
});

const setItemPostedDetails = (itemPostedDetails) => ({
    type: SET_ITEM_POSTED_DETAILS,
    itemPostedDetails,
});

const setValueFilter = (value, type) => ({
    type: type,
    value,
});

const setDataStats = dataStats => ({
    type: SET_DATA_STATS,
    dataStats,
});

const setJobStatus = jobStatus => ({
    type: SET_JOB_STATUS,
    jobStatus,
});

const setDownloadLoader = (downloadLoader) => ({
    type: SET_DOWNLOAD_LOADER,
    downloadLoader,
});

const getNameAllJobType = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get("job_type/all_job_type")
        .then((response) => {
            let JOB = [{
                label: "All",
                value: '',
            }];
            response.map((nameJob, indexOne) => {
                if (nameJob.job_type_option.length > 0) {
                    nameJob.job_type_option.map((option, index) => {
                        JOB.push({
                            value: indexOne + '.' + index,
                            jobTypeId: nameJob.id,
                            optionId: option.id,
                            label: option.name,
                        });
                    });
                } else {
                    JOB.push({
                        jobTypeId: nameJob.id,
                        label: nameJob.name,
                        value: indexOne,
                        optionId: null,
                    });
                }
            });
            dispatch(setNameJob(JOB));
        })
        .catch((error) => {
            console.log('getAllJobType error--', error);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const getAllOffices = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('brokerage/option_select')
        .then(response => {
            let option = [];
            option = [{
                label: 'All',
                value: '',
            }, ...response.results];
            dispatch(setDataOffices(option));
        })
        .catch(() => {
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const getDataRevenue = (page = 1, brokerage = '', agent_id = '', start_date, end_date) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const source = getStore()['stats'];
    const job_type = source.jobType;
    const job_type_option = source.jobOption;
    if (start_date === undefined) {
        const today = moment().date()
        start_date = moment(Date.now()).subtract(today - 1, 'days').format('YYYY-MM-DD')
    }
    if (end_date === undefined) {
        end_date = moment(Date.now()).format('YYYY-MM-DD');
    }
    if (moment(start_date) > moment(end_date)) {
        NotificationManager.error('Start date is greater than end date', 'ERROR', 3000);
        dispatch(setLoader(false));
    } else {
        const params = {
            page,
            job_type_option,
            start_date,
            brokerage,
            end_date,
            job_type,
            agent_id,
        };
        api.get('broker-user/stats_revenue', params)
            .then(response => {
                dispatch(setItem(response));
                dispatch(setPagination(page));
            })
            .catch((error) => {
                console.log('--- error getDataRevenue ---', error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    }
}

const getPerformance = (page = 1, brokerage = '', agent_id = '', start_date, end_date, rating = '') => (dispatch) => {
    dispatch(setLoader(true));
    if (start_date === undefined) {
        const today = moment().date()
        start_date = moment(Date.now()).subtract(today - 1, 'days')
            // .subtract(5, "months")
            .format('YYYY-MM-DD')
    }
    if (end_date === undefined) {
        end_date = moment(Date.now()).format('YYYY-MM-DD');
    }
    if (moment(start_date) > moment(end_date)) {
        NotificationManager.error('Start date is greater than end date', 'ERROR', 3000);
        dispatch(setLoader(false));
    } else {
        const params = {
            start_date,
            brokerage,
            end_date,
            agent_id,
            rating,
            page,
        };
        api.get('agent/stats_performance', params)
            .then(response => {
                dispatch(setItemPerformance(response));
                dispatch(setPagination2(page));
            })
            .catch((error) => {
                console.log('--- error getPerformance ---', error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    }
}

const getJobPostedStats = (page = 1, brokerage = '', agent_id = '', start_date, end_date) => (dispatch) => {
    dispatch(setLoader(true));
    if (start_date === undefined) {
        const today = moment().date()
        start_date = moment(Date.now()).subtract(today - 1, 'days').format('YYYY-MM-DD')
    }
    if (end_date === undefined) {
        end_date = moment(Date.now()).format('YYYY-MM-DD');
    }
    if (moment(start_date) > moment(end_date)) {
        NotificationManager.error('Start date is greater than end date', 'ERROR', 3000);
        dispatch(setLoader(false));
    } else {
        const params = {
            start_date,
            brokerage,
            end_date,
            agent_id,
            page,
        };
        api.get('agent/job_posted_stats', params)
            .then(response => {
                dispatch(setJopPostedData(response));
                dispatch(setPaginationJobPosted(page));
            })
            .catch((error) => {
                console.log('--- error getPerformance ---', error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    }
}

const getAgentsByOffice = (brokerage = '') => (dispatch) => {
    api.get("broker-user/agent_by_office", {brokerage})
        .then((response) => {
            const labelUser = response.data;
            let userArray = [{
                label: 'All',
                value: '',
            }];
            if (labelUser.length > 0) {
                labelUser.map((user) => {
                    userArray.push({
                        label: `${user.first_name + ' ' + user.last_name}`,
                        value: user.id,
                        brokerage: user.brokerage_information_id,
                    });
                });
            } else {
                userArray = [{
                    label: 'No options',
                    value: '',
                }]
            }
            dispatch(change('PerformanceForm', 'agent', ''))
            dispatch(setDateAgent(userArray));
        })
        .catch((error) => {
        })
        .finally(() => {
        });
}

// -------------------------------------------------
// -------  SET VALUES TO FILTER           ---------
// -------------------------------------------------
const setJobTypeFilter = (selected) => (dispatch) => {
    // console.log('selected', selected)
    if (selected.value === '') {
        dispatch(setValueFilter('', SET_JOB_TYPE_ID_STATS));
        dispatch(setValueFilter('', SET_JOB_TYPE_ID_STATS));
        dispatch(setValueFilter('', SET_JOB_OPTION_ID_STATS));
    } else if (selected.optionId === null) {
        // filter by job type
        dispatch(setValueFilter('', SET_JOB_OPTION_ID_STATS));
        dispatch(setValueFilter(selected.jobTypeId, SET_JOB_TYPE_ID_STATS));
    } else {
        // filter by job type option
        dispatch(setValueFilter('', SET_JOB_TYPE_ID_STATS));
        dispatch(setValueFilter(selected.optionId, SET_JOB_OPTION_ID_STATS));
    }
};

const goPage = (url) => (dispatch) => {
    dispatch(push(url));
};

const getReviewDetails = (agent = '', page=1) => (dispatch) => {
    dispatch(setLoader(true));
    api.get("broker-user/review_detail", {agent_id: agent, page})
        .then((response) => {
            const change_ = {...response}
            dispatch(setDetailsAgent(change_));
        })
        .catch((error) => {
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
}

const getPostsDetails = (agent = '', page=1) => (dispatch) => {
    dispatch(setLoader(true));
    api.get("broker-user/posts_details", {agent_id: agent, page})
        .then((response) => {
            console.log('data: ', response)
            const change_ = {...response}
            dispatch(setItemPostedDetails(change_));
        })
        .catch((error) => {
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
}

const setDefaultValue = () => (dispatch) => {
    dispatch(setDetailsAgent(
        {
            results: [],
            count: 0,
        }
    ));
}

// ------- JOB DETAILS RAW DATA

const getJobDetailsRawData = (page=1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore()
    const source = store['stats'];
    if (page === 1) {
        dispatch(setDataStats({
            results: [],
            count: 0,
        }));
    }
    let params = {page}

    // filter
    // const {jobStatus} = source;
    // params.status = jobStatus ? jobStatus : '';
    const {jobDetailsRawDataForm} = store.form;

    if (jobDetailsRawDataForm && jobDetailsRawDataForm.values) {
        const {time, start_date, end_date, jobStatus} = jobDetailsRawDataForm.values;
        if (jobStatus !== "" && jobStatus !== null && jobStatus !== undefined && jobStatus !== SELECT_ALL_OPTION["value"]) {
            if (jobStatus.length == 0) {
                params.status = "null"
            }else {
                params.status = jobStatus;
            }
        }
        if (time !== "" && time !== null && time !== undefined) {
            if (time === 'custom') {
                let startDate = moment().format("DD-MM-YYYY")
                let endDate = moment().format("DD-MM-YYYY")

                if (start_date && start_date !== "" & start_date !== null && start_date !== undefined){
                    startDate = moment(start_date).format("DD-MM-YYYY")
                }
                if (end_date && end_date !== "" & end_date !== null && end_date !== undefined){
                    endDate = moment(end_date).format("DD-MM-YYYY")
                }
                params.time = [startDate, endDate];
            }else {
                params.time = time;
            }
        }else {
            params.time = getDashboardDateRange(2, "weeks");
        }
    }else {
        params.time = getDashboardDateRange(2, "weeks");
    }

    api.get('broker-user/job_details_raw_data_stats', params)
        .then((response) => {
            console.log(response)
            dispatch(setDataStats(response));
            dispatch(setPagination(page));
        })
        .catch((error) => {
            console.log('--- error getDataJobDetailsRawData ---', error);
            let message = "Error obtaining data"
            if (error.detail) message = error.detail;
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
}

const setJobStatusFilter = (selected) => (dispatch) =>  {
    // if(selected.value === '') {
    //     dispatch(setJobStatus(''))
    // }else {
    //     dispatch(setJobStatus(selected.value))
    // }
    // setTimeout(() => {
    //     dispatch(getJobDetailsRawData())
    // }, 600);
    dispatch(getJobDetailsRawData())
}

const downloadJobDetailsRawDataReport = () => (dispatch, getStore) => {
    dispatch(setDownloadLoader(true));
    const source = getStore()['stats'];
    let params = {type_request: 'report', frontend: 'broker'}
    // filter
    const {jobStatus} = source;
    params.status = jobStatus ? jobStatus : '';
    api.get('broker-user/job_details_raw_data_stats', params)
        .then((data) => {
            if (data) {
                NotificationManager.info(
                    "The download will start in a moment. Please do not reload the page until the file has been downloaded",
                    "Download in progress!",
                    8000
                );
                dispatch(waitDownload(data.id))
            }
        })
        .catch((error) => {
            console.log('--- error downloadJobDetailsRawDataReport ---', error);
            let message = "Error obtaining data"
            if (error.detail) message = error.detail;
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setDownloadLoader(false));
        });

};


const waitDownload = (id) => (dispatch) => {
    let intervalPromise;

    function listener() {
        api.get('archive/download_status', {id})
        .then((resp) => {
            if (resp.status === 10) {
                // PROCESANDO
                dispatch(setDownloadLoader(true));
            } else if (resp.status === 20) {
                // TERMINADO
                clearInterval(intervalPromise);
                let name = resp.file
                    ? resp.file.split("/media/archives/")[1]
                    : "job_details_raw_data.xlsx";

                const context = {
                    name: name,
                    url: resp.file,
                };
                
                dispatch(setDownloadLoader(false));
                
                //  DOWNLOAD EXCEL FILE
                const a = document.createElement('a');
                a.href = context.url;
                a.download = context.name
                document.body.appendChild(a);
                a.click();
                a.remove();
                NotificationManager.success('File downloaded successfully', 'SUCCESS', 3000);
            }
        })
        .catch((err) => {
            let msg =
                "An error occurred while downloading the file. Please try later";
            if (err.status) {
                msg = err.notes;
            }
            dispatch(setDownloadLoader(false));
            clearInterval(intervalPromise);
            NotificationManager.error(msg, 'ERROR', 3000);
        })
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
}

const setTimeFilter = (value) => (dispatch) =>  {
    setTimeout(() => {
        dispatch(getJobDetailsRawData())
    }, 400);
}

const changeJobTransferType = (data) => (dispatch, getStore) => {
    const store = getStore()
    const {page} = store['stats']
    const {transfer_type} = data
    console.log("DATA HOLD: ", data);

    let text = ""
    if (transfer_type == 200) {
        text = "<p>When you choose to hold the payment to the ShowIn Agent, it means that the funds associated with that payment will be retained Permanently within your account until you decide to release the payment. Essentially, you have control over if the funds are transferred to the ShowIn Agent.</p>"
        text += "<p>This allows you to keep the funds in your account until you are satisfied with the service provided by the ShowIn Agent. You can review the performance or completion of the task before releasing the payment to ensure that your expectations have been met.</p>"
        text += "<p>Holding the payment provides you with an added layer of security and control over job performance, giving you the ability to manage and assess the quality of the service before finalizing the payment.</p>"
    } else {
        text = "<p>When pressing release, the Broker will recieve this message and they need to confirm or decnline</p>"
        text += "<p>By releasing the payment, you confirm that the job was done properly and professionally. It signifies your satisfaction with the service provided by the ShowIn Agent. Releasing the payment also initiates the immediate transfer of funds to the ShowIn Agent's account.</p>"
    }

    Swal.fire({
        html: text,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#c4183c',
        cancelButtonColor: '#B9BDC1',
        confirmButtonText: 'CONFIRM',
        cancelButtonText: 'DECLINE',
        reverseButtons: true,
        width: '60%'
    }).then((result) => {
        if (result.value) {
            dispatch(setLoader(true));
            api.put(`broker-user/change_job_transfer_type`, data)
                .then(response => {
                    NotificationManager.success('The job was successfully edited', 'Success', 3000);
                    dispatch(getJobDetailsRawData(page))
                }).catch((error) => {
                    console.log('--- error postEditJob ---', error);
                    let message = "Could not edit the job"
                    if (error.detail) message = error.detail;
                    NotificationManager.error(message, 'ERROR', 3000);
                }).finally(() => {
                    dispatch(setLoader(false));
                })
        }
    })
}

// -----------------------------------------------------
// ------------------   ACTION      --------------------
// -----------------------------------------------------
export const actions = {
    getNameAllJobType,
    getAgentsByOffice,
    getJobPostedStats,
    getReviewDetails,
    setJobTypeFilter,
    getPostsDetails,
    setDefaultValue,
    getDataRevenue,
    getPerformance,
    getAllOffices,
    goPage,
    getJobDetailsRawData,
    setJobStatusFilter,
    downloadJobDetailsRawDataReport,
    setTimeFilter,
    changeJobTransferType,
};


export const reducers = {
    [SET_LOADER]: (state, {loader}) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_ITEM]: (state, {item}) => {
        return {
            ...state,
            item,
        };
    },
    [SET_ITEM_PERFORMANCE]: (state, {itemPerformance}) => {
        return {
            ...state,
            itemPerformance,
        };
    },
    [SET_PAGE]: (state, {page}) => {
        return {
            ...state,
            page,
        };
    },
    [SET_PAGE_2]: (state, {page2}) => {
        return {
            ...state,
            page2,
        };
    },
    [SET_DATA_OFFICES]: (state, {dataOfficeName}) => {
        return {
            ...state,
            dataOfficeName,
        };
    },
    [SET_DATA_JOB]: (state, {dataJobType}) => {
        return {
            ...state,
            dataJobType,
        };
    },
    [SET_DATA_AGENT]: (state, {dataAgentName}) => {
        return {
            ...state,
            dataAgentName,
        };
    },
    [SET_JOB_TYPE_ID_STATS]: (state, {value}) => {
        return {
            ...state,
            jobType: value,
        };
    },
    [SET_JOB_OPTION_ID_STATS]: (state, {value}) => {
        return {
            ...state,
            jobOption: value,
        };
    },
    [SET_ITEM_AGENT_DETAIL]: (state, {itemDetails}) => {
        return {
            ...state,
            itemDetails,
        };
    },
    [SET_JOB_POSTED_DATA]: (state, {jobPostedData}) => {
        return {
            ...state,
            jobPostedData,
        };
    },
    [SET_ITEM_POSTED_DETAILS]: (state, {itemPostedDetails}) => {
        return {
            ...state,
            itemPostedDetails,
        };
    },
    [SET_DATA_STATS]: (state, {dataStats}) => {
        return {
            ...state,
            dataStats,
        };
    },
    [SET_JOB_STATUS]: (state, {jobStatus}) => {
        return {
            ...state,
            jobStatus,
        };
    },
    [SET_DOWNLOAD_LOADER]: (state, {downloadLoader}) => {
        return {
            ...state,
            downloadLoader,
        };
    },
};

export const initialState = {
    // values to filter by job in stats-revenue
    page: 1,
    page2: 1,
    pageJobsPosted: 1,
    jobType: '',
    jobOption: '',
    // values of all data
    loader: false,
    dataJobType: [],
    dataAgentName: [],
    dataOfficeName: [],
    item: {
        results: [],
        count: 0,
        date: [moment(Date.now()).format('YYYY-MMM-DD'), moment(Date.now()).format('YYYY-MMM-DD')]
    },
    itemPerformance: {
        results: [],
        count: 0,
    },
    jobPostedData: {
        results: [],
        count: 0,
    },
    itemDetails: {
        results: [],
        count: 0,
    },
    itemPostedDetails: {
        results: [],
        count: 0,
    },
    dataStats: {
        results: [],
        count: 0,
    },
    jobStatus: '',
    downloadLoader: false,
};

export default handleActions(reducers, initialState);

