import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import {
    renderField,
    renderFilePicker,
    renderNumber, SelectField,
} from '../../Utils/renderField/renderField';
import {USER_ROLES_LIST} from "../../../../utility/constants";
import ChangedPasswordForm from "../../AgentList/Password/ChangedPasswordForm";
import {combine, validate, validatorFromFunction, validators} from "validate-redux-form";


let ProfileForm = (props) => {
    const { handleSubmit, me, setAvatar, is_view, onSubmit2 } = props;
    return (
        <div className="d-flex flex-column mb-5">
            <div className=" pl-1 pt-3 no-gutters row">
                <div className="mb-2">
                    <div className="header-page">{is_view ? 'Profile' : 'Edit Profile'}</div>
                </div>
            </div>
            <div className="card border-card-page py-2">
                <div className="col-sm-12 col-md-9 mx-auto">
                    <form action="" onSubmit={handleSubmit} className="py-4">
                        <div className="flex">
                            <div className="pt-3 d-flex flex-column flex-md-row">
                                <div className="flex-column flex-1">
                                    <label htmlFor="avatar" className="pl-3">Photo</label>
                                    <Field
                                        name="photo"
                                        setFile={setAvatar}
                                        disabled={is_view}
                                        placeholder={'Upload photo'}
                                        component={renderFilePicker}
                                        className={'pl-4'}
                                        photo={me.photo ? me.photo : null}
                                    />
                                </div>
                                <div className="d-flex flex-column flex-1 mx-3">
                                    <div className="form-group has-feedback">
                                        <label htmlFor="first_name">First Name</label>
                                        <Field
                                            name="first_name"
                                            placeholder="first name"
                                            component={renderField}
                                            type="text" className="form-control"
                                            disabled={is_view}
                                        />
                                    </div>
                                    <div className="form-group has-feedback ">
                                        <label htmlFor="last_name">Last Name</label>
                                        <Field
                                            name="last_name"
                                            placeholder="last name"
                                            component={renderField}
                                            type="text" className="form-control"
                                            disabled={is_view}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-md-row">
                                <div className="d-flex flex-column flex-1">
                                    <div className="form-group has-feedback mx-3">
                                        <label htmlFor="mobile_number">Mobile number</label>
                                        <Field
                                            disabled={is_view}
                                            name="mobile_number"
                                            component={renderNumber}
                                            className="form-control"
                                            placeholder="mobile number"
                                            numberFormat={"###-###-####"}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex flex-column flex-1 mx-3">
                                    <div className="form-group has-feedback">
                                        <label htmlFor="email">Email</label>
                                        <Field
                                            name="email"
                                            placeholder="Email address"
                                            component={renderField}
                                            type="text" className="form-control"
                                            disabled={is_view}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-md-row">
                                <div className="d-flex flex-column flex-1">
                                    <div className="form-group has-feedback mx-3">
                                        <label htmlFor="roles">Role in Office</label>
                                        <Field
                                            name="roles"
                                            label="roles"
                                            options={USER_ROLES_LIST}
                                            component={SelectField}
                                            disabled={true}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="d-flex flex-column flex-1 mx-3">
                                    {/*<div className="form-group has-feedback">*/}
                                    {/*    <label htmlFor="office_phone">Office phone number</label>*/}
                                    {/*    <Field*/}
                                    {/*        name="office_phone_number"*/}
                                    {/*        component={renderNumber}*/}
                                    {/*        className="form-control"*/}
                                    {/*        disabled={true}*/}
                                    {/*        placeholder="office phone number"*/}
                                    {/*        numberFormat={"###-###-####"}*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div className="row w-100 mt-3 justify-content-center mt-sm-5">
                                {!is_view &&
                                <React.Fragment>
                                    <Link to={'/'} className="btn btn-danger text-white mx-3 py-1 col-3">Cancel</Link>
                                    <button className="btn btn-primary mx-3 py-1 col-3 text-white">Save</button>
                                </React.Fragment>
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            {/*----------------------------------------------------------------------*/}
            {/*------------------   CHANGE YOUR ACCOUNT PASSWORD   ------------------*/}
            {/*----------------------------------------------------------------------*/}
            <div className="card border-card-page py-2 mt-5 col-sm-12 col-md-6 col-lg-6 mx-auto">
                <div className="card-title px-3 border-bottom pt-3">
                    <label>Change your account password</label>
                </div>
                <div className="mt-2">
                    <ChangedPasswordForm
                        onSubmit={onSubmit2}
                        item={{}}
                        isProfile={true}
                    />
                </div>
            </div>
        </div>
    );
};

const numberPhone = (number) => validatorFromFunction(() => {
    return number.length === 10;
});

export default reduxForm({
    form: 'profile', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            mobile_number: combine(
                validators.exists()('This field is required'),
                numberPhone(data.mobile_number)()('Invalid phone number'),
            ),
            office_phone_number: combine(
                validators.exists()('This field is required'),
                numberPhone(data.office_phone_number)()('Invalid phone number'),
            ),
        });
    },
})(ProfileForm);


// export default reduxForm({
//     form: 'profile', // a unique identifier for this form
// })(ProfileForm);
