import './wholeSalerRegister.css';
import React, { Component } from "react";
import LoadMask from "../Utils/LoadMask/LoadMask";
import { RegisterSuccess, StepProgressBar, SignUpInfo } from './WholeSalerRegisterComponents';
import WholeSalerInfoForm from './WholeSalerInfoForm';
import WholeSalerTeamForm from './WholeSalerTeamForm';
import WholeSalerPhoneForm from './WholeSalerPhoneForm';
import WholeSalerEmailForm from './WholeSalerEmailForm';
import WholeSalerPasswordForm from './WholeSalerPasswordForm';


class WholeSalerRegister extends Component {

    componentDidMount() {
        this.props.getTeams()
        //  Get TEAM ID BY URL
        const { match } = this.props;
        const { params } = match;
        const { team_id } = params;
        console.log("TEAM ID", team_id);
        if (team_id) {
            this.props.resetInitialData(team_id)
        } else {
            this.props.resetInitialData()
        }
    }


    render() {
        //  State
        const { loader, success, step, teams, stepBack, code, email_confirmed, phone_confirmed, disabledTeam } = this.props;

        //  Bind
        const { onSubmitStep, goBack, resendCodeEmail, resendCodePhone, validateCode, validatePhoneCode, signUp, skipTeam } = this.props;

        return (
            <div className="body-img">
                <div className="blue-gradient-bg d-flex align-items-center">
                    <div className='wholesaler-register-container'>
                        <div
                            className="wholesaler-register-wrapper"
                        >
                            <div
                                id='WHOLESALER-REGISTER'
                                className={`card wholesaler-register-card col-lg-10 col-sm-11`}
                            >
                                <div className="wholesaler-register-card-body">
                                    <div className="row flex-1">
                                        <div className="col-lg-4 col-md-3 col-12">
                                            <div className="wholesaler-register-title-page-container">
                                                <div className="wholesaler-register-titles-container mt-1 mt-lg-3">
                                                    <span className='wholesaler-register-title'>WholeSaler</span>
                                                    <span className='wholesaler-register-subtitle'>Sign Up</span>
                                                </div>
                                                <div className="wholesaler-register-logo-container mt-4 mt-md-0">
                                                    <img
                                                        src={require("../../../../assets/img/logo-01.png")}
                                                        alt={"showIt logo"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-9 col-12">
                                            <div className="wholesaler-register-form-container">
                                                <LoadMask loading={loader} light>
                                                    <div className="mb-2">
                                                        <div className="d-flex flex-row align-items-center mb-2">
                                                            <div className='wholesaler-register-back-button-container'>
                                                                {!success && step > 0 && step <= (disabledTeam ? 4 : 5) && (
                                                                    <div className='wholesaler-register-back-button' onClick={() => goBack()}>
                                                                        <img
                                                                            src={require("../../../../assets/img/chevron-left-solid.svg")}
                                                                            alt="back"
                                                                            />
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <span className='wholesaler-register-form-title'>Create an Account</span>
                                                        </div>
                                                        {!success && step <= (disabledTeam ? 4 : 5) && (
                                                            <StepProgressBar progress={step} disabledTeam={disabledTeam} />
                                                        )}
                                                    </div>

                                                    {step === 0 && (
                                                        <SignUpInfo onSubmit={onSubmitStep} />
                                                    )}

                                                    {step === 1 && (
                                                        <WholeSalerInfoForm onSubmit={() => onSubmitStep()} />
                                                    )}

                                                    {step === 2 && !disabledTeam && (
                                                        <WholeSalerTeamForm teams={teams} onSubmit={() => onSubmitStep()} skipAction={skipTeam} />
                                                    )}

                                                    {((step === 3 && !disabledTeam) || (step === 2  && disabledTeam)) && (
                                                        <WholeSalerPhoneForm
                                                            onSubmit={onSubmitStep}
                                                            validateCode={validatePhoneCode}
                                                            code={code}
                                                            stepBack={stepBack}
                                                            resendCode={resendCodePhone}
                                                            phoneConfirmed={phone_confirmed}
                                                        />
                                                    )}

                                                    {((step === 4 && !disabledTeam) || (step === 3  && disabledTeam)) && (
                                                        <WholeSalerEmailForm
                                                            onSubmit={onSubmitStep}
                                                            validateCode={validateCode}
                                                            code={code}
                                                            stepBack={stepBack}
                                                            resendCode={resendCodeEmail}
                                                            emailConfirmed={email_confirmed}
                                                        />
                                                    )}

                                                    {((step === 5 && !disabledTeam) || (step === 4  && disabledTeam)) && (
                                                        <WholeSalerPasswordForm
                                                            onSubmit={() => signUp()}
                                                        />
                                                    )}

                                                    {((step === 6 && !disabledTeam) || (step === 5  && disabledTeam)) && success && (
                                                        <RegisterSuccess />
                                                    )}
                                                </LoadMask>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default WholeSalerRegister;