import PropTypes from 'prop-types';
import React, {Component} from 'react';
import LoadMask from "../LoadMask/LoadMask";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

export default class GridTableWithScroll extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    render() {
        const {
            loading,
            data,
            page,
            remote = true,
            expandableRow,
            expandComponent,
            cellEditProp,
            afterSave,
            keyField = 'id',
            onPageChange,
            onSortChange,
            pagination,
            expanding,
            onExpand,
            trClassName,
            sizePerPage = 20,
            hidePagination,
            wrapperClasses='table-scroll-1',
            rowEvents,
            height=null,
            ...other
        } = this.props;

        return (
            <React.Fragment>
                <LoadMask loading={loading} dark blur>
                    <div className="d-flex flex-column flex-overflow-hidden-1" style={height ? {maxHeight: `${height - 30}px`}: {}}>
                        <BootstrapTable
                            wrapperClasses={wrapperClasses}
                            // classes="table-scroll-1"
                            keyField={keyField}
                            bordered={false}
                            striped={true}
                            hover={true}
                            expandableRow={expandableRow}
                            expandComponent={expandComponent}
                            noDataIndication={'No data'}
                            rowEvents={rowEvents}
                            trStyle={{cursor:'pointer'}}
                            // headerWrapperClasses={"sticky-top-header"}
                            // headerClasses={"sticky-top-header"}
                            onTableChange={(type, newState) => {
                                if (type === "filter") {
                                    // Not implemented
                                } else if (type === "pagination") {
                                    onPageChange(newState.page, newState)
                                } else if (type === "sort") {
                                    onSortChange(newState.sortField, newState.sortOrder, newState)
                                } else if (type === "cellEdit") {
                                    onPageChange(newState.page, newState)
                                }
                            }}
                            trClassName={trClassName}
                            cellEdit={cellEditProp}
                            data={loading ? [] : (data.results ? data.results : [])}
                            remote={remote}
                            pagination={hidePagination ? null :
                                paginationFactory({
                                    page,
                                    sizePerPage,
                                    hideSizePerPage: true,
                                    totalSize: data.count ? data.count : 0 || 0,
                                })
                            }
                            fetchInfo={{dataTotalSize: data.count ? data.count : 0}}
                            {...other}
                        />
                    </div>
                </LoadMask>
            </React.Fragment>
        )
    }
}
