import React, {Component} from 'react';
import ListGrid from "./ListGrid";

class List extends Component {

    componentDidMount() {
        const {toList} = this.props;
        toList();
    }

    render() {
        const {loader, page, data, searchChange, toList, remove, me} = this.props;
        return (
            <ListGrid
                loader={loader}
                data={data}
                page={page}
                toList={toList}
                searchAction={searchChange}
                remove={remove}
                me={me}
            />
        )
    }
}

export default List;
