import React, { useRef, useState, useEffect } from 'react'
import { GOOGLE_MAPS_KEY } from '../../../../utility/constants'
import { MAPS_THEME } from '../../../../utility/mapsTheme';
import GoogleMap from 'google-map-react';

const Map = ({
  children,
  map
}) => {

  return (
    <React.Fragment>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
      })}
    </ React.Fragment>
  );
};

const Marker = (options) => {
  const [marker, setMarker] = useState();

  useEffect(() => {
    if (!marker) {
      setTimeout(() => {
        setMarker(new google.maps.Marker());
      }, 800);
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

const Circle = (options) => {
  const [circle, setCircle] = useState();

  useEffect(() => {
    if (!circle) {
      setTimeout(() => {
        setCircle(new google.maps.Circle());
      }, 800);
    }

    // remove circle from map on unmount
    return () => {
      if (circle) {
        circle.setMap(null);
      }
    };
  }, [circle]);

  useEffect(() => {
    if (circle) {
      circle.setOptions(options);
    }
  }, [circle, options]);

  return null;
};

const ProfileMap = (props) => {

    const { center, radius, children } = props
    const [zoom, setZoom] = useState(5); // initial zoom
    const [radiusKm, setRadiusKm] = useState(0)
    const [map, setMap] = useState(null)

    useEffect(() => {
        if (radius > 0) {
            let _radiusKm = parseFloat(radius);
            _radiusKm = _radiusKm > 0 ? _radiusKm : 0; // default 20 miles
            _radiusKm = _radiusKm * 1609.34; // radius in meters

            setRadiusKm(_radiusKm);
            setTimeout(() => {
                const newZoom = zoomLevel(_radiusKm)
                setZoom(newZoom)
            }, 500);
        } else {
            setRadiusKm(0)
        }
    }, [radius])

    const zoomLevel = (_radius) => {
        let _zoomLevel = 15;
        if (_radius > 0) {
            const scale = _radius / 170;
            _zoomLevel = (16 - Math.log(scale) / Math.log(2))
        }

        return _zoomLevel;
    }

    return (
        <div className='rounded' style={{ flexGrow: '1', height: '100%', overflow: "hidden"}}>
            <GoogleMap
                bootstrapURLKeys={{
                    key: GOOGLE_MAPS_KEY,
                    libraries: ['drawing', 'places']
                }}
                center={center}
                zoom={zoom}
                options={{
                    styles: MAPS_THEME,
                    draggable: false,
                    panControl: false,
                    rotateControl: false,
                    scaleControl: false,
                    zoomControl: false,
                    fullscreenControl: false,
                    keyboardShortcuts: false,
                    streetViewControl: false,
                }}
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={({ map, maps }) => {
                    console.log("MAP: ", map);
                    setMap(map)

                }}
            >
                {map && <Map map={map}>
                    <Marker position={center} />
                    <Circle
                        center={center}
                        radius={radiusKm}
                        fillColor="rgba(81, 173, 207, 0.9)"
                        strokeWeight={1}
                        strokeColor={"rgba(81, 173, 207, 0)"} />
                </Map>}
            </GoogleMap>
        </div>
    )
}

const AnyReactComponent = ({ text }) => <i className="material-icons text-danger">place</i>;


export default ProfileMap


