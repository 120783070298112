import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/influencer/leadingList'
import LeadingList from "./LeadingList";

const ms2p = (state) => {
    return{
        ...state.influecerLeading,
        user: state.login ? state.login.me : null,
    };
};

const md2p = {
    ...actions
};

export default connect(ms2p, md2p)(LeadingList);