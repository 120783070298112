import React, { Component } from 'react'
import {Link} from "react-router-dom";
import {Modal} from "react-responsive-modal";
import CreateTeamForm from "./CreateTeamForm"
import SponcerGrid from "./SponcerGrid"
import {MonthlyPRofiteChart, AnnualyProfiteChart, AgentsChart} from './LeadingComponents'
class LeadingList extends Component {
    state = {
        stateModal: false,
        nameFile: '',
        active: true,
        tableHeight: 500,
        editTeam: false,
        fileName: null,
    }

    constructor(props) {
        super(props)
    }

    // componentWillMount() {
    //     console.log("WIDTH: ", document.getElementById('LEADING-CONTAINER'));
        
    // }

    async componentDidMount() {
        await this.setTableHeigh()

        setTimeout(() => {
            this.props.toList()
            this.props.getUrlExelTemplate();
            this.props.setItemToNull()
            this.props.getMonthlyProfite()
            this.props.getYearlyProfite()
        }, 400);
    }

    openModal = (edit=false, fileName=null) => {
        this.setState({stateModal: true, editTeam: edit, fileName})
    }

    closeModal = () => {
        this.setState({stateModal: false, fileName: null})
    }
    onChanged = (file) => {
        const {setExcel} = this.props;
        this.setState({
            nameFile: file.name,
            active: false,
        });
        setExcel(file);
    };

    setActive = (value) => {
        this.setState({
            nameFile: '',
            active,
        });
        const {setExcel} = this.props;
        setExcel(null);
    };

    onSubmit = (data) => {
        const {createTeam} = this.props;
        createTeam(data, this.closeModal)
    }

    setTableHeigh = () => {

        return new Promise((resolve, reject) => {
            const containerItem = document.getElementById('LEADING-CONTAINER')
            const titleItem = document.getElementById('LEADING-TITLE')
            const graphItem = document.getElementById('LEADING-GRAPH')
            let _height = 500
            if (containerItem && titleItem && graphItem) {
                _height = containerItem.clientHeight - titleItem.clientHeight - graphItem.clientHeight
                if (_height < 500) {
                    _height = 500
                }
            }

            this.setState({tableHeight: _height})
            console.log("HEIGHT: ", _height);
            resolve(_height)
        })
    }

    openEditTeam = (id) => {
        this.props.getTeam(id, (fileName) => this.openModal(true, fileName))
    }
    editTeam = (data) => {
        if (data.id) {
            this.props.updateTeam(data.id, data, this.closeModal)
        }
    }

    render() {
        //  State
        const {stateModal, nameFile, active, tableHeight, editTeam, fileName} = this.state;
        const {loader2, loader, urlTemplate, user, data, monthlyProfite, monthlyProfiteLoader, yearlyProfite, yearlyProfiteLoader, year} = this.props;
        //  Bind
        const {handleOnChangeExcel, changeYear, getTeam} = this.props;
        const submitTeam = editTeam ? this.editTeam : this.onSubmit; 

        return (
            <div id="LEADING-CONTAINER" className="d-flex flex-column flex-1">
                <div
                    id="LEADING-TITLE"
                    className="page-header pl-1 pt-3 no-gutters row w-100"
                >
                    <div>
                        <div className="header-page">Sponsor List</div>
                    </div>
                </div>

                <div className="card border-card-page px-lg-4 py-2 flex-1">
                    <div className="col-sm-12 p-0 w-100">
                        <div className="row align-items-center">
                            <div className="mx-3 my-2 shadow-sm rounded px-3 py-2">
                                <img
                                    src={
                                        data && data.extra && data.extra.logo
                                            ? data.extra.logo
                                            : require("../../../../../../assets/img/uploadLogo.PNG")
                                    }
                                    alt="logo"
                                    style={{ width: "100px" }}
                                />
                            </div>
                            {user &&
                                user.influencer_type == 100 &&
                                !user.has_team && (
                                    <div className="col-lg-9 col-sm-12 d-flex justify-content-end flex-row">
                                        <button
                                            onClick={() => this.openModal(false)}
                                            className="btn btn-primary text-white"
                                        >
                                            Create your agents team{" "}
                                            <i className="fa fa-plus"></i>
                                        </button>
                                    </div>
                                )}
                        </div>
                    </div>
                    <div className="col-sm-12 p-0 w-100 mt-3">
                        {/* <h4>Sponcer List</h4> */}
                        <SponcerGrid
                            loader={loader}
                            data={data}
                            height={tableHeight}
                            openEditTeam={this.openEditTeam}
                        />
                    </div>
                </div>
                <div id="LEADING-GRAPH" className="row my-3">
                    <div
                        className="col-lg-5 col-md-4 col-sm-12"
                        style={{ height: "inherit" }}
                    >
                        <MonthlyPRofiteChart
                            data={monthlyProfite}
                            loader={monthlyProfiteLoader}
                            year={year}
                            changeYear={changeYear}
                        />
                    </div>
                    <div
                        className="col-lg-5 col-md-4 col-sm-12"
                        style={{ height: "inherit" }}
                    >
                        <AnnualyProfiteChart
                            data={yearlyProfite}
                            loader={yearlyProfiteLoader}
                        />
                    </div>
                    <div
                        className="col-lg-2 col-md-4 col-sm-12"
                        style={{ height: "inherit" }}
                    >
                        <AgentsChart
                            total={
                                data && data.totals && data.totals.total_agents
                            }
                            registered={
                                data &&
                                data.totals &&
                                data.totals.total_agents_registered
                            }
                        />
                    </div>
                </div>

                <Modal
                    center
                    closeOnEsc
                    open={stateModal}
                    closeOnOverlayClick={false}
                    onClose={this.closeModal}
                    classNames={{
                        modal: "col-lg-7 col-sm-11 px-0 border-card-20 pt-5 pb-2",
                    }}
                >
                    <CreateTeamForm
                        onSubmit={submitTeam}
                        text={nameFile}
                        handleOnChange={this.onChanged}
                        loader2={loader2}
                        active={active}
                        setActive={this.setActive}
                        closeModal={this.closeModal}
                        urlTemplate={urlTemplate}
                        edit={editTeam}
                        fileName={fileName}
                    />
                </Modal>
            </div>
        );
    }
}

export default LeadingList